import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import map from "lodash/map";
import TextField from "shared/components/TextField";
import { intl } from "shared/hoc/Intl";
import Tabs from "shared/components/Tabs";
import Modal from "shared/components/Modal";
import { UserFilled } from "shared/components/Icons";
import { getInitialsFromName } from "shared/utils/avatar";
import formValidation from "./formValidation";
import { fetchManagers, addActivityToManager, deleteActivityFromManager } from "modules/Activities/reducers/details";
import ActivityUser from "modules/Activities/components/ActivityUser";
import "./styles.scss";
import { replaceValues } from "shared/services/lang";
export const FORM_NAME = "activities/DETAILS";

class DetailsModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen && this.props.data.id) {
      this.props.fetchManagers({ activityId: this.props.data.id });
    }
  }

  onAdd = (id) => () => {
    const { data } = this.props;
    this.props.addActivityToManager({ activityId: data.id, managerId: id });
  };

  onDelete = (id) => () => {
    const { data } = this.props;
    this.props.deleteActivityFromManager({
      activityId: data.id,
      managerId: id,
    });
  };

  filterManagers = () => {
    const { formValues, managers } = this.props;
    const search = get(formValues, "search");
    if (search) {
      return filter(managers, ({ name, surname, email }) =>
        includes(toLower(`${name} ${surname} ${email}`), toLower(search))
      );
    }
    return managers;
  };

  render() {
    const { data, lang } = this.props;
    const filteredManagers = this.filterManagers();
    const assignedManagers = filter(filteredManagers, ({ hasActivity }) => hasActivity);
    const unAssignedManagers = filter(filteredManagers, ({ hasActivity }) => !hasActivity);
    return (
      <Modal
        title={`${data.activityType}`}
        titleLeft
        subTitle={[
          <UserFilled key="details-icon" />,
          replaceValues(lang.assignedManagers, {
            managers: assignedManagers.length,
          }),
        ]}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className="ActivityDetailsModal">
          <Tabs>
            <div label={lang.assigned} id="assigned">
              <Field component={TextField} name="search" placeholder={lang.searchForManager} icon="scope" />
              {map(assignedManagers, (manager) => (
                <ActivityUser
                  label={getInitialsFromName(manager.name, manager.surname)}
                  id={manager.userId}
                  assigned
                  onAdd={this.onAdd(manager.userId)}
                  onDelete={this.onDelete(manager.userId)}
                  key={`assigned-manager-${manager.userId}`}
                  isLoading={this.props.isLoading && this.props.loadingId === manager.userId}
                >
                  {manager.name} {manager.surname}
                </ActivityUser>
              ))}
            </div>
            <div label={lang.notAssigned} id="not-assigned">
              <Field component={TextField} name="search" placeholder={lang.searchForManager} icon="scope" />
              {map(unAssignedManagers, (manager) => (
                <ActivityUser
                  label={getInitialsFromName(manager.name, manager.surname)}
                  id={manager.userId}
                  onAdd={this.onAdd(manager.userId)}
                  onDelete={this.onDelete(manager.userId)}
                  key={`unassigned-manager-${manager.userId}`}
                  isLoading={this.props.isLoading && this.props.loadingId === manager.userId}
                >
                  {manager.name} {manager.surname}
                </ActivityUser>
              ))}
            </div>
          </Tabs>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = ({ activities: { details }, ...state }) => ({
  states: details.managers.states,
  managers: details.managers.data,
  isLoading: details.addActivity.states.isLoading || details.deleteActivity.states.isLoading,
  loadingId: details.loadingId,
  formValues: getFormValues(FORM_NAME)(state),
});
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchManagers, addActivityToManager, deleteActivityFromManager }, dispatch);

export default compose(
  intl("modules.Activities.DetailsModal"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME, validate: formValidation })
)(DetailsModal);
