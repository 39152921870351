import update from "immutability-helper";
import join from "lodash/join";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const REQUESTS_CREATE = createRequestTypes("requests/CREATE");
export const ASSIGN_MANAGER = createRequestTypes("requests/ASSIGN_MANAGER");
export const REQUESTS_CLEAN_DATA = "requests/CLEAN_DATA";

export const cleanCreateRequestData = () => ({
  type: REQUESTS_CLEAN_DATA,
});

export const performAssignManager = ({ id }) => ({
  type: ASSIGN_MANAGER.REQUEST,
  payload: {
    id,
  },
});

export const performRequestCreation = ({
  contractorDetails,
  createdAt,
  contractorCompany,
  projectName,
  contractorReference,
  clientReference,
  suggestedDateBegin,
  suggestedDateEnd,
  safety,
  witness,
  subSubcontractorName,
  location,
  equipment,
  equipmentParts,
  equipmentPartsNumber,
  operationDesignation,
  followupDocumentRevisionNumber,
  phaseNumber,
  otherContactInformation,
  filesList,
  mailingList,
  activityId,
  status,
  id,
  sendToManager,
  thirdParties,
  isDraft,
}) => {
  return {
    type: REQUESTS_CREATE.REQUEST,
    isDraft,
    id,
    sendToManager,
    status,
    payload: {
      contractorDetails,
      createdAt,
      contractorCompany,
      projectName,
      contractorReference,
      clientReference,
      suggestedDateBegin,
      suggestedDateEnd,
      safety,
      witness,
      subSubcontractorName,
      activityId,
      locationLat: location.lat,
      locationLong: location.lng,
      county: location.county,
      locationText: location.name,
      equipment,
      equipmentParts,
      equipmentPartsNumber,
      operationDesignation,
      followupDocumentRevisionNumber,
      phaseNumber,
      otherContactInformation,
      filesList, //: convertFiles(filesList),
      mailingList: join(mailingList, ";"),
      thirdParties: thirdParties
        ? JSON.stringify(
            thirdParties.map((tp) => {
              return {
                name: tp.name,
                witness: tp.witness === "true" || tp.witness === true || tp.witness === undefined ? true : false,
              };
            })
          )
        : JSON.stringify([]),
    },
  };
};

const initState = {
  states: loadingStates,
  response: null,
  assignManager: {
    states: loadingStates,
    data: {},
    response: null,
  },
  draft: {
    data: {},
    states: loadingStates,
  },
  deleteRequest: {
    states: loadingStates,
    response: null,
  },
};

export default createReducer(initState, {
  [REQUESTS_CLEAN_DATA]: (state) => {
    return update(state, {
      $set: initState,
    });
  },
  [ASSIGN_MANAGER.REQUEST]: (state) => {
    return update(state, {
      assignManager: {
        $merge: {
          response: null,
          data: {},
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ASSIGN_MANAGER.SUCCESS]: (state, action) => {
    return update(state, {
      assignManager: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [ASSIGN_MANAGER.FAILURE]: (state, action) => {
    return update(state, {
      assignManager: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_CREATE.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
        assignManager: {
          response: null,
          data: {},
          states: loadingStates,
        },
      },
    });
  },
  [REQUESTS_CREATE.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
      },
    });
  },
  [REQUESTS_CREATE.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
