import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const ABSENCE_CREATE = createRequestTypes("shared/absence/ABSENCE_CREATE");

export const ABSENCE_UPDATE = createRequestTypes("shared/absence/ABSENCE_UPDATE");

export const ABSENCE_DELETE = createRequestTypes("shared/absence/ABSENCE_DELETE");

export const ABSENCE_FETCH_INSPECTOR = createRequestTypes("shared/absence/ABSENCE_FETCH_INSPECTOR");

export const ABSENCE_FETCH_MANAGER = createRequestTypes("shared/absence/ABSENCE_FETCH_MANAGER");

export const absenceCreate = ({ absence }) => ({
  type: ABSENCE_CREATE.REQUEST,
  payload: { absence },
});

export const absenceUpdate = ({ absence }) => ({
  type: ABSENCE_UPDATE.REQUEST,
  payload: { absence },
});

export const absenceDelete = (absenceId) => ({
  type: ABSENCE_DELETE.REQUEST,
  payload: absenceId,
});

export const absencesFetchInspector = () => ({
  type: ABSENCE_FETCH_INSPECTOR.REQUEST,
});

export const absencesFetchManager = () => ({
  type: ABSENCE_FETCH_MANAGER.REQUEST,
});

const initState = {
  createUpdateDelete: {
    states: loadingStates,
    data: {},
    errMsg: {},
  },
  fetch: {
    states: loadingStates,
    data: {},
    errMsg: {},
  },
};

export default createReducer(initState, {
  [ABSENCE_CREATE.REQUEST]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_CREATE.SUCCESS]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_CREATE.FAILURE]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          errMsg: action.payload,
        },
      },
    });
  },
  [ABSENCE_UPDATE.REQUEST]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_UPDATE.SUCCESS]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_UPDATE.FAILURE]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          errMsg: action.payload,
        },
      },
    });
  },
  [ABSENCE_DELETE.REQUEST]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ABSENCE_DELETE.SUCCESS]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [ABSENCE_DELETE.FAILURE]: (state, action) => {
    return update(state, {
      createUpdateDelete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          errMsg: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_INSPECTOR.REQUEST]: (state, action) => {
    state.fetch.data = {};
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_INSPECTOR.SUCCESS]: (state, action) => {
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_INSPECTOR.FAILURE]: (state, action) => {
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          errMsg: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_MANAGER.REQUEST]: (state, action) => {
    state.fetch.data = {};
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_MANAGER.SUCCESS]: (state, action) => {
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ABSENCE_FETCH_MANAGER.FAILURE]: (state, action) => {
    return update(state, {
      fetch: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          errMsg: action.payload,
        },
      },
    });
  },
});
