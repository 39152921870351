import React, { PureComponent } from "react";
import Translated, { langLabel } from "shared/components/Translated";
import RequestDetailsRow from "shared/components/RequestDetailsRow";
import RequestDetailsRowSeparator from "shared/components/RequestDetailsRowSeparator";
import SelectField from "shared/components/SelectField";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { intl } from "shared/hoc/Intl";
import { ROLE_MANAGER, ROLE_INSPECTOR, ROLE_CONTRACTOR } from "shared/constants/auth";
import { performFetchRequestFeedback, modifyRequestFeedback } from "shared/reducers/requests/requestDetails";
import { renderQuill } from "shared/components/redux-form-react-quill";
import Notification from "shared/components/Notification";
import Button from "shared/components/Button";
import "./styles.scss";

const FORM_NAME = "REQUEST_DETAIL_FEEDBACK";
class RequestDetailsFeedback extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here
    // this.onCommentChange = this.onCommentChange.bind(this);
    this.requestId = props.requestId;
    this.initialValues = {};
  }

  componentDidMount() {
    this.props.performFetchRequestFeedback({ id: this.requestId });
  }

  // The value are defined in com.mr.backend.request.feedback.RequestFeedbackStatus
  // NOT_SPECIFIED, ADEQUATE, NOT_TOTALLY_ADEQUATE, NON_ADEQUATE;
  getOptions = (props) => {
    return [
      { label: langLabel("request.modal.feedbackDropDown.notSpecified", props), value: "NOT_SPECIFIED" },
      { label: langLabel("request.modal.feedbackDropDown.adequate", props), value: "ADEQUATE" },
      { label: langLabel("request.modal.feedbackDropDown.notTotallyAdequate", props), value: "NOT_TOTALLY_ADEQUATE" },
      { label: langLabel("request.modal.feedbackDropDown.nonAdequate", props), value: "NON_ADEQUATE" },
    ];
  };

  saveFeedback = (values) => {
    if (values.requestId === undefined || values.requestId == null) values.requestId = this.requestId;
    this.props.modifyRequestFeedback(values);
  };

  render() {
    const {
      userRole,
      isLoading,
      isLoaded,
      hasError,
      handleSubmit,
      response,
      submitSucceeded,
      submitFailed,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.saveFeedback)}>
        <div>
          <RequestDetailsRow
            label={<Translated path="request.modal.feedbackAdvice" shared />}
            value={
              <span>
                <Field
                  component={SelectField}
                  dataSource={this.getOptions(this.props)}
                  name="notice"
                  disabled={userRole === ROLE_CONTRACTOR}
                  noMargin
                />
              </span>
            }
          />
          <div>
            <RequestDetailsRowSeparator />
          </div>
          {(userRole === ROLE_MANAGER || userRole === ROLE_INSPECTOR) && (
            <div>
              <div>
                <RequestDetailsRow label={<Translated path="request.modal.feedbackComment" shared />} value="" />
                {/* <ReactQuill value={this.state.text} onChange={this.onCommentChange} /> */}
                <Field name="comment" component={renderQuill} />
              </div>
              {(submitSucceeded || submitFailed || hasError) && (
                <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
                  {<Translated path={response} />}
                </Notification>
              )}
              <div className="divButton">
                <Button
                  type="primary"
                  isLoading={isLoading}
                  name=""
                  className="RequestsCreateButtons__button"
                  icon="save"
                >
                  <Translated path="buttons.save" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const filterInitialValues = (data) => {
  if (data.notice === undefined || data.notice == null) data.notice = "NOT_SPECIFIED";
  return data;
};

const mapStateToProps = (shared) => ({
  feedback: shared.shared.requestDetails.feedback,
  isLoaded: shared.shared.requestDetails.feedback.states.isLoaded,
  isLoading: shared.shared.requestDetails.feedback.states.isLoading,
  hasError: shared.shared.requestDetails.feedback.states.hasError,
  response: shared.shared.requestDetails.feedback.response,
  initialValues: filterInitialValues(shared.shared.requestDetails.feedback.feedbackData),
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ performFetchRequestFeedback, modifyRequestFeedback }, dispatch);

export default compose(
  intl("request"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(RequestDetailsFeedback);
