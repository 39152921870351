import { validateEmail } from "shared/utils/validation";

export default ({ name, email }) => {
  const errors = {};
  if (!name) {
    errors["name"] = "FILL_IN";
  }
  if (!email) {
    errors["email"] = "FILL_IN";
  }
  if (!validateEmail(email)) {
    errors["email"] = "WRONG_EMAIL_FORMAT";
  }
  return errors;
};
