import update from "immutability-helper";
import map from "lodash/map";
import includes from "lodash/includes";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const FETCH_NOTIFICATIONS = createRequestTypes("app/FETCH_NOTIFICATIONS");

export const MARK_ALL_NOTIFICATIONS_AS_READ = createRequestTypes("app/MARK_ALL_NOTIFICATIONS_AS_READ");

export const fetchNotifications = () => ({
  type: FETCH_NOTIFICATIONS.REQUEST,
});

export const markAllAsRead = ({ ids }) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ.REQUEST,
  payload: { ids },
});

const initState = {
  states: loadingStates,
  data: [],
  response: null,
};

export default createReducer(initState, {
  [MARK_ALL_NOTIFICATIONS_AS_READ.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        data: map(state.data, (notification) => ({
          ...notification,
          displayed: includes(action.payload.ids, notification.id) || notification.displayed,
        })),
      },
    });
  },
  [FETCH_NOTIFICATIONS.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [FETCH_NOTIFICATIONS.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
        data: action.payload.data,
      },
    });
  },
  [FETCH_NOTIFICATIONS.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
