import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COMPANIES_EDIT } from "modules/Companies/reducers/edit/";
import { fetchCompaniesList } from "modules/Companies/reducers/list/";

function* editCompany(action) {
  try {
    const { data } = yield apiService({}).put(`/company/${action.payload.id}`, {
      companyType: action.payload.companyType,
    });
    yield put({ type: COMPANIES_EDIT.SUCCESS, payload: data });
    yield put(fetchCompaniesList());
  } catch (err) {
    yield put({ type: COMPANIES_EDIT.FAILURE, payload: getError(err) });
  }
}

function* editCompanySaga() {
  yield takeEvery(COMPANIES_EDIT.REQUEST, editCompany);
}

export default [editCompanySaga];
