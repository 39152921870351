import React, { PureComponent } from "react";
import { reduxForm, getFormValues } from "redux-form";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "assets/icons/logo_gray.svg";
import Translated from "shared/components/Translated";
import RotateLoader from "shared/components/RotateLoader";
import { intl } from "shared/hoc/Intl";
import { setLang } from "shared/reducers/lang";
// import { fetchActivitiesList } from './reducers/activities';
import { fetchActivitiesList } from "shared/reducers/activities";
import { performTokenValidation } from "./reducers/verify";
import { performRegistration } from "./reducers/create";
import Form from "./components/Form";
import "./styles.scss";

export const FORM_NAME = "user/register";
class Register extends PureComponent {
  submit = () => {
    this.props.performRegistration({
      ...this.props.formValues,
      token: this.props.match.params.token,
      email: this.props.user.email,
    });
  };

  componentDidMount() {
    const { invalidToken } = this.props;
    if (invalidToken) {
      return;
    }
    const { token } = this.props.match.params;
    this.props.fetchActivitiesList({ token });
    this.props.performTokenValidation({ token });
  }

  componentDidUpdate() {}
  changeLanguage = (lang) => () => this.props.setLang({ lang });
  render() {
    const { handleSubmit, activities, invalidToken, isVerifying, user, states, isLogingIn } = this.props;

    return (
      <div className="Register">
        <div className="Register__lang">
          <span className="Register__lang__item" onClick={this.changeLanguage("en")}>
            EN
          </span>
          <span className="Register__lang__item" onClick={this.changeLanguage("fr")}>
            FR
          </span>
        </div>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="row justify-content-center">
                  <div className="col-md-12 Register__title">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <Logo />
                      <h1>
                        <Translated path="title" />
                      </h1>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {isVerifying && (
                      <div className="d-flex align-items-center justify-content-center">
                        <RotateLoader relative />
                      </div>
                    )}
                    {!invalidToken && !isVerifying && (
                      <Form activities={activities} userRole={user.role} isLoading={states.isLoading || isLogingIn} />
                    )}
                    {invalidToken && <div>Invalid token</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export const mapStateToProps = ({ shared, register: { verify, create }, auth, ...state }, { match }) => {
  return {
    activities: shared.activities.data.rows,
    formValues: getFormValues(FORM_NAME)(state) || {},
    verify: verify.response,
    isLogingIn: auth.login.states.isLoading,
    isVerifying: verify.states.isLoading,
    states: create.states,
    user: verify.user,
    invalidToken: !match.params.token || verify.response === false,
  };
};
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActivitiesList,
      performTokenValidation,
      performRegistration,
      setLang,
    },
    dispatch
  );
export default compose(
  intl("modules.Register"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(Register);
