import React, { PureComponent } from "react";
import size from "lodash/size";
import Notification from "shared/components/Notification";
import Button from "shared/components/Button";
import Translated from "shared/components/Translated";
import "./styles.scss";

class CreateButtons extends PureComponent {
  render() {
    const {
      formErrors,
      submitFailed,
      loadingStates,
      isSaving,
      response,
      onCancel,
      submitSucceeded,
      assignManager,
      onPrintPdf,
    } = this.props;
    const showError = size(formErrors) && submitFailed;
    const formSubmitted = submitSucceeded || submitFailed;
    const hasResponse = loadingStates.hasError || loadingStates.isLoaded;

    return (
      <div className="row">
        <div className="col-md-12 justify-content-end d-flex">
          {!!showError && (
            <span className="RequestsCreateButtons__error">
              <Translated path="formErrors.checkForErrors" />
            </span>
          )}
        </div>
        <div className="col-md-6">
          {onPrintPdf && (
            <Button buttonType="button" onClick={onPrintPdf}>
              PDF
            </Button>
          )}
        </div>
        <div className="col-md-6 justify-content-end d-flex">
          <Button
            className="RequestsCreateButtons__button--mr RequestsCreateButtons__button"
            buttonType="button"
            onClick={onCancel}
          >
            <Translated path="buttons.cancel" />
          </Button>
          <Button
            type="primary"
            isLoading={isSaving}
            disabled={isSaving}
            name=""
            className="RequestsCreateButtons__button"
          >
            <Translated path="buttons.sendToManager" />
          </Button>
        </div>
        <div className="col-md-12">
          {!isSaving && hasResponse && formSubmitted && (
            <Notification {...loadingStates}>{<Translated path={`responses.${response}`} />}</Notification>
          )}
          {!isSaving && assignManager.response && formSubmitted && (
            <Notification {...assignManager.states}>
              {<Translated path={`responses.${assignManager.response}`} />}
            </Notification>
          )}
        </div>
      </div>
    );
  }
}
export default CreateButtons;
