import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import reducer from "./reducers";
import mySaga from "./sagas";
import authMiddleware from "shared/middlewares/auth";
import langMiddleware from "shared/middlewares/lang";
import documentTitle from "shared/middlewares/document";

export const history = createBrowserHistory();
const enhancers = [];
const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}
const composedEnchancers = compose(
  applyMiddleware(routerMiddleware(history), langMiddleware, authMiddleware, documentTitle, sagaMiddleware),
  ...enhancers
);

export const store = createStore(reducer(history), {}, composedEnchancers);
mySaga.forEach((saga) => sagaMiddleware.run(saga));

export const getGlobalState = () => {
  return store.getState();
};
