export const VOID_COUNTY = "00";
// Counties are stored with 2 characters in DB and are the beginning of the postal code, to ease the comparison functions the 9 first county are duplicated, examples: 1 & 01 ...
export const LIST_COUNTY = [
  "1",
  "01",
  "2",
  "02",
  "3",
  "03",
  "4",
  "04",
  "5",
  "05",
  "6",
  "06",
  "7",
  "07",
  "8",
  "08",
  "9",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
];
// DOM are not used "971", "972", "973", "974", "976"
/*
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "VG",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "CV",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CK",
  "CR",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "CD",
  "DK",
  "DJ",
  "DM",
  "DO",
  "TL",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "CI",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "XK",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "AN",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "KP",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "CG",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "CS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "VI",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UM",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW"
];
*/
// DOM are not used "971", "972", "973", "974", "976"
/*
The countries codes use the ISO 3166 ALPHA-2 standard : https://laendercode.net/fr/countries.html
Afghanistan : AF
Îles Aland : AX
Albanie : AL
Algérie : DZ
Samoa américaines : AS
Andorre : AD
Angola : AO
Anguilla : AI
Antarctique : AQ
Antigua-et-Barbuda : AG
Argentine : AR
Arménie : AM
Aruba : AW
Australie : AU
Autriche : AT
Azerbaïdjan : AZ
Bahamas : BS
Bahreïn : BH
Bangladesh : BD
Barbades : BB
Biélorussie : BY
Belgique : BE
Belize : BZ
Bénin : BJ
Bermudes : BM
Bhoutan : BT
Bolivie : BO
Saba, Saint-Eustache et Bonaire : BQ
Bosnie-Herzégovine : BA
Botswana : BW
Île Bouvet : BV
Brésil : BR
Territoire Britannique de l'Océan Indien : IO
Îles Vierges britanniques : VG
Brunei : BN
Bulgarie : BG
Burkina Faso : BF
Burundi : BI
Cambodge : KH
Cameroun : CM
Canada : CA
Cap vert : CV
Îles Caïmans : KY
République centrafricaine : CF
Tchad : TD
Chili : CL
Chine : CN
Îles Christmas : CX
Îles Cocos : CC
Colombie : CO
Comores : KM
Îles Cook : CK
Costa Rica : CR
Croatie : HR
Cuba : CU
Curaçao : CW
Chypre : CY
République Tchèque : CZ
République démocratique du Congo : CD
Danemark : DK
Djibouti : DJ
Dominique : DM
République Dominicaine : DO
Timor oriental : TL
Équateur : EC
Égypte : EG
Le Salvador : SV
Guinée équatoriale : GQ
Érythrée : ER
Estonie : EE
Éthiopie : ET
Îles Falkland : FK
Îles Féroé : FO
Fiji : FJ
Finlande : FI
France : FR
Guyane française : GF
Polynésie française : PF
Terres australes et antarctiques françaises : TF
Gabon : GA
Gambie : GM
État de Géorgie : GE
Allemagne : DE
Ghana : GH
Gribraltar : GI
Grèce : GR
Groenland : GL
Grenade : GD
Guadeloupe : GP
Guam : GU
Guatémala : GT
Guernesey : GG
Guinée : GN
Guinée-Bissau : GW
Guyane : GY
Haïti : HT
Îles Heard et îles MacDonald : HM
Honduras : HN
Hong Kong : HK
Hongrie : HU
Islande : IS
Inde : IN
Indonésie : ID
Iran : IR
Irak : IQ
Irlande : IE
Île de Man : IM
Israël : IL
Italie : IT
Côte-d'Ivoire : CI
Jamaïque : JM
Japon : JP
Jersey : JE
Jordanie : JO
Kazakhstan : KZ
Kenya : KE
Kiribati : KI
Kosovo : XK
Koweït : KW
Kirghizistan : KG
Laos : LA
Lettonie : LV
Liban : LB
Lesotho : LS
Libéria : LR
Libye : LY
Liechtenstein : LI
Lituanie : LT
Luxembourg : LU
Macao : MO
Macédoine : MK
Madagascar : MG
Malawi : MW
Malaysie : MY
Maldives : MV
Mali : ML
Malte : MT
Îles Marshall : MH
Martinique : MQ
Mauritanie : MR
Île Maurice : MU
Mayotte : YT
Mexique : MX
Micronésie : FM
Moldavie : MD
Monaco : MC
Mongolie : MN
Monténégro : ME
Montserrat : MS
Maroc : MA
Mozambique : MZ
Myanmar : MM
Namibie : NA
Nauru : NR
Népal : NP
Pays-Bas : NL
Antilles néerlandaises : AN
Nouvelle-Calédonie : NC
Nouvelle Zélande : NZ
Nicaragua : NI
Niger : NE
Nigéria : NG
Niue : NU
Île Norfolk : NF
Corée du Nord : KP
Îles Mariannes du Nord : MP
Norvège : NO
Oman : OM
Pakistan : PK
Palaos : PW
Territoire Palestinien : PS
Panama : PA
Papouasie-Nouvelle-Guinée : PG
Paraguay : PY
Pérou : PE
Philippines : PH
Îles Pitcairn : PN
Pologne : PL
Portugal : PT
Puerto Rico : PR
Qatar : QA
République du Congo : CG
La Réunion : RE
Roumanie : RO
Russie : RU
Rwanda : RW
Saint Barthélemy : BL
Sainte-Hélène : SH
Saint-Kitts-et-Nevis : KN
Sainte-Lucie : LC
Saint-Martin : MF
Saint Pierre et Miquelon : PM
Saint-Vincent-et-les-Grenadines : VC
Samoa : WS
San Marin : SM
Sao Tomé-et-Principe : ST
Arabie Saoudite : SA
Sénégal : SN
Serbie : RS
Serbie et Montenegro : CS
Seychelles : SC
Sierra Leone : SL
Singapour : SG
Saint-Martin : SX
Slovaquie : SK
Slovénie : SI
Îles Salomon : SB
Somalie : SO
Afrique du Sud : ZA
Géorgie du Sud et les îles Sandwich : GS
Corée du Sud : KR
Soudan du Sud : SS
Espagne : ES
Sri Lanka : LK
Soudan : SD
Surinam : SR
Svalbard et Jan Mayen : SJ
Swaziland : SZ
Suède : SE
Suisse : CH
Syrie : SY
Taiwan : TW
Tajikistan : TJ
Tanzanie : TZ
Thaïlande : TH
Togo : TG
Tokelau : TK
Tonga : TO
Trinité et Tobago : TT
Tunisie : TN
Turquie : TR
Turkménistan : TM
Îles Turques et Caïques : TC
Tuvalu : TV
Îles Vierges des États-Unis : VI
Ouganda : UG
Ukraine : UA
Émirats Arabes Unis : AE
Royaume-Uni : GB
États-Unis (USA) : US
Îles Mineures éloignées des États-Unis : UM
Uruguay : UY
Ouzbékistan : UZ
Vanuatu : VU
Vatican : VA
Vénézuela : VE
Vietnam : VN
Wallis et Futuna : WF
Sahara occidental : EH
Yemen : YE
Zambie : ZM
Zimbabwe : ZW
*/
