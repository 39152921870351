import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COMPANIES_DELETE } from "modules/Companies/reducers/remove/";
import { fetchCompaniesList } from "modules/Companies/reducers/list";

function* deleteCompany(action) {
  try {
    const { data } = yield apiService({}).delete(`/company/${action.payload.id}`);
    yield put({ type: COMPANIES_DELETE.SUCCESS, payload: data });
    yield put(fetchCompaniesList());
  } catch (err) {
    yield put({ type: COMPANIES_DELETE.FAILURE, payload: getError(err) });
  }
}

function* deleteCompanySaga() {
  yield takeEvery(COMPANIES_DELETE.REQUEST, deleteCompany);
}

export default [deleteCompanySaga];
