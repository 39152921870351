import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const GET_AN_ICS_URL = createRequestTypes("planning/GET_AN_ICS_URL");

export const REMOVE_ICS_URL = createRequestTypes("planning/REMOVE_ICS_URL");

export const CREATE_ICS_URL = createRequestTypes("planning/CREATE_ICS_URL");

export const getIcsUrl = () => ({
  type: GET_AN_ICS_URL.REQUEST,
});

export const removeIcsUrl = () => ({
  type: REMOVE_ICS_URL.REQUEST,
});

export const createIcsUrl = () => ({
  type: CREATE_ICS_URL.REQUEST,
});

// const initState = {
//   getIcsUrl: {
//     states: loadingStates,
//     data: ""
//   },
//   removeIcsUrl: {
//     states: loadingStates
//   },
//   createIcsUrl: {
//     states: loadingStates
//   }
// };

const initState = {
  data: "",
  getIcsUrl: {
    states: loadingStates,
  },
  removeIcsUrl: {
    states: loadingStates,
  },
  createIcsUrl: {
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [GET_AN_ICS_URL.REQUEST]: (state, action) => {
    return update(state, {
      $merge: { data: "" },
      getIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [GET_AN_ICS_URL.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: { data: action.payload },
      getIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          // data: action.payload
        },
      },
    });
  },
  [GET_AN_ICS_URL.FAILURE]: (state, action) => {
    return update(state, {
      $merge: { data: "" },
      getIcsUrl: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [REMOVE_ICS_URL.REQUEST]: (state, action) => {
    return update(state, {
      removeIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REMOVE_ICS_URL.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: { data: "" },
      removeIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [REMOVE_ICS_URL.FAILURE]: (state, action) => {
    return update(state, {
      removeIcsUrl: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [CREATE_ICS_URL.REQUEST]: (state, action) => {
    return update(state, {
      $merge: { data: "" },
      createIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [CREATE_ICS_URL.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: { data: action.payload },
      createIcsUrl: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [CREATE_ICS_URL.FAILURE]: (state, action) => {
    return update(state, {
      $merge: { data: "" },
      createIcsUrl: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
});
