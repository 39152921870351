import filter from "lodash/filter";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { createRequestTypes } from "shared/utils/request";
import { loadingStates, setLoadingStates } from "shared/constants/redux";

export const USERS_FETCH = createRequestTypes("users/FETCH_USERS");
export const DELETE_USER_FROM_LIST = "users/DELETE_USER_FROM_LIST";
export const USERS_FILTER_CLEAR = "USERS_FILTER_CLEAR";

export const userClearFilter = () => ({
  type: USERS_FILTER_CLEAR,
});

export const deleteUserFromList = ({ id }) => ({
  type: DELETE_USER_FROM_LIST,
  payload: {
    id,
  },
});

export const performUserFetch = ({ pager, query, sort, filter }) => ({
  type: USERS_FETCH.REQUEST,
  payload: {
    filter,
    pager,
    query,
    sort,
  },
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  filters: {
    filter: {
      role: "",
      status: "",
    },
    pager: {
      page: 1,
      limit: 10,
    },
    query: "",
    sort: {
      field: "email",
      direction: "DESC",
    },
  },
  response: null,
};
const checkIfExists = (val) => !!(identity(val) || val === "");

export default createReducer(initState, {
  [USERS_FILTER_CLEAR]: (state, action) => {
    return initState;
  },
  [DELETE_USER_FROM_LIST]: (state, action) => {
    return update(state, {
      data: {
        $merge: {
          rows: filter(state.data.rows, (user) => user.id !== action.payload.id),
        },
      },
    });
  },
  [USERS_FETCH.REQUEST]: (state, action) => {
    const filters = update(state.filters, {
      $merge: {
        ...pickBy(action.payload, checkIfExists),
      },
    });
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
        filters,
      },
    });
  },
  [USERS_FETCH.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [USERS_FETCH.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
