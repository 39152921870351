import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const SHARED_REQUESTS_FETCH_LIST_DETAILS = createRequestTypes(
  "shared/request/SHARED_REQUESTS_FETCH_LIST_DETAILS"
);

export const performFetchRequestsListWithDetails = () => ({
  type: SHARED_REQUESTS_FETCH_LIST_DETAILS.REQUEST,
  payload: {},
});

const initState = {
  states: loadingStates,
  response: null,
  data: {},
};

export default createReducer(initState, {
  [SHARED_REQUESTS_FETCH_LIST_DETAILS.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [SHARED_REQUESTS_FETCH_LIST_DETAILS.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [SHARED_REQUESTS_FETCH_LIST_DETAILS.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
