import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import "./styles.scss";

class AppLoader extends PureComponent {
  render() {
    const { children, isLoading, isLoaded } = this.props;
    return (
      <div className="AppLoader">
        {isLoading && <div className="AppLoader__loader" />}
        {isLoaded && <div className="AppLoader__content">{children}</div>}
      </div>
    );
  }
}
const mapStateToProps = ({ lang }) => ({
  isLoading: lang.states.isLoading,
  isLoaded: lang.states.isLoaded,
  hasError: lang.states.hasError,
});
export default compose(withRouter, connect(mapStateToProps))(AppLoader);
