import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COMPANIES_FETCH_LIST } from "modules/Companies/reducers/list/";

function* fetchCompanies() {
  try {
    const { data } = yield apiService({}).get("/company");
    // const { admins } = yield apiService({}).get('/company');
    // const data = companies;
    yield put({
      type: COMPANIES_FETCH_LIST.SUCCESS,
      payload: {
        rows: data,
        total: data.length,
      },
    });
  } catch (err) {
    yield put({ type: COMPANIES_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

function* fetchCompaniesSaga() {
  yield takeEvery(COMPANIES_FETCH_LIST.REQUEST, fetchCompanies);
}

export default [fetchCompaniesSaga];
