import React from "react";
import PropTypes from "prop-types";
import Translated from "shared/components/Translated";
import "./styles.scss";

const FieldError = ({ meta }) => {
  if (!(meta.error && meta.touched)) {
    return null;
  }
  return (
    <div className="FieldError">
      <Translated path={`formErrors.${meta.error}`} />
    </div>
  );
};

FieldError.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

export default FieldError;
