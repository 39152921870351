import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COUNTIES_DELETE } from "modules/Counties/reducers/remove/";
import { fetchCountiesList } from "modules/Counties/reducers/list";
import { fetchDefaultCounty } from "modules/Counties/reducers/defaultCounty";

function* deleteCounty(action) {
  try {
    const { data } = yield apiService({}).delete(`/county-rule/${action.payload.id}`);
    yield put({ type: COUNTIES_DELETE.SUCCESS, payload: data });
    const {
      counties: {
        list: { filters },
      },
    } = yield select();
    yield put(fetchCountiesList(filters));
    yield put(fetchDefaultCounty());
  } catch (err) {
    yield put({ type: COUNTIES_DELETE.FAILURE, payload: getError(err) });
  }
}

function* deleteCountySaga() {
  yield takeEvery(COUNTIES_DELETE.REQUEST, deleteCounty);
}

export default [deleteCountySaga];
