import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import queryString from "qs";
import ceil from "lodash/ceil";
import forEach from "lodash/forEach";
import map from "lodash/map";
import includes from "lodash/includes";
import { intl } from "shared/hoc/Intl";
import Translated, { langLabel } from "shared/components/Translated";
import Pagination from "shared/components/Pagination";
import ListHeader from "shared/components/ListHeader";
import { routes } from "shared/constants/routes";
import { Table, Thead, Tbody, Tr, Td, Th } from "shared/components/Table";
import { listDate } from "shared/services/date";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_PENDING_MANAGER,
  REQUEST_STATUS_ASSIGNED_AS_DRAFT,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_STATUS_ASSIGNED,
} from "shared/constants/request";
import { Pencil, Duplicate, Trash } from "shared/components/Icons";
import RequestDetails from "shared/containers/RequestDetails";
import { ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";
import { requestClearFilter, performRequestFetch } from "modules/Requests/reducers/list";
import { deleteRequest } from "shared/reducers/requests/requestDetails";
import ListSearch from "modules/Requests/containers/ListSearch";
import ButtonExcelExport from "shared/components/Button/ExcelExport";
import { doRequestsLists } from "shared/utils/multiDataSetForExcelExport.js";
import { performFetchRequestsListWithDetails } from "shared/reducers/requests/requestsListWithDetails";
import { fetchActivitiesList } from "shared/reducers/activities";
import ConfirmModal from "shared/components/ConfirmModal";

import "./styles.scss";

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deleteModal: false,
      mySelectedId: -1,
      requestDetailsModal: !!props.match.params.id,
      modalData: {
        id: props.match.params.id,
      },
    };
  }

  componentDidMount() {
    const filters = {};
    const parsed = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    forEach(parsed, (value, key) => {
      filters[key] = value;
    });
    this.props.performRequestFetch(filters);
    // All these fetch are for the Excel Export
    this.props.fetchActivitiesList();
    this.props.performFetchRequestsListWithDetails();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.match.params.id && this.props.match.params.id && !this.state.requestDetailsModal) {
      this.toggleModal("requestDetailsModal", {
        id: this.props.match.params.id,
      })();
    }
  }

  componentWillUnmount() {
    this.props.requestClearFilter();
  }

  toggleModal = (key, modalData = {}) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (key === "deleteModal" && this.state.deleteModal === true) {
      this.setState({ mySelectedId: -1, deleteModal: false });
    }
    if (key === "requestDetailsModal") {
      if (this.state.requestDetailsModal === false && 0 !== Object.keys(modalData).length) {
        this.setState({ requestDetailsModal: true, modalData });
        this.props.history.push(routes.requests.details(modalData.id).path);
      } else {
        this.setState({ requestDetailsModal: false, modalData });
        this.props.history.push(routes.requests.list.path);
      }
    }
  };

  onSort = (key) => (value) => {
    const {
      filters: { sort },
    } = this.props;
    const newSort = {
      field: key,
      direction: "DESC",
    };
    if (sort.direction === "DESC" && key === sort.field) {
      newSort.direction = "ASC";
    }
    if (sort.direction === "ASC" && key === sort.field) {
      newSort.direction = "DESC";
    }
    this.props.performRequestFetch({ sort: newSort });
  };

  onPageChange = (page) => {
    this.props.performRequestFetch({
      pager: { page, limit: this.props.filters.pager.limit },
    });
  };

  goToCreate = () => {
    this.props.history.push(routes.requests.create.path);
  };

  onRowClick = (request) => () => {
    this.toggleModal("requestDetailsModal", {
      id: request.id,
    })();
  };

  goToEdit = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(routes.requests.draft(id).path);
  };

  goToDuplicate = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(routes.requests.duplicate(id).path);
  };

  onClickDelete = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ mySelectedId: id, deleteModal: true });
  };

  deleteDraft = () => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.toggleModal("deleteModal")();
    this.props.deleteRequest({ id: this.state.mySelectedId });
  };

  deleteWithId = (id) => {
    return this.deleteDraft({ id: id });
  };

  render() {
    const { isLoading, isDeleting, filters, data, total, pages, role } = this.props;

    return (
      <div className="RequestsList">
        <ConfirmModal
          title={<Translated path="modal.areYouSure" shared />}
          isLoading={isDeleting}
          isOpen={this.state.deleteModal}
          paragraph={<Translated path="request.modal.deleteParagraph" shared />}
          onClose={this.toggleModal("deleteModal")}
          onConfirm={this.deleteDraft()}
          confirmLabel={<Translated path="buttons.delete" shared />}
          cancelLabel={<Translated path="buttons.cancel" shared />}
        />
        <RequestDetails
          className="requestDetails"
          id={this.state.modalData.id}
          isOpen={this.state.requestDetailsModal}
          onClose={this.toggleModal("requestDetailsModal")}
          history={this.props.history}
        />

        <div className="container">
          <ListHeader>
            <div className="header-row">
              <h1>
                <Translated path={`title`} shared /> ({total})
              </h1>
              <ButtonExcelExport combinedDataSet={doRequestsLists(this.props)} tabName="Export ClaimOver" />
            </div>

            <ListSearch history={this.props.history} match={this.props.match} location={this.props.location} />
          </ListHeader>

          <div className="row">
            <div className="col-md-12">
              <Table isLoading={isLoading}>
                <Thead>
                  <Tr>
                    <Th sortable onSort={this.onSort("id")}>
                      #
                    </Th>
                    <Th sortable onSort={this.onSort("projectName")}>
                      <Translated path={`request.labels.projectTitle`} shared />
                    </Th>

                    <Th sortable onSort={this.onSort("contractorReference")} specialClass="hideMobile">
                      <Translated path={`request.labels.subReference`} shared />
                    </Th>
                    <Th sortable onSort={this.onSort("clientReference")} specialClass="hideMobile">
                      <Translated path={`request.labels.clientReference`} shared />
                    </Th>

                    {(role === ROLE_MANAGER || role === ROLE_CONTRACTOR) && (
                      <Th sortable onSort={this.onSort("suggestedDateBegin")}>
                        <Translated path={`request.labels.suggestedDate`} shared />
                      </Th>
                    )}
                    <Th sortable onSort={this.onSort("plannedDateBegin")}>
                      <Translated path={`request.labels.inspectionDate`} shared />
                    </Th>
                    <Th sortable onSort={this.onSort("operationDesignation")} specialClass="hideMobile">
                      <Translated path={`request.labels.operationDesignation`} shared />
                    </Th>
                    <Th sortable onSort={this.onSort("status")}>
                      <Translated path={`request.labels.status`} shared />
                    </Th>
                    {(role === ROLE_MANAGER || role === ROLE_CONTRACTOR) && (
                      <Th specialClass="hideMobile col-center">
                        <Translated path={`table.action`} shared />
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data, (request, index) => (
                    <Tr key={`request-${index}-${request.projectName}`} onClick={this.onRowClick(request)}>
                      <Td>{request.id}</Td>
                      {(role === ROLE_MANAGER || role === ROLE_INSPECTOR) && (
                        <Td>{request.contractorCompany + " - " + request.projectName}</Td>
                      )}
                      {role === ROLE_CONTRACTOR && <Td>{request.projectName}</Td>}
                      <Td specialClass="hideMobile">{request.contractorReference}</Td>
                      <Td specialClass="hideMobile">{request.clientReference}</Td>
                      {(role === ROLE_MANAGER || role === ROLE_CONTRACTOR) && (
                        <Td>
                          {listDate(request.suggestedDateBegin) || "-"}
                          <br />
                          {listDate(request.suggestedDateEnd) || "-"}
                        </Td>
                      )}
                      <Td>
                        {listDate(request.plannedDateBegin) || "-"}
                        <br />
                        {listDate(request.plannedDateEnd) || "-"}
                      </Td>
                      <Td specialClass="hideMobile">
                        <div className="operation-wrapper">{request.operationDesignation}</div>
                      </Td>
                      <Td>
                        <Translated path={`request.status.${request.status}`} />
                      </Td>

                      {/* SHOW ONLY ACTION COLUMN FOR SUBCONTRACTOR :  */}
                      {(role === ROLE_MANAGER || role === ROLE_CONTRACTOR) && (
                        <Td specialClass="hideMobile col-center-row">
                          {includes(
                            [
                              REQUEST_STATUS_DRAFT,
                              REQUEST_STATUS_PENDING_MANAGER,
                              REQUEST_STATUS_ASSIGNED_AS_DRAFT,
                              REQUEST_STATUS_PENDING_SUBCONTRACTOR,
                              REQUEST_STATUS_ASSIGNED,
                            ],
                            request.status
                          ) &&
                            ((role === ROLE_MANAGER && request.status !== REQUEST_STATUS_PENDING_SUBCONTRACTOR) ||
                              role === ROLE_CONTRACTOR) && (
                              <button
                                onClick={this.goToEdit(request.id)}
                                className="RequestsList__button"
                                data-rh={langLabel("tooltips.editRequest", this.props)}
                              >
                                <Pencil />
                              </button>
                            )}
                          <button
                            onClick={this.goToDuplicate(request.id)}
                            className="RequestsList__button"
                            data-rh={langLabel("tooltips.duplicateRequest", this.props)}
                          >
                            <Duplicate />
                          </button>
                          {request.status === REQUEST_STATUS_DRAFT && (
                            <button
                              onClick={this.onClickDelete(request.id)}
                              className="RequestsList__button"
                              data-rh={langLabel("tooltips.deleteDraftRequest", this.props)}
                            >
                              <Trash />
                            </button>
                          )}
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination onPageChange={this.onPageChange} pages={pages} currentPage={filters.pager.page} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ requests, auth, shared }) => ({
  role: auth.data.role,
  isLoading:
    requests.list.states.isLoading ||
    shared.requestsListWithDetails.states.isLoading ||
    shared.requestDetails.delete.states.isLoading,
  isDeleting: shared.requestDetails.delete.states.isLoading,
  filters: requests.list.filters,
  data: requests.list.data.rows,
  total: requests.list.data.total,
  pages: ceil(requests.list.data.total / requests.list.filters.pager.limit),
  requestsListWithDetails: shared.requestsListWithDetails.data.rows,
  activitiesList: shared.activities.data.rows,
});
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestClearFilter,
      performRequestFetch,
      fetchActivitiesList,
      performFetchRequestsListWithDetails,
      deleteRequest,
    },
    dispatch
  );

export default compose(intl("modules.Requests.List"), connect(mapStateToProps, mapDispatchToProps))(List);
