import React from "react";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import RotateLoader from "shared/components/RotateLoader";
import "./styles.scss";

export default ({ isLoading }) => {
  const loadingClasses = classnames("PlanningCalendarLoader", {
    "PlanningCalendarLoader--active": true,
  });
  return (
    <CSSTransition in={isLoading} timeout={300} classNames="cssanim-global-fade" unmountOnExit>
      <div className={loadingClasses}>
        <RotateLoader relative />
      </div>
    </CSSTransition>
  );
};
