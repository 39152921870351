import React from "react";
import { Field } from "redux-form";
import Translated from "shared/components/Translated";
import { Calendar } from "shared/components/Icons";
import DateTimeField from "shared/components/DateTimeField";

export default (props) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <Field
          name="suggestedDateBegin"
          component={DateTimeField}
          label={<Translated path="request.labels.dateBegin" shared />}
          iconLeft={<Calendar />}
          placeholder=""
          isRequired
          locale={props.lang}
          inputProps={{ timeConstraints: { minutes: { step: 15 } } }}
        />
      </div>
      <div className="col-md-6">
        <Field
          name="suggestedDateEnd"
          component={DateTimeField}
          label={<Translated path="request.labels.dateEnd" shared />}
          iconLeft={<Calendar />}
          placeholder=""
          isRequired
          locale={props.lang}
          inputProps={{ timeConstraints: { minutes: { step: 15 } } }}
        />
      </div>
    </div>
  );
};
