import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { SHARED_REQUESTS_FETCH_LIST_DETAILS } from "shared/reducers/requests/requestsListWithDetails/";

function* fetchListWithDetails(action) {
  try {
    const { data } = yield apiService({}).get(`/request/export`);
    yield put({
      type: SHARED_REQUESTS_FETCH_LIST_DETAILS.SUCCESS,
      payload: { rows: data, response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: SHARED_REQUESTS_FETCH_LIST_DETAILS.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(SHARED_REQUESTS_FETCH_LIST_DETAILS.REQUEST, fetchListWithDetails);
  },
];
