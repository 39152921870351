import validator from "validator";

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const extractAllEmail = (emailList) => {
  const re = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

  return emailList.match(re) ? emailList.match(re) : false;
};

export const passwordValidation = (password) => {
  // TODO : test all the special caracters to test if they are all compatible with the http calls
  // https://owasp.org/www-community/password-special-characters
  // !”#$%&’()*+,-./:;<=>?@[\]^_`{|}~ and space
  const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  return regex.test(password);
};

export const validatePhoneNumber = (number) => {
  //const isValidPhoneNumber = validator.isMobilePhone(number, [ 'fr-FR', 'en-GB', 'en-US' ]);
  const isValidPhoneNumber = validator.isMobilePhone(number.replace(/[^\d+]/g, ""));
  return isValidPhoneNumber;
};

export const cleanPhoneNumber = (number) => {
  return number.replace(/[^\d+() .-]/g, "");
};
