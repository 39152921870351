import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const DEFAULT_COUNTY_FETCH_LIST = createRequestTypes("counties/DEFAULT_FETCH_LIST");

export const fetchDefaultCounty = () => ({
  type: DEFAULT_COUNTY_FETCH_LIST.REQUEST,
  payload: {},
});

const initState = {
  states: loadingStates,
  value: {},
  response: null,
};

export default createReducer(initState, {
  [DEFAULT_COUNTY_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [DEFAULT_COUNTY_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        value: action.payload,
      },
    });
  },
  [DEFAULT_COUNTY_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
