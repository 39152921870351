import { put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import apiService, { getError } from "shared/services/api";
import { redirections } from "shared/utils/request";
import { REQUESTS_REASSIGN_PERFORM, REQUESTS_REASSIGN_FETCH_MANAGERS } from "modules/Requests/reducers/reassign/";

// #10uxum En tant que Manager je veux pouvoir réassigner une convocation sans prendre en compte l'activité
// function* fetchManagers(action) {
function* fetchManagers() {
  try {
    // #10uxum En tant que Manager je veux pouvoir réassigner une convocation sans prendre en compte l'activité
    //    const { data } = yield apiService({}).get(`/manager/with-activity/${action.payload.activityId}`);
    const { data } = yield apiService({}).get(`/manager`);
    yield put({
      type: REQUESTS_REASSIGN_FETCH_MANAGERS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: REQUESTS_REASSIGN_FETCH_MANAGERS.FAILURE,
      payload: getError(err),
    });
  }
}

function* reAssignManager(action) {
  try {
    const { data } = yield apiService({}).put(
      `/request/${action.payload.requestId}/reassign-to/${action.payload.managerId}`
    );
    const {
      auth: {
        data: { role },
      },
    } = yield select();
    const path = redirections({ requestId: "" });
    yield put(push(path[role]));
    yield put({
      type: REQUESTS_REASSIGN_PERFORM.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: REQUESTS_REASSIGN_PERFORM.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(REQUESTS_REASSIGN_PERFORM.REQUEST, reAssignManager);
  },
  function* () {
    yield takeEvery(REQUESTS_REASSIGN_FETCH_MANAGERS.REQUEST, fetchManagers);
  },
];
