import { push } from "connected-react-router";
import { AUTHORIZED_PATH, REGISTER_PATH, UNAUTHORIZED_PATH, routes } from "shared/constants/routes";
import { AUTH_PERFORM_REFRESH_TOKEN, performLogout } from "modules/Auth/reducers/login";

const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export default (store) => (next) => (action) => {
  if (action.type === AUTH_PERFORM_REFRESH_TOKEN) {
    return store.dispatch(performLogout);
  }

  if (action.type !== LOCATION_CHANGE) {
    return next(action);
  }
  const { auth } = store.getState();
  const { pathname } = action.payload.location;
  const isAuthorizedPath = pathname.indexOf(AUTHORIZED_PATH) === 0;
  const isUnAuthorizedPath = pathname.indexOf(UNAUTHORIZED_PATH) === 0;
  const isRegisterPath = pathname.indexOf(REGISTER_PATH) === 0;

  // TODO: Think how to get rid of nested conditional
  if (!isAuthorizedPath && !isUnAuthorizedPath && !isRegisterPath) {
    if (auth.data.token) {
      return store.dispatch(push(routes.app.default.path));
    }
    return store.dispatch(push(routes.auth.login.path));
  }
  // No token && authorized path
  if (!auth.data.token && isAuthorizedPath) {
    return store.dispatch(push(routes.auth.login.path));
  }
  return next(action);
};
