import React from "react";
import { Field } from "redux-form";
import get from "lodash/get";
import TextField from "shared/components/TextField";
import Translated from "shared/components/Translated";
import RadioField from "shared/components/RadioField";

export default ({ lang, sharedLang }) => (
  <div className="row">
    <div className="col-md-12">
      <Field
        component={TextField}
        name="equipment"
        label={<Translated path="request.labels.equipment" shared />}
        placeholder={get(sharedLang, "request.placeholders.equipment")}
        isRequired
      />
    </div>
    <div className="col-md-12">
      <Field
        component={TextField}
        name="equipmentParts"
        label={<Translated path="request.labels.equipmentParts" shared />}
        placeholder={get(sharedLang, "request.placeholders.equipmentParts")}
        isRequired
      />
    </div>
    <div className="col-md-12">
      <Field
        component={TextField}
        name="equipmentPartsNumber"
        label={<Translated path="request.labels.equipmentPartsNumber" shared />}
        placeholder={get(sharedLang, "request.placeholders.equipmentPartsNumber")}
        isRequired
      />
    </div>
    <div className="col-md-12">
      <Field
        component={RadioField}
        name="safety"
        label={<Translated path="request.labels.safety.label" shared />}
        options={[
          {
            label: <Translated path="request.labels.safety.yes" shared />,
            value: true,
          },
          {
            label: <Translated path="request.labels.safety.no" shared />,
            value: false,
          },
        ]}
        isRequired
      />
    </div>
  </div>
);
