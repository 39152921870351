import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const HeaderProfileNavigationItem = ({ children, icon, to, onClick }) => {
  return (
    <Link to={to} className="HeaderProfileNavigationItem" onClick={onClick}>
      <img src={require(`assets/icons/${icon}.svg`)} alt="Profile" className="HeaderProfileNavigationItem__icon" />
      {children}
    </Link>
  );
};

export default HeaderProfileNavigationItem;
