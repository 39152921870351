import React, { PureComponent } from "react";
import "./styles.scss";

class CalendarHeading extends PureComponent {
  render() {
    const { children, handleSearchChange } = this.props;

    return (
      <div className="CalendarHeading">
        <div className="CalendarHeading__left">
          <input
            type="text"
            className="filteringInspectors"
            placeholder={this.props.lang.inspectorFilter}
            onChange={handleSearchChange}
          />
          <img src={require(`assets/icons/scope.svg`)} alt="Scope" className="FieldWrapper__icon" />
        </div>
        <div className="CalendarHeading__right">{children}</div>
      </div>
    );
  }
}
export default CalendarHeading;
