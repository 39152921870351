import React from "react";
import { readableDate } from "shared/services/date";
import Translated from "shared/components/Translated";
import RequestDetailsRow from "shared/components/RequestDetailsRow";
import RequestDetailsRowMutiple from "shared/components/RequestDetailsRowMultiple";
import "./styles.scss";
export default ({ data }) => {
  const suggestedPeriod = readableDate(data.suggestedDateBegin) + " - " + readableDate(data.suggestedDateEnd);
  return (
    <div>
      <RequestDetailsRow label={<Translated path="request.labels.projectTitle" />} value={data.projectName} />
      <RequestDetailsRow label={<Translated path="request.labels.phaseNumber" />} value={data.phaseNumber} />
      <RequestDetailsRow label={<Translated path="request.labels.activity" />} value={data.activityName} />
      <RequestDetailsRow
        label={<Translated path="request.labels.subSubContractorName" />}
        value={data.subSubcontractorName}
      />
      <RequestDetailsRow
        label={<Translated path="request.labels.contractorCompany" />}
        value={data.contractorCompany}
      />
      <RequestDetailsRow label={<Translated path="request.labels.subReference" />} value={data.contractorReference} />
      <RequestDetailsRow
        label={<Translated path="request.labels.followupDocumentRevisionNumber" />}
        value={data.followupDocumentRevisionNumber}
      />
      <RequestDetailsRow label={<Translated path="request.labels.clientReference" />} value={data.clientReference} />
      <RequestDetailsRow
        label={<Translated path="request.labels.operationDesignation" />}
        value={data.operationDesignation}
      />
      <RequestDetailsRow label={<Translated path="request.labels.suggestedDate" />} value={suggestedPeriod} />
      <RequestDetailsRow
        label={<Translated path="request.labels.witness.label" />}
        value={
          data.witness ? (
            <Translated path="request.labels.witness.witness" />
          ) : (
            <Translated path="request.labels.witness.hold" />
          )
        }
      />
      <RequestDetailsRow label={<Translated path="request.labels.mailingList" />} value={data.mailingList} />
      <RequestDetailsRow
        label={<Translated path="request.labels.otherContactInformation" />}
        value={data.otherContactInformation}
      />

      {data.thirdParties ? (
        <div>
          <div
            style={{
              color: "#35404a",
              fontWeight: "700",
              fontSize: "20px",
              marginTop: "10px",
            }}
          >
            <Translated path="request.labels.orgName" />
          </div>
          <hr />
          {data.thirdParties.map((el, index) => (
            <div key={index}>
              <RequestDetailsRowMutiple
                label1={<Translated path="request.labels.orgTitle" />}
                value1={el.name}
                label2={<Translated path="request.labels.witness.label" />}
                value2={
                  el.witness ? (
                    <Translated path="request.labels.witness.witness" />
                  ) : (
                    <Translated path="request.labels.witness.hold" />
                  )
                }
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
