import React, { PureComponent } from "react";
// import { DragSource } from 'react-dnd';
import classnames from "classnames";
import { moment, DATE_TIME_FORMAT } from "shared/services/date";
import { RequestMarker, RequestCalendar, RequestUser, DragIcon, Info } from "shared/components/Icons";
import "./styles.scss";

class Request extends PureComponent {
  render() {
    const { isDragging, request, activityName, onClick, hover } = this.props;
    var dragIcon = this.props.noDragIcon === undefined ? true : false;
    // cos-fc-event is used as an identifier by the FullCalendar API when draging the requests on the ExtFullCalendar
    const classes = classnames("cos-fc-event", "PlanningRequestsListRequest", {
      "PlanningRequestsListRequest--dragging": isDragging,
      PlanningRequestListRequest__isHold: !request.witness,
    });
    // encode the request object to transfer it via an tag attribute in html
    var requestData = encodeURIComponent(JSON.stringify(request));
    let dateBegin = moment(request.suggestedDateBegin).format(DATE_TIME_FORMAT);
    let dateEnd = moment(request.suggestedDateEnd).format(DATE_TIME_FORMAT);
    if (hover && request.status !== "PENDING_MANAGER") {
      dateBegin = moment(request.plannedDateBegin).format(DATE_TIME_FORMAT);
      dateEnd = moment(request.plannedDateEnd).format(DATE_TIME_FORMAT);
    }
    return (
      <div className={classes} onClick={onClick} request-data={requestData} id={"waitingListRequestId" + request.id}>
        {dragIcon && (
          <button className="PlanningRequestsListRequest__drag">
            <DragIcon />
          </button>
        )}
        <h3 className="PlanningRequestsListRequest__title">
          #{request.id} - {request.projectName}
        </h3>
        <h4 className="PlanningRequestsListRequest__sub-title">{activityName}</h4>
        <span className="PlanningRequestsListRequest__separator" />
        <div className="PlanningRequestsListRequest__info">
          <div className="PlanningRequestsListRequest__data">
            <Info className="PlanningRequestsListRequest__data-icon" />
            {request.clientReference}
          </div>
          <div className="PlanningRequestsListRequest__data">
            <Info className="PlanningRequestsListRequest__data-icon" />
            {request.operationDesignation}
          </div>
          <div className="PlanningRequestsListRequest__data">
            <RequestUser className="PlanningRequestsListRequest__data-icon" />
            {request.contractorCompany} {request.subSubcontractorName ? " - " + request.subSubcontractorName : ""}
          </div>
          <div className="PlanningRequestsListRequest__data">
            <RequestMarker className="PlanningRequestsListRequest__data-icon" />
            {request.locationText}
          </div>
          <div className="PlanningRequestsListRequest__data">
            <RequestCalendar className="PlanningRequestsListRequest__data-icon" />
            <div>
              {dateBegin}
              <br />
              {dateEnd}
            </div>
          </div>
        </div>
        <div className="PlanningRequestsListRequest__witness">{request.witness ? "WITNESS" : "HOLD"}</div>
      </div>
    );
  }
}

export default Request;
