import React, { PureComponent } from "react";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import FormButtons from "shared/components/FormButtons";
import Translated from "shared/components/Translated";
import { connect } from "react-redux";
import { compose } from "redux";
import "./styles.scss";

class DeleteIcsUrlModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.statesIcsUrl.isLoading && this.props.statesIcsUrl.isLoaded) {
      this.props.onCancel();
    }
  }

  render() {
    const { onOk, onCancel, statesIcsUrl } = this.props;
    return (
      <div className="DeleteIcsUrlModal">
        <div className="DeleteIcsUrlModal__paragraph">
          <Translated path="paragraph" />
        </div>

        <FormButtons>
          <Button type="red" isLoading={statesIcsUrl.isLoading} onClick={onOk}>
            <Translated path="buttons.yesRemove" shared />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </FormButtons>
      </div>
    );
  }
}

// export default intl('modules.Calendar.DeleteIcsUrlModal')(DeleteIcsUrlModal);

const mapStateToProps = ({ account }) => ({
  statesIcsUrl: account.icsUrl.removeIcsUrl.states,
  response: account.icsUrl.removeIcsUrl.response,
});

export default compose(intl("modules.Account.DeleteIcsUrlModal"), connect(mapStateToProps))(DeleteIcsUrlModal);
