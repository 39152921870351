import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { performLogin } from "modules/Auth/reducers/login";
import { AUTH_PERFORM_RESET_PASSWORD, AUTH_RESET_PASSWORD_VERIFY_TOKEN } from "modules/Auth/reducers/resetPassword/";

function* performResetPassword(action) {
  try {
    const { data } = yield apiService({}).post(`/password/new`, action.payload);

    yield put({
      type: AUTH_PERFORM_RESET_PASSWORD.SUCCESS,
      payload: { response: "success", data },
    });
    yield put(
      performLogin({
        username: action.email,
        password: action.payload.password,
        remember: true,
      })
    );
  } catch (err) {
    yield put({
      type: AUTH_PERFORM_RESET_PASSWORD.FAILURE,
      payload: getError(err),
    });
  }
}

function* validateToken(action) {
  try {
    const { data } = yield apiService({}).get(`/token?token=${action.payload.token}`);
    yield put({
      type: AUTH_RESET_PASSWORD_VERIFY_TOKEN.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: AUTH_RESET_PASSWORD_VERIFY_TOKEN.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(AUTH_PERFORM_RESET_PASSWORD.REQUEST, performResetPassword);
  },
  function* () {
    yield takeEvery(AUTH_RESET_PASSWORD_VERIFY_TOKEN.REQUEST, validateToken);
  },
];
