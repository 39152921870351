import React from "react";
import classnames from "classnames";
import Datetime from "react-datetime";
import { ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";
import { readableDateUTC } from "shared/services/date";
import FormButtons from "shared/components/FormButtons";
import Button from "shared/components/Button";
import Translated, { langLabel } from "shared/components/Translated";
import { RequestCalendar } from "shared/components/Icons";
import { Calendar } from "shared/components/Icons";
import { whiteCol, greyFilterCol } from "shared/constants/colors.js";
import { moment, DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "shared/services/date";

const EVENT_TYPE_ABSENCE = "ABSENCE";

export const absencesToEvent = (eventsList, absencesList, role, user, inspectorList, props) => {
  if (absencesList !== undefined && absencesList !== null && Array.isArray(absencesList) && absencesList.length !== 0) {
    absencesList.forEach((absence) => {
      // inspector view
      let inspector = { name: user.name, surname: user.surname };
      // manager view
      if (
        role === ROLE_MANAGER &&
        inspectorList !== undefined &&
        inspectorList != null &&
        Array.isArray(inspectorList) &&
        inspectorList.length !== 0
      ) {
        inspector = inspectorList.filter((inspector) => inspector.userId === absence.inspectorId)[0];
        if (inspector === undefined) inspector = { name: "", surname: "KO" };
      }
      let tmp = {
        id: absence.id,
        title: langLabel("absence.title", props) + " " + inspector.name + " " + inspector.surname,
        start: absence.dateBegin,
        end: absence.dateEnd,
        backgroundColor: whiteCol,
        borderColor: greyFilterCol,
        textColor: greyFilterCol,
        editable: false,
        resourceEditable: false,
        resourceId: absence.inspectorId,
        eventType: EVENT_TYPE_ABSENCE,
      };
      eventsList.push(tmp);
    });
  }
  return eventsList;
};

export const absenceToolTip = (start, end, props) => {
  return (
    <div className="PlanningRequestsListRequest">
      <h3 className="PlanningRequestsListRequest__title">{langLabel("absence.title", props)}</h3>
      <span className="PlanningRequestsListRequest__separator" />
      <div className="PlanningRequestsListRequest__info">
        <div className="PlanningRequestsListRequest__data">
          <RequestCalendar className="PlanningRequestsListRequest__data-icon" />
          <div>
            {readableDateUTC(start)}
            <br />
            {readableDateUTC(end)}
          </div>
        </div>
      </div>
    </div>
  );
};

const labelInspector = (inspector) => {
  if (inspector !== undefined && inspector !== null) {
    return inspector.name + " " + inspector.surname;
  } else return "KO";
};

export const absenceModal = (
  tmpAbsence,
  errMsgAbsence,
  absenceModalOnClose,
  absenceValidate,
  absenceDeleteConfirmModal,
  props
) => {
  let classWrapper = classnames("FieldWrapper__input-wrapper");
  let classDateBegin = classnames(classWrapper, {
    "FieldWrapper__input-wrapper--error": errMsgAbsence.dateBegin !== null,
  });
  let classDateEnd = classnames(classWrapper, {
    "FieldWrapper__input-wrapper--error": errMsgAbsence.dateEnd !== null,
  });
  let absenceSelectDisabled = props.role === ROLE_MANAGER ? false : true;
  if (absenceSelectDisabled === false && tmpAbsence.inspectorId && tmpAbsence.id) absenceSelectDisabled = true;
  let classAbsenceSelect = classnames("absence-select", {
    "absence-select--disabled": absenceSelectDisabled,
  });
  let chooseInListText = langLabel("request.modal.feedbackDropDown.notSpecified", props);
  let dataSource = [];
  if (props.role === ROLE_INSPECTOR && tmpAbsence.inspectorId === null) {
    tmpAbsence.inspectorId = props.user.userId;
  }
  if (props.inspectorList !== undefined && props.inspectorList !== null) {
    if (tmpAbsence.inspectorId === null || absenceSelectDisabled === false) {
      dataSource = [
        {
          label: chooseInListText,
          value: 0,
        },
      ];
    }
    props.inspectorList.forEach((inspector) => {
      if (tmpAbsence.inspectorId === null || absenceSelectDisabled === false) {
        dataSource.push({
          label: labelInspector(inspector),
          value: inspector.userId,
        });
      } else {
        if (tmpAbsence.inspectorId === inspector.userId) {
          dataSource.push({
            label: labelInspector(inspector),
            value: inspector.userId,
          });
        }
      }
    });
  } else {
    dataSource = [
      {
        label: labelInspector(props.user),
        value: props.user.userId,
      },
    ];
  }
  let locale = "fr";
  if (moment.locale() !== "fr") {
    locale = moment.locale();
    moment.updateLocale(locale, { week: { dow: 1 } });
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="FieldWrapper">
            <label className="FieldWrapper__label">
              {tmpAbsence.inspectorId === null ? (
                <Translated path="absence.chooseInspector" />
              ) : (
                <Translated path="absence.inspector" />
              )}
            </label>
            <div className={classWrapper}>
              <select
                name="selectInspector"
                id="selectInspector"
                className={classAbsenceSelect}
                disabled={absenceSelectDisabled}
              >
                {dataSource.map((option) => (
                  <option
                    key={`selectInspector-${option.value}`}
                    value={option.value}
                    onClick={(e) => {
                      tmpAbsence.inspectorId = option.value;
                    }}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="FieldError">{errMsgAbsence.inspector}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="FieldWrapper">
            <label className="FieldWrapper__label">
              <Translated path="request.labels.dateBegin" />
            </label>
            <div className={classDateBegin}>
              <div className="FieldWrapper__icon-left">
                <Calendar />
              </div>
              <Datetime
                locale={locale}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                className="TextField__input"
                name="dateBegin"
                defaultValue={tmpAbsence.dateBegin !== null ? tmpAbsence.dateBegin : ""}
                timeConstraints={{ minutes: { step: 15 } }}
                onChange={(e) => {
                  tmpAbsence.dateBegin = e._d;
                }}
              />
            </div>
            <div className="FieldError">{errMsgAbsence.dateBegin}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="FieldWrapper">
            <label className="FieldWrapper__label">
              <Translated path="request.labels.dateEnd" />
            </label>
            <div className={classDateEnd}>
              <div className="FieldWrapper__icon-left">
                <Calendar />
              </div>
              <Datetime
                locale={locale}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                className="TextField__input"
                name="dateEnd"
                defaultValue={tmpAbsence.dateEnd !== null ? tmpAbsence.dateEnd : ""}
                timeConstraints={{ minutes: { step: 15 } }}
                onChange={(e) => {
                  tmpAbsence.dateEnd = e._d;
                }}
              />
            </div>
            <div className="FieldError">{errMsgAbsence.dateEnd}</div>
          </div>
        </div>
      </div>
      <FormButtons>
        <Button type="primary" onClick={absenceValidate}>
          <Translated path="buttons.save" />
        </Button>
        <Button type="white" buttonType="button" onClick={absenceModalOnClose}>
          <Translated path="buttons.cancel" />
        </Button>
        {tmpAbsence.id !== null && (
          <Button type="red" onClick={absenceDeleteConfirmModal}>
            <Translated path="buttons.delete" />
          </Button>
        )}
      </FormButtons>
      <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6">
          <div className="FieldError">{errMsgAbsence.general}</div>
        </div>
      </div>
    </div>
  );
};

const inspectorFormCheck = (tmpAbsence, errMsgAbsence) => {
  if (tmpAbsence.inspectorId === null || tmpAbsence.inspectorId === 0) {
    errMsgAbsence.inspector = <Translated path="shared.formErrors.COMPULSARY_INSPECTOR" />;
    return true;
  } else {
    errMsgAbsence.inspector = null;
    return false;
  }
};

const dateBeginFormCheck = (tmpAbsence, errMsgAbsence) => {
  if (tmpAbsence.dateBegin === null) {
    errMsgAbsence.dateBegin = <Translated path="shared.formErrors.FILL_IN" />;
  } else {
    errMsgAbsence.dateBegin = null;
    return false;
  }
};

const dateEndFormCheck = (tmpAbsence, errMsgAbsence) => {
  if (tmpAbsence.dateEnd === null) {
    errMsgAbsence.dateEnd = <Translated path="shared.formErrors.FILL_IN" />;
    return true;
  } else if (tmpAbsence.dateBegin && tmpAbsence.dateEnd) {
    if (!moment.isMoment(tmpAbsence.dateBegin)) tmpAbsence.dateBegin = moment(tmpAbsence.dateBegin, DATE_TIME_FORMAT);
    if (!moment.isMoment(tmpAbsence.dateEnd)) tmpAbsence.dateEnd = moment(tmpAbsence.dateEnd, DATE_TIME_FORMAT);
    if (tmpAbsence.dateEnd.isBefore(tmpAbsence.dateBegin)) {
      errMsgAbsence.dateEnd = <Translated path="shared.formErrors.END_SUGGESTED_DATE_BEFORE_START_SUGGESTED_DATE" />;
      return true;
    }
  }
  errMsgAbsence.dateEnd = null;
  return false;
};

const sameDate = (tmpAbsence, errMsgAbsence) => {
  if (tmpAbsence.dateBegin.isSame(tmpAbsence.dateEnd)) {
    errMsgAbsence.dateBegin = <Translated path="shared.formErrors.BEGIN_AND_END_DATE_ARE_THE_SAME" />;
    errMsgAbsence.dateEnd = <Translated path="shared.formErrors.BEGIN_AND_END_DATE_ARE_THE_SAME" />;
    return true;
  }
  return false;
};

export const absenceFormCheck = (tmpAbsence, errMsgAbsence) => {
  let tests =
    inspectorFormCheck(tmpAbsence, errMsgAbsence) |
    dateBeginFormCheck(tmpAbsence, errMsgAbsence) |
    dateEndFormCheck(tmpAbsence, errMsgAbsence);
  if (!tests) tests = sameDate(tmpAbsence, errMsgAbsence);
  return tests;
};
