import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import FormButtons from "shared/components/FormButtons";
import Translated from "shared/components/Translated";
import { performCompanyRemoval } from "modules/Companies/reducers/remove";
import "./styles.scss";

class DeleteCompanyModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.states.isLoading && this.props.states.isLoaded) {
      this.props.onCancel();
    }
  }

  remove = () => {
    this.props.performCompanyRemoval({ id: this.props.data.id });
  };

  render() {
    const { onCancel, states } = this.props;
    return (
      <div className="DeleteCompanyModal">
        <div className="DeleteCompanyModal__paragraph">
          <Translated path="paragraph" />
        </div>

        <FormButtons>
          <Button type="red" isLoading={states.isLoading} onClick={this.remove}>
            <Translated path="buttons.yesRemove" shared />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </FormButtons>
      </div>
    );
  }
}
const mapStateToProps = ({ activities }) => ({
  states: activities.remove.states,
  response: activities.remove.response,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performCompanyRemoval }, dispatch);

export default compose(
  intl("modules.Companies.DeleteCompanyModal"),
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteCompanyModal);
