import update from "immutability-helper";
import get from "lodash/get";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";
import { arrayToIds } from "shared/utils/request";
import { cleanPhoneNumber } from "shared/utils/validation.js";

export const REGISTER_CREATE_ACCOUNT = createRequestTypes("register/CREATE_ACCOUNT");

export const performRegistration = ({
  password,
  name,
  surname,
  phoneNumber,
  gender,
  location,
  activities,
  token,
  email,
}) => ({
  type: REGISTER_CREATE_ACCOUNT.REQUEST,
  payload: {
    password,
    name,
    surname,
    phoneNumber: cleanPhoneNumber(phoneNumber),
    gender,
    locationLat: get(location, "lat"),
    locationLong: get(location, "lng"),
    county: get(location, "county"),
    activities: arrayToIds(activities),
    token,
  },
  additional: {
    email,
  },
});

const initState = {
  states: loadingStates,
  response: null,
};

export default createReducer(initState, {
  [REGISTER_CREATE_ACCOUNT.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [REGISTER_CREATE_ACCOUNT.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload,
      },
    });
  },
  [REGISTER_CREATE_ACCOUNT.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload,
      },
    });
  },
});
