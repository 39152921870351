import { combineReducers } from "redux";

import create from "./create";
import defaultCounty from "./defaultCounty";
import list from "./list";
import remove from "./remove";

export default combineReducers({
  create,
  defaultCounty,
  list,
  remove,
});
