import React from "react";
import { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Info } from "shared/components/Icons";
import Translated from "shared/components/Translated";
import sha256 from "js-sha256";

import "./styles.scss";

class FilesField extends PureComponent {
  //takes an array of JavaScript File objects
  getFiles = (files) => {
    return Promise.all(files.map((file) => this.getFile(file)));
  };

  //take a single JavaScript File object
  getFile = (file) => {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error parsing file"));
      };
      reader.onload = function () {
        //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
        let bytes = Array.from(new Uint8Array(this.result));
        //if you want the base64encoded file you would use the below line:
        let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));
        var hash = sha256.create();
        hash.update(bytes);
        let sha256String = hash.hex();

        //Resolve the promise with your custom file structure
        resolve({
          // bytes: bytes,
          // fileType: file.type,
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          type: file.type,
          sha256: sha256String,
          base64StringFile: base64StringFile,
        });
      };
      reader.readAsArrayBuffer(file);
    });
  };

  convertFiles = (files) => {
    if (files && files.length > 0) {
      this.getFiles(files).then((val) => {
        var tmpPivot = this.props.filesList;
        if (tmpPivot === undefined || tmpPivot === null) tmpPivot = [];
        var tmpReturn = [];
        for (let index = 0; index < tmpPivot.length; index++) {
          tmpReturn.push(tmpPivot[index]);
        }
        val.forEach((el) => tmpReturn.push(el));
        this.props.input.onChange(tmpReturn);
      });
    }
  };

  render() {
    const { ...props } = this.props;
    // maxSize is in bytes, 5242880 bytes = 5 MB
    return (
      <div className="dropzone-container">
        <div>
          <p>
            <Info />
            <Translated path="request.labels.dropzoneInfo" shared />
          </p>
        </div>
        <Dropzone
          icon={<Info />}
          maxFiles={5}
          maxSize={5242880}
          onDrop={(acceptedFiles) => {
            this.convertFiles(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  <Translated path="request.labels.dropzone" shared />
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <div>
          {this.props.filesList &&
            this.props.filesList.map((f, index) => (
              <div className="file-item" key={`file-${index}`}>
                <label>{f.name}</label>
                <span
                  className="file-remove-button"
                  onClick={() => props.input.onChange(props.filesList.filter((v, i) => i !== index))}
                >
                  X
                </span>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default FilesField;
