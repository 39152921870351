import { validateEmail } from "shared/utils/validation";

export default ({ email, role }) => {
  const errors = {};
  if (!email || !validateEmail(email)) {
    errors["email"] = "NO_EMAIL";
  }
  if (!role) {
    errors["role"] = "NO_ROLE";
  }
  return errors;
};
