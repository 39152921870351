import React from "react";
import ReactDOM from "react-dom";
import ReactDomServer from "react-dom/server";
import { connect } from "react-redux";
import { Swipeable } from "react-swipeable";
import { bindActionCreators, compose } from "redux";
import frLocale from "@fullcalendar/core/locales/fr";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";
import { blueCol, lightRedCol } from "shared/constants/colors.js";
import { REQUEST_STATUS_ASSIGNED_AS_DRAFT, REQUEST_STATUS_PENDING_MANAGER } from "shared/constants/request";
import { routes } from "shared/constants/routes";
import Request from "modules/Planning/components/Request";
import { retrieveActivityName } from "shared/utils/request.js";
import Translated, { langLabel } from "shared/components/Translated";
import { moment, readableDate, DATE_TIME_FORMAT } from "shared/services/date";
import {
  absenceCreate,
  absenceUpdate,
  absenceDelete,
  absencesFetchManager,
  absencesFetchInspector,
} from "shared/reducers/absence";
import { intl } from "shared/hoc/Intl";
import Modal from "shared/components/Modal";
import ConfirmModal from "shared/components/ConfirmModal";
import { absencesToEvent, absenceModal, absenceToolTip, absenceFormCheck } from "shared/components/Absence";
import Avatar from "shared/components/Avatar";
import { getInitialsFromName, stringToHslColor } from "shared/utils/avatar";
import "./styles.scss";

const EVENT_TYPE_REQUEST = "REQUEST";

// Externalised from the ExtFullCalendar class because the inner "this" methods are not initialized when in componentDidMount()
const requestToEvent = (isDrag, role, request, viewType, isPending) => {
  const beg = moment(request.plannedDateBegin);
  const end = moment(request.plannedDateEnd);

  if (isDrag === undefined || isDrag == null) isDrag = false;
  let startDate = isDrag ? request.suggestedDateBegin : request.plannedDateBegin;
  let endDate = isDrag ? request.suggestedDateEnd : request.plannedDateEnd;
  let status = request.status;
  if (request.inspectorId === undefined || request.inspectorId === null) {
    startDate = request.suggestedDateBegin;
    endDate = request.suggestedDateEnd;
    status = REQUEST_STATUS_PENDING_MANAGER;
  }

  var isEditDrop =
    (request.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT || request.status === REQUEST_STATUS_PENDING_MANAGER) &&
    role === ROLE_MANAGER
      ? true
      : false;
  // title is constructed according to #c4xwkj
  var title = request.contractorCompany ? request.contractorCompany : "";
  title = request.subSubcontractorName ? title + " - " + request.subSubcontractorName : title;
  //title = request.operationDesignation ? title + "\n" + request.operationDesignation : title;
  var classNames =
    (request.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT || request.status === REQUEST_STATUS_PENDING_MANAGER
      ? "cos-fc-event-draft-border"
      : "cos-fc-event-validated-border") + (request.witness ? " witness" : " hold");
  if (isPending) {
    classNames += " pending";
  }
  return {
    id: request.id,
    title: "[|title|]",
    extendedProps: {
      compagnyName: request.contractorCompany,
      witness: request.witness,
      location: request.locationText,
      duration: (end - beg) / 3600000,
      inspectorId: request.inspectorId,
      title_text: "#" + request.id + " - " + title,
      description_text: request.operationDesignation || "",
    },
    start: startDate,
    end: endDate,
    // backgroundColor: whiteCol,
    borderColor: request.witness ? blueCol : lightRedCol,
    // textColor: darkerGreyCol,
    editable: isEditDrop,
    resourceEditable: isEditDrop,
    // durationEditable: isEditDrop,
    resourceId: request.inspectorId ? request.inspectorId : 0, // id 0 for the pending request
    className: classNames,
    status: status,
    eventType: EVENT_TYPE_REQUEST,
  };
};

const extractRequestData = (eventEL) => {
  var requestData = eventEL.getAttribute("request-data");
  if (requestData !== undefined && requestData != null) {
    let request = JSON.parse(decodeURIComponent(requestData));
    return requestToEvent(true, ROLE_MANAGER, request);
  } else {
    return { id: 0, title: "KO!!" };
  }
};

// FullCalendar Wrapper
class ExtFullCalendar extends React.Component {
  // BEGIN !important keep these comments below describing the State Machine.
  // **[1]**
  // The opening and closing of toolTipPopUps follow a State Machine.
  // The states are in the variable myStates.
  //
  // **[Original State]** , mouse is outside the events and no toolTipPopUp is open : myStates = { toolTipPopUpOpen: null, hoverEvent: false };
  //  ^                               |
  //  |                               |(eventMouseEnter)
  //  |(toolTipPopUpClose)             |
  //  |                               V
  //  +<----(eventMouseLeave)---- **[Hover an event]** : open the toolTipPopUp : myStates = { toolTipPopUpOpen: dom_elem_id, hoverEvent: true };
  // END !important

  state = {
    absenceEditModal: false,
    absenceDeleteConfirmModal: false,
    viewType: "",
    refresh: null,
    showPending: true,
  };

  myStates = {
    toolTipPopUpOpen: null,
    hoverEvent: false,
  };

  errMsgAbsence = {
    inspector: null,
    dateBegin: null,
    dateEnd: null,
    general: null,
  };

  tmpAbsence = {
    id: null,
    inspectorId: null,
    dateBegin: null,
    dateEnd: null,
  };

  calendarRef = React.createRef();

  clearErrMsgAbsenceOnNew = () => {
    this.errMsgAbsence = { inspector: null, dateBegin: null, dateEnd: null, general: null };
    this.toggleModal("absenceEditModal");
  };

  setViewTypeState = (type) => {
    this.setState({ viewType: type });
  };

  togglePendingRequests = () => {
    this.setState({ showPending: !this.state.showPending });
  };

  calculateOffsetsPopUp = (el, estimatePopUpHeight, type) => {
    let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // Retrieve position of the event in the Calendar
    // parent <div id="toolTipPopUps" />
    let toolTipPopUpRect = document.getElementById("toolTipPopUps").getBoundingClientRect();
    let elemRect = el.getBoundingClientRect();
    // X & Y position are relative to the parent <div id="toolTipPopUps" />
    let offsetLeft = elemRect.left - toolTipPopUpRect.left;
    let offsetTop = 0;
    let diffUp = elemRect.top;
    let diffDown = height - (elemRect.bottom + 3);
    let directionUp = diffDown < estimatePopUpHeight && diffDown < diffUp ? true : false; // default false to show the pop-up under
    if (directionUp) {
      // shifting the position to let the request/absence/inspector name visible when showing the pop-up above
      let shift = 0;
      if (type === "SKILLS") shift = -20;
      else shift = +10;
      offsetTop = elemRect.top - toolTipPopUpRect.top - estimatePopUpHeight + shift;
    } else {
      offsetTop = elemRect.bottom - toolTipPopUpRect.top + 3; // +3 to make a gap with the event
    }
    return { offsetLeft, offsetTop };
  };

  componentDidMount() {
    let draggableEl = document.getElementById("external-cos-fc-events");
    if (draggableEl !== undefined && draggableEl != null) {
      new Draggable(draggableEl, {
        itemSelector: ".cos-fc-event",
        eventData: function (eventEL) {
          return extractRequestData(eventEL);
        },
      });
    }
    this.absencesFetch();
  }

  toggleModal = (key) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key] });
  };

  toggleModalFalse = (key) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: false });
  };

  absencesFetch = () => {
    // Comming from the manager Planning
    if (this.props.history.location.pathname === routes.planning.default.path) {
      this.props.absencesFetchManager();
    }
    // Comming from the inspector Calendar
    else {
      this.props.absencesFetchInspector();
    }
  };

  endDateAfterBegin = () => {};

  absenceValidate = (e) => {
    if (absenceFormCheck(this.tmpAbsence, this.errMsgAbsence)) {
      this.setState({ refresh: Math.random() });
    } else {
      let absence = this.tmpAbsence;
      // when DateTime receive the dateBegin and dateEnd, they are converted to string
      // if only one of the date is modified, then the field is not parsed by the java backend
      // hence forcing the fields to be moment again
      if (typeof absence.dateBegin === "string") absence.dateBegin = moment(absence.dateBegin, DATE_TIME_FORMAT);
      if (typeof absence.dateEnd === "string") absence.dateEnd = moment(absence.dateEnd, DATE_TIME_FORMAT);
      if (absence.id === null) {
        this.props.absenceCreate({ absence });
      } else {
        this.props.absenceUpdate({ absence });
      }
      this.absenceModalOnClose(e);
    }
  };

  absenceModal = () => {
    return absenceModal(
      this.tmpAbsence,
      this.errMsgAbsence,
      this.absenceModalOnClose,
      this.absenceValidate,
      this.absenceDeleteConfirmModalF,
      this.props
    );
  };

  absenceModalOnClose = (e) => {
    this.tmpAbsence = {
      id: null,
      inspectorId: null,
      dateBegin: null,
      dateEnd: null,
    };
    this.errMsgAbsence = {
      inspector: null,
      dateBegin: null,
      dateEnd: null,
      general: null,
    };
    this.setState({ refresh: Math.random() });
    this.setState({ absenceEditModal: false });
    // Refetch
    setTimeout(this.absencesFetch, 1000);
  };

  absenceDelete = (e) => {
    this.props.absenceDelete(parseInt(this.tmpAbsence.id));
    this.setState({ absenceDeleteConfirmModal: false });
    this.absenceModalOnClose(e);
  };

  absenceDeleteConfirmModalF = (e) => {
    this.setState({ absenceEditModal: false });
    this.setState({ absenceDeleteConfirmModal: true });
  };

  onSwipe = (eventData) => {
    let calendarApi = this.calendarRef.current.getApi();
    const dir = eventData.dir;
    if (dir === "Left") {
      calendarApi.next();
    } else if (dir === "Right") {
      calendarApi.prev();
    }
  };

  toolTipPopUpId = (id) => "eventToolTipPopUp" + id;

  closeToolTipPopUp = (id) => {
    var el = document.getElementById(this.toolTipPopUpId(id));
    if (el != null) ReactDOM.unmountComponentAtNode(el.parentNode);
    // update states, cf **[1]** comment at the beginning of the class
    this.myStates.toolTipPopUpOpen = null;
    this.myStates.hoverEvent = false;
  };

  openToolTipPopUp = (mouseEnterInfo, activities, requestList, dataRequestsPending) => {
    let id = Number(mouseEnterInfo.event._def.publicId); // the request id is a number in DB
    let request = this.retrieveEventRequestId(id);
    let estimatePopUpHeight = mouseEnterInfo.event._def.extendedProps.eventType === EVENT_TYPE_REQUEST ? 305 : 200;
    let offSets = this.calculateOffsetsPopUp(mouseEnterInfo.el, estimatePopUpHeight, "REQABS");
    let options;
    let requestPannel;
    if (mouseEnterInfo.event._def.extendedProps.eventType === EVENT_TYPE_REQUEST) {
      // insert the toolTipPopUp <div id="eventXX" class="toolTipPopUp">...</div>
      options = {
        request: request,
        activityName: retrieveActivityName(activities, request),
        isDragging: false,
        onClick: null,
        noDragIcon: true,
        hover: true,
      };
      requestPannel = React.createElement(Request, options, null);
    }
    // Affichage du toolTip pour les absences
    else {
      requestPannel = React.createElement(
        "div",
        {},
        absenceToolTip(mouseEnterInfo.event._instance.range.start, mouseEnterInfo.event._instance.range.end, this.props)
      );
    }

    let style = {
      position: "absolute",
      left: offSets.offsetLeft,
      top: offSets.offsetTop,
      zIndex: 10000,
    };
    options = {
      id: this.toolTipPopUpId(id),
      key: this.toolTipPopUpId(id),
      className: "toolTipPopUp",
      style: style,
    };
    let resultCode = React.createElement("div", options, requestPannel);
    ReactDOM.render(resultCode, document.getElementById("toolTipPopUps"));

    // update states, cf **[1]** comment at the beginning of the class
    this.myStates.toolTipPopUpOpen = this.toolTipPopUpId(mouseEnterInfo.event._def.publicId);
    this.myStates.hoverEvent = true;
  };

  onRequestClick = (info) => {
    var id = info.event._def.publicId;
    this.closeToolTipPopUp(id);
    if (info.event._def.extendedProps.eventType === EVENT_TYPE_REQUEST) {
      // Comming from the manager Planning
      if (this.props.history.location.pathname === routes.planning.default.path) {
        this.props.history.push(routes.planning.details({ id }).path);
      }
      // Comming from the inspector Calendar
      else {
        this.props.history.push(routes.calendar.details({ id }).path);
      }
    }
    // EVENT_TYPE_ABSENCE
    else {
      id = parseInt(id);
      let tmpAbsence = this.props.absencesList.filter((absence) => id === absence.id)[0];
      this.tmpAbsence = tmpAbsence;
      this.tmpAbsence.dateBegin = readableDate(this.tmpAbsence.dateBegin);
      this.tmpAbsence.dateEnd = readableDate(this.tmpAbsence.dateEnd);
      this.toggleModal("absenceEditModal")(null);
    }
  };

  skillsCertificationsPopUpId = (id) => {
    return "skillsCertificationsPopUpId" + id;
  };

  skillsCertificationsPopUp = (skillsCertifications) => {
    return (
      <div className="PlanningRequestsListRequest">
        <div className="PlanningRequestsListRequest__title">
          {langLabel("user.labels.skillsCertifications", this.props)}
        </div>
        <span className="PlanningRequestsListRequest__separator" />
        <div className="PlanningRequestsListRequest__info">
          {skillsCertifications.map((sc) => {
            return (
              <div className="PlanningRequestsListRequest__data" key={Math.random()}>
                {/* Each key has to be unique, but not used, hence Math.random() */}
                {sc}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  skillsCertificationsMouseEnter = (id, inspectorCell, skillsCertifications) => {
    // Formula to calculate the heigth of the made tooltip (with browser default zoom) : 62 + (number of skills)*37 + 45
    let nbSkills = skillsCertifications.length || 0;
    let estimatePopUpHeight = 62 + nbSkills * 37 + 45;
    let offSets = this.calculateOffsetsPopUp(inspectorCell, estimatePopUpHeight, "SKILLS");

    let style = {
      position: "absolute",
      left: offSets.offsetLeft,
      top: offSets.offsetTop,
      zIndex: 10000,
    };
    let options = {
      className: "toolTipPopUp",
      id: this.skillsCertificationsPopUpId(id),
      key: this.skillsCertificationsPopUpId(id),
      style: style,
    };
    let resultCode = React.createElement("div", options, this.skillsCertificationsPopUp(skillsCertifications));
    ReactDOM.render(resultCode, document.getElementById("toolTipPopUps"));
  };

  skillsCertificationsMouseLeave = (id) => {
    let el = document.getElementById(this.skillsCertificationsPopUpId(id));
    if (el != null) ReactDOM.unmountComponentAtNode(el.parentNode);
  };

  compareInspectorField = (a, b) => {
    if (a.inspector < b.inspector) {
      return -1;
    }
    if (a.inspector > b.inspector) {
      return 1;
    }
    return 0;
  };

  retrieveEventRequestId = (id) => {
    let request = this.props.requestsList.filter((request) => request.id === id)[0];
    if (request === undefined || request === null) {
      request = this.props.dataRequestsPending.filter((request) => request.id === id)[0];
    }
    return request;
  };

  setCalendarHeight = (h) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.setOption("height", h);
  };

  // ------------------------------------------------------------
  // ------------------------------------------------------------
  // RENDER
  // ------------------------------------------------------------
  // ------------------------------------------------------------

  render() {
    const {
      role,
      user,
      requestsList,
      inspectorList,
      absencesList,
      activities,
      langCurrent,
      dataRequestsPending,
    } = this.props;

    // Only the manager and inspector have a calendar
    if (role !== ROLE_MANAGER && role !== ROLE_INSPECTOR) {
      return (
        <div>
          <p>
            <Translated path="shared.error" />
            <br />
            Code : WR
          </p>
        </div>
      );
    }

    var isEditDrop = role === ROLE_MANAGER ? true : false;
    // prepare the eventList in FullCalendar format
    var eventsList =
      requestsList !== undefined || requestsList != null
        ? requestsList.map((request) => requestToEvent(false, role, request))
        : [];

    var pendingRequestEventsList =
      dataRequestsPending !== undefined || dataRequestsPending != null
        ? dataRequestsPending.map((request) => requestToEvent(true, role, request, null, true))
        : [];

    // pending request are added to the eventList when the view is resourceTimelineWeek || resourceTimelineMonth
    if (
      dataRequestsPending &&
      this.state.showPending &&
      dataRequestsPending.length > 0 &&
      (this.state.viewType === "resourceTimelineWeek" || this.state.viewType === "resourceTimelineMonth")
    ) {
      eventsList = eventsList.concat(pendingRequestEventsList);
    }

    // add the absences
    eventsList = absencesToEvent(eventsList, absencesList, role, user, inspectorList, this.props);

    // Ressources declaration, Manager only
    var resourceColumns, resources, resourceOrder;
    if (role === ROLE_MANAGER) {
      resourceOrder = ["-type", "inspector"];
      resourceColumns = [
        {
          labelText: this.props.lang.inspector,
          field: "inspector",
        },
      ];
      if (inspectorList !== undefined && inspectorList != null) {
        resources = [];
        if (dataRequestsPending && dataRequestsPending.length > 0) {
          resources.push({
            id: 0,
            inspector: langLabel("pendingRequests", this.props),
            type: "pending",
          });
        }
        inspectorList.forEach((inspector) => {
          return resources.push({
            id: inspector.userId,
            inspector: inspector.surname + " " + inspector.name,
            skillsCertifications: inspector.skillsCertifications,
            type: "inspector",
          });
        });
      }
    }
    // https://fullcalendar.io/docs/plugin-index
    const plugins = [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin];

    // Calendar Header construction & defaultView setting
    var defaultView = "dayGridMonth";
    var headers = {
      left: "prev,today,next",
      center: "title",
    };
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    if (role === ROLE_MANAGER) {
      // création d'absence désactivé pour framatome :
      headers["right"] = "absenceCreate resourceTimelineMonth,resourceTimelineWeek,listWeek";
      // sans les absences : headers["right"] = "resourceTimelineMonth,resourceTimelineWeek,listWeek";
      // defaultView = "resourceTimelineWeek";
      defaultView = x > 812 ? "resourceTimelineWeek" : "listWeek";
    } else {
      // création d'absence désactivé pour framatome :
      headers["right"] = "absenceCreate dayGridMonth,timeGridWeek,listWeek";
      // sans les absences : headers["right"] = "dayGridMonth,timeGridWeek,listWeek";
      // defaultView = "timeGridWeek";
      defaultView = x > 812 ? "timeGridWeek" : "listWeek";
    }

    var customButtons = {
      absenceCreate: {
        text: "+ " + langLabel("absence.absenceAddInspector", this.props),
        click: this.toggleModal("absenceEditModal"),
      },
    };

    // Change the FullCalendar options view by view
    var views = {
      resourceTimelineWeek: {
        droppable: isEditDrop,
        editable: isEditDrop,
        snapDuration: "00:30:00",
        slotDuration: "02:00:00",
        slotWidth: "75",
        displayEventTime: false,
      },
      resourceTimelineMonth: {
        // droppable: false,
        // editable: false,
        aspectRatio: 1,
        slotWidth: "75",
      },
      timeGridWeek: {
        slotDuration: "00:30:00",
      },
    };

    var randomNumberForRefresh = Math.floor(Math.random() * 1000000000);
    eventsList.forEach((e) => {
      e.editable = e.editable && this.state.viewType === "resourceTimelineWeek";
      e.resourceEditable = e.resourceEditable && this.state.viewType === "resourceTimelineWeek";
    });

    if (this.calendarRef.current) {
      var toppos = document.getElementsByClassName("Calendar__content")[0].getBoundingClientRect().top;
      this.calendarRef.current.calendar.setOption("height", y - toppos - 10);
      //this.calendarRef.current.calendar.setOption("contentHeight", y - toppos);
    }

    return (
      <div>
        <Swipeable className="Calendar__content" onSwiped={this.onSwipe}>
          <ConfirmModal
            title={<Translated path="absence.absenceDeleteConfirm" />}
            isLoading={false}
            isOpen={this.state.absenceDeleteConfirmModal}
            paragraph={<Translated path="absence.areYouSureDelete" />}
            onClose={this.toggleModalFalse("absenceDeleteConfirmModal")}
            onConfirm={this.absenceDelete}
            confirmLabel={<Translated path="buttons.delete" />}
            cancelLabel={<Translated path="buttons.cancel" />}
          />
          <Modal
            title={<Translated path="absence.absenceManage" />}
            isOpen={this.state.absenceEditModal}
            onClose={this.absenceModalOnClose}
          >
            {this.absenceModal()}
          </Modal>
          <FullCalendar
            refresh={randomNumberForRefresh}
            ref={this.calendarRef}
            droppable={false}
            editable={false}
            views={views}
            customButtons={customButtons}
            height="auto"
            events={eventsList}
            displayEventTime={true}
            firstDay={1}
            locales={[frLocale]}
            locale={langCurrent}
            plugins={plugins}
            defaultView={defaultView}
            header={headers}
            resourceColumns={resourceColumns}
            resources={resources}
            resourceOrder={resourceOrder}
            minTime="08:00:00"
            maxTime="20:00:00"
            snapDuration="00:30:00"
            // slotDuration="02:00:00"
            resourceRender={(info) => {
              let id = info.resource._resource.id;
              var inspectorCell = info.el.querySelector(".fc-cell-text");
              if (info.resource._resource.extendedProps.skillsCertifications) {
                inspectorCell.addEventListener("mouseenter", () =>
                  this.skillsCertificationsMouseEnter(
                    id,
                    inspectorCell,
                    info.resource._resource.extendedProps.skillsCertifications
                  )
                );

                inspectorCell.addEventListener("mouseleave", () => this.skillsCertificationsMouseLeave(id));
                var questionMark = document.createElement("strong");
                questionMark.innerText = " (?) ";
                inspectorCell.appendChild(questionMark);
              }
              if (info.resource._resource.extendedProps.type === "pending") {
                // Si on veut un jour rajouter le nombre de requêtes en attente sur la période affichée dans le planning (aujourd'hui dataRequestsPending = toutes les requêtes en attente)

                // var spanCounter = document.createElement("span");
                // spanCounter.innerHTML = this.props.dataRequestsPending.length;
                // info.el.firstChild.firstChild.appendChild(spanCounter);

                var innerDiv = document.createElement("div");
                innerDiv.innerHTML = `<button class="Button Button--primary show-pending-button">${
                  this.state.showPending ? langLabel("request.hide", this.props) : langLabel("request.show", this.props)
                }</button>`;
                innerDiv.addEventListener("click", () => this.togglePendingRequests());
                info.el.firstChild.firstChild.appendChild(innerDiv);
              } else {
                inspectorCell.classList.add("fc-inspector-cell");
                inspectorCell.addEventListener("click", () => {
                  this.props.history.push(routes.users.edit(id).path);
                });
              }
            }}
            eventClick={(info) => {
              this.onRequestClick(info);
            }}
            eventDragStart={(info) => {
              this.closeToolTipPopUp(info.event._def.publicId);
            }}
            drop={(dropInfo) => {
              var requestData = dropInfo.draggedEl.attributes.getNamedItem("request-data").value;
              var request = JSON.parse(decodeURIComponent(requestData));
              request.inspectorUserId = Number(dropInfo.resource._resource.id); // the request inspectorUserId is a number in DB
              // When a request is dropped on the month view, assign the suggested date
              this.props.onDrop(dropInfo.draggedEl, request, dropInfo.dateStr, false, dropInfo.view.type);
            }}
            eventAllow={(dropInfo, draggedEvent) => {
              let ressourceId = Number(dropInfo.resource._resource.id);
              // let requestId = Number(draggedEvent._def.publicId);
              // let request = this.retrieveEventRequestId(requestId);
              if (ressourceId !== 0) {
                return true;
              } else {
                return false;
              }
            }}
            eventDrop={(eventDropInfo) => {
              let inspectorUserId = 0;
              let dateStr = null;
              if (
                eventDropInfo &&
                eventDropInfo.newResource &&
                eventDropInfo.newResource._resource &&
                eventDropInfo.newResource._resource.id
              ) {
                inspectorUserId = Number(eventDropInfo.newResource._resource.id); // the request inspectorUserId is a number in DB
              }
              if (eventDropInfo.view.type === "resourceTimelineWeek") {
                let id = Number(eventDropInfo.event._def.publicId); // the request id is a number in DB
                let request = this.retrieveEventRequestId(id);
                if (request.status === "PENDING_MANAGER") {
                  request.plannedDateBegin = moment(request.suggestedDateBegin).add(eventDropInfo.delta, "ms");
                  request.plannedDateEnd = moment(request.suggestedDateEnd).add(eventDropInfo.delta, "ms");
                  dateStr = request.plannedDateBegin;
                } else {
                  request.plannedDateBegin = moment(request.plannedDateBegin).add(eventDropInfo.delta, "ms");
                  request.plannedDateEnd = moment(request.plannedDateEnd).add(eventDropInfo.delta, "ms");
                }
                if (eventDropInfo.newResource !== null) {
                  request.inspectorUserId = inspectorUserId;
                }
                this.props.onDrop(eventDropInfo.el, request, dateStr, false, eventDropInfo.view.type);
              }
            }}
            eventReceive={(info) => {
              info.event.remove();
            }}
            eventMouseEnter={(mouseEnterInfo) => {
              this.openToolTipPopUp(mouseEnterInfo, activities, requestsList, dataRequestsPending);
            }}
            eventMouseLeave={(mouseLeaveInfo) => {
              this.closeToolTipPopUp(mouseLeaveInfo.event._def.publicId);
            }}
            eventRender={(info) => {
              if (info.event._def.extendedProps.eventType === EVENT_TYPE_REQUEST) {
                //Set title content

                info.el.innerHTML = info.el.innerHTML.replace(
                  "[|title|]",
                  `<div class="fc-event-custom-title">${info.event._def.extendedProps.title_text}</div><div class="fc-event-custom-subtitle">${info.event._def.extendedProps.description_text}</div>`
                );

                // Strings Needed for the innerHtml
                const location_svg = `<span class="svg_icon"><svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="13px" height="13px" viewBox="0 0 466.583 466.582" style="enable-background:new 0 0 466.583 466.582;" xml:space="preserve"><g><path d="M233.292,0c-85.1,0-154.334,69.234-154.334,154.333c0,34.275,21.887,90.155,66.908,170.834   c31.846,57.063,63.168,104.643,64.484,106.64l22.942,34.775l22.941-34.774c1.317-1.998,32.641-49.577,64.483-106.64   c45.023-80.68,66.908-136.559,66.908-170.834C387.625,69.234,318.391,0,233.292,0z M233.292,233.291c-44.182,0-80-35.817-80-80   s35.818-80,80-80c44.182,0,80,35.817,80,80S277.473,233.291,233.292,233.291z" style="fill: rgb(119, 131, 161);"/></g></svg></span>`;
                const people_svg = `<span class="svg_icon"><svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="14" height="14"><g><path id="user" d="M18.58,15.57c-0.06-0.53-0.17-1.06-0.32-1.57c-0.14-0.48-0.34-0.94-0.61-1.36c-0.25-0.38-0.6-0.7-1-0.93  c-0.43-0.25-0.91-0.37-1.4-0.36c-0.86,0.86-2.03,1.34-3.24,1.32c-1.22,0.02-2.4-0.46-3.26-1.33c-0.49-0.01-0.97,0.11-1.4,0.36  c-0.4,0.23-0.75,0.55-1,0.93C6.09,13.06,5.89,13.52,5.76,14c-0.16,0.52-0.27,1.04-0.33,1.58c-0.06,0.55-0.09,1.11-0.09,1.67  c-0.01,0.7,0.23,1.38,0.67,1.93c0.36,0.5,0.93,0.8,1.55,0.82h8.89c0.62-0.02,1.19-0.32,1.56-0.82c0.44-0.55,0.67-1.24,0.65-1.95  C18.66,16.68,18.64,16.12,18.58,15.57z M14.84,5.18C14.1,4.41,13.08,3.98,12.01,4c-1.07-0.02-2.09,0.41-2.83,1.18  C8.42,5.92,7.99,6.94,8.01,8c-0.02,1.06,0.41,2.09,1.17,2.83c0.74,0.76,1.77,1.19,2.83,1.17c1.06,0.02,2.09-0.41,2.83-1.17  c0.76-0.74,1.19-1.77,1.17-2.83C16.02,6.94,15.6,5.92,14.84,5.18z" style="fill: rgb(119, 131, 161);" vector-effect="non-scaling-stroke"/></g></svg></span>`;
                const compagnyName = `<p class="event-subtitle" style="vertical-align: middle">${people_svg} ${info.event.extendedProps.compagnyName}</p>`;
                const locationString = `<p class="event-subtitle location" style="vertical-align: middle"> ${location_svg} ${info.event.extendedProps.location}</p>`;
                const witness = info.event.extendedProps.witness
                  ? '<div class="fc-insert witness"><p>WITNESS</p></div>'
                  : '<div class="fc-insert hold"><p>HOLD</p></div>';

                //Duration calculation
                let startDuration, finishDuration, duration;

                if (info.el.text) {
                  startDuration = new Date(
                    `2020-01-01 ${
                      info.el.text[0] === "1" || info.el.text[0] === "2"
                        ? info.el.text.slice(0, 5)
                        : info.el.text.slice(0, 4)
                    }`
                  );
                  startDuration =
                    startDuration < new Date("2020-01-01 8:00") ? new Date("2020-01-01 8:00") : startDuration;
                  finishDuration = new Date(
                    `2020-01-01 ${
                      info.el.text[0] === "1" || info.el.text[0] === "2"
                        ? info.el.text[8] === "1" || info.el.text[8] === "2"
                          ? info.el.text.slice(8, 13)
                          : info.el.text.slice(8, 12)
                        : info.el.text[7] === "1" || info.el.text[7] === "2"
                        ? info.el.text.slice(7, 12)
                        : info.el.text.slice(7, 11)
                    }`
                  );
                  duration =
                    (finishDuration - startDuration) / 3600000 > 0
                      ? (finishDuration - startDuration) / 3600000
                      : (finishDuration - startDuration) / -3600000;
                }
                //Get the inspector fullname for display
                let inspector = 0;
                let inspectorString = "";
                let inspectorAvatar = "";
                if (role === ROLE_MANAGER && info.event.extendedProps.inspectorId) {
                  inspector = inspectorList.find((el) => el.userId === info.event.extendedProps.inspectorId);
                  inspectorString = inspector.name + " " + inspector.surname;
                  inspectorAvatar = ReactDomServer.renderToString(
                    <Avatar color={stringToHslColor(inspector.name, inspector.surname)}>
                      {getInitialsFromName(inspector.name, inspector.surname)}
                    </Avatar>
                  );
                }

                // Where all the infos are put together depending on the duration of the event
                if (!info.el.className.includes("fc-list-item")) {
                  info.el.innerHTML =
                    duration > 3
                      ? info.el.innerHTML + compagnyName + locationString + witness
                      : duration > 2
                      ? info.el.innerHTML + compagnyName + witness
                      : duration > 1
                      ? info.el.innerHTML + witness
                      : info.el.innerHTML;
                } else {
                  // For the planning view
                  info.el.innerHTML =
                    info.el.innerHTML.slice(0, -5) +
                    `<hr/><div class="inspector-row">${
                      role === ROLE_MANAGER && info.event.extendedProps.inspectorId
                        ? inspectorAvatar +
                          `<p class="event-subtitle" style="color: black; font-weight: bold; margin-top: .6em">  ${inspectorString}</p>`
                        : ""
                    }</div>` +
                    compagnyName +
                    locationString +
                    witness +
                    "</td>";
                }
                // To improve the UI for events lasting between 1 and 2 hours
                if (duration < 2 && duration >= 1) info.el.className = info.el.className + " fc-middle-short";
              } else {
                if (info.el.className.includes("fc-list-item")) {
                  info.el.innerHTML = info.el.innerHTML.slice(0, -5) + `<hr/>`;
                }
              }
              if (info.event._def.extendedProps.eventType !== EVENT_TYPE_REQUEST) {
                info.el.classList.add("unavailability-event");
              }
            }}
            eventResize={(eventResizeInfo) => {
              if (eventResizeInfo.view.type === "resourceTimelineWeek") {
                let id = Number(eventResizeInfo.event._def.publicId); // the request id is a number in DB
                let request = this.retrieveEventRequestId(id);
                request.plannedDateBegin = moment(request.plannedDateBegin).add(eventResizeInfo.startDelta, "ms");
                request.plannedDateEnd = moment(request.plannedDateEnd).add(eventResizeInfo.endDelta, "ms");
                this.props.onDrop(eventResizeInfo.el, request, null, false, eventResizeInfo.view.type);
              }
            }}
            eventResizeStart={(info) => {
              this.closeToolTipPopUp(info.event._def.publicId);
            }}
            datesRender={(info) => {
              this.props.setDateRangeFunction(info.view.props.dateProfile.currentRange);
            }}
            viewSkeletonRender={(info) => {
              this.setViewTypeState(info.view.type);
            }}
          />
          <div id="toolTipPopUps" style={{ position: "relative" }}>
            &nbsp;
          </div>
        </Swipeable>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user }, auth, shared, lang }) => ({
  activities: shared.activities.data.rows,
  role: auth.data.role,
  absencesList: shared.absence.fetch.data,
  user: user.data,
  langCurrent: lang.currentLang,
  isLoadedActivities: shared.activities.states.isLoaded,
  isLoadingActivities: shared.activities.states.isLoading,
  isLoadedAbsenceCreateUpdateDelete: shared.absence.createUpdateDelete.states.isLoaded,
  isLoadingAbsenceCreateUpdateDelete: shared.absence.createUpdateDelete.states.isLoading,
  isLoadedAbsenceFetch: shared.absence.fetch.states.isLoaded,
  isLoadingAbsenceFetch: shared.absence.fetch.states.isLoading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      absenceCreate,
      absenceUpdate,
      absenceDelete,
      absencesFetchManager,
      absencesFetchInspector,
    },
    dispatch
  );

export default compose(
  intl("shared"),
  intl("modules.Planning.RequestList"),
  connect(mapStateToProps, mapDispatchToProps)
)(ExtFullCalendar);
