import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import { routes } from "shared/constants/routes";
import { performLogout } from "modules/Auth/reducers/login";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import { setLang } from "shared/reducers/lang";
import HeaderProfileNavigationItem from "modules/App/components/HeaderProfileNavigationItem";
import { ROLE_SUPERADMIN } from "shared/constants/auth";
import "./styles.scss";

const HeaderProfileNavigation = ({ setLang, currentLang, performLogout, role }) => {
  const changeLanguage = (lang) => () => setLang({ lang });
  const langs = [
    { short: "en", label: "English" },
    { short: "fr", label: "Français" },
  ];
  return (
    <div className="HeaderProfileNavigation">
      {role !== ROLE_SUPERADMIN && (
        <HeaderProfileNavigationItem icon="profile" to={routes.account.default.path}>
          <Translated path="myProfile" />
        </HeaderProfileNavigationItem>
      )}
      {role !== ROLE_SUPERADMIN && (
        <HeaderProfileNavigationItem icon="mail" to={routes.contact.default.path}>
          <Translated path="contact" />
        </HeaderProfileNavigationItem>
      )}

      <HeaderProfileNavigationItem icon="logout" to={routes.auth.login.path} onClick={performLogout}>
        <Translated path="logout" />
      </HeaderProfileNavigationItem>

      <div className="HeaderProfileNavigation__lang">
        {langs.map((lang) => (
          <span
            key={`HeaderProfileNavigation-lang-${lang.short}`}
            className={classnames("HeaderProfileNavigation__lang__item", {
              "HeaderProfileNavigation__lang__item--active": lang.short === currentLang,
            })}
            onClick={changeLanguage(lang.short)}
          >
            {lang.label}
          </span>
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = ({ lang, auth }) => ({
  currentLang: lang.currentLang,
  role: auth.data.role,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setLang, performLogout }, dispatch);
export default compose(
  intl("modules.App.HeaderProfileNavigation"),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderProfileNavigation);
