import get from "lodash/get";

export const getInitialsFromName = (name, surname) => {
  const nameFirstLetter = get(name, "[0]") || "";
  const surnameFirstLetter = get(surname, "[0]") || "";
  return `${nameFirstLetter}${surnameFirstLetter}`;
};

export const stringToHslColor = (name, surname) => {
  const s = 30;
  const l = 80;
  const combinedString = `${name}${surname}`;
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    hash = combinedString.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsla(${h},${s}%, ${l}%, 0.4)`;
};
