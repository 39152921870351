import React, { PureComponent } from "react";
import { Close, UserAdd } from "shared/components/Icons";
import { stringToHslColor } from "shared/utils/avatar";
import Avatar from "shared/components/Avatar";
import "./styles.scss";

class ModalListUser extends PureComponent {
  render() {
    const { label, children, assigned, isLoading, onAdd, onDelete, name, surname } = this.props;

    return (
      <div className="ModalListUser">
        <Avatar color={stringToHslColor(name, surname)}>{label}</Avatar>
        <div className="ModalListUser__name">{children}</div>
        {!assigned && !isLoading && (
          <div className="ModalListUser__actions">
            {!isLoading && (
              <button className="ModalListUser__actions__button" onClick={onAdd}>
                <UserAdd />
              </button>
            )}
          </div>
        )}
        {assigned && !isLoading && (
          <div className="ModalListUser__actions">
            <button className="ModalListUser__actions__button" onClick={onDelete}>
              <Close />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ModalListUser;
