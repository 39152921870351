import axios from "axios";
import storeJS from "store";
import get from "lodash/get";
import { store } from "store/index.js";
import { STORAGE_AUTH_KEY } from "shared/constants/auth";
import { performRefreshToken, performLogout } from "modules/Auth/reducers/login";

export default ({ baseUrl, headers: newHeaders = {} }) => {
  const auth = storeJS.get(STORAGE_AUTH_KEY);
  const appStore = store.getState();
  const { token, refresh_token } = get(appStore, "auth.data");
  const authToken = get(auth, "access_token") || token;
  const authRefreshToken = get(auth, "refresh_token") || refresh_token;
  const basicToken = `Basic ${btoa(`test:test`)}`;
  const headers = {
    Authorization: authToken ? `Bearer ${authToken}` : basicToken,
    ...newHeaders,
  };

  const instance = axios.create({
    baseURL: baseUrl || process.env.REACT_APP_BACKEND_URL,
    headers,
  });
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401 && error.response.data.error === "invalid_token") {
        const refreshTokenStates = get(appStore, "auth.login.refreshToken");
        if (!refreshTokenStates.isLoading && !refreshTokenStates.hasError) {
          store.dispatch(performRefreshToken({ refreshToken: authRefreshToken }));
        }
        if (refreshTokenStates.hasError) {
          store.dispatch(performLogout());
        }
      }
      return Promise.reject({ ...error.response.data, type: "API_SERVICE" });
    }
  );
  return instance;
};

export const getError = (response) => {
  let result = {
    response: get(response, "errors[0]") || response.error || "error",
  };

  if (response.error === "invalid_token") {
    result.type = "UNAUTHORIZED_TOKEN";
  }

  // Expired password validity
  if (response.error === "invalid_grant" && response.error_description === "User account is locked") {
    result.response = "expired_password";
    result.type = "LOCKED_ACCOUNT";
  }
  // Same Password
  if (response.message === "same_password") {
    result.response = "same_password";
  }

  return result;
};
