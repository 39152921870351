import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { moment } from "shared/services/date";
import RequestDetails from "shared/containers/RequestDetails";
import { routes } from "shared/constants/routes";
import { performRequestsFetch } from "./reducers/requests";
import { intl } from "shared/hoc/Intl";
import ExtFullCalendar from "shared/containers/ExtFullCalendar";
import "./styles.scss";

class Calendar extends PureComponent {
  constructor(props) {
    super(props);
    const currentDate = moment();
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;
    // y = w.innerHeight || e.clientHeight || g.clientHeight;
    this.windowWidth = x;
    // this.windowHeight = y;
    const { id } = props.match.params;
    this.state = {
      detailsModal: !!id,
      currentDate,
      startWeek: moment(currentDate).utc().startOf("isoWeek"),
      endWeek: moment(currentDate).utc().endOf("isoWeek"),
    };
  }

  componentDidMount() {
    this.props.performRequestsFetch(this.state.startWeek, this.state.endWeek);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const { id: prevId } = prevProps.match.params;

    if (id && prevId !== id) {
      this.setState({ detailsModal: true });
    }
  }

  onClose = () => {
    this.props.history.push(routes.calendar.default.path);
    this.setState({ detailsModal: false });
  };

  setDateRange = (dateRange) => {
    this.setState({
      startWeek: moment(dateRange.start),
      endWeek: moment(dateRange.end),
    });
    this.props.performRequestsFetch(this.state.startWeek, this.state.endWeek);
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <div className="Calendar">
        <RequestDetails isOpen={this.state.detailsModal} onClose={this.onClose} id={id} history={this.props.history} />
        <ExtFullCalendar
          requestsList={this.props.requests}
          history={this.props.history}
          setDateRangeFunction={this.setDateRange}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ calendar }) => ({
  requests: calendar.requests.data.rows,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ performRequestsFetch }, dispatch);

export default compose(intl("modules.Calendar"), connect(mapStateToProps, mapDispatchToProps))(Calendar);
