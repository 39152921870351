import React, { PureComponent } from "react";
import { Bar } from "react-chartjs-2";
import map from "lodash/map";
import Translated from "shared/components/Translated";
import round from "lodash/round";
import get from "lodash/get";
import Box from "modules/Dashboard/components/Box";
import "./styles.scss";

class DoneRequests extends PureComponent {
  state = {
    rectangleSet: false,
  };
  prepareData = () => {
    const { statuses } = this.props;
    const labels = map(statuses, ({ companyName }) => companyName);
    const data = {
      labels,
      datasets: [
        {
          barPercentage: 0.4,
          barThickness: "flex",
          // backgroundColor: '#17d1af',
          data: map(statuses, ({ doneCount, totalCount }) => round((doneCount / totalCount) * 100)),
        },
      ],
    };
    return data;
  };

  calculateWidth = () => {
    const { statuses } = this.props;
    let length = get(statuses, "length");
    if (!length) {
      return null;
    }
    if (this.refs.wrapper) {
      if (get(this.refs.wrapper, "offsetWidth") > length * 50) {
        return this.refs.wrapper.offsetWidth;
      }
    }
    return length * 50;
  };

  // Method for ChartJS to display Scale seperatly
  onComplete = () => {
    if (!this.refs.doneRequestChart || this.isResponsive()) {
      return false;
    }
    const chartTest = this.refs.doneRequestChart.chartInstance;
    if (!this.state.rectangleSet) {
      const scale = window.devicePixelRatio;

      const sourceCanvas = chartTest.chart.canvas;
      const copyWidth = chartTest.scales["y-axis-0"].width - 10;
      const copyHeight = chartTest.scales["y-axis-0"].height + chartTest.scales["y-axis-0"].top + 10;

      const targetCtx = this.refs.doneRequestScale.getContext("2d");
      targetCtx.scale(scale, scale);
      targetCtx.canvas.height = copyHeight * scale;

      targetCtx.canvas.style.height = `${copyHeight}px`;
      targetCtx.drawImage(
        sourceCanvas,
        0,
        0,
        copyWidth * scale,
        copyHeight * scale,
        0,
        0,
        copyWidth * scale,
        copyHeight * scale
      );
      this.setState({
        rectangleSet: true,
      });
    }
  };

  // Method for ChartJS to display Scale seperatly
  onProgress = () => {
    const chartTest = this.refs.doneRequestChart.chartInstance;
    if (this.state.rectangleSet === true) {
      const copyWidth = chartTest.scales["y-axis-0"].width;
      const copyHeight = chartTest.scales["y-axis-0"].height + chartTest.scales["y-axis-0"].top + 10;

      const sourceCtx = chartTest.chart.canvas.getContext("2d");
      sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
    }
  };

  isResponsive = () => {
    const { statuses = [] } = this.props;
    let length = get(statuses, "length");
    if (get(this.refs.wrapper, "offsetWidth") > length * 50) {
      return true;
    }
    return false;
  };

  getOptions = () => ({
    legend: false,
    aspectRatio: 0.5,
    maintainAspectRatio: false,
    responsive: this.isResponsive(),
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSize: 10,
          },
        },
      ],
    },

    animation: {
      onComplete: this.onComplete,
      onProgress: this.onProgress,
    },
    plugins: {
      colorschemes: {
        scheme: "tableau.ClassicCyclic13",
        reverse: true,
      },
    },
  });

  render() {
    return (
      <Box title={<Translated path="doneRequests" />} subTitle={<Translated path="percentDonePerSent" />}>
        <div ref="wrapper">
          <div className="DashboardDoneRequests">
            <div className="DashboardDoneRequests__wrapper">
              <Bar
                ref="doneRequestChart"
                data={this.prepareData()}
                id="doneRequestChart"
                options={this.getOptions()}
                height={400}
                redraw
                width={this.calculateWidth()}
              />
            </div>
            <div className="DashboardDoneRequests__legend">
              <canvas ref="doneRequestScale" height={400} width={600} />
            </div>
          </div>
        </div>
      </Box>
    );
  }
}

export default DoneRequests;
