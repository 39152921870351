import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { USERS_FETCH } from "modules/Users/reducers/list/";
import forEach from "lodash/forEach";
import { objToParams } from "shared/utils/api";

function* fetchList() {
  try {
    const { filter, sort, pager, ...filters } = yield select(({ users }) => users.list.filters);
    const params = {};
    forEach(sort, (value, key) => (params[`sort.${key}`] = value));
    forEach(pager, (value, key) => (params[`pager.${key}`] = value));
    forEach(filter, (value, key) => (value ? (params[encodeURIComponent(`filter[${key}]`)] = value) : null));

    const queryParams = objToParams({ ...params, ...filters });
    const { data } = yield apiService({}).get(`/user?${queryParams}`);
    yield put({
      type: USERS_FETCH.SUCCESS,
      payload: {
        rows: data.data,
        total: data.total,
      },
    });
  } catch (err) {
    yield put({ type: USERS_FETCH.FAILURE, payload: getError(err) });
  }
}

function* fetchUsersListSaga() {
  yield takeEvery(USERS_FETCH.REQUEST, fetchList);
}

export default [fetchUsersListSaga];
