import map from "lodash/map";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { moment } from "shared/services/date";

// return an array of formated date for the graph legends in the dashboard
export const utilGetAllWeeks = (statuses) => {
  if (!statuses) {
    return [];
  } else if (statuses.length === 0) {
    return [];
  }
  const weeks = uniq(map(sortBy(map(statuses, ({ week }) => moment(week)))));
  const startDate = weeks[0];
  const endDate = weeks[weeks.length - 1];

  var weekArray = [];
  var currentWeek = startDate;
  while (currentWeek.isSameOrBefore(endDate)) {
    weekArray.push(moment(currentWeek));
    currentWeek = moment(currentWeek.add(7, "days"));
  }
  return weekArray;
};
