import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { AUTH_PERFORM_FORGOT_PASSWORD } from "modules/Auth/reducers/forgotPassword/";

function* performForgotPassword(action) {
  try {
    const { data } = yield apiService({
      headers: {
        "Content-Type": "text/plain",
      },
    }).put(`/password/reset`, action.payload.email);

    yield put({
      type: AUTH_PERFORM_FORGOT_PASSWORD.SUCCESS,
      payload: { data, response: "success" },
    });
  } catch (err) {
    yield put({
      type: AUTH_PERFORM_FORGOT_PASSWORD.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(AUTH_PERFORM_FORGOT_PASSWORD.REQUEST, performForgotPassword);
  },
];
