import React, { PureComponent } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import { CSSTransition } from "react-transition-group";
import TextField from "shared/components/TextField";
import RadioField from "shared/components/SelectField";
import Translated from "shared/components/Translated";
import "./styles.scss";
import Button from "shared/components/Button";
import onClickOutside from "react-onclickoutside";

const FORM_NAME = "planning/REQUEST_FILTERS";

class RequestListFilters extends PureComponent {
  filter = () => {
    this.props.onFilter(this.props.formValues);
  };

  handleClickOutside = () => {
    if (this.props.isOpen) this.props.onClickOutside();
  };

  render() {
    const { sharedLang } = this.props;
    return (
      <CSSTransition in={this.props.isOpen} timeout={300} classNames="cssanim-global-fade" unmountOnExit>
        <div className="RequestListFilters">
          <div className="RequestListFilters__content">
            <Field
              name="contractorCompany"
              component={TextField}
              label={<Translated path="user.labels.contractorCompanyName" shared />}
            />

            <Field
              component={RadioField}
              name="witness"
              label={<Translated path="request.labels.witness.label" shared />}
              dataSource={[
                { label: get(sharedLang, "common.all"), value: "" },
                {
                  label: get(sharedLang, "request.labels.witness.hold"),
                  value: "false",
                },
                {
                  label: get(sharedLang, "request.labels.witness.witness"),
                  value: "true",
                },
              ]}
              isRequired
            />
            <Button onClick={this.filter} type="primary">
              <Translated path="buttons.search" shared />
            </Button>
          </div>
          {/* <div className="RequestListFilters__bg" /> */}
        </div>
      </CSSTransition>
    );
  }
}

export default compose(
  connect((state) => ({ formValues: getFormValues(FORM_NAME)(state) })),
  reduxForm({ form: FORM_NAME })
)(onClickOutside(RequestListFilters));
