import React from "react";
import { Field } from "redux-form";
import DateField from "shared/components/DateField";
import Translated from "shared/components/Translated";
// import SelectField from 'shared/components/SelectField';
import TextField from "shared/components/TextField";
import { Calendar, Mail, Phone } from "shared/components/Icons";

export default (props) => {
  let isNewOrDuplicate = false;
  if (props && props.isNewOrDuplicate) isNewOrDuplicate = props.isNewOrDuplicate;
  return (
    <div className="row">
      <div className="col-md-6">
        <Field
          component={TextField}
          label={<Translated path="headers.contractor.company" shared />}
          placeholder=""
          name="contractorCompany"
          isRequired
          disabled={!isNewOrDuplicate}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={DateField}
          name="createdAt"
          label={<Translated path="headers.contractor.receiptDate" shared />}
          iconLeft={<Calendar />}
          placeholder=""
          isRequired
          disabled={!isNewOrDuplicate}
          originalDate={props.originalDate}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={TextField}
          label={<Translated path="user.labels.name" shared />}
          placeholder=""
          name="contractorDetails.name"
          isRequired
          disabled={!isNewOrDuplicate}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={TextField}
          label={<Translated path="user.labels.surname" shared />}
          placeholder=""
          name="contractorDetails.surname"
          isRequired
          disabled={!isNewOrDuplicate}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={TextField}
          label={<Translated path="user.labels.email" shared />}
          placeholder=""
          name="contractorDetails.email"
          iconLeft={<Mail />}
          isRequired
          disabled={!isNewOrDuplicate}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={TextField}
          label={<Translated path="user.labels.phone" shared />}
          placeholder=""
          name="contractorDetails.phoneNumber"
          iconLeft={<Phone />}
          disabled={!isNewOrDuplicate}
        />
      </div>
    </div>
  );
};
