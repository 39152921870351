import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const REGISTER_VERIFY_TOKEN = createRequestTypes("register/VERIFY_TOKEN");

export const performTokenValidation = ({ token }) => ({
  type: REGISTER_VERIFY_TOKEN.REQUEST,
  payload: { token },
});

const initState = {
  states: loadingStates,
  response: null,
  user: {
    role: "",
    email: "",
  },
};

export default createReducer(initState, {
  [REGISTER_VERIFY_TOKEN.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [REGISTER_VERIFY_TOKEN.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: true,
        user: action.payload,
      },
    });
  },
  [REGISTER_VERIFY_TOKEN.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: false,
      },
    });
  },
});
