import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { ACCOUNT_FETCH_CURRENT, ACCOUNT_PROFILE_EDIT } from "modules/Account/reducers/profile/";

function* editUser(action) {
  try {
    const { data } = yield apiService({}).put(`/user/current`, action.payload);
    yield put({
      type: ACCOUNT_PROFILE_EDIT.SUCCESS,
      payload: { data, response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: ACCOUNT_PROFILE_EDIT.FAILURE, payload: getError(err) });
  }
}

function* fetchUser() {
  try {
    const { data } = yield apiService({}).get(`/user/current`);
    yield put({
      type: ACCOUNT_FETCH_CURRENT.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ACCOUNT_FETCH_CURRENT.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(ACCOUNT_PROFILE_EDIT.REQUEST, editUser);
  },

  function* () {
    yield takeEvery(ACCOUNT_FETCH_CURRENT.REQUEST, fetchUser);
  },
];
