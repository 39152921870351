import React, { PureComponent } from "react";
// import { DragDropContext } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import RequestDetails from "shared/containers/RequestDetails";
import { routes } from "shared/constants/routes";
import RequestList from "./containers/RequestList";
import Calendar from "./containers/Calendar";
import { performPendingRequestFetch } from "modules/Planning/reducers/requests";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import "./styles.scss";

export class Planning extends PureComponent {
  constructor(props) {
    super(props);
    this.props.performPendingRequestFetch();
    const { id } = this.props.match.params;
    this.state = {
      detailsModal: !!id,
      expand: true,
      key: 0,
    };
    this.planning = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const { id: prevId } = prevProps.match.params;

    if (id && prevId !== id) {
      this.setState({ detailsModal: true });
    }
  }

  onClose = () => {
    this.props.history.push(routes.planning.default.path);
    this.setState({ detailsModal: false });
  };

  refreshList = () => {
    this.setState({ key: Math.random() });
  };

  toggleExpand = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        expand: !prevState.expand,
      };
    });

  delegatePerformPendingRequestFetch = (values) => {
    this.props.performPendingRequestFetch(values);
  };

  render() {
    const { id } = this.props.match.params;
    const { dataRequestsPending } = this.props;
    return (
      <div className="Planning">
        <RequestDetails isOpen={this.state.detailsModal} onClose={this.onClose} id={id} history={this.props.history} />
        <div
          ref={this.planning}
          className="Planning__requests"
          style={{
            marginLeft: this.state.expand ? "0" : window.innerWidth * 0.3 >= 340 ? "-290px" : "calc(-30% + 50px) ",
          }}
        >
          <RequestList
            history={this.props.history}
            toggleExpand={this.toggleExpand}
            expand={this.state.expand}
            refresh={this.state.key}
            dataRequestsPending={dataRequestsPending}
            delegatePerformPendingRequestFetch={this.delegatePerformPendingRequestFetch}
          />
        </div>
        <div className="Planning__calendar">
          <Calendar {...this.props} refresh={this.refreshList} dataRequestsPending={dataRequestsPending} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ planning }) => ({
  dataRequestsPending: planning.requests.pending.data.rows,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      performPendingRequestFetch,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(Planning);
