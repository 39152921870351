import React from "react";
import "./styles.scss";
export default ({ label1, value1, label2, value2 }) => (
  <div className="RequestDetailsRow">
    <div className="RowElement">
      <div className="RequestDetailsRow__label">{label1}</div>
      <div className="RequestDetailsRow__value">{value1}</div>
    </div>
    <div className="RowElement">
      <div className="RequestDetailsRow__label">{label2}</div>
      <div className="RequestDetailsRow__value">{value2}</div>
    </div>
  </div>
);
