import React, { PureComponent } from "react";
import Translated from "shared/components/Translated";
import FieldWrapper from "shared/components/FieldWrapper";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { change } from "redux-form";
import { REQUEST_CREATE_FORM_NAME } from "shared/constants/request";
import { MAX_LENGTH_TEXTAREA } from "shared/constants/request.js";
import "./styles.scss";

class TextArea extends PureComponent {
  state = {
    isOverflow: false,
  };

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  maxLengthTextarea = (id, maxLength) => {
    var element = document.getElementById(id);
    if (element && element.value.length > maxLength) {
      element.value = element.value.substring(0, maxLength);
      this.setState({ isOverflow: true });
      this.props.dispatch(change(REQUEST_CREATE_FORM_NAME, "otherContactInformation", element.value));
    } else this.setState({ isOverflow: false });
  };

  render() {
    const { _cols, _rows, input, isLoading } = this.props;

    return (
      <div>
        <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading}>
          <textarea
            className="TextArea"
            onKeyUp={this.maxLengthTextarea(input.name, MAX_LENGTH_TEXTAREA)}
            {...input}
            cols={_cols && !isNaN(_cols) ? _cols : ""}
            rows={_rows && !isNaN(_rows) ? _rows : ""}
            id={input.name}
          ></textarea>
        </FieldWrapper>
        {this.state.isOverflow && (
          <div className="warning">
            <Translated path="textAreaOverflow" />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(dispatch);

export default compose(connect(mapDispatchToProps))(TextArea);
