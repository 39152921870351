import React, { PureComponent } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues, change } from "redux-form";
import { Link } from "react-router-dom";
import Button from "shared/components/Button";
import { routes } from "shared/constants/routes";
import Notification from "shared/components/Notification";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import AuthTitle from "modules/Auth/components/AuthTitle";
import AuthTextField from "modules/Auth/components/AuthTextField";
import { performLogin } from "modules/Auth/reducers/login";
import formValidation from "./formValidation";
import "./styles.scss";

const FORM_NAME = "auth/login";

class Login extends PureComponent {
  login = () => {
    this.props.performLogin(this.props.formValues);
  };

  componentDidUpdate(prevProps) {
    if (this.props.hasError && !prevProps.hasError) {
      this.props.dispatch(change(FORM_NAME, "username", null));
      this.props.dispatch(change(FORM_NAME, "password", null));
    }
  }

  render() {
    const { handleSubmit, isLoading, submitSucceeded, submitFailed, isLoaded, response, hasError } = this.props;
    return (
      <div className="Login">
        <AuthTitle>
          <Translated path={"loginTitle"} />
        </AuthTitle>
        <form onSubmit={handleSubmit(this.login)}>
          <Field
            component={AuthTextField}
            name="username"
            aria-label="username"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder={this.props.lang.email}
          />
          <Field
            component={AuthTextField}
            name="password"
            type="password"
            aria-label="password"
            placeholder={this.props.lang.password}
          />
          <Button type="primary" fullWidth isLoading={isLoading}>
            <Translated path={"login"} />
          </Button>
          <Link className="Login__forgot-password" to={routes.auth.forgotPassword.path}>
            <Translated path={"forgotPassword"} />
          </Link>
          {(submitSucceeded || submitFailed) && (
            <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
              {<Translated path={response === undefined ? "invalid_grant" : response} />}
            </Notification>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, ...state }) => ({
  isLoading: auth.login.states.isLoading,
  isLoaded: auth.login.states.isLoaded,
  hasError: auth.login.states.hasError,
  response: auth.login.response,
  formValues: getFormValues(FORM_NAME)(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ performLogin }, dispatch);

export default compose(
  intl("modules.Auth.Login"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(Login);
