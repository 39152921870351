import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";
import map from "lodash/map";
import forEach from "lodash/forEach";
import filter from "lodash/filter";
import includes from "lodash/includes";
import uniqBy from "lodash/uniqBy";
import Button from "shared/components/Button";
import Translated from "shared/components/Translated";
import { moment, DATE_FORMAT } from "shared/services/date";
import SelectInspectors from "modules/Dashboard/components/SelectInspectors";
import Modal from "shared/components/Modal";
import Box from "modules/Dashboard/components/Box";
import "./styles.scss";
import "chartjs-plugin-colorschemes";
import { utilGetAllWeeks } from "shared/utils/dashboard.js";

class DoneRequestsByInspector extends PureComponent {
  state = {
    inspectorsModal: false,
    modalData: {},
    hiddenInspectors: [],
  };

  toggleModal = (key, modalData) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  get options() {
    return {
      legend: {
        position: "bottom",
      },
      aspectRatio: 3,
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 1,
            },
          },
        ],
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.ClassicCyclic13",
        },
      },
    };
  }

  get inspectors() {
    const { statuses } = this.props;
    const inspectors = uniqBy(statuses, "userId");
    return inspectors;
  }

  setHiddenInspectors = (hiddenInspectors) => {
    this.setState({ hiddenInspectors });
  };

  render() {
    const { statuses } = this.props;
    const byUser = {};
    forEach(statuses, (row) => {
      if (!byUser[row.userId]) {
        byUser[row.userId] = [row];
        return;
      }
      byUser[row.userId].push(row);
    });
    const filteredByUser = filter(byUser, (data) => !includes(this.state.hiddenInspectors, data[0].userId));

    const weeksLabels = map(utilGetAllWeeks(statuses), (d) => d.format(DATE_FORMAT));

    const filteredByUserWithWeeks = [];
    filteredByUser.forEach((u) => {
      const uWeeks = map(u, ({ week }) => moment(week).format(DATE_FORMAT));
      var tmp = [];
      weeksLabels.forEach((l) => {
        if (uWeeks.indexOf(l) > -1) {
          tmp.push(u.find((x) => moment(x.week).format(DATE_FORMAT) === l));
        } else {
          tmp.push({
            week: l,
            count: 0,
            email: u[0].email,
            name: u[0].name,
            surname: u[0].surname,
            userId: u[0].userId,
          });
        }
      });
      filteredByUserWithWeeks.push(tmp);
    });

    const data = {
      labels: weeksLabels,
      datasets: map(filteredByUserWithWeeks, (data, key) => ({
        label: `${data[0].name} ${data[0].surname}`,
        // borderColor: stringToHslColor(data[0].name, data[0].surname),
        backgroundColor: "white",
        pointBackgroundColor: "white",
        fill: false,
        lineTension: 0.1,
        data: map(data, ({ count, week }) => ({
          x: week,
          y: count,
        })),
      })),
    };

    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;

    return (
      <Box
        title={<Translated path="inspections" />}
        subTitle={<Translated path="doneInspectionsInPeriod" />}
        right={<Button onClick={this.toggleModal("inspectorsModal")}>Inspectors</Button>}
      >
        <Modal
          title={"Select Inspectors"}
          titleLeft
          isOpen={this.state.inspectorsModal}
          onClose={this.toggleModal("inspectorsModal")}
        >
          <SelectInspectors
            inspectors={this.inspectors}
            hiddenInspectors={this.state.hiddenInspectors}
            setHiddenInspectors={this.setHiddenInspectors}
          />
        </Modal>
        <div className="DoneRequestsByInspector">
          <Line data={data} options={this.options} height={x <= 812 ? 300 : null} />
        </div>
      </Box>
    );
  }
}

export default DoneRequestsByInspector;
