import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";
import filter from "lodash/filter";
import "./styles.scss";

class Tabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.children[0].props.id,
    };
  }

  setActive = (id) => () => this.setState({ activeTab: id });

  render() {
    const { children, overflow } = this.props;
    const { activeTab } = this.state;
    const contentClasses = classnames("Tabs__content", {
      "Tabs__content--overflow": overflow,
    });
    return (
      <div className="Tabs">
        <div className="Tabs__head">
          {map(children, (child) => {
            const { label, id } = child.props;
            const tabClasses = classnames("Tabs__head__item", {
              "Tabs__head__item--active": activeTab === id,
            });
            return (
              <div className={tabClasses} key={id} label={label} onClick={this.setActive(id)}>
                {label}
              </div>
            );
          })}
        </div>
        <div className={contentClasses}>
          {map(
            filter(children, (child) => child.props.id === activeTab),
            (child) => child.props.children
          )}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs;
