import React from "react";
import { Route, Redirect, withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { routes } from "shared/constants/routes";

export const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return rest.isAuthorized &&
          localStorage.getItem("auth") !== "undefined" &&
          localStorage.getItem("auth") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.auth.login.path,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

AuthorizedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({ isAuthorized: !!auth.data.role });
export default compose(withRouter, connect(mapStateToProps))(AuthorizedRoute);
