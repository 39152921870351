import React from "react";
import { BackArrow } from "shared/components/Icons";
import RotateLoader from "shared/components/RotateLoader";
import { Link } from "react-router-dom";
import "./styles.scss";

export default ({ backTo, title, children, buttons, isLoading }) => (
  <div className="SingleFormPage">
    <div className="row justify-content-center">
      <div className="col-md-8">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center align-items-center SingleFormPage__heading">
            <Link className="mr-auto" to={backTo}>
              <BackArrow />
            </Link>
            <h1 className="SingleFormPage__title mr-auto">{title}</h1>
          </div>

          <div className="col-md-12">
            <div className="SingleFormPage__content">
              {isLoading && (
                <div className="SingleFormPage__loader">
                  <RotateLoader relative />
                </div>
              )}
              {children}
            </div>
          </div>
          <div className="SingleFormPage__buttons col-md-12">{buttons}</div>
        </div>
      </div>
    </div>
  </div>
);

export const SingleFormSeparator = () => <div className="RequestsCreate__separator" />;
export const SingleFormSectionTitle = ({ children }) => <h2 className="RequestsCreate__section-title">{children}</h2>;
