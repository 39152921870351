import React from "react";
import ReactQuill from "react-quill-2";
import "react-quill-2/dist/quill.snow.css";
import "./styles.scss";

export function renderQuill({ input }) {
  return (
    <ReactQuill
      {...input}
      onChange={(newValue, delta, source) => {
        if (source === "user") {
          input.onChange(newValue);
        }
      }}
      onBlur={(range, source, quill) => {
        input.onBlur(quill.getHTML());
      }}
    />
  );
}
