import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Button from "shared/components/Button";
import { intl } from "shared/hoc/Intl";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import filter from "lodash/filter";
import Modal from "shared/components/Modal";
import { moment, DATE_TIME_FORMAT } from "shared/services/date";
import { REQUEST_STATUS_ASSIGNED_AS_DRAFT } from "shared/constants/request";
import { Calendar } from "shared/components/Icons";
import { approveRequests } from "modules/Planning/reducers/requests";
import "./styles.scss";
import Translated from "shared/components/Translated";

class CalendarHeading extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.isLoaded && prevProps.isLoading) {
      this.props.onClose();
    }
  }

  submit = () => {
    const ids = flatMap(this.props.events, (row) => row.id);

    this.props.approveRequests({ ids });
  };

  render() {
    const { isOpen, onClose, events, isLoading } = this.props;

    return (
      <Modal
        title={
          <div>
            <Translated path="areYouSure" />
          </div>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="ValidateRequests">
          <div className="ValidateRequests__events">
            {!events.length && "No events to validate"}
            {map(events, (event) => (
              <div className="ValidateRequests__event" key={`${event.id}-${event.projectName}`}>
                <div className="ValidateRequests__event__left">
                  <div className="ValidateRequests__event__title">{event.projectName}</div>
                  <div className="ValidateRequests__event__sub-title">{event.clientReference}</div>
                </div>
                <div className="ValidateRequests__event__date">
                  <Calendar />
                  <span>{moment(event.plannedDateBegin).format(DATE_TIME_FORMAT)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ValidateRequests__buttons">
          {!!events.length && (
            <Button type="primary" onClick={this.submit} isLoading={isLoading}>
              <Translated path="yesValidate" />
            </Button>
          )}
          <Button onClick={onClose}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ planning: { inspectors, requests } }) => ({
  isLoading: requests.approve.states.isLoading,
  isLoaded: requests.approve.states.isLoaded,
  events: flatMap(inspectors.data.rows, (row) => {
    const requests = filter(row.requests, (request) => request.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT);
    return requests;
  }),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ approveRequests }, dispatch);

export default compose(
  intl("modules.Planning.ValidateRequests"),
  connect(mapStateToProps, mapDispatchToProps)
)(CalendarHeading);
