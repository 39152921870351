import React from "react";
import { Line } from "react-chartjs-2";
import map from "lodash/map";
import Translated from "shared/components/Translated";
import { moment, DATE_FORMAT } from "shared/services/date";
import Box from "modules/Dashboard/components/Box";
import { REQUEST_COLORS } from "shared/constants/request";
import { utilGetAllWeeks } from "shared/utils/dashboard.js";
import "./styles.scss";

export default ({ statuses, lang }) => {
  const weeksLabels = map(utilGetAllWeeks(statuses), (d) => d.format(DATE_FORMAT));

  var allData = [];
  weeksLabels.forEach((l) => {
    if (map(statuses, ({ week }) => moment(week).format(DATE_FORMAT)).indexOf(l) > -1) {
      allData.push({
        x: l,
        y: statuses.find((s) => moment(s.week).format(DATE_FORMAT) === l).count,
      });
    } else {
      allData.push({
        x: l,
        y: 0,
      });
    }
  });

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

  const data = {
    labels: weeksLabels,
    datasets: [
      {
        fill: false,
        pointBackgroundColor: "white",
        pointBorderColor: REQUEST_COLORS["DONE"],
        borderColor: REQUEST_COLORS["DONE"],
        backgroundColor: "white",
        lineTension: 0.1,
        data: allData,
      },
    ],
  };

  const options = {
    legend: false,
    aspectRatio: 3,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };

  return (
    <Box title={<Translated path="inspections" />} subTitle={<Translated path="doneInspectionsInPeriod" />}>
      <div className="InspectorRequests">
        <Line data={data} options={options} height={x <= 812 ? 300 : null} />
      </div>
    </Box>
  );
};
