import Tour from "reactour";
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { intl } from "shared/hoc/Intl";
import { routes } from "shared/constants/routes";
import Translated from "shared/components/Translated";
import _ from "lodash";

import "./styles.scss";

import { ROLE_ADMIN, ROLE_CONTRACTOR, ROLE_INSPECTOR, ROLE_MANAGER } from "shared/constants/auth";

class CustomTour extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      disableInteraction: true,
    };
  }

  UNSAFE_componentWillMount() {
    const init_TourManager_state = {
      "/app/users": {
        ADMIN: {
          Intro: false,
          InviteUser: false,
          AssignInspector: false,
        },
      },
      "/app/counties": {
        ADMIN: {
          CountyRules: false,
        },
      },
      "/app/activities": {
        ADMIN: {
          Create: false,
        },
      },
      "/app/": {
        CONTRACTOR: {
          Intro: false,
        },
      },
      "/app/requests": {
        CONTRACTOR: {
          Create: false,
        },
        SHARED: {
          Requests: false,
        },
      },
      "/app/requests/create": {
        CONTRACTOR: {
          FillForm: false,
        },
        MANAGER: {
          FillForm: false,
        },
      },
      "/app/calendar": {
        INSPECTOR: {
          Intro: false,
        },
      },
      "/app/planning": {
        MANAGER: {
          Intro: false,
          Planning: false,
          Create: false,
        },
      },
    };
    let localStorageTM = JSON.parse(localStorage.getItem("TourManager"));
    if (!localStorage.getItem("TourManager")) {
      localStorage.setItem("TourManager", JSON.stringify(init_TourManager_state));
    } else {
      localStorage.setItem("TourManager", JSON.stringify(_.merge(init_TourManager_state, localStorageTM)));
    }
  }

  closeTourA = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem("TourManager", this.state.localStorageTourManagerOnClose);
    document.body.style.overflowY = "auto";
  };

  getAdminIntroSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.claimover.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.claimover.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.users.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.users.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".Navigation",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.menu.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.menu.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderNotifications__icon",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.notification.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.notification.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderProfile",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.profile.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.profile.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: '[href="/app/activities"]',
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.intro.activities.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.intro.activities.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    const lastStepNextButtonClick = () => this.props.history.push(routes.activities.default.path);
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getAdminCreateActivitySteps() {
    const steps = [
      {
        selector: ".btn-create-activity",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.createActivity.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.createActivity.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getAdminInviteUser() {
    const steps = [
      {
        selector: ".invite-user-btn",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.inviteUser.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.inviteUser.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getAdminAssignInspector() {
    const steps = [
      {
        selector: ".Table",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.assignInspector.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.assignInspector.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getAdminCountyRules() {
    const steps = [
      {
        selector: ".container",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.counties.s1.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.counties.s1.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".defaultCounty",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.counties.s2.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.counties.s2.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".Button--primary",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.admin.counties.s3.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.admin.counties.s2.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getContractorIntroSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.claimover.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.claimover.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.users.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.users.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".Navigation",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.menu.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.menu.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderNotifications__icon",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.notification.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.notification.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderProfile",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.profile.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.profile.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: '[href="/app/requests"]',
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.intro.activities.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.intro.activities.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    const lastStepNextButtonClick = () => this.props.history.push(routes.requests.default.path);
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getContactorOpenCreateRequestSteps() {
    const steps = [
      {
        selector: ".newRequest",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.createRequest.clickBtn.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.createRequest.clickBtn.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    const lastStepNextButtonClick = () => this.props.history.push(routes.requests.create.path);
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getContactorFillFormCreateRequestSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.createRequest.fillForm.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.createRequest.fillForm.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".SingleFormPage__buttons",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.supplier.createRequest.validateForm.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.supplier.createRequest.validateForm.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getInspectorIntroSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.claimover.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.claimover.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.users.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.users.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".Navigation",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.menu.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.menu.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderNotifications__icon",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.notification.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.notification.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderProfile",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.intro.profile.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.intro.profile.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    const lastStepNextButtonClick = () => this.props.history.push(routes.requests.default.path);
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getInspectorCalendarSteps() {
    const steps = [
      {
        selector: ".Calendar__list",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.inspector.calendar.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.inspector.calendar.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }
  getRequestsSteps() {
    const steps = [
      {
        selector: ".container",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.shared.requestsTable.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.shared.requestsTable.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".RequestsListSearch",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.shared.requestsTable.filterAndSearch.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.shared.requestsTable.filterAndSearch.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".btn-export-excel",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.shared.requestsTable.exportExcel.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.shared.requestsTable.exportExcel.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.shared.requestDetails.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.shared.requestDetails.intro.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getManagerIntroSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.claimover.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.claimover.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.users.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.users.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".Navigation",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.menu.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.menu.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderNotifications__icon",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.notification.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.notification.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".HeaderProfile",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.intro.profile.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.intro.profile.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getManagerPlanningSteps() {
    const steps = [
      {
        selector: ".Planning",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.planning.intro.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.planning.intro.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".PlanningRequestsList",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.planning.pending.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.planning.pending.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".CalendarHeading__right",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.planning.validate.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.planning.validate.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".TextField__input",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.planning.filter.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.planning.filter.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.planning.details.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.planning.details.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getManagerOpenCreateRequestSteps() {
    const steps = [
      {
        selector: ".newRequest",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.createRequest.clickBtn.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.createRequest.clickBtn.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    const lastStepNextButtonClick = () => this.props.history.push(routes.requests.create.path);
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  getManagerFillFormCreateRequestSteps() {
    const steps = [
      {
        selector: "",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.createRequest.fillForm.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.createRequest.fillForm.content" />
            </div>
          </Fragment>
        ),
      },
      {
        selector: ".SingleFormPage__buttons",
        content: () => (
          <Fragment>
            <h2>
              <Translated path="tutorial.manager.createRequest.validateForm.title" />
            </h2>
            <div className="content">
              <Translated path="tutorial.manager.createRequest.validateForm.content" />
            </div>
          </Fragment>
        ),
      },
    ];
    let lastStepNextButtonClick;
    return { steps: steps, lastStepNextButtonClick: lastStepNextButtonClick };
  }

  componentWillUnmount() {
    if (this.state.localStorageTourManagerOnClose) {
      localStorage.setItem("TourManager", this.state.localStorageTourManagerOnClose);
    }
  }

  getSteps(role, activeRoute) {
    /*
            get steps depending on active route, role and localstorage
        */

    let steps = { steps: [], lastStepNextButtonClick: undefined };
    let TourManager = JSON.parse(localStorage.getItem("TourManager"));
    //TODO : Faire un truc plus propre

    if (activeRoute === "/app/users" && role === ROLE_ADMIN && !TourManager["/app/users"]["ADMIN"]["Intro"]) {
      steps = this.getAdminIntroSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/users"]["ADMIN"]["Intro"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/activities" &&
      role === ROLE_ADMIN &&
      !TourManager["/app/activities"]["ADMIN"]["Create"]
    ) {
      steps = this.getAdminCreateActivitySteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/activities"]["ADMIN"]["Create"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/users" &&
      role === ROLE_ADMIN &&
      !TourManager["/app/users"]["ADMIN"]["InviteUser"]
    ) {
      steps = this.getAdminInviteUser();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/users"]["ADMIN"]["InviteUser"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/users" &&
      role === ROLE_ADMIN &&
      !TourManager["/app/users"]["ADMIN"]["AssignInspector"]
    ) {
      steps = this.getAdminAssignInspector();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/users"]["ADMIN"]["AssignInspector"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/requests" &&
      role === ROLE_CONTRACTOR &&
      !TourManager["/app/"]["CONTRACTOR"]["Intro"]
    ) {
      steps = this.getContractorIntroSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/"]["CONTRACTOR"]["Intro"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/requests" &&
      role === ROLE_CONTRACTOR &&
      !TourManager["/app/requests"]["CONTRACTOR"]["Create"]
    ) {
      steps = this.getContactorOpenCreateRequestSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/requests"]["CONTRACTOR"]["Create"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/requests/create" &&
      role === ROLE_CONTRACTOR &&
      !TourManager["/app/requests/create"]["CONTRACTOR"]["FillForm"]
    ) {
      steps = this.getContactorFillFormCreateRequestSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/requests/create"]["CONTRACTOR"]["FillForm"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/counties" &&
      role === ROLE_ADMIN &&
      !TourManager["/app/counties"]["ADMIN"]["CountyRules"]
    ) {
      steps = this.getAdminCountyRules();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/counties"]["ADMIN"]["CountyRules"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/calendar" &&
      role === ROLE_INSPECTOR &&
      !TourManager["/app/calendar"]["INSPECTOR"]["Intro"]
    ) {
      steps = this.getInspectorIntroSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/calendar"]["INSPECTOR"]["Intro"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/calendar" &&
      role === ROLE_INSPECTOR &&
      !TourManager["/app/calendar"]["INSPECTOR"]["Calendar"]
    ) {
      steps = this.getInspectorCalendarSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/calendar"]["INSPECTOR"]["Calendar"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/calendar" &&
      role === ROLE_INSPECTOR &&
      !TourManager["/app/calendar"]["INSPECTOR"]["Calendar"]
    ) {
      steps = this.getInspectorCalendarSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/calendar"]["INSPECTOR"]["Calendar"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (activeRoute === "/app/requests" && !TourManager["/app/requests"]["SHARED"]["Requests"]) {
      steps = this.getRequestsSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/requests"]["SHARED"]["Requests"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/planning" &&
      role === ROLE_MANAGER &&
      !TourManager["/app/planning"]["MANAGER"]["Intro"]
    ) {
      steps = this.getManagerIntroSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/planning"]["MANAGER"]["Intro"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/planning" &&
      role === ROLE_MANAGER &&
      !TourManager["/app/planning"]["MANAGER"]["Planning"]
    ) {
      steps = this.getManagerPlanningSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/planning"]["MANAGER"]["Planning"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/planning" &&
      role === ROLE_MANAGER &&
      !TourManager["/app/planning"]["MANAGER"]["Create"]
    ) {
      steps = this.getManagerOpenCreateRequestSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/planning"]["MANAGER"]["Create"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    } else if (
      activeRoute === "/app/requests/create" &&
      role === ROLE_MANAGER &&
      !TourManager["/app/requests/create"]["MANAGER"]["FillForm"]
    ) {
      steps = this.getManagerFillFormCreateRequestSteps();
      let tourManagerOnClose = TourManager;
      tourManagerOnClose["/app/requests/create"]["MANAGER"]["FillForm"] = true;
      this.setState({
        localStorageTourManagerOnClose: JSON.stringify(tourManagerOnClose),
      });
    }

    if (steps.steps.length > 0) {
      this.setState({ isTourOpen: true });
    }
    return steps;
  }

  render() {
    const { role, activeRoute } = this.props;

    const steps = this.getSteps(role, activeRoute);
    return (
      <Fragment>
        {steps.steps.length > 0 && (
          <Tour
            steps={steps.steps}
            className="tour-class"
            startAt={0}
            goToStep={0}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.closeTourA}
            onAfterOpen={(target) => (document.body.style.overflowY = "hidden")}
            onBeforeClose={(target) => (document.body.style.overflowY = "auto")}
            accentColor="#51dbb3"
            rounded={5}
            disableInteraction={this.state.disableInteraction}
            closeWithMask={false}
            lastStepNextButton={
              <div className="reactour__endButton" onClick={steps.lastStepNextButtonClick}>
                <Translated path="tutorial.endButton" />
              </div>
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ router, auth }, history) => ({
  role: auth.data.role,
  activeRoute: router.location.pathname,
  history: history.history,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(intl("modules.CustomTour"), connect(mapStateToProps, mapDispatchToProps))(CustomTour);
