import React from "react";
import PropTypes from "prop-types";
import Translated from "shared/components/Translated";
import "./styles.scss";

const FieldWarning = ({ meta }) => {
  if (!(meta.warning && meta.touched)) {
    return null;
  }
  return (
    <div className="FieldWarning">
      <Translated path={`formErrors.${meta.warning}`} />
    </div>
  );
};

FieldWarning.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

export default FieldWarning;
