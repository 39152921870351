import React, { PureComponent } from "react";
import Datetime from "react-datetime";
import FieldWrapper from "shared/components/FieldWrapper";

import "./styles.scss";

class DateTimeField extends PureComponent {
  render() {
    const { locale, input, inputProps } = this.props;
    return (
      <FieldWrapper
        {...this.props}
        isLoading={false}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Datetime {...input} {...inputProps} {...locale} />
      </FieldWrapper>
    );
  }
}

export default DateTimeField;
