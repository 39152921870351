import React, { PureComponent } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { Link } from "react-router-dom";
import Button from "shared/components/Button";
import { routes } from "shared/constants/routes";
import Notification from "shared/components/Notification";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import AuthTitle from "modules/Auth/components/AuthTitle";
import AuthTextField from "modules/Auth/components/AuthTextField";
import { performForgotPassword } from "modules/Auth/reducers/forgotPassword";
import formValidation from "./formValidation";
import "./styles.scss";

const FORM_NAME = "auth/FORGOT_PASSWORD";

class ForgotPassword extends PureComponent {
  performForgot = () => {
    this.props.performForgotPassword(this.props.formValues);
  };

  render() {
    const { handleSubmit, isLoading, submitSucceeded, submitFailed, isLoaded, hasError, response } = this.props;
    return (
      <div className="ForgotPassword">
        <AuthTitle>
          <Translated path={"title"} />
        </AuthTitle>
        <form onSubmit={handleSubmit(this.performForgot)}>
          <Field component={AuthTextField} name="email" placeholder={this.props.lang.email} />
          <Button type="primary" fullWidth isLoading={isLoading}>
            <Translated path={"remind"} />
          </Button>
          <Link className="ForgotPassword__login" to={routes.auth.login.path}>
            <Translated path={"login"} />
          </Link>
          {(submitSucceeded || submitFailed) && (
            <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
              {<Translated path={response} />}
            </Notification>
          )}
        </form>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, ...state }) => ({
  isLoading: auth.forgotPassword.states.isLoading,
  isLoaded: auth.forgotPassword.states.isLoaded,
  hasError: auth.forgotPassword.states.hasError,
  response: auth.forgotPassword.response,
  formValues: getFormValues(FORM_NAME)(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ performForgotPassword }, dispatch);
export default compose(
  intl("modules.Auth.ForgotPassword"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(ForgotPassword);
