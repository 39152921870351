import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues, getFormSyncErrors } from "redux-form";
import map from "lodash/map";
import filter from "lodash/filter";
import { bindActionCreators, compose } from "redux";
import SingleFormPage from "shared/components/SingleFormPage";
import Translated from "shared/components/Translated";
import { intl } from "shared/hoc/Intl";
import SelectField from "shared/components/SelectField";
import ConfirmModal from "shared/components/ConfirmModal";
import TextField from "shared/components/TextField";
import Button from "shared/components/Button";
import { redirections } from "shared/utils/request";
import { fetchManagers, performReAssignManager } from "modules/Requests/reducers/reassign";
import { performFetchOneRequest } from "shared/reducers/requests/requestDetails";
import formValidation from "./formValidation.js";
import "./styles.scss";

const FORM_NAME = "request/REASSIGN";

export class Reassign extends PureComponent {
  state = {
    confirmModal: false,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.performFetchOneRequest({ id });
    //#10uxum En tant que Manager je veux pouvoir réassigner une convocation sans prendre en compte l'activité
    this.props.fetchManagers();
  }

  //#10uxum En tant que Manager je veux pouvoir réassigner une convocation sans prendre en compte l'activité
  // componentDidUpdate(prevProps) {
  //   const { activityId } = this.props.data;
  //   if (!prevProps.data.activityId && activityId) {
  //     this.props.fetchManagers({ activityId });
  //   }
  // }

  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  reassign = () => {
    const { id: requestId } = this.props.match.params;
    const { manager: managerId } = this.props.formValues;
    this.props.performReAssignManager({ requestId, managerId });
  };

  cancel = () => {
    const { id } = this.props.match.params;
    const { role } = this.props;
    const path = redirections({ requestId: id });
    this.props.history.push(path[role]);
  };

  render() {
    const { id } = this.props.match.params;
    const { managers, isLoading, isAssigning, role } = this.props;
    const path = redirections({ requestId: id });
    return (
      <div>
        <ConfirmModal
          title={<Translated path="modal.areYouSure" shared />}
          isLoading={isAssigning}
          isOpen={this.state.confirmModal}
          paragraph={<Translated path="confirm" />}
          onClose={this.toggleModal}
          onConfirm={this.reassign}
          confirmLabel={<Translated path="buttons.confirm" />}
          cancelLabel={<Translated path="buttons.cancel" />}
        />
        <form onSubmit={this.props.handleSubmit(this.toggleModal)}>
          <SingleFormPage
            backTo={path[role]}
            isLoading={isLoading}
            title={<Translated path="title" />}
            buttons={
              <div className="row">
                <div className="col-md-12 justify-content-end d-flex RequestReassign__buttons">
                  <Button onClick={this.cancel}>
                    <Translated path="buttons.cancel" />
                  </Button>
                  <Button type="primary" isLoading={isAssigning}>
                    <Translated path="buttons.reassign" />
                  </Button>
                </div>
              </div>
            }
          >
            <Field
              component={TextField}
              name="projectName"
              label={<Translated path="request.labels.projectTitle" />}
              disabled
            />
            <Field
              component={SelectField}
              name="manager"
              dataSource={managers}
              required
              label={<Translated path="request.labels.manager" />}
              disabled={managers && managers.length < 1 ? "disabled" : ""}
            />
            {managers && managers.length < 1 && (
              <div className="warning">
                <Translated path="noManager" />
              </div>
            )}
          </SingleFormPage>
        </form>
      </div>
    );
  }
}

export const mapStateToProps = ({
  requests: {
    reassign: { managers, request, assignManager },
  },
  app: { user },
  auth,
  shared,
  ...state
}) => {
  const filteredManagers = filter(managers.data, (manager) => manager.userId !== user.data.userId);

  return {
    formValues: getFormValues(FORM_NAME)(state),
    data: shared.requestDetails.data,
    formErrors: getFormSyncErrors(FORM_NAME)(state),
    role: auth.data.role,
    isAssigning: assignManager.states.isLoading,
    isLoading: managers.states.isLoading || request.states.isLoading || shared.requestDetails.states.isLoading,
    managers: map(filteredManagers, (manager) => ({
      value: manager.userId,
      label: `${manager.name} ${manager.surname}`,
    })),
    initialValues: shared.requestDetails.data,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchManagers, performFetchOneRequest, performReAssignManager }, dispatch);

export default compose(
  intl("modules.Requests.Reassign"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: formValidation,
  })
)(Reassign);
