import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues, getFormSyncErrors } from "redux-form";
import { bindActionCreators, compose } from "redux";
import map from "lodash/map";
import includes from "lodash/includes";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import ConfirmModal from "shared/components/ConfirmModal";
import { readableDate } from "shared/services/date";
import GoogleField from "shared/components/GoogleField";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_PENDING_MANAGER,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_STATUS_ASSIGNED_AS_DRAFT,
  REQUEST_STATUS_ASSIGNED,
  REQUEST_CREATE_FORM_NAME,
} from "shared/constants/request";
import { ROLE_MANAGER, ROLE_CONTRACTOR } from "shared/constants/auth";
import { routes } from "shared/constants/routes";
import SingleFormPage, { SingleFormSeparator, SingleFormSectionTitle } from "shared/components/SingleFormPage";
import CreateDetailsForm from "modules/Requests/components/CreateDetailsForm";
import CreateEquipmentsForm from "modules/Requests/components/CreateEquipmentsForm";
import CreateInspectionDateForm from "modules/Requests/components/CreateInspectionDateForm";
import { performRequestCreation, cleanCreateRequestData } from "modules/Requests/reducers/create";
import { performFetchOneRequest } from "shared/reducers/requests/requestDetails";
import CreateOtherInformationForm from "modules/Requests/components/CreateOtherInformationForm";
import CreateContractorInformationsForm from "modules/Requests/components/CreateContractorInformationsForm";
import { errorChecks, warnChecks } from "./formValidate";
import { fetchActivitiesList } from "shared/reducers/activities";
import CreateButtons from "modules/Requests/components/CreateButtons";
import RequestProtector from "shared/hoc/RequestProtector";
import { redirections } from "shared/utils/request";
import update from "immutability-helper";
import { fetchCurrentProfile } from "modules/Account/reducers/profile";
import "./styles.scss";

import printPDF from "shared/utils/pdf.js";
import { VOID_COUNTY } from "shared/constants/county";

class Create extends PureComponent {
  state = {
    confirmationModal: false,
    modalData: {
      sendToManager: false,
    },
    submitValues: {},
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
    this.props.fetchCurrentProfile();
    const { id } = this.props.match.params;
    if (id) {
      this.props.performFetchOneRequest({ id });
    }
  }

  componentWillUnmount() {
    this.props.cleanCreateRequestData();
    this.props.destroy();
  }

  goBack = () => {
    this.props.history.push(routes.requests.list.path);
  };

  toggleModal = (key, modalData = {}) => (e) => {
    this.setState({ [key]: !this.state[key], modalData });
  };

  submit = (values) => {
    if (values && Object.keys(values).length > 0) {
      if (values.location && values.location.county === null) {
        values.location.county = VOID_COUNTY;
      }
      if (this.willBeReassigned()) {
        this.setState({ submitValues: values });
        if (includes([REQUEST_STATUS_DRAFT, REQUEST_STATUS_PENDING_SUBCONTRACTOR], this.props.data.status)) {
          this.toggleModal("confirmationModal", { sendToManager: true })();
        } else {
          this.toggleModal("confirmationModal")();
        }
        return;
      }
      this.create(values);
    }
  };

  create = (values) => {
    if (!this.props.isDuplicatePath) var id = this.props.match.params.id;
    const {
      modalData: { sendToManager },
    } = this.state;

    if (!(values && Object.keys(values).length > 0 && values.activityId)) {
      values = this.state.submitValues;
    }

    let isDraft =
      this.props.data.status === undefined ||
      this.props.data.status === null ||
      this.props.data.status === REQUEST_STATUS_DRAFT;
    if (isDraft & !id) {
      this.props.performRequestCreation({
        ...values,
        isDraft,
      });
    } else {
      this.props.performRequestCreation({
        ...values,
        id,
        sendToManager,
      });
    }
  };

  suggestedDateChanged = () => {
    const { data, formValues } = this.props;
    var isChanged =
      false ||
      readableDate(data.suggestedDateBegin) !== readableDate(formValues.suggestedDateBegin) ||
      readableDate(data.suggestedDateEnd) !== readableDate(formValues.suggestedDateEnd);
    return isChanged;
  };

  willBeReassigned = () => {
    const suggestedDateChanged = this.suggestedDateChanged();
    const statusAndDateChanged = includes(
      [REQUEST_STATUS_PENDING_MANAGER, REQUEST_STATUS_ASSIGNED_AS_DRAFT, REQUEST_STATUS_ASSIGNED],
      this.props.data.status
    );
    const otherConditions = includes(
      [REQUEST_STATUS_DRAFT, REQUEST_STATUS_PENDING_SUBCONTRACTOR],
      this.props.data.status
    );
    return (suggestedDateChanged && statusAndDateChanged) || otherConditions;
  };

  getPopupText = () => {
    const {
      data: { status },
      role,
    } = this.props;
    if (includes([REQUEST_STATUS_ASSIGNED], status) && role === ROLE_MANAGER) {
      return {
        title: <Translated path="confirmations.sendToContractor" />,
        paragraph: <Translated path="confirmations.manager.assigned" />,
      };
    }
    if (includes([REQUEST_STATUS_ASSIGNED], status) && role === ROLE_CONTRACTOR) {
      return {
        title: <Translated path="confirmations.sendToManager" />,
        paragraph: <Translated path="confirmations.contractor.assigned" />,
      };
    }
    if (includes([REQUEST_STATUS_PENDING_MANAGER, REQUEST_STATUS_ASSIGNED_AS_DRAFT], status)) {
      return role === ROLE_MANAGER
        ? {
            title: <Translated path="confirmations.sendToContractor" />,
            paragraph: <Translated path="confirmations.manager.pending" />,
          }
        : {
            title: <Translated path="confirmations.sendToManager" />,
            paragraph: <Translated path="confirmations.contractor.pending" />,
          };
    }
    if (includes([REQUEST_STATUS_PENDING_SUBCONTRACTOR], status)) {
      return {
        title: <Translated path="confirmations.sendToManager" />,
        paragraph: <Translated path="confirmations.contractor.assigned" />,
      };
    }
    return {
      title: <Translated path="confirmations.contractor.pending" />,
      paragraph: <Translated path="confirmations.contractor.assigned" />,
    };
  };

  getPopupLabel = () => {
    return "Send to manager";
  };

  onPrintPdf = () => {
    let { user, formValues } = this.props;
    let valOrDef = (value, def) => {
      return value ? value : def;
    };

    let managerDetails;
    if (formValues.managerDetails) {
      managerDetails = formValues.managerDetails;
    } else if (user.role === ROLE_MANAGER) {
      managerDetails = {
        email: user.email,
        name: user.name,
        surname: user.surname,
        phoneNumber: user.phoneNumber,
      };
    } else {
      managerDetails = { email: "", name: "", surname: "", phoneNumber: "" };
    }
    let contractorDetails;
    if (formValues.contractorDetails && formValues.contractorDetails.userId === null && user.role === ROLE_CONTRACTOR) {
      contractorDetails = {
        email: user.email,
        name: user.name,
        surname: user.surname,
        phoneNumber: user.phoneNumber,
      };
    } else {
      contractorDetails = {
        email: valOrDef(formValues.contractorDetails.email, ""),
        name: valOrDef(formValues.contractorDetails.name, ""),
        surname: valOrDef(formValues.contractorDetails.surname, ""),
        phoneNumber: valOrDef(formValues.contractorDetails.phoneNumber, ""),
      };
    }

    // In preview (creating/editing a request), the form values are string or undefined, and printPDF needs boolean value for the third party witness attribute
    if (formValues.thirdParties && Array.isArray(formValues.thirdParties)) {
      let result = [];
      formValues.thirdParties.forEach((elt) => {
        if (elt) {
          if (elt.witness) {
            if (elt.witness === "false") elt.witness = false;
            if (elt.witness === "true") elt.witness = true;
          } else {
            elt.witness = true;
          }
          result.push(elt);
        }
      });
      formValues.thirdParties = result;
    } else {
      formValues.thirdParties = [];
    }

    // init the data structure for printPDF
    let data = {
      status: valOrDef(formValues.status, ""),
      suggestedDateBegin: valOrDef(formValues.suggestedDateBegin, Date.now()),
      suggestedDateEnd: valOrDef(formValues.suggestedDateEnd, Date.now()),
      projectName: valOrDef(formValues.projectName, ""),
      clientReference: valOrDef(formValues.clientReference, ""),
      equipment: valOrDef(formValues.equipment, ""),
      equipmentParts: valOrDef(formValues.equipmentParts, ""),
      equipmentPartsNumber: valOrDef(formValues.equipmentPartsNumber, ""),
      followupDocumentRevisionNumber: valOrDef(formValues.followupDocumentRevisionNumber, ""),
      operationDesignation: valOrDef(formValues.operationDesignation, ""),
      otherContactInformation: valOrDef(formValues.otherContactInformation, ""),
      phaseNumber: valOrDef(formValues.phaseNumber, ""),
      subSubcontractorName: valOrDef(formValues.subSubcontractorName, ""),
      safety: valOrDef(formValues.safety, false),
      witness: valOrDef(formValues.witness, false),
      mailingList: valOrDef(formValues.mailingList, []),
      locationText: valOrDef(formValues.locationText, ""),
      managerDetails: managerDetails,
      contractorDetails: contractorDetails,
      contractorCompany: valOrDef(formValues.contractorCompany, ""),
      contractorReference: valOrDef(formValues.contractorReference, ""),
      thirdParties: valOrDef(formValues.thirdParties, []),
      history: valOrDef(formValues.history, []),
      filesList: valOrDef(formValues.filesList, []),
    };
    printPDF(data)(null);
  };

  render() {
    const {
      handleSubmit,
      lang,
      sharedLang,
      activities,
      isLoading,
      data,
      role,
      isDuplicatePath,
      createState,
    } = this.props;

    // this.goBack() was originally called in the this.create() function
    // but it was executed every time
    // As the states are updated one after another, and we can not synchronise on the event
    // we want to quit the create page when the form is submitted without error,
    // but stay on the create page when they are no manager available,
    // we have this if underneath with a lot of tested states at once to ensure this behaviour
    // Direct access to the states : getGlobalStates().requests.create.assignManager.states.hasError
    if (
      createState.response !== null &&
      createState.states.isLoaded === true &&
      createState.states.hasError === false &&
      data.managerDetails !== undefined &&
      createState.assignManager.response !== "NO_MANAGER_AVAILABLE" &&
      createState.assignManager.states.hasError === false
    ) {
      this.goBack();
    }

    const isDraft = data.status === REQUEST_STATUS_DRAFT || !data.id;

    const popupTexts = this.getPopupText();
    const isDraftPath = /draft/.test(this.props.location.pathname) || false;

    let conditionalTitle;
    if (isDuplicatePath) {
      conditionalTitle = <Translated path="titleDuplicate" />;
    } else if (isDraftPath) {
      conditionalTitle = <Translated path="titleEdit" />;
    } else {
      conditionalTitle = <Translated path="title" />;
    }

    const isNewOrDuplicate =
      (this.props.formValues.contractorDetails && this.props.formValues.contractorDetails.userId === null) ||
      isDuplicatePath;

    // WorkAround, the county is calculated by Google, but when you edit an user, the async calculation is longer than the refresh of the component
    // if the request exist or is a duplicate, take the county value from the request from DB
    // if the user is new or the GoogleField is empty, the user is forced to validate the input address and this workaround is without effect
    let initCounty;
    if (this.props.initialValues && this.props.initialValues.county) {
      initCounty = this.props.initialValues.county;
    }
    return (
      <div>
        <ConfirmModal
          title={popupTexts.title}
          isLoading={false}
          isOpen={this.state.confirmationModal}
          paragraph={popupTexts.paragraph}
          onClose={this.toggleModal("confirmationModal")}
          onConfirm={this.create}
          confirmLabel={<Translated path="confirmations.confirm" />}
          cancelLabel={<Translated path="buttons.cancel" />}
        />
        <form onSubmit={handleSubmit(this.submit)}>
          <SingleFormPage
            backTo={redirections({ requestId: "" })[role]}
            isLoading={isLoading}
            title={conditionalTitle}
            buttons={<CreateButtons {...this.props} onCancel={this.goBack} onPrintPdf={this.onPrintPdf} />}
          >
            {
              /* Manager can create a request, change the flow of the presentation */
              /* enclose in div would have change the presentation, hence the multiple "if" blocks to add the contractor informations */
              role === ROLE_MANAGER && (
                <SingleFormSectionTitle>
                  <Translated path="headers.contractor.informations" />
                </SingleFormSectionTitle>
              )
            }
            {
              /* cf. Manager can create a request */
              role === ROLE_MANAGER && (
                <CreateContractorInformationsForm
                  lang={lang}
                  sharedLang={sharedLang}
                  isNewOrDuplicate={isNewOrDuplicate}
                  originalDate={data.createdAt}
                />
              )
            }
            {
              /* cf. Manager can create a request */
              role === ROLE_MANAGER && <SingleFormSeparator />
            }
            <SingleFormSectionTitle>
              <Translated path="headers.details" />
            </SingleFormSectionTitle>
            <CreateDetailsForm lang={lang} sharedLang={sharedLang} activities={activities} isDraft={isDraft} />
            <SingleFormSeparator />
            <SingleFormSectionTitle>
              <Translated path="headers.location" />
            </SingleFormSectionTitle>

            <Field component={GoogleField} name="location" disabled={!isDraft} initCounty={initCounty} />

            <SingleFormSeparator />
            <SingleFormSectionTitle>
              <Translated path="headers.inspectionDate" />
            </SingleFormSectionTitle>
            <CreateInspectionDateForm lang={lang === "fr" ? "fr-fr" : lang} sharedLang={sharedLang} />

            <SingleFormSeparator />
            <SingleFormSectionTitle>
              <Translated path="headers.equipments" />
            </SingleFormSectionTitle>
            <CreateEquipmentsForm lang={lang} sharedLang={sharedLang} />

            <SingleFormSeparator />
            <SingleFormSectionTitle>
              <Translated path="headers.otherInformation" />
            </SingleFormSectionTitle>
            <CreateOtherInformationForm
              update={update}
              data={this.props.formValues}
              lang={lang}
              sharedLang={sharedLang}
            />
          </SingleFormPage>
        </form>
      </div>
    );
  }
}

// We use the request modify path to retrieve the data to duplicate,
// then we prune/modify the values to have a new "create" form filled with the values

const emptyContractorDetails = () => ({
  userId: null,
  email: null,
  name: null,
  surname: null,
  role: "CONTRACTOR",
  phoneNumber: null,
});

const todayNoonDate = () => {
  let tmp = new Date();
  tmp.setSeconds(0, 0);
  tmp.setHours(12);
  tmp.setMinutes(0);
  return tmp.toISOString();
};

// #20jdun manager creat request" needs contractorDetails (was unset before)
const unset = (data) => {
  return update(data, {
    $unset: ["id", "status", "managerDetails", "createdAt"],
    // #2anbbh createdAt is initialized with the current date on a duplicate request created by a manager
    createdAt: { $set: todayNoonDate() },
  });
};

// #20jdun manager creat request" create an empty contractorDetails on a new request
const set = (data) => {
  return update(data, {
    contractorDetails: { $set: emptyContractorDetails() },
  });
};

const duplicateData = (state, data) => {
  const isDuplicatePath = /duplicate/.test(state.router.location.pathname) || false;
  const isCreatePath = /create/.test(state.router.location.pathname) || false;
  if (isDuplicatePath) {
    data = unset(data);
  } else if (isCreatePath) {
    // #2anbbh createdAt is initialized with the current date on a new request created by a manager
    data = update(data, { createdAt: { $set: todayNoonDate() } });
  }
  if (data.contractorDetails === undefined) data = set(data);
  return data;
};

const mapStateToProps = (
  {
    requests: { create },
    account: {
      profile: { user },
    },
    auth: {
      data: { role },
    },
    shared,
    ...state
  },
  { match }
) => ({
  isDuplicatePath: /duplicate/.test(state.router.location.pathname) || false,
  formValues: duplicateData(state, getFormValues(REQUEST_CREATE_FORM_NAME)(state) || {}),
  role,
  user: user.data,
  isLoading:
    create.states.isLoading ||
    shared.activities.states.isLoading ||
    shared.requestDetails.states.isLoading ||
    create.assignManager.states.isLoading,
  assignManager: create.assignManager,
  loadingStates: create.states,
  isSaving: create.assignManager.states.isLoading || create.states.isLoading,
  response: create.response,
  activities: map(shared.activities.data.rows, ({ activityType, id }) => ({
    label: activityType,
    value: id,
  })),
  formErrors: getFormSyncErrors(REQUEST_CREATE_FORM_NAME)(state),
  initialValues: duplicateData(state, match.params.id ? shared.requestDetails.data : {}),
  data: duplicateData(state, match.params.id ? shared.requestDetails.data : {}),
  createState: create,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCurrentProfile,
      performRequestCreation,
      fetchActivitiesList,
      performFetchOneRequest,
      cleanCreateRequestData,
    },
    dispatch
  );

export default compose(
  intl("modules.Requests.Create"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: REQUEST_CREATE_FORM_NAME,
    validate: errorChecks,
    warn: warnChecks,
    enableReinitialize: true,
  }),
  RequestProtector
)(Create);
