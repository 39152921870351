import { combineReducers } from "redux";

import create from "./create";
import list from "./list";
import edit from "./edit";
import remove from "./remove";

export default combineReducers({
  create,
  list,
  edit,
  remove,
});
