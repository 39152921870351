import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const CALENDAR_REQUESTS_FETCH = createRequestTypes("calendar-inspector/FETCH_PENDING_REQUESTS");

export const performRequestsFetch = (startWeek, endWeek) => ({
  type: CALENDAR_REQUESTS_FETCH.REQUEST,
  payload: {
    startWeek: startWeek,
    endWeek: endWeek,
    // sort: {
    //   field: 'suggestedDateBegin',
    //   direction: 'DESC'
    // }
  },
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
};

export default createReducer(initState, {
  [CALENDAR_REQUESTS_FETCH.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [CALENDAR_REQUESTS_FETCH.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [CALENDAR_REQUESTS_FETCH.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
