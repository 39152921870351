import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { createRequestTypes } from "shared/utils/request";
import { loadingStates, setLoadingStates } from "shared/constants/redux";

export const COUNTIES_FETCH_LIST = createRequestTypes("counties/FETCH_LIST");

export const fetchCountiesList = ({ pager, query, sort, filter }) => ({
  type: COUNTIES_FETCH_LIST.REQUEST,
  payload: {
    filter,
    pager,
    query,
    sort,
  },
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  filters: {
    filter: {
      role: "",
      status: "",
    },
    pager: {
      page: 1,
      limit: 10,
    },
    query: "",
    sort: {
      field: "requestCounty",
      direction: "ASC",
    },
  },
  response: null,
};

const checkIfExists = (val) => !!(identity(val) || val === "");

export default createReducer(initState, {
  [COUNTIES_FETCH_LIST.REQUEST]: (state, action) => {
    const filters = update(state.filters, {
      $merge: {
        ...pickBy(action.payload, checkIfExists),
      },
    });
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
        filters,
      },
    });
  },
  [COUNTIES_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [COUNTIES_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
