import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const SHARED_ACTIVITIES_FETCH_LIST = createRequestTypes("shared/activities/FETCH_LIST");

export const fetchActivitiesList = (emptyOrToken) => ({
  type: SHARED_ACTIVITIES_FETCH_LIST.REQUEST,
  payload: emptyOrToken !== undefined || emptyOrToken !== null ? emptyOrToken : {},
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  response: null,
};

export default createReducer(initState, {
  [SHARED_ACTIVITIES_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [SHARED_ACTIVITIES_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [SHARED_ACTIVITIES_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
