import { put, takeEvery, select } from "redux-saga/effects";
import { performRequestFetch } from "modules/Requests/reducers/list";
import apiService, { getError } from "shared/services/api";
import { parseRequest } from "shared/services/request";
import {
  REQUESTS_FETCH_FEEDBACK,
  REQUESTS_MODIFY_FEEDBACK,
  REQUESTS_FETCH_ONE,
  REQUESTS_FETCH_DETAILS,
  REQUESTS_CANCEL_REQUEST,
  REQUESTS_DELETE_REQUEST,
  REQUESTS_DECLINE_REQUEST,
} from "shared/reducers/requests/requestDetails/";
import { deleteRequestInInspector } from "modules/Planning/reducers/inspectors";
import { mapRequestResponseToFormWithThirdPartie } from "shared/utils/request";

function* fetchFeedback(action) {
  try {
    const { data } = yield apiService({}).get(`/request/feedback/${action.payload.id}`);
    yield put({
      type: REQUESTS_FETCH_FEEDBACK.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REQUESTS_FETCH_FEEDBACK.FAILURE, payload: getError(err) });
  }
}

function* modifyFeedback(action) {
  try {
    const { data } = yield apiService({}).post(`/request/feedback`, action.payload.feedbackFormValues);
    yield put({
      type: REQUESTS_MODIFY_FEEDBACK.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REQUESTS_MODIFY_FEEDBACK.FAILURE, payload: getError(err) });
  }
}

function* fetchRequest(action) {
  try {
    const data = yield callFetchRequest(action);

    yield put({
      type: REQUESTS_FETCH_ONE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REQUESTS_FETCH_ONE.FAILURE, payload: getError(err) });
  }
}

function* callFetchRequest(action) {
  const { data } = yield apiService({}).get(`/request/${action.payload.id}`);
  return mapRequestResponseToFormWithThirdPartie(data);
}

function* fetchRequestWithHistory(action) {
  try {
    const {
      auth: {
        data: { role },
      },
    } = yield select();
    const data = yield callFetchRequest(action);
    const { data: history } = yield apiService({}).get(`/request/${action.payload.id}/history`);
    const response = parseRequest({ ...data, history }, role);
    yield put({
      type: REQUESTS_FETCH_DETAILS.SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({ type: REQUESTS_FETCH_DETAILS.FAILURE, payload: getError(err) });
  }
}

function* deleteRequest(action) {
  try {
    const { data } = yield apiService({}).delete(`/request/${action.payload.id}`);

    yield put(performRequestFetch({}));
    yield put({
      type: REQUESTS_DELETE_REQUEST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: REQUESTS_DELETE_REQUEST.FAILURE,
      payload: getError(err),
    });
  }
}

function* declineRequest(action) {
  try {
    const { data } = yield apiService({}).put(`/request/${action.payload.id}/decline`);

    yield put(performRequestFetch({}));
    yield put({
      type: REQUESTS_DECLINE_REQUEST.SUCCESS,
      payload: data,
    });
    yield put(deleteRequestInInspector({ id: action.payload.id }));
  } catch (err) {
    yield put({
      type: REQUESTS_DECLINE_REQUEST.FAILURE,
      payload: getError(err),
    });
  }
}

function* cancelRequest(action) {
  try {
    yield apiService({}).put(`/request/${action.payload.id}/cancel`);
    yield put(performRequestFetch({}));
    yield put({
      type: REQUESTS_CANCEL_REQUEST.SUCCESS,
    });
  } catch (err) {
    yield put({
      type: REQUESTS_CANCEL_REQUEST.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(REQUESTS_FETCH_FEEDBACK.REQUEST, fetchFeedback);
  },
  function* () {
    yield takeEvery(REQUESTS_MODIFY_FEEDBACK.REQUEST, modifyFeedback);
  },
  function* () {
    yield takeEvery(REQUESTS_FETCH_ONE.REQUEST, fetchRequest);
  },
  function* () {
    yield takeEvery(REQUESTS_FETCH_DETAILS.REQUEST, fetchRequestWithHistory);
  },
  function* () {
    yield takeEvery(REQUESTS_CANCEL_REQUEST.REQUEST, cancelRequest);
  },
  function* () {
    yield takeEvery(REQUESTS_DELETE_REQUEST.REQUEST, deleteRequest);
  },
  function* () {
    yield takeEvery(REQUESTS_DECLINE_REQUEST.REQUEST, declineRequest);
  },
];
