import React from "react";
import map from "lodash/map";
import Translated from "shared/components/Translated";
import { readableDate } from "shared/services/date";

export default ({ data }) => {
  return map(data.history, (row) => (
    <div className="RequestDetailsHistory" key={`${data.id}-history-${row.id}`}>
      <div className="RequestDetailsHistory__date">{readableDate(row.changeDate)}</div>
      <div className="RequestDetailsHistory__content">
        <Translated path={`request.history.statusChangedTo`} shared />{" "}
        <strong>
          <Translated path={`request.status.${row.toStatus}`} />
        </strong>
      </div>
    </div>
  ));
};
