import React, { PureComponent } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import get from "lodash/get";
import TextField from "shared/components/TextField";
import SelectField from "shared/components/SelectField";
import Translated from "shared/components/Translated";
import { intl } from "shared/hoc/Intl";
import {
  USER_ROLES,
  USER_STATUS_REGISTERED,
  USER_STATUS_PENDING,
  USER_STATUS_EXTERNAL,
  USER_STATUS_DISABLED,
} from "shared/constants/auth";
import { performUserFetch } from "modules/Users/reducers/list";
import "./styles.scss";

const FORM_NAME = "users/LIST_SEARCH";

class ListSearch extends PureComponent {
  constructor() {
    super();
    this.performChange = debounce(this.performSearch, 400);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formValues && prevProps.formValues !== this.props.formValues) {
      this.performChange();
    }
  }

  performSearch = () => {
    this.props.performUserFetch({
      ...this.props.formValues,
      pager: { page: 1, limit: this.props.filters.pager.limit },
    });
  };

  render() {
    const { lang, sharedLang } = this.props;
    return (
      <div className="UsersListSearch">
        <div className="row d-flex justify-content-end">
          <div className="col-md-3 col-sm-6">
            <Field
              component={SelectField}
              label={<Translated path="user.labels.status" shared />}
              labelLeft
              noMargin
              name="filter[status]"
              dataSource={[
                { value: "", label: get(sharedLang, "common.all") },
                {
                  value: USER_STATUS_REGISTERED,
                  label: get(sharedLang, "user.status.REGISTERED"),
                },
                {
                  value: USER_STATUS_PENDING,
                  label: get(sharedLang, "user.status.PENDING"),
                },
                {
                  value: USER_STATUS_EXTERNAL,
                  label: get(sharedLang, "user.status.EXTERNAL"),
                },
                {
                  value: USER_STATUS_DISABLED,
                  label: get(sharedLang, "user.status.DISABLED"),
                },
              ]}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <Field
              component={SelectField}
              label={<Translated path="user.labels.role" shared />}
              noMargin
              labelLeft
              name="filter[role]"
              dataSource={[{ value: "", label: "All" }, ...USER_ROLES(sharedLang)]}
            />
          </div>
          <div className="col-md-5">
            <Field component={TextField} placeholder={lang.searchPlaceholder} name="query" noMargin icon="scope" />
          </div>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = ({ users, ...state }) => ({
  filters: users.list.filters,
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: {
    query: "",
    "filter[role]": "",
    "filter[status]": "",
  },
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performUserFetch }, dispatch);
export default compose(
  intl("modules.Users.ListSearch"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME })
)(ListSearch);
