import { combineReducers } from "redux";

import login from "./login";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";
import data from "./data";

export default combineReducers({
  login,
  forgotPassword,
  resetPassword,
  data,
});
