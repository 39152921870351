import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import find from "lodash/find";
import TextField from "shared/components/TextField";
import FieldOptions from "shared/components/FieldOptions";
import "./styles.scss";

class SelectField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.refs.wrapper && !this.refs.wrapper.contains(event.target) && this.state.isOpen) {
      this.onClose(event);
    }
  };

  onClick = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  onClose = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ isOpen: false });
  };

  onSelect = (value) => () => {
    this.onClose();
    this.props.input.onChange(value);
  };

  render() {
    const { dataSource, ...props } = this.props;
    const classes = classnames("SelectField", {
      "SelectField--disabled": props.disabled,
    });

    const value = get(find(dataSource, { value: props.input.value }), "label") || props.input.value || "";
    const input = { ...props.input, value };

    return (
      <div className={classes} ref="wrapper" onClick={this.onClick}>
        <TextField {...props} input={input} icon="arrow_down" disabled hiddenDisabled onClick={this.onClick} />
        <FieldOptions
          dataSource={dataSource}
          isOpen={this.state.isOpen}
          onSelect={this.onSelect}
          name={input.name}
          currentValue={props.input.value}
        />
      </div>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    })
  ),
};

export default SelectField;
