import React from "react";
import Translated from "shared/components/Translated";
import { secondsToDays } from "shared/services/date";
import Box from "modules/Dashboard/components/Box";
import "./styles.scss";

export default ({ firstSentToInspectionDate, firstSentToLastAssigned }) => (
  <Box title={<Translated path="averageDelay" />}>
    <div className="DashboardAverageDelay">
      <div className="row">
        <div className="col-md-6">
          <div className="DashboardAverageDelay__time">
            {secondsToDays(firstSentToInspectionDate)}{" "}
            {firstSentToInspectionDate <= 86400 ? (
              <Translated path="averageDelayLessThanADay" />
            ) : (
              <Translated path="averageDelayDay" />
            )}
          </div>
          <div className="DashboardAverageDelay__description">
            <Translated path="averageDelayFirstSentToInspectionDate" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="DashboardAverageDelay__time">
            {secondsToDays(firstSentToLastAssigned)}{" "}
            {firstSentToInspectionDate <= 86400 ? (
              <Translated path="averageDelayLessThanADay" />
            ) : (
              <Translated path="averageDelayDay" />
            )}
          </div>
          <div className="DashboardAverageDelay__description">
            <Translated path="averageDelayFirstSentToLastAssigned" />
          </div>
        </div>
      </div>
    </div>
  </Box>
);
