import React from "react";
import classnames from "classnames";
import "./styles.scss";
import RotateLoader from "shared/components/RotateLoader";

export const Table = ({ children, isLoading }) => {
  const classes = classnames("Table", {
    "Table--is-loading": isLoading,
  });
  const loaderClasses = classnames("TableWrapper__loader", {
    "TableWrapper__loader--active": isLoading,
  });
  return (
    <div className="TableWrapper">
      <div className={loaderClasses}>{isLoading && <RotateLoader />}</div>
      <table className={classes}>{children}</table>
    </div>
  );
};
export const Tbody = ({ children }) => <tbody className="Tbody">{children}</tbody>;
export const Thead = ({ children }) => <thead className="Thead">{children}</thead>;
export const Th = ({ children, sortable, onSort, specialClass }) => {
  let classNameCustom = "Th";
  if (specialClass) {
    classNameCustom += " " + specialClass;
  }
  return (
    <th className={classNameCustom} onClick={onSort}>
      <div className="Th__childDiv">
        {children}
        {sortable && (
          <button className="Th__sort">
            <img src={require("assets/icons/sort.svg")} alt="sort" />
          </button>
        )}
      </div>
    </th>
  );
};
export const Tr = ({ children, onClick }) => (
  <tr className="Tr" onClick={onClick}>
    {children}
  </tr>
);
export const Td = ({ children, specialClass }) => {
  let classNameCustom = "Td";
  if (specialClass) {
    classNameCustom += " " + specialClass;
  }
  return <td className={classNameCustom}>{children}</td>;
};
