import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { PLANNING_FETCH_INSPECTORS, ASSIGN_INSPECTOR, UNASSIGN_INSPECTOR } from "modules/Planning/reducers/inspectors/";
import { performPendingRequestFetch } from "modules/Planning/reducers/requests";

function* assignInspector(action) {
  try {
    const { requestId, inspectorId, plannedDateBegin, plannedDateEnd } = action.payload;
    const { data } = yield apiService({}).put(`/request/${requestId}/assign-inspector`, {
      inspectorUserId: inspectorId,
      plannedDateBegin,
      plannedDateEnd,
    });
    yield put({
      type: ASSIGN_INSPECTOR.SUCCESS,
      payload: { request: data, inspectorId, requestId },
    });
  } catch (err) {
    yield put({ type: ASSIGN_INSPECTOR.FAILURE, payload: getError(err) });
  }
}

function* fetchList(action) {
  const { startDate, endDate } = action.payload;
  try {
    const { data } = yield apiService({}).get(`/inspector?startDate=${startDate}&endDate=${endDate}`);
    yield put({
      type: PLANNING_FETCH_INSPECTORS.SUCCESS,
      payload: {
        rows: data,
        total: data.length,
      },
    });
  } catch (err) {
    yield put({
      type: PLANNING_FETCH_INSPECTORS.FAILURE,
      payload: getError(err),
    });
  }
}

function* unAssignInspector(action) {
  try {
    const { requestId, inspectorId } = action.payload;

    const { data } = yield apiService({}).put(`/request/${requestId}/unassign-inspector`);

    yield put(performPendingRequestFetch());
    yield put({
      type: UNASSIGN_INSPECTOR.SUCCESS,
      payload: { request: data, requestId, inspectorId },
    });
  } catch (err) {
    yield put({ type: UNASSIGN_INSPECTOR.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(PLANNING_FETCH_INSPECTORS.REQUEST, fetchList);
  },
  function* () {
    yield takeEvery(ASSIGN_INSPECTOR.REQUEST, assignInspector);
  },
  function* () {
    yield takeEvery(UNASSIGN_INSPECTOR.REQUEST, unAssignInspector);
  },
];
