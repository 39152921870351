import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import Button from "shared/components/Button";
import "./styles.scss";

const ConfirmModal = ({ paragraph, isOpen, isLoading, onClose, onConfirm, title, confirmLabel, cancelLabel }) => {
  return (
    <CSSTransition in={isOpen} timeout={300} classNames="confirm-modal-anim" unmountOnExit>
      <div className="ConfirmModal">
        <button onClick={onClose} className="ConfirmModal__close">
          <i className="fa fa-close" />
        </button>
        <div className="ConfirmModal__wrapper">
          <div className="ConfirmModal__content">
            <div className="ConfirmModal__title">{title}</div>
            <div className="ConfirmModal__paragraph">{paragraph}</div>
            <div className="ConfirmModal__buttons">
              <Button onClick={onConfirm} type="red" isLoading={isLoading}>
                {confirmLabel}
              </Button>
              <Button onClick={onClose} type="white">
                {cancelLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
ConfirmModal.propTypes = {
  cancelLabel: PropTypes.node.isRequired,
  confirmLabel: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  paragraph: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
