import { ROLE_MANAGER, ROLE_CONTRACTOR, ROLE_ADMIN, ROLE_INSPECTOR, ROLE_SUPERADMIN } from "./auth";

export const AUTHORIZED_PATH = "/app/";
export const UNAUTHORIZED_PATH = "/auth/";
export const REGISTER_PATH = "/register";

export const routes = {
  app: {
    default: {
      title: "Default",
      path: "/app/",
    },
  },
  dashboard: {
    default: {
      title: "Dashboard",
      path: "/app/dashboard/",
    },
  },
  account: {
    default: {
      title: "Account",
      path: "/app/account/",
    },
  },
  contact: {
    default: {
      title: "Contact",
      path: "/app/contact/",
    },
  },
  calendar: {
    default: {
      title: "Calendar",
      path: "/app/calendar/",
    },
    details: ({ id }) => ({
      title: "Calendar",
      path: `/app/calendar/${id}`,
    }),
  },
  planning: {
    default: {
      title: "Planning",
      path: "/app/planning/",
    },
    details: ({ id }) => ({
      title: "Planning",
      path: `/app/planning/${id}`,
    }),
  },
  inspectors: {
    default: {
      title: "Inspectors",
      path: "/app/inspectors",
    },
  },
  activities: {
    default: {
      title: "Activities",
      path: "/app/activities",
    },
    list: {
      title: "Activities",
      path: "/app/activities",
    },
  },
  counties: {
    default: {
      title: "Counties",
      path: "/app/counties",
    },
    list: {
      title: "Counties",
      path: "/app/counties",
    },
  },
  companies: {
    default: {
      title: "Companies",
      path: "/app/companies",
    },
    list: {
      title: "Companies",
      path: "/app/companies",
    },
  },
  requests: {
    default: {
      title: "Requests",
      path: "/app/requests",
      roles: [ROLE_MANAGER, ROLE_CONTRACTOR],
    },
    details: (id) => ({
      title: "Details request",
      path: `/app/requests/${id}`,
      roles: [ROLE_MANAGER, ROLE_CONTRACTOR],
    }),
    reassign: (id) => ({
      title: "Reassign request",
      path: `/app/requests/reassign/${id}`,
      roles: [ROLE_MANAGER],
    }),
    list: {
      title: "Requests",
      path: `/app/requests`,
      roles: [ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR],
    },
    manage: (id) => ({
      title: "Create request",
      path: `/app/requests/${id}`,
      roles: [ROLE_MANAGER, ROLE_CONTRACTOR, ROLE_INSPECTOR],
    }),
    draft: (id) => ({
      title: "Create request",
      path: `/app/requests/draft/${id}`,
      roles: [ROLE_MANAGER, ROLE_CONTRACTOR],
    }),
    duplicate: (id) => ({
      title: "Duplicate request",
      path: `/app/requests/duplicate/${id}`,
      roles: [ROLE_MANAGER, ROLE_CONTRACTOR],
    }),
    create: {
      title: "Create request",
      path: "/app/requests/create",
      roles: [ROLE_CONTRACTOR],
    },
  },
  auth: {
    login: {
      title: "Login",
      path: "/auth/login",
    },
    register: {
      title: "Register",
      path: "/auth/register",
    },
    forgotPassword: {
      title: "Forgot Password",
      path: "/auth/forgot-password",
    },
    resetPassword: {
      title: "Reset Password",
      path: "/auth/reset-password/:token",
    },
  },
  users: {
    default: {
      title: "Users",
      path: "/app/users",
    },
    edit: (id) => ({
      title: "Edit user",
      path: `/app/users/edit/${id}`,
    }),
    list: {
      title: "Users",
      path: "/app/users/list",
    },
  },
};
export const baseRedirections = {
  [ROLE_MANAGER]: routes.planning.default.path,
  [ROLE_CONTRACTOR]: routes.requests.default.path,
  [ROLE_ADMIN]: routes.users.default.path,
  [ROLE_INSPECTOR]: routes.calendar.default.path,
  [ROLE_SUPERADMIN]: routes.companies.default.path,
};
