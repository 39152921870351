import React from "react";
import { Field } from "redux-form";
import TextField from "shared/components/TextField";
import RadioField from "shared/components/RadioField";
import Translated from "shared/components/Translated";
import SelectField from "shared/components/SelectField";
import TextArea from "shared/components/TextArea";
import get from "lodash/get";

export default ({ sharedLang, activities, isDraft }) => (
  <div className="row">
    <div className="col-md-12">
      <Field
        component={TextField}
        name="projectName"
        label={<Translated path="request.labels.projectTitle" shared />}
        isRequired
      />
    </div>
    <div className="col-md-6">
      <Field
        component={TextField}
        name="contractorReference"
        label={<Translated path="request.labels.subReference" shared />}
      />
    </div>
    <div className="col-md-6">
      <Field
        component={TextField}
        name="clientReference"
        label={<Translated path="request.labels.clientReference" shared />}
        isRequired
      />
    </div>
    <div className="col-md-12">
      <Field
        component={TextField}
        name="subSubcontractorName"
        label={<Translated path="request.labels.subSubContractorName" shared />}
      />
    </div>
    <div className="col-md-6">
      <Field
        component={TextField}
        name="phaseNumber"
        label={<Translated path="request.labels.phaseNumber" shared />}
        placeholder={get(sharedLang, "request.placeholders.phaseNumber")}
      />
    </div>
    <div className="col-md-6">
      <Field
        component={RadioField}
        name="witness"
        label={<Translated path="request.labels.witness.label" shared />}
        options={[
          {
            label: <Translated path="request.labels.witness.hold" shared />,
            value: false,
          },
          {
            label: <Translated path="request.labels.witness.witness" shared />,
            value: true,
          },
        ]}
        isRequired
      />
    </div>
    <div className="col-md-12">
      <Field
        component={TextField}
        name="followupDocumentRevisionNumber"
        label={<Translated path="request.labels.followupDocumentRevisionNumber" shared />}
      />
    </div>
    <div className="col-md-12">
      <Field
        component={TextArea}
        name="operationDesignation"
        label={<Translated path="request.labels.operationDesignation" shared />}
        placeholder=""
        _cols="200"
        _rows="5"
      />
    </div>
    <div className="col-md-12">
      <Field
        component={SelectField}
        name="activityId"
        dataSource={activities}
        label={<Translated path="request.labels.activity" shared />}
        isRequired
        disabled={!isDraft}
      />
    </div>
  </div>
);
