export default ({ username, password }) => {
  const errors = {};
  if (!username) {
    errors["username"] = "NO_EMAIL";
  }
  if (!password) {
    errors["password"] = "NO_PASSWORD";
  }
  return errors;
};
