import React from "react";
import Translated from "shared/components/Translated";
import RequestDetailsRow from "shared/components/RequestDetailsRow";
import RequestDetailsRowSeparator from "shared/components/RequestDetailsRowSeparator";

export default ({ data }) => (
  <div>
    <RequestDetailsRow
      label={<Translated path="request.modal.contactLabels.contractor.name" shared />}
      value={
        <span>
          {data.contractorDetails.name} {data.contractorDetails.surname}
        </span>
      }
    />
    <RequestDetailsRow
      label={<Translated path="request.modal.contactLabels.contractor.email" shared />}
      value={data.contractorDetails.email}
    />
    <RequestDetailsRow
      label={<Translated path="request.modal.contactLabels.contractor.phone" shared />}
      value={data.contractorDetails.phoneNumber}
    />
    {data.inspectorDetails && (
      <div>
        <RequestDetailsRowSeparator />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.inspector.name" shared />}
          value={
            <span>
              {data.inspectorDetails.name} {data.inspectorDetails.surname}
            </span>
          }
        />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.inspector.email" shared />}
          value={data.inspectorDetails.email}
        />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.inspector.phone" shared />}
          value={data.inspectorDetails.phoneNumber}
        />
      </div>
    )}
    {data.managerDetails && (
      <div>
        <RequestDetailsRowSeparator />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.manager.name" shared />}
          value={
            <span>
              {data.managerDetails.name} {data.managerDetails.surname}
            </span>
          }
        />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.manager.email" shared />}
          value={data.managerDetails.email}
        />
        <RequestDetailsRow
          label={<Translated path="request.modal.contactLabels.manager.phone" shared />}
          value={data.managerDetails.phoneNumber}
        />
      </div>
    )}
  </div>
);
