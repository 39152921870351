import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { deleteUserFromList } from "modules/Users/reducers/list";
import { USERS_REMOVE } from "modules/Users/reducers/remove/";

function* removeUser(action) {
  try {
    yield apiService({}).delete(`/user/${action.payload.id}`);
    yield put({ type: USERS_REMOVE.SUCCESS });
    yield put(deleteUserFromList({ id: action.payload.id }));
  } catch (err) {
    yield put({ type: USERS_REMOVE.FAILURE, payload: getError(err) });
  }
}

function* removeUserSaga() {
  yield takeEvery(USERS_REMOVE.REQUEST, removeUser);
}

export default [removeUserSaga];
