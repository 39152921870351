import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const REQUESTS_REASSIGN_PERFORM = createRequestTypes("requests/reassign/PERFORM");
export const REQUESTS_REASSIGN_FETCH_MANAGERS = createRequestTypes("requests/reassign/FETCH_MANAGERS");

// #10uxum En tant que Manager je veux pouvoir réassigner une convocation sans prendre en compte l'activité
// export const fetchManagers = ({ activityId }) => ({
export const fetchManagers = () => ({
  type: REQUESTS_REASSIGN_FETCH_MANAGERS.REQUEST,
  //  #10uxum :: payload: { activityId }
  payload: {},
});

export const performReAssignManager = ({ requestId, managerId }) => ({
  type: REQUESTS_REASSIGN_PERFORM.REQUEST,
  payload: {
    requestId,
    managerId,
  },
});

const initState = {
  managers: {
    states: loadingStates,
    data: [],
    response: null,
  },
  assignManager: {
    states: loadingStates,
    data: {},
    response: null,
  },
  request: {
    data: {},
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [REQUESTS_REASSIGN_FETCH_MANAGERS.REQUEST]: (state) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_REASSIGN_FETCH_MANAGERS.SUCCESS]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REQUESTS_REASSIGN_FETCH_MANAGERS.FAILURE]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_REASSIGN_PERFORM.REQUEST]: (state) => {
    return update(state, {
      assignManager: {
        $merge: {
          response: null,
          data: {},
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_REASSIGN_PERFORM.SUCCESS]: (state, action) => {
    return update(state, {
      assignManager: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_REASSIGN_PERFORM.FAILURE]: (state, action) => {
    return update(state, {
      assignManager: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
