import update from "immutability-helper";
import store from "store";
import { createRequestTypes } from "shared/utils/request";
import { LANG_STORE_KEY } from "shared/constants/lang";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { moment } from "shared/services/date";

export const SET_LANG = createRequestTypes("lang/SET_LANG");
export const INITIALIZE_LANG = createRequestTypes("lang/INITIALIZE");

export const setLang = ({ lang }) => ({
  type: SET_LANG.REQUEST,
  payload: { lang },
});

export const initialize = ({ lang }) => ({
  type: INITIALIZE_LANG.REQUEST,
  payload: { lang },
});

const initState = {
  initialized: false,
  currentLang: "",
  states: loadingStates,
};
const fromStore = store.get(LANG_STORE_KEY);
if (fromStore) {
  initState[fromStore.lang] = fromStore.data;
  initState.currentLang = fromStore.lang;
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case INITIALIZE_LANG.REQUEST:
      moment.locale(action.payload.lang);
      return update(state, {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      });
    case INITIALIZE_LANG.FAILURE:
      return update(state, {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      });
    case INITIALIZE_LANG.SUCCESS:
      return update(state, {
        $merge: {
          [action.payload.lang]: action.payload.data,
          currentLang: action.payload.lang,
          initialized: true,
          states: setLoadingStates({ isLoaded: true }),
        },
      });
    case SET_LANG.SUCCESS:
      moment.locale(action.payload.lang);
      return update(state, {
        $merge: {
          [action.payload.lang]: action.payload.data,
          currentLang: action.payload.lang,
        },
      });
    default:
      return state;
  }
};

export default reducer;
