import React, { PureComponent } from "react";
import get from "lodash/get";
import isString from "lodash/isString";
import { Google, getCountyPostalCodeByPlaceId } from "shared/services/google";
import { VOID_COUNTY } from "shared/constants/county";
import TextField from "shared/components/TextField";
import { fetchDefaultCounty } from "modules/Counties/reducers/defaultCounty";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./styles.scss";

class GoogleField extends PureComponent {
  state = { inputRef: null, isLoading: false, isInited: false };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.input.value && this.props.input.value.lat && !this.state.isInited) {
      this.props.fetchDefaultCounty();
      this.setState({ isLoading: true, isInited: true });
      const geocoder = new Google.maps.Geocoder();
      geocoder.geocode({ location: this.props.input.value }, (results, status) => {
        this.setState({ isLoading: false });
        if (results && results[0]) {
          const result = {
            name: results[0].formatted_address,
            lat: this.props.input.value.lat,
            lng: this.props.input.value.lng,
            county:
              this.props.initCounty ||
              get(this.props.input, "value.county") ||
              this.props.defaultCounty.managerCounty ||
              VOID_COUNTY,
          };
          this.props.input.onChange(result);
        }
      });
    }
    if (!prevState.inputRef && this.state.inputRef) {
      const autocomplete = new Google.maps.places.Autocomplete(this.state.inputRef, {
        types: ["address"],
      });
      Google.maps.event.addListener(autocomplete, "place_changed", this.onPlaceChanged(autocomplete));
    }
  }

  onPlaceChanged = (autocomplete) => async () => {
    const place = autocomplete.getPlace();
    if (place && place.geometry) {
      const { location } = place.geometry;
      const county = await getCountyPostalCodeByPlaceId(place.place_id, this.props.defaultCounty.managerCounty);
      const result = {
        name: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
        county,
      };
      this.props.input.onChange(result);
    }
  };

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onKeyUp = (ref) => (e) => {
    if (e.keyCode === 13) {
      let elem = document.getElementById("location");
      elem.blur();
      this.setRef(ref);
      elem.focus();
    }
  };

  setRef = (ref) => {
    this.setState({ inputRef: ref });
  };

  render() {
    const { label, meta, noMarginTop, noMargin, className, disabled, labelLeft, iconLeft, icon, ...props } = this.props;

    const value = get(props.input.value, "name") || props.input.value;
    const input = {
      ...props.input,
      value: isString(value) ? value : "",
    };

    return (
      <TextField
        meta={this.props.meta}
        input={input}
        setRef={this.setRef}
        label={label}
        isLoading={this.state.isLoading}
        onBlur={this.setRef(this.state.inputRef)}
        onFocus={this.setRef(this.state.inputRef)}
        onKeyUp={this.onKeyUp(this.state.inputRef)}
      />
    );
  }
}

GoogleField.propTypes = {};

// export default GoogleField;

const mapStateToProps = ({ counties }) => ({
  defaultCounty: counties.defaultCounty.value || VOID_COUNTY,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchDefaultCounty }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GoogleField);
