import { combineReducers } from "redux";

import list from "./list";
import edit from "./edit";
import invite from "./invite";
import remove from "./remove";
import details from "./details";
import recycleExpiredToken from "./recycleExpiredToken";

export default combineReducers({
  list,
  edit,
  invite,
  remove,
  details,
  recycleExpiredToken,
});
