import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import map from "lodash/map";
import get from "lodash/get";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { Field, reduxForm, getFormValues } from "redux-form";
import TextField from "shared/components/TextField";
import Tabs from "shared/components/Tabs";
import { getInitialsFromName } from "shared/utils/avatar";
import ModalListUser from "modules/Dashboard/components/ModalListUser";

import "./styles.scss";
export const FORM_NAME = "kpi/SEARCH_INSPECTOR";

class SelectInspectors extends PureComponent {
  filterInspectors = () => {
    const { formValues, inspectors } = this.props;
    const search = get(formValues, "search");
    if (search) {
      return filter(inspectors, ({ name, surname, email }) =>
        includes(toLower(`${name} ${surname} ${email}`), toLower(search))
      );
    }
    return inspectors;
  };

  onAdd = (id) => () => {
    const hiddenInspectors = filter(this.props.hiddenInspectors, (inspectorId) => inspectorId !== id);
    this.props.setHiddenInspectors(hiddenInspectors);
  };

  onDelete = (id) => () => {
    const { hiddenInspectors } = this.props;
    this.props.setHiddenInspectors([...hiddenInspectors, id]);
  };

  render() {
    const { hiddenInspectors } = this.props;
    const visibleInspectors = filter(this.filterInspectors(), ({ userId }) => !includes(hiddenInspectors, userId));
    const notVisibleInspectors = filter(this.filterInspectors(), ({ userId }) => includes(hiddenInspectors, userId));
    return (
      <div className="SelectInspectorsModal">
        <Tabs>
          <div label="Selected" id="assigned">
            <Field component={TextField} name="search" placeholder="Search for inspector" icon="scope" />
            {map(visibleInspectors, (inspector) => (
              <ModalListUser
                label={getInitialsFromName(inspector.name, inspector.surname)}
                key={`visible-inspector-${inspector.userId}`}
                id={inspector.userId}
                assigned
                onDelete={this.onDelete(inspector.userId)}
                onAdd={this.onAdd(inspector.userId)}
              >
                {inspector.name} {inspector.surname}
              </ModalListUser>
            ))}
          </div>
          <div label="Not selected" id="not-assigned">
            <Field component={TextField} name="search" placeholder="Search for inspector" icon="scope" />
            {map(notVisibleInspectors, (inspector) => (
              <ModalListUser
                label={getInitialsFromName(inspector.name, inspector.surname)}
                key={`hidden-inspector-${inspector.userId}`}
                id={inspector.userId}
                name={inspector.name}
                surname={inspector.surname}
                onDelete={this.onDelete(inspector.userId)}
                onAdd={this.onAdd(inspector.userId)}
              >
                {inspector.name} {inspector.surname}
              </ModalListUser>
            ))}
          </div>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = ({ activities, ...state }, ownProps) => ({
  states: activities.edit.states,
  initialValues: ownProps.data,
  formValues: getFormValues(FORM_NAME)(state),
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({ form: FORM_NAME }))(SelectInspectors);
