import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

class IosCheckboxField extends PureComponent {
  render() {
    const { input, meta, className, disabled, label, name, ...props } = this.props;

    const inputClasses = classnames("IosCheckboxField__input", {});
    const classes = classnames("IosCheckboxField", className, {});
    const boxClasses = classnames("IosCheckboxField__box", {
      "IosCheckboxField__box--active": input.value,
    });

    const id = `${name}-checkbox`;
    return (
      <label className={classes} htmlFor={id}>
        <span className="IosCheckboxField__label">{label}</span>
        <input type="checkbox" id={id} {...input} {...props} className={inputClasses} disabled={disabled} />
        <div className={boxClasses} />
      </label>
    );
  }
}

IosCheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default IosCheckboxField;
