import { passwordValidation } from "shared/utils/validation";

export default ({ confirmPassword, password }) => {
  const errors = {};
  if (!confirmPassword) {
    errors["confirmPassword"] = "FILL_IN";
  }
  if (!password) {
    errors["password"] = "FILL_IN";
  }
  if (!passwordValidation(password)) {
    errors.password = "WRONG_PASSWORD_FORMAT";
  }
  if (confirmPassword !== password) {
    errors.confirmPassword = "PASSWORDS_MUST_MATCH";
  }
  return errors;
};
