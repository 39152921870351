import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import map from "lodash/map";
import { intl } from "shared/hoc/Intl";
import { Table, Thead, Tbody, Tr, Td, Th } from "shared/components/Table";
import Pagination from "shared/components/Pagination";
import Modal from "shared/components/Modal";
import Button from "shared/components/Button";
import CreateCompanyModal from "modules/Companies/containers/CreateCompanyModal";
import EditCompanyModal from "modules/Companies/containers/EditCompanyModal";
import DeleteCompanyModal from "modules/Companies/containers/DeleteCompanyModal";
import { fetchCompaniesList } from "modules/Companies/reducers/list";
import Translated from "shared/components/Translated";

import "./styles.scss";

class List extends PureComponent {
  state = {
    editModal: false,
    createModal: false,
    deleteModal: false,
    modalData: {},
  };

  componentDidMount() {
    this.props.fetchCompaniesList();
  }

  toggleModal = (key, modalData) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  render() {
    const { isLoading, lang, data, total, pages } = this.props;
    return (
      <div className="CompaniesList">
        <Modal
          title={<Translated path="create" />}
          isOpen={this.state.createModal}
          onClose={this.toggleModal("createModal")}
        >
          <CreateCompanyModal onCancel={this.toggleModal("createModal")} />
        </Modal>
        <Modal title={<Translated path="edit" />} isOpen={this.state.editModal} onClose={this.toggleModal("editModal")}>
          <EditCompanyModal onCancel={this.toggleModal("editModal")} data={this.state.modalData} />
        </Modal>
        <Modal
          title={<Translated path="delete" />}
          isOpen={this.state.deleteModal}
          onClose={this.toggleModal("deleteModal", {})}
        >
          <DeleteCompanyModal onCancel={this.toggleModal("deleteModal")} data={this.state.modalData} />
        </Modal>
        <div className="container">
          <div className="CompaniesList__header d-flex align-items-center">
            <h1>
              {<Translated path="title" />} ({total})
            </h1>
            <Button icon="plus" type="primary" noMargin onClick={this.toggleModal("createModal")}>
              {lang.create}
            </Button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={isLoading}>
                <Thead>
                  <Tr>
                    <Th>{<Translated path="companyName" />}</Th>
                    <Th>{<Translated path="companyEmail" />}</Th>
                    <Th>{<Translated path="action" />}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data, (company) => (
                    <Tr key={`company-${company.id}`} onClick={this.toggleModal("detailsModal", company)}>
                      <Td>{company.name}</Td>
                      <Td>
                        {map(company.emails, (email) => (
                          <div>{email}</div>
                        ))}
                      </Td>
                      <Td>-</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination onPageChange={this.onPageChange} pages={pages} currentPage={1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ companies }) => ({
  data: companies.list.data.rows,
  total: companies.list.data.total,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchCompaniesList }, dispatch);

export default compose(intl("modules.Companies.List"), connect(mapStateToProps, mapDispatchToProps))(List);
