import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { intl } from "shared/hoc/Intl";
import map from "lodash/map";
import get from "lodash/get";
import includes from "lodash/includes";
import classnames from "classnames";
import queryString from "qs";
import { REQUEST_COLORS } from "shared/constants/request";
import SelectField from "shared/components/SelectField";
import Translated from "shared/components/Translated";
import RotateLoader from "shared/components/RotateLoader";
import { ROLE_MANAGER, ROLE_INSPECTOR, ROLE_CONTRACTOR } from "shared/constants/auth";
import { moment } from "shared/services/date";
import { routes } from "shared/constants/routes";
import AverageDelay from "./components/AverageDelay";
import RequestCount from "./components/RequestCount";
import DoneRequests from "./components/DoneRequests";
import PeriodRequests from "./components/PeriodRequests";
import InspectorRequests from "./components/InspectorRequests";
import DoneRequestsByInspector from "./components/DoneRequestsByInspector";
import InspectorStatusByDate from "./components/InspectorStatusByDate";
import { fetchKpi } from "./reducers/kpi";
import { langLabel } from "shared/components/Translated";
import { Calendar } from "shared/components/Icons";
import DateField from "shared/components/DateField";
import "./styles.scss";

const FORM_NAME = "DASHBOARD";

const NOT_SPECIFIED = "NOT_SPECIFIED";

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    const { startDate, endDate } = this.dashBoardDateRange(props.initialValues.weeks);

    this.state = {
      startDate,
      endDate,
      isScrolled: false,
      isTourOpen: false,
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.props.change("formStartDate", startDate);
    this.props.change("formEndDate", endDate);
    this.props.fetchKpi({
      startDate: startDate.format(),
      endDate: endDate.format(),
    });
    this.setState({ isTourOpen: !localStorage.getItem("DashboardTourDone") });
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  dashBoardDateRange = (substractWeeks = 0) => {
    const currentDate = moment();
    return {
      startDate: moment(currentDate)
        .utc()
        .startOf("isoWeek")
        .subtract(substractWeeks * 7, "days"),
      endDate: moment(currentDate).utc().endOf("isoWeek"),
    };
  };

  onScroll = (e) => {
    if (window.scrollY > 0 && !this.state.isScrolled) {
      this.setState({ isScrolled: true });
      return;
    }
    if (window.scrollY === 0 && this.state.isScrolled) {
      this.setState({ isScrolled: false });
      return;
    }
  };

  onChangeStartDate = (value) => {
    let startDate = moment(value).utc();
    this.props.change("weeks", NOT_SPECIFIED);
    this.setState({ startDate: startDate });
    this.props.fetchKpi({
      startDate: startDate.format(),
      endDate: this.state.endDate.format(),
    });
  };

  onChangeEndDate = (value) => {
    let endDate = moment(value).utc();
    this.props.change("weeks", NOT_SPECIFIED);
    this.setState({ endDate: endDate });
    this.props.fetchKpi({
      startDate: this.state.startDate.format(),
      endDate: endDate.format(),
    });
  };

  onChangeWeeks = (value) => {
    if (value !== NOT_SPECIFIED) {
      const { startDate, endDate } = this.dashBoardDateRange(value);
      this.props.change("formStartDate", startDate);
      this.props.change("formEndDate", endDate);
      this.setState({ startDate: startDate, endDate: endDate });
      this.props.fetchKpi({
        startDate: startDate.format(),
        endDate: endDate.format(),
      });
    }
  };

  getOptions = () => {
    return [
      { label: langLabel("customized", this.props), value: NOT_SPECIFIED },
      { label: "1 " + langLabel("week", this.props), value: 1 },
      { label: "4 " + langLabel("weeks", this.props), value: 4 },
      { label: "10 " + langLabel("weeks", this.props), value: 10 },
    ];
  };

  goToRequestList = (status) => (e) => {
    const { role } = this.props;
    e.preventDefault();
    if (!includes([ROLE_CONTRACTOR, ROLE_MANAGER], role)) {
      return;
    }
    this.props.history.push({
      pathname: routes.requests.list.path,
      search: queryString.stringify({ filter: { status } }),
    });
  };

  render() {
    const { data, sharedLang, isLoading, role } = this.props;
    const titleClasses = classnames("Dashboard__title", {
      "Dashboard__title--is-scrolled": this.state.isScrolled,
    });

    return (
      <div className="Dashboard">
        <div className={titleClasses}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-3">
                <Translated path="title" />
              </div>
              <div className="col-md-3">
                <Field
                  name="formStartDate"
                  component={DateField}
                  label={<Translated path="request.labels.fromDate" shared />}
                  iconLeft={<Calendar />}
                  placeholder=""
                  isRequired
                  onChange={this.onChangeStartDate}
                  originalDate={this.state.startDate}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="formEndDate"
                  component={DateField}
                  label={<Translated path="request.labels.toDate" shared />}
                  iconLeft={<Calendar />}
                  placeholder=""
                  isRequired
                  onChange={this.onChangeEndDate}
                  originalDate={this.state.endDate}
                />
              </div>
              <div className="col-md-3">
                <Field
                  component={SelectField}
                  dataSource={this.getOptions()}
                  name="weeks"
                  onChange={this.onChangeWeeks}
                  noMargin
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="Dashboard__content">
            {isLoading && (
              <div className="Dashboard__loader">
                <RotateLoader relative />
              </div>
            )}
            {includes([ROLE_MANAGER, ROLE_CONTRACTOR], role) && (
              <div className="DashboardStatus">
                {map(data.statusCountTotal, ({ status, count }) => (
                  <button
                    key={`dashboard-top-${status}`}
                    className="DashboardStatus__status"
                    style={{ borderBottomColor: REQUEST_COLORS[status] }}
                    onClick={this.goToRequestList(status)}
                  >
                    <div className="DashboardStatus__status__count">{count}</div>
                    <div className="DashboardStatus__status__label">{get(sharedLang, `request.status.${status}`)}</div>
                  </button>
                ))}
              </div>
            )}
            {includes([ROLE_MANAGER, ROLE_CONTRACTOR], role) && (
              <div className="row">
                <div className="col-md-6">
                  <RequestCount statuses={data.statusCountTotal} lang={sharedLang} />
                </div>
                <div className="col-md-6">
                  <AverageDelay
                    lang={sharedLang}
                    firstSentToInspectionDate={data.averageDelayFirstSentToInspectionDate}
                    firstSentToLastAssigned={data.averageDelayFirstSentToLastAssigned}
                  />
                </div>
              </div>
            )}
            {includes([ROLE_MANAGER, ROLE_CONTRACTOR], role) && (
              <div className="row Dashboard__mt">
                <div className="col-md-12">
                  <PeriodRequests lang={sharedLang} statuses={data.statusCountByManagerFirstAssignedDate} />
                </div>
              </div>
            )}
            {role === ROLE_INSPECTOR && (
              <div className="row Dashboard__mt">
                <div className="col-md-12">
                  <InspectorRequests lang={sharedLang} statuses={data.doneCountByPlannedDate} />
                </div>
              </div>
            )}
            {
              /* #1rhkdu disabled, waiting for decision */ false && role === ROLE_INSPECTOR && (
                <div className="row Dashboard__mt">
                  <div className="col-md-12">
                    <InspectorStatusByDate lang={sharedLang} statuses={data.statusCountByInspectorLastAssignedDate} />
                  </div>
                </div>
              )
            }
            {role === ROLE_MANAGER && (
              <div className="row Dashboard__mt">
                <div className="col-md-12">
                  <DoneRequestsByInspector
                    lang={sharedLang}
                    statuses={data.doneCountWithInspectorDetailsByPlannedDate}
                  />
                </div>
              </div>
            )}
            {role === ROLE_MANAGER && (
              <div className="row Dashboard__mt">
                <div className="col-md-12">
                  <DoneRequests lang={sharedLang} statuses={data.doneToTotalCountWithContractorDetail} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const dashboardWarnChecks = (fields) => {
  const warnings = {};
  let startDate = moment(fields.formStartDate);
  let endDate = moment(fields.formEndDate);
  if (endDate.isBefore(startDate)) {
    warnings["formStartDate"] = "END_DATE_BEFORE_START_DATE";
    warnings["formEndDate"] = "END_DATE_BEFORE_START_DATE";
  }
  return warnings;
};

export default compose(
  intl("modules.Dashboard"),
  connect(
    ({ auth, dashboard }) => ({
      role: auth.data.role,
      data: dashboard.kpi.data,
      isLoading: dashboard.kpi.states.isLoading,
      initialValues: {
        weeks: 10,
      },
    }),
    (dispatch) => bindActionCreators({ fetchKpi }, dispatch)
  ),
  reduxForm({ form: FORM_NAME, warn: dashboardWarnChecks })
)(Dashboard);
