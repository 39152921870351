import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { RECYCLE_EXPIRED_TOKEN } from "modules/Users/reducers/recycleExpiredToken";

function* recycleExpiredToken(action) {
  try {
    yield apiService({}).get(`/token/${action.payload.id}`);
    yield put({
      type: RECYCLE_EXPIRED_TOKEN.SUCCESS,
      payload: { response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: RECYCLE_EXPIRED_TOKEN.FAILURE, payload: getError(err) });
  }
}

function* recycleExpiredTokenSaga() {
  yield takeEvery(RECYCLE_EXPIRED_TOKEN.REQUEST, recycleExpiredToken);
}

export default [recycleExpiredTokenSaga];
