import React from "react";
import { Doughnut } from "react-chartjs-2";
import forEach from "lodash/forEach";
import map from "lodash/map";
import get from "lodash/get";
import Translated from "shared/components/Translated";
import { REQUEST_COLORS } from "shared/constants/request";
import Box from "modules/Dashboard/components/Box";
import "./styles.scss";

export default ({ statuses, lang }) => {
  const data = {
    datasets: [
      {
        data: map(statuses, ({ count }) => count),
        borderWidth: 2,
        backgroundColor: map(statuses, ({ status }) => REQUEST_COLORS[status]),
      },
    ],
    labels: map(statuses, ({ status }) => get(lang, `request.status.${status}`)),
  };
  const options = {
    cutoutPercentage: 90,
    aspectRatio: 1,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  };
  let count = 0;
  forEach(statuses, (status) => {
    count += status.count;
  });

  return (
    <Box title={<Translated path="requests" />} subTitle={<Translated path="requestsPerEachStatus" />}>
      <div className="DashboardAverageDelay">
        <div className="row align-baseline">
          <div className="col-md-6">
            <div className="DashboardAverageDelay__count">{count}</div>
            <div className="DashboardAverageDelay__subtitle">
              <Translated path="totalRequests" />
            </div>
          </div>
          <div className="col-md-6">
            <Doughnut data={data} options={options} width={100} height={100} />
          </div>
        </div>
      </div>
    </Box>
  );
};
