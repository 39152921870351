import lang from "shared/sagas/lang";
import auth from "modules/Auth/sagas/";
import users from "modules/Users/sagas/";
import requests from "modules/Requests/sagas";
import app from "modules/App/sagas";
import activities from "modules/Activities/sagas";
import companies from "modules/Companies/sagas";
import register from "modules/Register/sagas";
import planning from "modules/Planning/sagas";
import calendar from "modules/Calendar/sagas";
import dashboard from "modules/Dashboard/sagas";
import account from "modules/Account/sagas";
import counties from "modules/Counties/sagas";
import shared from "shared/sagas";

export default [
  ...app,
  ...account,
  ...lang,
  ...auth,
  ...users,
  ...requests,
  ...activities,
  ...companies,
  ...planning,
  ...register,
  ...shared,
  ...calendar,
  ...dashboard,
  ...counties,
];
