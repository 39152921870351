import { LIST_COUNTY } from "shared/constants/county.js";

export default ({ managerCounty, requestCounty }) => {
  const errors = {};
  if (!managerCounty) {
    errors["managerCounty"] = "FILL_IN";
  } else {
    if (!LIST_COUNTY.includes(managerCounty)) {
      errors["managerCounty"] = "NOT_VALID_COUNTY";
    }
  }
  if (!requestCounty) {
    errors["requestCounty"] = "FILL_IN";
  } else {
    if (!LIST_COUNTY.includes(requestCounty)) {
      errors["requestCounty"] = "NOT_VALID_COUNTY";
    }
  }
  return errors;
};
