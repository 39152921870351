import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { USERS_INVITE_NEW } from "modules/Users/reducers/invite/";
import { performUserFetch } from "modules/Users/reducers/list";

function* inviteUser(action) {
  try {
    const { data } = yield apiService({}).post("/user", action.payload);
    yield put({
      type: USERS_INVITE_NEW.SUCCESS,
      payload: { ...data, response: "responses.success" },
    });
    const {
      users: {
        list: { filters },
      },
    } = yield select();

    yield put(performUserFetch(filters));
  } catch (err) {
    yield put({ type: USERS_INVITE_NEW.FAILURE, payload: getError(err) });
  }
}

function* inviteUserSaga() {
  yield takeEvery(USERS_INVITE_NEW.REQUEST, inviteUser);
}

export default [inviteUserSaga];
