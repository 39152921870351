import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import map from "lodash/map";
import ceil from "lodash/ceil";
import includes from "lodash/includes";
import { intl } from "shared/hoc/Intl";
import { Table, Thead, Tbody, Tr, Td, Th } from "shared/components/Table";
import Pagination from "shared/components/Pagination";
import Avatar from "shared/components/Avatar";
import Modal from "shared/components/Modal";
import Translated, { langLabel } from "shared/components/Translated";
import Button from "shared/components/Button";
import { User, Pencil, Recycle, Trash } from "shared/components/Icons";
import ListHeader from "shared/components/ListHeader";
import { shortDate } from "shared/services/date";
import { routes } from "shared/constants/routes";
import {
  USER_STATUS_EXTERNAL,
  USER_STATUS_REGISTERED,
  USER_STATUS_PENDING,
  USER_STATUS_DISABLED,
  USER_STATUS_EXPIRED_TOKEN,
} from "shared/constants/auth";
import { getInitialsFromName, stringToHslColor } from "shared/utils/avatar";
import { userClearFilter, performUserFetch } from "modules/Users/reducers/list";
import { recycleExpiredToken } from "modules/Users/reducers/recycleExpiredToken";
import ListSearch from "modules/Users/containers/ListSearch";
import InviteUserForm from "modules/Users/containers/InviteUserForm";
import DeleteUserModal from "modules/Users/containers/DeleteUserModal";
import UserDetails from "modules/Users/containers/UserDetails";

import "./styles.scss";

class List extends PureComponent {
  state = {
    createModal: false,
    modalData: {},
    deleteModal: false,
    userDetails: false,
  };

  componentDidMount() {
    this.props.performUserFetch({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeleting && this.props.isDeleted) {
      this.setState({ deleteModal: false, modalData: {} });
    }
    if (prevProps.isTokenRefreshing && this.props.isTokenRefreshed) {
      this.props.performUserFetch({});
    }
  }

  componentWillUnmount() {
    this.props.userClearFilter();
  }

  toggleModal = (key, modalData = {}) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  onSort = (key) => (value) => {
    const {
      filters: { sort },
    } = this.props;
    const newSort = {
      field: key,
      direction: "DESC",
    };
    if (sort.direction === "DESC" && key === sort.field) {
      newSort.direction = "ASC";
    }
    if (sort.direction === "ASC" && key === sort.field) {
      newSort.direction = "DESC";
    }
    this.props.performUserFetch({ sort: newSort });
  };

  onPageChange = (page) => {
    this.props.performUserFetch({
      pager: { page, limit: this.props.filters.pager.limit },
    });
  };

  goToEdit = (id, status) => () => {
    if (includes([USER_STATUS_PENDING, USER_STATUS_EXPIRED_TOKEN], status)) {
      return;
    }
    this.props.history.push(routes.users.edit(id).path);
  };

  recycleExpiredToken = (id) => () => {
    this.props.recycleExpiredToken(id);
  };

  render() {
    const { isLoading, filters, data, total, pages } = this.props;
    return (
      <div className="UsersList">
        <Modal
          title={<Translated path="userDetails" />}
          isOpen={this.state.userDetails}
          onClose={this.toggleModal("userDetails")}
        >
          <UserDetails onCancel={this.toggleModal("userDetails")} data={this.state.modalData} />
        </Modal>
        <Modal
          title={<Translated path="inviteUser" />}
          isOpen={this.state.createModal}
          onClose={this.toggleModal("createModal")}
        >
          <InviteUserForm onCancel={this.toggleModal("createModal")} data={this.state.modalData} />
        </Modal>
        <Modal
          title={<Translated path="deleteUser" />}
          isOpen={this.state.deleteModal}
          onClose={this.toggleModal("deleteModal")}
        >
          <DeleteUserModal onCancel={this.toggleModal("deleteModal")} data={this.state.modalData} />
        </Modal>
        <div className="container">
          <ListHeader>
            <div className="header-row">
              <h1>
                <Translated path="users" /> ({total})
              </h1>
              <Button
                icon="plus"
                type="primary"
                noMargin
                onClick={this.toggleModal("createModal")}
                className="invite-user-btn"
              >
                <Translated path="inviteUser" />
              </Button>
            </div>
            <ListSearch />
          </ListHeader>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={isLoading}>
                <Thead>
                  <Tr>
                    <Th specialClass="hideMobile">
                      <User />
                    </Th>
                    <Th sortable onSort={this.onSort("name")}>
                      <Translated path="user.labels.surname" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("surname")}>
                      <Translated path="user.labels.name" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("role")}>
                      <Translated path="user.labels.role" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("email")} specialClass="hideMobile">
                      <Translated path="user.labels.email" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("createdAt")} specialClass="hideMobile">
                      <Translated path="user.labels.createdAt" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("status")} specialClass="hideMobile">
                      <Translated path="user.labels.status" shared />
                    </Th>
                    <Th specialClass="col-center">
                      <Translated path="table.action" shared />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data, (user) => (
                    <Tr key={`user-${user.id}`} onClick={this.goToEdit(user.id, user.status)}>
                      <Td specialClass="hideMobile">
                        <Avatar color={stringToHslColor(user.name, user.surname)}>
                          {getInitialsFromName(user.name, user.surname)}
                        </Avatar>
                      </Td>
                      <Td>{user.surname}</Td>
                      <Td>{user.name}</Td>
                      <Td>{<Translated path={`user.role.${user.role}`} />}</Td>
                      <Td specialClass="hideMobile">{user.email}</Td>
                      <Td specialClass="hideMobile">{shortDate(user.createdAt)}</Td>
                      <Td specialClass="hideMobile">
                        <span style={user.status === USER_STATUS_EXPIRED_TOKEN ? { color: "#f99e44" } : {}}>
                          <Translated path={`user.status.${user.status}`} />
                        </span>
                      </Td>
                      <Td specialClass="col-center-row">
                        {(user.status === USER_STATUS_REGISTERED ||
                          user.status === USER_STATUS_DISABLED ||
                          user.status === USER_STATUS_EXTERNAL) && (
                          <Pencil
                            onClick={this.goToEdit(user.id)}
                            className="UsersList__icon"
                            data-rh={langLabel("tooltips.editUser", this.props)}
                          />
                        )}
                        {user.status === USER_STATUS_EXPIRED_TOKEN && (
                          <Recycle
                            onClick={this.recycleExpiredToken(user.id)}
                            className="UsersList__icon"
                            data-rh={langLabel("tooltips.recycleExpiredToken", this.props)}
                          />
                        )}
                        {(user.status === USER_STATUS_PENDING || user.status === USER_STATUS_EXPIRED_TOKEN) && (
                          <Trash
                            onClick={this.toggleModal("deleteModal", user)}
                            className="UsersList__icon"
                            data-rh={langLabel("tooltips.deleteUser", this.props)}
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination onPageChange={this.onPageChange} pages={pages} currentPage={filters.pager.page} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  isDeleting: users.remove.states.isLoading,
  isDeleted: users.remove.states.isLoaded,
  isLoading: users.list.states.isLoading,
  isTokenRefreshing: users.recycleExpiredToken.states.isLoading ? users.recycleExpiredToken.states.isLoading : false,
  isTokenRefreshed: users.recycleExpiredToken.states.isLoaded ? users.recycleExpiredToken.states.isLoaded : false,
  filters: users.list.filters,
  data: users.list.data.rows,
  total: users.list.data.total,
  pages: ceil(users.list.data.total / users.list.filters.pager.limit),
});
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userClearFilter, performUserFetch, recycleExpiredToken }, dispatch);

export default compose(intl("modules.Users.List"), connect(mapStateToProps, mapDispatchToProps))(List);
