import { put, takeEvery, select } from "redux-saga/effects";
import { replace, push } from "connected-react-router";
import apiService, { getError } from "shared/services/api";
import get from "lodash/get";
import { routes } from "shared/constants/routes";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_CREATE_FORM_NAME,
} from "shared/constants/request";
import { setSubmitFailed, setSubmitSucceeded } from "redux-form";
import { REQUESTS_CREATE, ASSIGN_MANAGER, performAssignManager } from "modules/Requests/reducers/create/";
import { stringToHslColor } from "shared/utils/avatar";

function* createRequest(action) {
  try {
    let data = {};
    const {
      app: { user },
    } = yield select();
    const color = stringToHslColor(get(user, "data.name"), get(user, "data.surname"));
    const bodyPayload = {
      ...action.payload,
      color,
    };
    // Create request if does not exists
    if (!action.id) {
      const { data: requestData } = yield apiService({}).post("/request", bodyPayload);
      data = requestData;
      yield put(replace(routes.requests.draft(data.id).path));
    }

    // Update request if exists and draft mode
    if (action.id) {
      const { data: requestData } = yield apiService({}).put(`/request/${action.id}`, bodyPayload);
      data = requestData;
      if (
        action.status !== REQUEST_STATUS_PENDING_SUBCONTRACTOR &&
        data.status === REQUEST_STATUS_PENDING_SUBCONTRACTOR
      ) {
        yield put(push(routes.planning.default.path));
      }
    }

    // Assign to manager
    if (
      ((action.isDraft !== undefined && action.isDraft === true) || action.status === REQUEST_STATUS_DRAFT) &&
      (data.managerDetails === undefined || data.managerDetails === null)
    ) {
      const id = action.id || data.id;
      yield put(performAssignManager({ id }));
    }

    // If user contractor then send to manager
    if (action.sendToManager) {
      const id = action.id || data.id;
      const { data: assignData } = yield apiService({}).put(`/request/${id}/send-to-assigned-manager`);
      data = assignData;
      yield put(push(routes.requests.list.path));
    }

    const response = action.id || data.id ? "modified.success" : "created.success";
    yield put(setSubmitSucceeded(REQUEST_CREATE_FORM_NAME));
    yield put({
      type: REQUESTS_CREATE.SUCCESS,
      payload: { ...data, response },
    });
  } catch (err) {
    yield put(setSubmitFailed(REQUEST_CREATE_FORM_NAME));
    yield put({ type: REQUESTS_CREATE.FAILURE, payload: getError(err) });
  }
}

function* assignManager(action) {
  try {
    const { data } = yield apiService({}).put(`/request/${action.payload.id}/assign-manager`);
    yield put(push(routes.requests.list.path));

    yield put({
      type: ASSIGN_MANAGER.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ASSIGN_MANAGER.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(ASSIGN_MANAGER.REQUEST, assignManager);
  },
  function* () {
    yield takeEvery(REQUESTS_CREATE.REQUEST, createRequest);
  },
];
