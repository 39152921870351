import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COMPANIES_CREATE_NEW } from "modules/Companies/reducers/create/";
import { fetchCompaniesList } from "modules/Companies/reducers/list/";

function* createCompany(action) {
  try {
    const modifiedPayload = {
      name: action.payload.name,
      emails: [action.payload.email],
    };
    const { data } = yield apiService({}).post("/company", modifiedPayload);
    yield put({
      type: COMPANIES_CREATE_NEW.SUCCESS,
      payload: { ...data, response: "responses.success" },
    });
    yield put(fetchCompaniesList());
  } catch (err) {
    yield put({ type: COMPANIES_CREATE_NEW.FAILURE, payload: getError(err) });
  }
}

function* createCompanySaga() {
  yield takeEvery(COMPANIES_CREATE_NEW.REQUEST, createCompany);
}

export default [createCompanySaga];
