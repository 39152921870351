import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import FieldError from "shared/components/FieldError";
import { Info } from "shared/components/Icons";
import "./styles.scss";

class AuthTextField extends PureComponent {

  render() {
    const { input, meta, noMarginTop, className, disabled, tooltip, ...props } = this.props;

    const inputClasses = classnames("AuthTextField__input", {
      "AuthTextField__input--disabled": disabled,
      "AuthTextField__input--error": meta.touched && meta.error,
    });
    const classes = classnames("AuthTextField", className, {});
    return (
      <div className={classes} >
        {tooltip ? (
          <div className="AuthTextField__tooltip">
            <div className="AuthTextField__tooltip__content">{tooltip}</div>
            <div style={{"display":"flex","flex-flow": "row nowrap"}}>
            <Info />
            <input   {...input} {...props} className={inputClasses} disabled={disabled} />
            </div>
          </div>
        ) : <input {...input} {...props} className={inputClasses} disabled={disabled} />
        }
        
        <FieldError meta={meta} />
      </div>
    );

  }
}

AuthTextField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

export default AuthTextField;
