import React from "react";
import { routes } from "shared/constants/routes";
import List from "./containers/List";
import Create from "./containers/Create";
import Reassign from "./containers/Reassign";
import { withRouter } from "react-router";
import ProtectedRoute from "shared/components/ProtectedRoute";
import "./styles.scss";

export default withRouter(() => [
  <ProtectedRoute
    path={routes.requests.details(":id(\\d+)?").path}
    key="requests-list"
    roles={routes.requests.list.roles}
    component={List}
    exact
  />,
  <ProtectedRoute
    path={routes.requests.manage("(draft|create|duplicate)/:id?").path}
    roles={routes.requests.draft().roles}
    key="requests-manager"
    component={Create}
    exact
  />,
  <ProtectedRoute
    path={routes.requests.reassign(":id").path}
    roles={routes.requests.reassign().roles}
    key="requests-reassign"
    component={Reassign}
    exact
  />,
]);
