import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Info } from "shared/components/Icons";
import FieldError from "shared/components/FieldError";
import FieldWarning from "shared/components/FieldWarning";
import RotateLoader from "shared/components/RotateLoader";
import "./styles.scss";

class FieldWrapper extends PureComponent {
  render() {
    const {
      meta,
      label,
      tooltip,
      labelLeft,
      noMargin,
      className,
      iconLeft,
      icon,
      input,
      children,
      onClick,
      onKeyDown,
      isRequired,
      disabled,
      hiddenDisabled,
      isLoading,
    } = this.props;
    const classes = classnames("FieldWrapper", className, {
      "FieldWrapper--label-left": labelLeft,
      "FieldWrapper--no-margin": noMargin,
    });
    const labelClasses = classnames("FieldWrapper__label", className, {
      "FieldWrapper__label--left": labelLeft,
    });
    const wrapperClasses = classnames("FieldWrapper__input-wrapper", className, {
      "FieldWrapper__input-wrapper--disabled": disabled && !hiddenDisabled,
      "FieldWrapper__input-wrapper--error": meta.touched && meta.error,
      "FieldWrapper__input-wrapper--warn": meta.touched && meta.warning,
    });

    return (
      <div className={classes} onClick={onClick} onKeyDown={onKeyDown}>
        {label && (
          <label className={labelClasses} htmlFor={input.name}>
            {label}
            {isRequired && "*"}
            {tooltip ? (
              <div className="FieldWrapper__tooltip">
                <Info />
                <div className="FieldWrapper__tooltip__content">{tooltip}</div>
              </div>
            ) : null}
          </label>
        )}
        <div className={wrapperClasses}>
          {iconLeft && <div className="FieldWrapper__icon-left">{iconLeft}</div>}
          {children}
          {icon && <img src={require(`assets/icons/${icon}.svg`)} alt="icon" className="FieldWrapper__icon" />}
          {isLoading && (
            <div className="FieldWrapper__loader">
              <RotateLoader relative />
            </div>
          )}
        </div>
        <FieldWarning meta={meta} />
        <FieldError meta={meta} />
      </div>
    );
  }
}

FieldWrapper.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  label: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.node,
  iconLeft: PropTypes.node,
  labelLeft: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default FieldWrapper;
