export const REQUEST_STATUS_DRAFT = "DRAFT";
export const REQUEST_STATUS_ASSIGNED = "ASSIGNED";
export const REQUEST_STATUS_PENDING_MANAGER = "PENDING_MANAGER";
export const REQUEST_STATUS_ASSIGNED_AS_DRAFT = "ASSIGNED_AS_DRAFT";
export const REQUEST_STATUS_PENDING_SUBCONTRACTOR = "PENDING_SUBCONTRACTOR";
export const REQUEST_STATUS_CANCELLED = "CANCELLED";
export const REQUEST_STATUS_DECLINED = "DECLINED";
export const REQUEST_STATUS_DONE = "DONE";

export const TIME_SLOT_ONE = {
  from: 8,
  to: 12,
};

export const TIME_SLOT_TWO = {
  from: 14,
  to: 18,
};

export const REQUEST_COLORS = {
  [REQUEST_STATUS_DRAFT]: "rgb(0, 179, 251)",
  [REQUEST_STATUS_ASSIGNED]: "rgb(110, 255, 50)",
  [REQUEST_STATUS_PENDING_MANAGER]: "rgb(0, 113, 179)",
  [REQUEST_STATUS_ASSIGNED_AS_DRAFT]: "rgb(0, 206, 159)",
  [REQUEST_STATUS_PENDING_SUBCONTRACTOR]: "rgb(113, 13, 163)",
  [REQUEST_STATUS_CANCELLED]: "rgb(227, 0, 0)",
  [REQUEST_STATUS_DECLINED]: "rgb(210, 30, 137)",
  [REQUEST_STATUS_DONE]: "rgb(27 ,173, 51)",
};

export const REQUEST_CREATE_FORM_NAME = "requests/create";

// integer days
export const MIN_REQUEST_DELAY = 10;

// Max length for TextArea
export const MAX_LENGTH_TEXTAREA = 8000;
