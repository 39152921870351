import React, { Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HeaderHelperNavigationItem from "modules/App/components/HeaderHelperNavigationItem";
import Translated from "shared/components/Translated";

import "./styles.scss";
import { ROLE_ADMIN, ROLE_CONTRACTOR, ROLE_INSPECTOR, ROLE_MANAGER } from "shared/constants/auth";

const HeaderHelper = ({ user, role, app }) => {
  return (
    <div className="HeaderHelper">
      <img src={require("assets/icons/help.svg")} className="HeaderHelper__icon" alt="Notifications" />
      <div className="HeaderHelper__content">
        {user.role === ROLE_ADMIN && (
          <Fragment>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/users", "ADMIN", "Intro"]}>
              <Translated path="helperMenu.intro" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/activities", "ADMIN", "Create"]}>
              <Translated path="helperMenu.createActivity" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/users", "ADMIN", "InviteUser"]}>
              <Translated path="helperMenu.inviteUser" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/users", "ADMIN", "AssignInspector"]}>
              <Translated path="helperMenu.assignInspector" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/counties", "ADMIN", "CountyRules"]}>
              <Translated path="helperMenu.countyRules" />
            </HeaderHelperNavigationItem>
          </Fragment>
        )}
        {user.role === ROLE_CONTRACTOR && (
          <Fragment>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/", "CONTRACTOR", "Intro"]}>
              <Translated path="helperMenu.intro" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/requests", "CONTRACTOR", "Create"]}>
              <Translated path="helperMenu.createRequest" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem
              icon="profile"
              localStorageRoute={["/app/requests/create", "CONTRACTOR", "FillForm"]}
            >
              <Translated path="helperMenu.fillRequest" />
            </HeaderHelperNavigationItem>
          </Fragment>
        )}
        {user.role === ROLE_INSPECTOR && (
          <Fragment>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/calendar", "INSPECTOR", "Intro"]}>
              <Translated path="helperMenu.intro" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/calendar", "INSPECTOR", "Calendar"]}>
              <Translated path="helperMenu.calendar" />
            </HeaderHelperNavigationItem>
          </Fragment>
        )}
        {user.role === ROLE_MANAGER && (
          <Fragment>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/planning", "MANAGER", "Intro"]}>
              <Translated path="helperMenu.intro" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/planning", "MANAGER", "Planning"]}>
              <Translated path="helperMenu.planning" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/planning", "MANAGER", "Create"]}>
              <Translated path="helperMenu.createRequest" />
            </HeaderHelperNavigationItem>
            <HeaderHelperNavigationItem
              icon="profile"
              localStorageRoute={["/app/requests/create", "MANAGER", "FillForm"]}
            >
              <Translated path="helperMenu.fillRequest" />
            </HeaderHelperNavigationItem>
          </Fragment>
        )}

        {user.role !== ROLE_ADMIN && (
          <Fragment>
            <HeaderHelperNavigationItem icon="profile" localStorageRoute={["/app/requests", "SHARED", "Requests"]}>
              <Translated path="helperMenu.requests" />
            </HeaderHelperNavigationItem>
          </Fragment>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ app }, auth) => ({ user: app.user.data, role: auth.role, app: app });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHelper);
