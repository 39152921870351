import { POSSIBLE_LANGUAGES } from "shared/constants/lang";
import forEach from "lodash/map";
import replace from "lodash/replace";

export const isLangPossible = (lang) => POSSIBLE_LANGUAGES.indexOf(lang) >= 0;
export const getBrowserLang = () => (navigator.language || navigator.userLanguage).split("-")[0];

export const replaceValues = (string, values) => {
  let newString = String(string);
  forEach(values, (value, key) => {
    newString = replace(newString, `{${key}}`, value);
  });
  return newString;
};
