import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import FormButtons from "shared/components/FormButtons";
import Translated from "shared/components/Translated";
import { performUserFetchDetails } from "modules/Users/reducers/details";
import "./styles.scss";

class UserDetails extends PureComponent {
  performInvite = () => {
    this.props.performUserFetchDetails({ id: this.props.id });
  };

  render() {
    const { onCancel } = this.props;
    return (
      <div className="UserDetails">
        <FormButtons>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.close" shared />
          </Button>
        </FormButtons>
      </div>
    );
  }
}
const mapStateToProps = ({ users, ...state }) => ({
  isLoading: users.edit.states.isLoading,
  hasError: users.edit.states.hasError,
  isLoaded: users.edit.states.isLoaded,
  response: users.edit.response,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performUserFetchDetails }, dispatch);

export default compose(intl("modules.Users.UserDetails"), connect(mapStateToProps, mapDispatchToProps))(UserDetails);
