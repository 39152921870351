import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { REQUEST_STATUS_ASSIGNED } from "shared/constants/request";
import { createRequestTypes } from "shared/utils/request";

import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { APPROVE_REQUESTS } from "modules/Planning/reducers/types";

export const PLANNING_FETCH_INSPECTORS = createRequestTypes("planning/PLANNING_FETCH_INSPECTORS");
export const ASSIGN_INSPECTOR = createRequestTypes("planning/ASSIGN_INSPECTOR");

export const UNASSIGN_INSPECTOR = createRequestTypes("planning/UNASSIGN_INSPECTOR");

export const DELETE_REQUEST_IN_INSPECTOR = "planning/DELETE_REQUEST_IN_INSPECTOR";

export const unAssignInspector = ({ requestId, inspectorId }) => ({
  type: UNASSIGN_INSPECTOR.REQUEST,
  payload: { requestId, inspectorId },
});

export const deleteRequestInInspector = ({ id }) => ({
  type: DELETE_REQUEST_IN_INSPECTOR,
  payload: { id },
});

export const assignInspector = ({ requestId, inspectorId, plannedDateBegin, plannedDateEnd }) => ({
  type: ASSIGN_INSPECTOR.REQUEST,
  payload: { requestId, inspectorId, plannedDateBegin, plannedDateEnd },
});
export const fetchInspectors = ({ startDate, endDate }) => ({
  type: PLANNING_FETCH_INSPECTORS.REQUEST,
  payload: {
    startDate,
    endDate,
  },
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  assign: {
    states: loadingStates,
  },
  unassign: {
    states: loadingStates,
  },
};
export default createReducer(initState, {
  [ASSIGN_INSPECTOR.REQUEST]: (state, action) => {
    return update(state, {
      assign: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [UNASSIGN_INSPECTOR.REQUEST]: (state, action) => {
    return update(state, {
      unassign: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DELETE_REQUEST_IN_INSPECTOR]: (state, action) => {
    return update(state, {
      $merge: {
        data: {
          rows: map(state.data.rows, (row) => ({
            ...row,
            requests: filter(row.requests, (request) => Number(request.id) !== Number(action.payload.id)),
          })),
        },
      },
    });
  },
  [APPROVE_REQUESTS.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        data: {
          rows: map(state.data.rows, (row) => {
            const requests = map(row.requests, (request) => {
              if (includes(action.payload, request.id)) {
                return { ...request, status: REQUEST_STATUS_ASSIGNED };
              }
              return request;
            });

            return {
              ...row,
              requests,
            };
          }),
        },
      },
    });
  },
  [UNASSIGN_INSPECTOR.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        data: {
          rows: map(state.data.rows, (row) => {
            if (row.userId === action.payload.inspectorId) {
              const requests = filter(row.requests, (request) => request.id !== action.payload.requestId);

              return {
                ...row,
                requests,
              };
            }
            return row;
          }),
        },
      },
      unassign: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [ASSIGN_INSPECTOR.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        data: {
          rows: map(state.data.rows, (row) => {
            const rowRequests = filter(row.requests, (r) => r.id !== action.payload.request.id);

            if (row.userId === action.payload.inspectorId) {
              const requests = rowRequests ? [...rowRequests, action.payload.request] : [action.payload.request];

              return {
                ...row,
                requests,
              };
            }
            return {
              ...row,
              requests: rowRequests,
            };
          }),
        },
      },
      assign: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [PLANNING_FETCH_INSPECTORS.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [PLANNING_FETCH_INSPECTORS.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [PLANNING_FETCH_INSPECTORS.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [PLANNING_FETCH_INSPECTORS.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
