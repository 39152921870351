import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { REGISTER_VERIFY_TOKEN } from "modules/Register/reducers/verify/";

function* validateToken(action) {
  try {
    const { data } = yield apiService({}).get(`/token?token=${action.payload.token}`);
    yield put({
      type: REGISTER_VERIFY_TOKEN.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REGISTER_VERIFY_TOKEN.FAILURE, payload: getError(err) });
  }
}

function* validateTokenSaga() {
  yield takeEvery(REGISTER_VERIFY_TOKEN.REQUEST, validateToken);
}

export default [validateTokenSaga];
