import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Avatar from "shared/components/Avatar";
import { getInitialsFromName, stringToHslColor } from "shared/utils/avatar";
import HeaderProfileNavigation from "modules/App/containers/HeaderProfileNavigation";
import "./styles.scss";

const HeaderProfile = ({ user }) => {
  return (
    <div className="HeaderProfile">
      <Avatar color={stringToHslColor(user.name, user.surname)}>{getInitialsFromName(user.name, user.surname)}</Avatar>
      <div className="HeaderProfile__arrow">
        <img src={require("assets/arrow_down.svg")} className="HeaderProfile__arrow__icon" alt="LK" />
      </div>
      <div className="HeaderProfile__content">
        <HeaderProfileNavigation />
      </div>
    </div>
  );
};
const mapStateToProps = ({ app }) => ({ user: app.user.data });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderProfile);
