import toLower from "lodash/toLower";
import { VOID_COUNTY } from "shared/constants/county";

export const Google = window.google;

export const getCountyPostalCodeByPlaceId = async (placeId, defaultCounty) => {
  return new Promise((resolve, reject) => {
    const geocoder = new Google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      //default value for error, defaultCounty and outside France
      let resultCounty = defaultCounty || VOID_COUNTY;
      if (results.length > 0) {
        let googleAddressBlock = results[0];
        resultCounty = getCountyByAddressComponents(googleAddressBlock, defaultCounty);
      }
      resolve(resultCounty);
    });
  });
};

const getCountyByAddressComponents = (googleAddressBlock, defaultCounty) => {
  let address_components = googleAddressBlock.address_components;
  let country =
    address_components
      .filter((component) => component.types.indexOf("country") === 0)
      .map((item) => toLower(item.short_name))
      .pop() || false;
  let result = defaultCounty || VOID_COUNTY;
  if (country === "fr") {
    result = address_components.filter((component) => component.types.indexOf("postal_code") === 0);
    result = result.map((item) => item.short_name).pop() || false;
    // the component postal_code is null, manual extraction of the county
    if (result === false) {
      let regexp = /[,] [0-9AB]{5}/;
      let formatted_address = googleAddressBlock.formatted_address;
      let index = formatted_address.search(regexp);
      if (index !== -1) {
        // +2 because of the initial ", "  and +4 to capture the 2 first digit of the postal code
        result = formatted_address.substring(index + 2, index + 4);
      } else {
        result = defaultCounty || VOID_COUNTY;
      }
    } else result = result.substring(0, 2);
  } else result = defaultCounty || VOID_COUNTY;
  return result;
};
