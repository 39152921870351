import React from "react";
import PropTypes from "prop-types";
import { Close } from "shared/components/Icons";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";

const Modal = ({ children, isOpen, onClose, title, subTitle, titleLeft, specialClass }) => {
  const classes = classnames({
    Modal: true,
    [`Modal__open`]: isOpen,
    [specialClass]: true,
  });
  const titleClasses = classnames("Modal__title", {
    "Modal__title--with-sub": !!subTitle,
    "Modal__title--left": titleLeft,
  });

  return (
    <div>
      <CSSTransition in={isOpen} timeout={200} classNames="modal-anim" unmountOnExit>
        <div className={classes}>
          <div className="Modal__wrapper">
            <div className="Modal__content">
              <button onClick={onClose} className="Modal__close">
                <Close />
              </button>
              <div className={titleClasses}>{title}</div>
              {subTitle && <div className="Modal__sub-title">{subTitle}</div>}
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
Modal.propTypes = {
  title: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default Modal;
