import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import {
  ABSENCE_CREATE,
  ABSENCE_UPDATE,
  ABSENCE_DELETE,
  ABSENCE_FETCH_INSPECTOR,
  ABSENCE_FETCH_MANAGER,
} from "shared/reducers/absence";

function* createAbsence(action) {
  try {
    let { data } = yield apiService({}).put("/absence", action.payload.absence);
    if (data === null) throw new Error("Return parameter Absence is null");
    yield put({
      type: ABSENCE_CREATE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ABSENCE_CREATE.FAILURE, payload: getError(err) });
  }
}

function* updateAbsence(action) {
  try {
    let { data } = yield apiService({}).post("/absence", action.payload.absence);
    if (data === null) throw new Error("Return parameter Absence is null");
    yield put({
      type: ABSENCE_UPDATE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ABSENCE_UPDATE.FAILURE, payload: getError(err) });
  }
}

function* fetchInspectorAbsences(action) {
  try {
    let { data } = yield apiService({}).get("/absence/inspector");
    if (data === null) throw new Error("Return parameter List<Absence> is null");
    yield put({
      type: ABSENCE_FETCH_INSPECTOR.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ABSENCE_FETCH_INSPECTOR.FAILURE, payload: getError(err) });
  }
}

function* fetchManagerAbsences(action) {
  try {
    let { data } = yield apiService({}).get("/absence/manager");
    if (data === null) throw new Error("Return parameter List<Absence> is null");
    yield put({
      type: ABSENCE_FETCH_MANAGER.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: ABSENCE_FETCH_MANAGER.FAILURE, payload: getError(err) });
  }
}

function* deleteAbsence(action) {
  try {
    apiService({}).delete(`/absence/${action.payload}`);
    put({
      type: ABSENCE_DELETE.SUCCESS,
    });
  } catch (err) {
    yield put({ type: ABSENCE_DELETE.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(ABSENCE_CREATE.REQUEST, createAbsence);
  },
  function* () {
    yield takeEvery(ABSENCE_UPDATE.REQUEST, updateAbsence);
  },
  function* () {
    yield takeEvery(ABSENCE_DELETE.REQUEST, deleteAbsence);
  },
  function* () {
    yield takeEvery(ABSENCE_FETCH_INSPECTOR.REQUEST, fetchInspectorAbsences);
  },
  function* () {
    yield takeEvery(ABSENCE_FETCH_MANAGER.REQUEST, fetchManagerAbsences);
  },
];
