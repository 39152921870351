import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Avatar = ({ children, color }) => {
  return (
    <div className="Avatar" style={{ background: `${color}` }}>
      {children}
    </div>
  );
};

Avatar.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
};

export default Avatar;
