import { put, takeEvery, select } from "redux-saga/effects";
import { getError } from "shared/services/api";
import forEach from "lodash/forEach";
import map from "lodash/map";
import { REQUESTS_FETCH } from "modules/Requests/reducers/list/";
import { objToParams } from "shared/utils/api";
import { parseRequest, callFetchRequest } from "shared/services/request";

function* fetchList() {
  try {
    const { filter, sort, pager, query } = yield select(({ requests }) => requests.list.filters);
    const {
      auth: {
        data: { role },
      },
    } = yield select();
    const params = {};
    forEach(sort, (value, key) => (params[`sort.${key}`] = value));
    forEach(pager, (value, key) => (params[`pager.${key}`] = value));
    forEach(filter, (value, key) => (value ? (params[encodeURIComponent(`filter[${key}]`)] = value) : null));

    const queryParams = objToParams({ ...params, query });
    const data = yield callFetchRequest(queryParams);
    yield put({
      type: REQUESTS_FETCH.SUCCESS,
      payload: {
        rows: map(data.data, (request) => parseRequest(request, role)),
        total: data.total,
      },
    });
  } catch (err) {
    yield put({ type: REQUESTS_FETCH.FAILURE, payload: getError(err) });
  }
}

function* fetchRequestsListSaga() {
  yield takeEvery(REQUESTS_FETCH.REQUEST, fetchList);
}

export default [fetchRequestsListSaga];
