import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const AUTH_PERFORM_RESET_PASSWORD = createRequestTypes("auth/PERFORM_RESET_PASSWORD");
export const AUTH_RESET_PASSWORD_VERIFY_TOKEN = createRequestTypes("auth/RESET_PASSWORD/VERIFY_TOKEN");

export const performTokenValidation = ({ token }) => ({
  type: AUTH_RESET_PASSWORD_VERIFY_TOKEN.REQUEST,
  payload: { token },
});

export const performResetPassword = ({ password, token, email }) => ({
  type: AUTH_PERFORM_RESET_PASSWORD.REQUEST,
  payload: {
    password,
    token,
  },
  email,
});
const initState = {
  refreshToken: loadingStates,
  states: loadingStates,
  response: "",
  verify: {
    states: loadingStates,
    data: {},
    response: "",
  },
};

export default createReducer(initState, {
  [AUTH_PERFORM_RESET_PASSWORD.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [AUTH_PERFORM_RESET_PASSWORD.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
      },
    });
  },
  [AUTH_PERFORM_RESET_PASSWORD.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
  [AUTH_RESET_PASSWORD_VERIFY_TOKEN.REQUEST]: (state) => {
    return update(state, {
      verify: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [AUTH_RESET_PASSWORD_VERIFY_TOKEN.SUCCESS]: (state, action) => {
    return update(state, {
      verify: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [AUTH_RESET_PASSWORD_VERIFY_TOKEN.FAILURE]: (state, action) => {
    return update(state, {
      verify: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
