import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const FETCH_CURRENT_USER = createRequestTypes("app/FETCH_CURRENT_USER");
export const fetchCurrentUser = () => ({
  type: FETCH_CURRENT_USER.REQUEST,
});

const initState = {
  states: loadingStates,
  data: {},
  response: null,
};

export default createReducer(initState, {
  [FETCH_CURRENT_USER.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [FETCH_CURRENT_USER.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
        data: action.payload.data,
      },
    });
  },
  [FETCH_CURRENT_USER.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
