import momentJs from "moment";
// import 'moment-timezone';
// Average Delay original formula import
// import ceil from 'lodash/ceil';
require("moment/locale/fr");

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const TIME_FORMAT = "HH:mm";

export const moment = momentJs;

export const readableDateUTC = (date) => {
  return moment(date).utc().format(DATE_TIME_FORMAT);
};

export const readableDate = (date) => moment(date).format(DATE_TIME_FORMAT);

export const shortDate = (date) => moment(date).format(DATE_FORMAT);

export const getWeekDays = (startWeek, endWeek) => {
  const days = [];
  let day = startWeek;

  while (day <= endWeek) {
    days.push(moment(day.toDate()));
    day = day.clone().add(1, "d");
  }

  return days;
};

export const getDateByDateTime = (date, time) => moment.utc(new Date(date).setHours(time, 0, 0)).format();
// Average Delay : the api kpi gives the duration in seconds
// Original formula gives a lot of side effects,
// -  if it's the same day, it's rounded to 1 full day (expected 0 days),
// -  if it's 2 successive days, but the difference in hour is more than 24h, it's rounded to 2 days (expected 1 day)
// export const secondsToDays = seconds => ceil(seconds / 60 / 60 / 24) || 0;
// Rounded formula with 1 decimal to avoid altogether the side effects
export const secondsToDays = (seconds) => roundToOne(seconds / 86400) || 0;
export const utcToLocal = (date) => moment(date).local();

function roundToOne(num) {
  return +(Math.round(num + "e+1") + "e-1");
}

// export const listDate = date => (date ? moment(date).format('LLLL') : null);
export const listDate = (date) => (date ? moment(date).format("ddd " + DATE_TIME_FORMAT) : null);

export const xlsxDate = (date) => (date ? moment(date).format(DATE_TIME_FORMAT).toString().substring(0, 10) : null);
