import React from "react";
import { Route } from "react-router";
import { routes } from "shared/constants/routes";
import UsersList from "./containers/List";
import Edit from "./containers/Edit";
import "./styles.scss";

export default () => [
  <Route path={routes.users.default.path} key="users-default" component={UsersList} exact />,
  <Route path={routes.users.edit(":id").path} key="users-edit" component={Edit} />,
  <Route path={routes.users.list.path} key="users-list" component={UsersList} exact />,
];
