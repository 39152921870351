import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Translated from "shared/components/Translated";
import Avatar from "shared/components/Avatar";
import { shortDate } from "shared/services/date.js";
import "./styles.scss";

const HeaderNotification = ({ body, isRead, time, onClick }) => {
  const classes = classnames("HeaderNotification", {
    "HeaderNotification--unread": isRead,
  });

  const contractorCompany = body.contractorCompany;
  const suggestedDateBegin =
    body.suggestedDateBegin !== undefined ? new Date(body.suggestedDateBegin.epochSecond * 1000) : null;
  const Component = body.to ? Link : "div";
  return (
    <Component to={body.to} className={classes} onClick={onClick}>
      <Avatar color={body.avatar.color}>{body.initials}</Avatar>

      <div className="HeaderNotification__content">
        <div className="HeaderNotification__content__title">
          {/* the boolean test are there to handle the old notifications which did not have the contractorCompany and the suggested Date*/}
          {contractorCompany !== undefined && contractorCompany + ": "}
          {contractorCompany !== undefined && <Translated path="FROM" />}
          {contractorCompany !== undefined && " " + body.nameInitial + ". " + body.surname + " "}
          <Translated path={body.text} />
          &nbsp;
          {body.requestId}&nbsp;
          {/* the boolean test are there to handle the old notifications which did not have the contractorCompany and the suggested Date*/}
          {contractorCompany !== undefined && suggestedDateBegin !== undefined && suggestedDateBegin != null && (
            <Translated path="FOR_THE" />
          )}
          {contractorCompany !== undefined &&
            suggestedDateBegin !== undefined &&
            suggestedDateBegin != null &&
            " " + shortDate(suggestedDateBegin)}
        </div>
        <div className="HeaderNotification__content__time">{time}</div>
      </div>
    </Component>
  );
};
export default HeaderNotification;
