import update from "immutability-helper";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const AUTH_PERFORM_FORGOT_PASSWORD = createRequestTypes("auth/PERFORM_FORGOT_PASSWORD");

export const performForgotPassword = ({ email }) => ({
  type: AUTH_PERFORM_FORGOT_PASSWORD.REQUEST,
  payload: {
    email,
  },
});
const initState = {
  refreshToken: loadingStates,
  states: loadingStates,
  response: "",
};

export default createReducer(initState, {
  [AUTH_PERFORM_FORGOT_PASSWORD.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [AUTH_PERFORM_FORGOT_PASSWORD.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
      },
    });
  },
  [AUTH_PERFORM_FORGOT_PASSWORD.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
