import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes, arrayToIds } from "shared/utils/request";
import { cleanPhoneNumber } from "shared/utils/validation.js";

export const ACCOUNT_PROFILE_EDIT = createRequestTypes("account/EDIT_PROFILE");
export const ACCOUNT_FETCH_CURRENT = createRequestTypes("account/FETCH_CURRENT");

export const fetchCurrentProfile = () => ({
  type: ACCOUNT_FETCH_CURRENT.REQUEST,
});

export const performUserEdit = ({ name, surname, activities, phoneNumber }) => ({
  type: ACCOUNT_PROFILE_EDIT.REQUEST,
  payload: {
    name,
    surname,
    activities: arrayToIds(activities),
    phoneNumber: cleanPhoneNumber(phoneNumber),
  },
});

const initState = {
  edit: {
    response: null,
    states: loadingStates,
  },
  user: {
    data: {},
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [ACCOUNT_FETCH_CURRENT.REQUEST]: (state) => {
    return update(state, {
      user: {
        $merge: {
          data: {},
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ACCOUNT_FETCH_CURRENT.SUCCESS]: (state, action) => {
    return update(state, {
      user: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ACCOUNT_FETCH_CURRENT.FAILURE]: (state, action) => {
    return update(state, {
      user: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [ACCOUNT_PROFILE_EDIT.REQUEST]: (state) => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ACCOUNT_PROFILE_EDIT.SUCCESS]: (state, action) => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [ACCOUNT_PROFILE_EDIT.FAILURE]: (state, action) => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
