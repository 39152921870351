import axios from "axios";
import store from "store";
import { put, takeEvery } from "redux-saga/effects";
import { SET_LANG, INITIALIZE_LANG } from "shared/reducers/lang/";
import { LANG_STORE_KEY } from "shared/constants/lang";

function* initialize(action) {
  try {
    const { data } = yield axios.get(`/translations/${action.payload.lang}.json`);
    store.set(LANG_STORE_KEY, { lang: action.payload.lang, data });
    yield put({
      type: INITIALIZE_LANG.SUCCESS,
      payload: { lang: action.payload.lang, data },
    });
  } catch (err) {
    yield put({ type: INITIALIZE_LANG.FAILURE, payload: err });
  }
}

function* initializeSaga() {
  yield takeEvery(INITIALIZE_LANG.REQUEST, initialize);
}

function* setLang(action) {
  try {
    const { data } = yield axios.get(`/translations/${action.payload.lang}.json`);
    store.set(LANG_STORE_KEY, { lang: action.payload.lang, data });
    yield put({
      type: SET_LANG.SUCCESS,
      payload: { lang: action.payload.lang, data },
    });
  } catch (err) {
    yield put({ type: SET_LANG.FAILURE, payload: err });
  }
}

function* setLangSaga() {
  yield takeEvery(SET_LANG.REQUEST, setLang);
}

export default [setLangSaga, initializeSaga];
