import React, { PureComponent } from "react";
import Button from "shared/components/Button";
import { Close, UserAdd } from "shared/components/Icons";
import Avatar from "shared/components/Avatar";
import RotateLoader from "shared/components/RotateLoader";
import Translated from "shared/components/Translated";
import "./styles.scss";

class ActivityUser extends PureComponent {
  state = {
    isConfirmation: false,
  };

  setDelete = () => {
    this.setState({ isConfirmation: true });
  };

  cancel = () => {
    this.setState({ isConfirmation: false });
  };

  render() {
    const { isConfirmation } = this.state;
    const { label, children, assigned, isLoading } = this.props;

    return (
      <div className="ActivityUser">
        <Avatar color="#e3a6a6">{label}</Avatar>
        <div className="ActivityUser__name">{children}</div>

        {isLoading && (
          <div className="ActivityUser__loader">
            <RotateLoader relative />
          </div>
        )}
        {!assigned && !isLoading && (
          <div className="ActivityUser__actions">
            {!isLoading && (
              <button className="ActivityUser__actions__button" onClick={this.props.onAdd}>
                <UserAdd />
              </button>
            )}
          </div>
        )}
        {assigned && !isLoading && (
          <div className="ActivityUser__actions">
            {isConfirmation && [
              <Button
                small
                type="primary"
                key="cancel"
                onClick={this.cancel}
                noMargin
                className="ActivityUser__actions__action"
              >
                <Translated path="buttons.cancel" />
              </Button>,
              <Button
                small
                key="confirm"
                noMargin
                onClick={this.props.onDelete}
                className="ActivityUser__actions__action"
              >
                <Translated path="buttons.confirm" />
              </Button>,
            ]}
            {!isConfirmation && (
              <button className="ActivityUser__actions__button" onClick={this.setDelete}>
                <Close />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ActivityUser;
