import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const COMPANIES_FETCH_LIST = createRequestTypes("companies/FETCH_LIST");

export const fetchCompaniesList = () => ({
  type: COMPANIES_FETCH_LIST.REQUEST,
  payload: {},
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  response: null,
};

export default createReducer(initState, {
  [COMPANIES_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [COMPANIES_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [COMPANIES_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
