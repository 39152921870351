import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Login from "./containers/Login";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import { setLang } from "shared/reducers/lang";
import Logo from "shared/components/Logo";
import "./styles.scss";
import { routes } from "shared/constants/routes";

const AuthContainer = ({ setLang, location }) => {
  const changeLanguage = (lang) => () => setLang({ lang });
  return (
    <div className="Auth">
      <div className="Auth__lang">
        <span className="Auth__lang__item" onClick={changeLanguage("en")}>
          EN
        </span>
        <span className="Auth__lang__item" onClick={changeLanguage("fr")}>
          FR
        </span>
      </div>
      <div className="Auth__left">
        <div>
          <Logo className="Auth__logo" />
        </div>
      </div>
      <TransitionGroup className="Auth__right">
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Switch location={location}>
            <Route path="/auth/" component={() => <div>daash</div>} exact />
            <Route path={routes.auth.forgotPassword.path} component={ForgotPassword} exact />
            <Route path={routes.auth.resetPassword.path} component={ResetPassword} exact />
            <Route path={routes.auth.login.path} component={Login} exact />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ setLang }, dispatch);

export default connect(null, mapDispatchToProps)(AuthContainer);
