import React from "react";
import { Field, FieldArray } from "redux-form";
import get from "lodash/get";
import Translated from "shared/components/Translated";
import TextArea from "shared/components/TextArea";
import TagsField from "shared/components/TagsField";
import { MailFilled } from "shared/components/Icons";
import TextField from "shared/components/TextField";
import RadioField from "shared/components/RadioField";
import FilesField from "shared/components/FilesField";

import { validateEmail } from "shared/utils/validation";
import "./style.scss";

const orgForm = ({ fields }) => {
  return (
    <div className="orgsWrapper">
      {fields.map((thirdParties, index) => (
        <div className="formOrgWrapper" key={index}>
          <div className="col-md-6 col-sm-12">
            <Field
              component={TextField}
              name={`${thirdParties}.name`}
              label={<Translated path="request.labels.orgName" shared />}
              placeholder=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Field
              component={RadioField}
              name={`${thirdParties}.witness`}
              label={<Translated path="request.labels.witness.label" shared />}
              options={[
                {
                  label: <Translated path="request.labels.witness.hold" shared />,
                  value: false,
                },
                {
                  label: <Translated path="request.labels.witness.witness" shared />,
                  value: true,
                },
              ]}
            />
          </div>
          <span className="TrashWrapper" onClick={() => fields.remove(index)}>
            <img src={require(`assets/icons/trash.svg`)} alt="Profile" className="TrashButton"></img>
          </span>
        </div>
      ))}
      <div className="AddWrapper">
        <span className="ButtonWrapper" onClick={() => fields.push()}>
          <img src={require(`assets/icons/plus.svg`)} alt="Profile" className="PlusButton"></img>
        </span>
        <div style={{ transform: "scale(.9)" }}>{<Translated path="request.labels.addOrg" shared />}</div>
      </div>
    </div>
  );
};
export default ({ lang, sharedLang, update, data }) => {
  return (
    <div className="row">
      <FieldArray name="thirdParties" component={orgForm} />

      <div className="col-md-12">
        <Field
          component={TagsField}
          name="mailingList"
          label={<Translated path="request.labels.mailingList" shared />}
          placeholder={get(sharedLang, "request.placeholders.mailingList")}
          TagIcon={MailFilled}
          validateTag={validateEmail}
        />
      </div>
      <div className="col-md-12">
        <Field
          component={TextArea}
          name="otherContactInformation"
          label={<Translated path="request.labels.otherContactInformation" shared />}
          placeholder=""
          _cols="200"
          _rows="10"
        />
      </div>
      <div className="col-md-12">
        <Field component={FilesField} name="filesList" label="" filesList={data.filesList}></Field>
      </div>
    </div>
  );
};
