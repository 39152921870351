import React, { PureComponent } from "react";
import { Google } from "shared/services/google";
import "./styles.scss";

class RequestDetailsLocation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      location: props.text,
    };
  }

  componentDidMount() {
    const geocoder = new Google.maps.Geocoder();
    const { lat, lng } = this.props;
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (results[0]) {
        this.setState({ location: results[0].formatted_address });
      }
    });
    const map = new Google.maps.Map(document.getElementById("RequestDetailsLocationMap"), {
      center: { lat, lng },
      zoom: 15,
    });
    new Google.maps.Marker({ position: { lat, lng }, map: map });
  }

  render() {
    return (
      <div className="RequestDetailsLocation">
        {this.state.location}
        <div id="RequestDetailsLocationMap" className="RequestDetailsLocation__map" />
      </div>
    );
  }
}

export default RequestDetailsLocation;
