import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COUNTIES_FETCH_LIST } from "modules/Counties/reducers/list/";
import forEach from "lodash/forEach";
import { objToParams } from "shared/utils/api";

function* fetchCounties() {
  try {
    const { filter, sort, pager, ...filters } = yield select(({ counties }) => counties.list.filters);
    const params = {};
    forEach(sort, (value, key) => (params[`sort.${key}`] = value));
    forEach(pager, (value, key) => (params[`pager.${key}`] = value));
    forEach(filter, (value, key) => (value ? (params[encodeURIComponent(`filter[${key}]`)] = value) : null));
    const queryParams = objToParams({ ...params, ...filters });
    const { data } = yield apiService({}).get(`/county-rule?${queryParams}`);
    yield put({
      type: COUNTIES_FETCH_LIST.SUCCESS,
      payload: {
        rows: data.data,
        total: data.total,
      },
    });
  } catch (err) {
    yield put({ type: COUNTIES_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

function* fetchCountiesSaga() {
  yield takeEvery(COUNTIES_FETCH_LIST.REQUEST, fetchCounties);
}

export default [fetchCountiesSaga];
