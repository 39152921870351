import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import TextField from "shared/components/TextField";
import FormButtons from "shared/components/FormButtons";
import Notification from "shared/components/Notification";
import Translated from "shared/components/Translated";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import formValidation from "./formValidation";
import { performActivityEdit } from "modules/Activities/reducers/edit";
export const FORM_NAME = "activities/EDIT";

class EditActivityModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.states.isLoading && this.props.states.isLoaded) {
      this.props.onCancel();
    }
  }

  performInvite = () => {
    this.props.performActivityEdit({
      id: this.props.data.id,
      activityType: this.props.formValues.activityType,
    });
  };

  render() {
    const { onCancel, handleSubmit, submitSucceeded, submitFailed, states, response } = this.props;
    return (
      <form onSubmit={handleSubmit(this.performInvite)}>
        <Field component={TextField} label={<Translated path="activity.labels.name" shared />} name="activityType" />
        <FormButtons>
          <Button type="primary" isLoading={states.isLoading}>
            <Translated path="buttons.save" shared />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </FormButtons>
        {(submitSucceeded || submitFailed) && <Notification {...states}>{<Translated path={response} />}</Notification>}
      </form>
    );
  }
}
const mapStateToProps = ({ activities, ...state }, ownProps) => ({
  states: activities.edit.states,
  response: activities.edit.response,
  initialValues: ownProps.data,
  formValues: getFormValues(FORM_NAME)(state),
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performActivityEdit }, dispatch);

export default compose(
  intl("modules.Activities.EditActivityModal"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME, validate: formValidation })
)(EditActivityModal);
