import { ROLE_CONTRACTOR } from "shared/constants/auth";
import { REQUEST_STATUS_PENDING_MANAGER, REQUEST_STATUS_ASSIGNED_AS_DRAFT } from "shared/constants/request";
import apiService from "shared/services/api";

export const parseRequest = (request, role) => {
  const newRequest = request;
  if (role === ROLE_CONTRACTOR && newRequest.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT) {
    newRequest.status = REQUEST_STATUS_PENDING_MANAGER;
  }

  return newRequest;
};

export function* callFetchRequest(queryParams) {
  const { data } = yield apiService({}).get(`/request?${queryParams}`);
  return data;
}
