import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route } from "react-router";
import { routes, baseRedirections } from "shared/constants/routes";
import Header from "./components/Header";
import Users from "modules/Users";
import Requests from "modules/Requests";
import Activities from "modules/Activities";
import Account from "modules/Account";
import Contact from "modules/Contact";
import Companies from "modules/Companies";
import Planning from "modules/Planning";
import Calendar from "modules/Calendar";
import Dashboard from "modules/Dashboard";
import { fetchNotifications } from "./reducers/notifications";
import { fetchCurrentUser } from "./reducers/user";
import "./styles.scss";
import { ProtectedRoute } from "shared/components/ProtectedRoute";
import { ROLE_CONTRACTOR, ROLE_INSPECTOR, ROLE_MANAGER, ROLE_ADMIN, ROLE_SUPERADMIN } from "shared/constants/auth";
import RotateLoader from "shared/components/RotateLoader";
import Counties from "modules/Counties";
import Tour from "shared/components/CustomTour";

import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";

const ReactHint = ReactHintFactory(React);

export class App extends PureComponent {
  interval;
  componentDidMount() {
    this.loadNotifications();
    this.props.fetchCurrentUser();
    // this.interval = setInterval(this.loadNotifications, 30 * 1000); // 30 seconds
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadNotifications = () => {
    this.props.fetchNotifications();
  };

  extractIdNewUrl = () => {
    const id = this.props.location.pathname.replace(/(.*)\/(.*)/, "$2");
    return routes.calendar.details({ id: id }).path;
  };

  render() {
    const { role } = this.props;
    if (!this.props.role) {
      return <RotateLoader relative />;
    }
    return (
      <div className="App">
        <ReactHint autoPosition events />
        <ReactHint persist attribute="data-custom" className="custom-hint" />
        <div className="App__header">
          <Header />
        </div>
        <div className="App__content">
          <ProtectedRoute
            path="/app/"
            component={Dashboard}
            role={role}
            exact
            roles={[ROLE_CONTRACTOR, ROLE_INSPECTOR, ROLE_MANAGER]}
            redirectTo={baseRedirections[role]}
          />
          <ProtectedRoute
            path={routes.dashboard.default.path}
            component={Dashboard}
            roles={[ROLE_CONTRACTOR, ROLE_INSPECTOR, ROLE_MANAGER]}
            role={role}
          />
          <ProtectedRoute
            path={routes.users.default.path}
            component={Users}
            roles={[ROLE_ADMIN, ROLE_MANAGER]}
            role={role}
          />
          <ProtectedRoute
            path={routes.requests.default.path}
            component={Requests}
            roles={[ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR]}
            role={role}
          />

          {/* Workaround for redirecting wrong url email notification 
               By default the url is http://<hostname>/app/requests/{id}
               but this view does not exist for the inspectors (they can not edit a request)
               So this url is intercepted and replace by http://<hostname>/app/calendar/{id} for the inspectors
            */
          /* Edit 2019-09-03 : the /app/request route has been added for the ROLE_INSPECTOR, so this workaround is not used anymore
              The commented code is "For Your Information" and in case of the inspector prefer to have this view on the calendar tab and not the request tab
            */}
          {/*role === ROLE_INSPECTOR && (
            this.props.location.pathname.replace(/(.*)\/(.*)\/(.*)/, "$2") === "requests" ? (
              this.props.history.push(this.extractIdNewUrl())
            ) : false
            )*/}

          <ProtectedRoute
            path={routes.activities.default.path}
            component={Activities}
            roles={[ROLE_ADMIN]}
            role={role}
          />
          <ProtectedRoute path={routes.counties.default.path} component={Counties} roles={[ROLE_ADMIN]} role={role} />
          <ProtectedRoute
            path={routes.companies.default.path}
            component={Companies}
            roles={[ROLE_SUPERADMIN]}
            role={role}
          />
          <Route path={routes.account.default.path} component={Account} />
          <Route path={routes.contact.default.path} component={Contact} />
          <Route path={routes.calendar.details({ id: ":id?" }).path} component={Calendar} />
          <Route path={routes.planning.details({ id: ":id?" }).path} component={Planning} />
        </div>
        <Tour history={this.props.history}></Tour>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user }, auth }) => ({
  isLoading: user.states.isLoading,
  isLoaded: user.states.isLoaded,
  role: auth.data.role,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchNotifications, fetchCurrentUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
