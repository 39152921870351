import React from "react";
import { Line } from "react-chartjs-2";
import map from "lodash/map";
import forEach from "lodash/forEach";
import get from "lodash/get";

import sortBy from "lodash/sortBy";
import Translated from "shared/components/Translated";
import { moment, DATE_FORMAT } from "shared/services/date";
import { REQUEST_COLORS } from "shared/constants/request";
import { fillUnusedSpace } from "shared/utils/chart";
import Box from "modules/Dashboard/components/Box";
import { utilGetAllWeeks } from "shared/utils/dashboard.js";
import "./styles.scss";

export default ({ statuses, lang }) => {
  const byStatus = {};
  forEach(statuses, (row) => {
    if (!byStatus[row.status]) {
      byStatus[row.status] = [row];
      return;
    }
    byStatus[row.status].push(row);
  });
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

  const sortedData = (data) =>
    map(sortBy(data, ["week"]), ({ count, week }) => ({
      x: moment(week).format(DATE_FORMAT),
      y: count,
    }));

  const weeksLabels = utilGetAllWeeks(statuses);

  const data = {
    labels: map(weeksLabels, (week) => moment(week).format(DATE_FORMAT)),
    datasets: map(byStatus, (data, key) => ({
      label: get(lang, `request.status.${key}`),
      fill: false,
      pointBackgroundColor: "white",
      pointBorderColor: REQUEST_COLORS[key],
      lineTension: 0.1,
      borderColor: REQUEST_COLORS[key],
      backgroundColor: "white",
      data: fillUnusedSpace(weeksLabels, sortedData(data)),
    })),
  };

  const options = {
    legend: {
      position: "bottom",
      display: true,
    },
    aspectRatio: 3,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: 1,
          },
          stacked: true,
        },
      ],
    },
  };
  return (
    <Box title={<Translated path="requests" />} subTitle={<Translated path="selectedPeriod" />}>
      <div className="DashboardPeriodRequests">
        <div id="legend-dashboardPeriodRequests"></div>
        <Line data={data} options={options} height={x <= 812 ? 300 : null} />
      </div>
    </Box>
  );
};
