import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import map from "lodash/map";
import { intl } from "shared/hoc/Intl";
import { Table, Thead, Tbody, Tr, Td, Th } from "shared/components/Table";
import Pagination from "shared/components/Pagination";
import Modal from "shared/components/Modal";
import Button from "shared/components/Button";
import { Pencil, Trash } from "shared/components/Icons";
import CreateActivityModal from "modules/Activities/containers/CreateActivityModal";
import EditActivityModal from "modules/Activities/containers/EditActivityModal";
import DetailsModal from "modules/Activities/containers/DetailsModal";
import DeleteActivityModal from "modules/Activities/containers/DeleteActivityModal";
import { fetchActivitiesList } from "shared/reducers/activities";
import { langLabel } from "shared/components/Translated";
import { getGlobalState } from "store/index.js";

import "./styles.scss";

class List extends PureComponent {
  state = {
    editModal: false,
    createModal: false,
    deleteModal: false,
    detailsModal: false,
    modalData: {},
    currentPage: 1,
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
  }

  toggleModal = (key, modalData = {}) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { isLoading, lang, data, total } = this.props;
    const globalLang = getGlobalState().lang.currentLang;
    const pages = Math.ceil(total / 10);
    const extractData = data
      .sort((a, b) => a.activityType.localeCompare(b.activityType, globalLang, { numeric: true }))
      .slice((this.state.currentPage - 1) * 10, this.state.currentPage * 10);
    return (
      <div className="UsersList">
        <Modal title={lang.createActivity} isOpen={this.state.createModal} onClose={this.toggleModal("createModal")}>
          <CreateActivityModal onCancel={this.toggleModal("createModal")} onPageChange={this.onPageChange} />
        </Modal>

        <DetailsModal
          onClose={this.toggleModal("detailsModal")}
          data={this.state.modalData}
          isOpen={this.state.detailsModal}
        />

        <Modal title={lang.editActivity} isOpen={this.state.editModal} onClose={this.toggleModal("editModal")}>
          <EditActivityModal onCancel={this.toggleModal("editModal")} data={this.state.modalData} />
        </Modal>
        <Modal
          title={lang.deleteActivity}
          isOpen={this.state.deleteModal}
          onClose={this.toggleModal("deleteModal", {})}
        >
          <DeleteActivityModal
            onCancel={this.toggleModal("deleteModal")}
            data={this.state.modalData}
            onPageChange={this.onPageChange}
          />
        </Modal>
        <div className="container">
          <div className="UsersList__header d-flex align-items-center">
            <h1>
              {lang.activities} ({total})
            </h1>
            <Button
              icon="plus"
              type="primary"
              noMargin
              onClick={this.toggleModal("createModal")}
              className="btn-create-activity"
            >
              {lang.createActivity}
            </Button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={isLoading}>
                <Thead>
                  <Tr>
                    <Th>{lang.activityName}</Th>
                    <Th>{lang.assignedManagers}</Th>
                    <Th specialClass="col-center">{lang.action}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(extractData, (activity) => (
                    <Tr key={`activity-${activity.id}`} onClick={this.toggleModal("detailsModal", activity)}>
                      <Td>{activity.activityType}</Td>
                      <Td>{activity.managersCount || 0}</Td>

                      <Td specialClass="col-center-row">
                        <Pencil
                          className="UsersList__icon"
                          onClick={this.toggleModal("editModal", {
                            id: activity.id,
                            activityType: activity.activityType,
                          })}
                          data-rh={langLabel("tooltips.editActivity", this.props)}
                        />
                        {activity.managersCount === 0 && (
                          <Trash
                            className="UsersList__icon"
                            onClick={this.toggleModal("deleteModal", {
                              id: activity.id,
                            })}
                            data-rh={langLabel("tooltips.deleteActivity", this.props)}
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination onPageChange={this.onPageChange} pages={pages} currentPage={this.state.currentPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ shared }) => ({
  data: shared.activities.data.rows,
  total: shared.activities.data.total,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchActivitiesList }, dispatch);

export default compose(intl("modules.Activities.List"), connect(mapStateToProps, mapDispatchToProps))(List);
