import React, { PureComponent } from "react";
import Translated from "shared/components/Translated";
import { routes } from "shared/constants/routes";
import SingleFormPage from "shared/components/SingleFormPage";
import { compose } from "redux";
import { intl } from "shared/hoc/Intl";
import { Field, reduxForm } from "redux-form";
import TextField from "shared/components/TextField";
import TextArea from "shared/components/TextArea";
import formValidation from "./formValidation";
import FormButtons from "shared/components/FormButtons";
import Button from "shared/components/Button";
import apiService from "shared/services/api";
import "shared/components/Notification/styles.scss";

export const FORM_NAME = "contact";

class Contact extends PureComponent {
  constructor() {
    super();

    this.initialState = {
      email: "",
      subject: "",
      content: "",
      errorSend: false,
    };
    this.state = this.initialState;
  }

  performEdit = () => {
    const { id } = this.props.match.params;
    this.toggleModal();
    this.props.performUserEdit({ ...this.props.formValues, id });
  };

  send = (formValues) => {
    apiService({})
      .post("/email/contactSupport", formValues, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        this.home();
      })
      .catch((error) => {
        this.setState({ errorSend: true });
      });
  };

  home = () => {
    this.props.history.push(routes.app.default.path);
  };

  render() {
    const { isLoading, handleSubmit } = this.props;

    return (
      <div>
        {this.state.errorSend && (
          <div className="col-md-12">
            <div className="Notification Notification--error">
              <Translated path={`responses.error`} />
            </div>
          </div>
        )}
        <SingleFormPage
          title={<Translated path="title" />}
          backTo={routes.app.default.path}
          isLoading={isLoading}
          buttons={
            <FormButtons>
              {" "}
              <Button onClick={this.home}>
                {" "}
                <Translated path="buttons.cancel" />{" "}
              </Button>
              <Button type="primary" onClick={handleSubmit(this.send)}>
                {" "}
                <Translated path="buttons.send" />{" "}
              </Button>{" "}
            </FormButtons>
          }
        >
          <form>
            <Field component={TextField} label={<Translated path="labels.email" />} placeholder="" name="from" />
            <Field component={TextField} label={<Translated path="labels.subject" />} placeholder="" name="subject" />
            <Field
              component={TextArea}
              label={<Translated path="labels.content" />}
              placeholder=""
              name="content"
              _cols="200"
              _rows="10"
            />
          </form>
        </SingleFormPage>
      </div>
    );
  }
}

export default compose(
  intl("modules.Contact.Contact"),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(Contact);
