import React from "react";
import Translated from "shared/components/Translated";
import RequestDetailsRow from "shared/components/RequestDetailsRow";

export default ({ data }) => (
  <div>
    <RequestDetailsRow label={<Translated path="request.labels.equipment" />} value={data.equipment} />
    <RequestDetailsRow label={<Translated path="request.labels.equipmentParts" />} value={data.equipmentParts} />
    <RequestDetailsRow
      label={<Translated path="request.labels.equipmentPartsNumber" />}
      value={data.equipmentPartsNumber}
    />
    <RequestDetailsRow
      label={<Translated path="request.labels.safety.label" />}
      value={
        data.safety ? <Translated path="request.labels.safety.yes" /> : <Translated path="request.labels.safety.no" />
      }
    />
  </div>
);
