import { darkGreyCol } from "shared/constants/colors.js";
import { readableDate } from "shared/services/date";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  REQUEST_STATUS_PENDING_MANAGER,
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_DECLINED,
  REQUEST_STATUS_CANCELLED,
} from "shared/constants/request";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MAX_LENGHT_ATTACHMENT_NAME = 47; // In fact 60, but we add "_", the date "ddMMYYYY" and the ".pdf" extension

const printPDF = (data) => (e) => {
  var sendDate = "ERROR";

  data.history.forEach((h) => {
    if (
      sendDate === "ERROR" &&
      h.fromStatus === REQUEST_STATUS_DRAFT &&
      h.toStatus === REQUEST_STATUS_PENDING_MANAGER
    ) {
      sendDate = h.changeDate;
    }
  });
  if (sendDate === "ERROR") {
    sendDate = Date.now();
  }

  var ended = data.status === REQUEST_STATUS_DECLINED || data.status === REQUEST_STATUS_CANCELLED;

  var dd = {
    content: [
      {
        style: "tableTitle",
        margin: [0, 30, 0, 0],
        table: {
          widths: ["40%", "30%", "30%"],
          body: [
            [
              {
                text: "NOTIFICATION FOR INSPECTION",
                fontSize: 20,
                alignment: "center",
                margin: [0, 5, 0, 5],
                colSpan: 3,
              },
              "",
              "",
            ],
            [
              {
                text: ended ? data.status : "",
                fontSize: 20,
                alignment: "center",
                margin: [0, 5, 0, 5],
                colSpan: 3,
                fillColor: ended ? "#f5e7ea" : "white",
                color: "#cb354b",
              },
              "",
              "",
            ],
            [
              {
                text: data.contractorCompany + "\n" + data.projectName,
                alignment: "center",
                fontSize: 10,
                margin: [0, 5, 0, 5],
              },
              {
                text: data.plannedDateBegin
                  ? "Planned date: \n" + readableDate(data.plannedDateBegin) + "\n" + readableDate(data.plannedDateEnd)
                  : "SUGGESTED DATE: \n" +
                    readableDate(data.suggestedDateBegin) +
                    "\n" +
                    readableDate(data.suggestedDateEnd),
                margin: [0, 5, 0, 5],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "Sent date : \n" + readableDate(sendDate),
                margin: [0, 5, 0, 5],
                fontSize: 10,
                alignment: "center",
              },
            ],
          ],
        },
        layout: {
          hLineColor: function (i, node) {
            return "#e5e5e5";
          },
          vLineColor: function (i, node) {
            return "#e5e5e5";
          },
          hLineWidth: function (i, node) {
            return 0.1;
          },
        },
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["50%", "50%", "15%"],

          body: [
            [
              {
                text: "INFORMATION",
                colSpan: 2,
                alignment: "center",
                border: [false, false, false, false],
                margin: [0, 5, 0, 5],
                characterSpacing: 7,
                fontSize: 14,
              },
              "",
            ],
            [
              {
                text: data.contractorCompany + "\n" + (data.subSubcontractorName ? data.subSubcontractorName : "-"),
                margin: [5, 5, 5, 5],
                alignment: "center",
              },
              {
                text: "FRAMATOME",
                margin: [5, 12, 5, 5],
                alignment: "center",
              },
            ],
            [
              {
                text: data.contractorReference ? data.contractorReference : "-",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: data.clientReference,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
            ],
            [
              {
                text: data.contractorDetails.surname + " " + data.contractorDetails.name,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: data.managerDetails.surname + " " + data.managerDetails.name,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
            ],
            [
              {
                text: data.contractorDetails.email,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: data.managerDetails.email,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
            ],
            [
              {
                text: data.contractorDetails.phoneNumber,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: data.managerDetails.phoneNumber,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i < 3 ? 1 : 0.1;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          hLineColor: function (i, node) {
            return i < 3 ? "#42c19a" : "#c5c5c5";
          },
        },
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["50%", "50%", "15%"],

          body: [
            [
              {
                text: "INSPECTION",
                colSpan: 2,
                alignment: "center",
                border: [false, false, false, false],
                margin: [0, 5, 0, 5],
                characterSpacing: 7,
                fontSize: 14,
              },
              "",
              {
                border: [false, false, false, false],
                relativePosition: { x: -78, y: 0 },
                table: {
                  widths: ["100%"],
                  body: [
                    [
                      {
                        // Duplicate key, the second one is used, so the first one is commented
                        // border: [true, true, true, true],
                        border: [false, false, false, false],
                        fillColor: data.witness ? "#f1f4f9" : "#f5e7ea",
                        color: data.witness ? "#759fd9" : "#cb354b",
                        text: data.witness ? "WITNESS" : "HOLD",
                        bold: true,
                        alignment: "center",
                        margin: [5, 5, 5, 5],
                      },
                    ],
                  ],
                },
              },
            ],
            [
              {
                text: "EQUIPMENTS",
                margin: [5, 5, 5, 5],
                alignment: "center",
              },
              {
                text: "PART or SUB-ASSEMBLY ( " + data.equipmentPartsNumber + " parts )",
                margin: [5, 5, 5, 5],
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: data.equipment,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: data.equipmentParts,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: "OPERATION : " + data.operationDesignation,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: data.inspectorDetails
                  ? "INSPECTEUR :\n\n" +
                    data.inspectorDetails.surname +
                    " " +
                    data.inspectorDetails.name +
                    "\n" +
                    data.inspectorDetails.email +
                    "\n" +
                    data.inspectorDetails.phoneNumber +
                    "\n"
                  : "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
                rowSpan: 4,
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: "FOLLOW-UP DOC. : " + data.followupDocumentRevisionNumber,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: "PHASE (operation) N° : " + data.phaseNumber,
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: "Is NSQ 100 applicable ? " + (data.safety ? "YES" : "NO"),
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "justify",
              },
              {
                text: "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: data.plannedDateBegin
                  ? "DATE : \n" + readableDate(data.plannedDateBegin) + " - " + readableDate(data.plannedDateEnd)
                  : "SUGGESTED DATE : \n" +
                    readableDate(data.suggestedDateBegin) +
                    " - " +
                    readableDate(data.suggestedDateEnd),
                margin: [5, 8, 5, 8],
                fontSize: 12,
                alignment: "center",
                colSpan: 2,
              },
              {
                text: "",
                margin: [5, 8, 5, 8],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: data.locationText,
                // Duplicate key, the second one is used, so the first one is commented
                // fontSize: 12,
                fontSize: 10,
                margin: [5, 8, 5, 8],
                alignment: "center",
                colSpan: 2,
              },
              {
                text: "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: data.thirdParties
                  ? data.thirdParties.map((tp) => {
                      return tp.name + " - " + (tp.witness ? "WITNESS" : "HOLD") + "\n";
                    })
                  : "No Third Parties",
                // Duplicate key, the second one is used, so the first one is commented
                // fontSize: 12,
                fontSize: 10,
                margin: [5, 8, 5, 8],
                alignment: "center",
                colSpan: 2,
              },
              {
                text: "",
                margin: [5, 2, 5, 2],
                fontSize: 10,
                alignment: "center",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i < 4 || i === 7 ? 1 : 0.1;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          hLineColor: function (i, node) {
            return i < 4 || i === 7 ? "#42c19a" : "#c5c5c5";
          },
        },
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["50%", "50%", "15%"],

          body: [
            [
              {
                text: "DISTRIBUTION",
                colSpan: 2,
                alignment: "center",
                border: [false, false, false, false],
                margin: [0, 5, 0, 5],
                characterSpacing: 7,
                fontSize: 14,
              },
              "",
            ],
            [
              {
                text: "DISTRIBUTION",
                margin: [5, 5, 5, 5],
                alignment: "center",
              },
              {
                text: "NOTES",
                margin: [5, 5, 5, 5],
                alignment: "center",
              },
            ],
            [
              {
                text:
                  data.mailingList !== undefined &&
                  data.mailingList !== null &&
                  Array.isArray(data.mailingList) &&
                  data.mailingList.length > 0
                    ? data.mailingList.join("\n")
                    : "",
                margin: [5, 2, 5, 2],
                alignment: "justify",
                fontSize: 10,
              },
              {
                text: data.otherContactInformation,
                margin: [5, 2, 5, 2],
                alignment: "justify",
                fontSize: 10,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i < 3 ? 1 : 0.1;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          hLineColor: function (i, node) {
            return i < 3 ? "#42c19a" : "#c5c5c5";
          },
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        table: {
          widths: ["30%", "40%", "30%"],
          body: [
            [
              {
                text: "generated on : " + readableDate(new Date()),
                fontSize: 8,
                margin: [50, 0, 0, 0],
                color: darkGreyCol,
              },
              {},
              {
                text: "page " + currentPage.toString() + " / " + pageCount,
                fontSize: 9,
                color: darkGreyCol,
                alignment: "right",
                margin: [0, 0, 50, 0],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
        },
      };
    },
    header: {
      table: {
        widths: ["35%", "30%", "35%"],
        body: [
          [
            {
              text: data.contractorCompany + "\n" + (data.subSubcontractorName ? data.subSubcontractorName : "-"),
              margin: [40, 10, 0, 0],
              fontSize: 9,
              color: darkGreyCol,
              border: [false, false, false, false],
            },
            {
              text: data.clientReference,
              margin: [40, 10, 0, 0],
              fontSize: 9,
              color: darkGreyCol,
              border: [false, false, false, false],
              alignment: "center",
            },
          ],
        ],
      },
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
  };
  let today = new Date().toLocaleString().slice(0, 10).replace(/-|\//g, "");
  let fileName =
    (data.contractorCompany ? data.contractorCompany : "") +
    (data.clientReference ? "_" + data.clientReference : "") +
    (data.contractorReference ? "_" + data.contractorReference : "");
  fileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // convert to ASCII without accented characters
  fileName = fileName.replace(/[&/\\#,+()$~%.'":*?<>{}`¨^ ]/g, "_").replace(/_+/g, "_"); // remove special char forbidden by the file systems
  let endIndex = fileName.length > MAX_LENGHT_ATTACHMENT_NAME ? MAX_LENGHT_ATTACHMENT_NAME : fileName.length; // calculate the enIndex to truncate because attachment name are limited in length
  pdfMake.createPdf(dd).download(fileName.substring(0, endIndex) + "_" + today + ".pdf");
};

export default printPDF;
