import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { CALENDAR_REQUESTS_FETCH } from "modules/Calendar/reducers/requests/";

function* fetchList(action) {
  try {
    /* #zuwa0 */
    // keep this code in comment until we activate dynamic loading according the displayed week
    // const { startWeek, endWeek} = action.payload;
    // const params = {startWeek : startWeek.startOf('day').valueOf(), endWeek : endWeek.endOf('day').valueOf()};
    // const queryParams = objToParams(params);
    // const { data } = yield apiService({}).get(`/request/calendar?${queryParams}`);
    const { data } = yield apiService({}).get("/request/calendar");
    yield put({
      type: CALENDAR_REQUESTS_FETCH.SUCCESS,
      payload: {
        rows: data,
      },
    });

    /* END #zuwa0 */
  } catch (err) {
    yield put({
      type: CALENDAR_REQUESTS_FETCH.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(CALENDAR_REQUESTS_FETCH.REQUEST, fetchList);
  },
];
