export default ({ subject, content, from }) => {
  const errors = {};
  if (!from) {
    errors["from"] = "NO_EMAIL";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(from)) {
    errors["from"] = "WRONG_EMAIL_FORMAT";
  }
  if (!subject) {
    errors["subject"] = "FILL_IN";
  }
  if (!content) {
    errors["content"] = "FILL_IN";
  }
  return errors;
};
