import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import includes from "lodash/includes";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_STATUS_CANCELLED,
  REQUEST_STATUS_DECLINED,
  REQUEST_STATUS_DONE,
} from "shared/constants/request";
import { ROLE_MANAGER, ROLE_CONTRACTOR } from "shared/constants/auth";
import { routes } from "shared/constants/routes";

export default (WrappedComponent) => {
  class RequestProtector extends React.Component {
    render() {
      const { role, data, location } = this.props;
      if (
        role === ROLE_CONTRACTOR &&
        includes([REQUEST_STATUS_CANCELLED, REQUEST_STATUS_DECLINED, REQUEST_STATUS_DONE], data.status)
      ) {
        return (
          <Redirect
            to={{
              pathname: routes.requests.list.path,
              state: { from: location },
            }}
          />
        );
      }
      if (
        role === ROLE_MANAGER &&
        includes(
          [
            REQUEST_STATUS_DRAFT,
            REQUEST_STATUS_CANCELLED,
            REQUEST_STATUS_DECLINED,
            REQUEST_STATUS_DONE,
            REQUEST_STATUS_PENDING_SUBCONTRACTOR,
          ],
          data.status
        )
      ) {
        return (
          <Redirect
            to={{
              pathname: routes.planning.default.path,
              state: { from: location },
            }}
          />
        );
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  RequestProtector.propTypes = {
    role: PropTypes.string.isRequired,
    data: PropTypes.shape({
      status: PropTypes.string,
    }),
  };
  return RequestProtector;
};
