import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { COUNTIES_CREATE_NEW } from "modules/Counties/reducers/create/";
import { fetchCountiesList } from "modules/Counties/reducers/list/";
import { fetchDefaultCounty } from "modules/Counties/reducers/defaultCounty";

function* createCounty(action) {
  try {
    const { data } = yield apiService({}).post("/county-rule", action.payload);
    yield put({
      type: COUNTIES_CREATE_NEW.SUCCESS,
      payload: { ...data, response: "responses.success" },
    });
    const {
      counties: {
        list: { filters },
      },
    } = yield select();
    yield put(fetchCountiesList(filters));
    yield put(fetchDefaultCounty());
  } catch (err) {
    yield put({ type: COUNTIES_CREATE_NEW.FAILURE, payload: getError(err) });
  }
}

function* createCountySaga() {
  yield takeEvery(COUNTIES_CREATE_NEW.REQUEST, createCounty);
}

export default [createCountySaga];
