import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const ACTIVITIES_DETAILS_FETCH_MANAGERS = createRequestTypes("activities/details/FETCH_MANAGERS");
export const ACTIVITIES_DETAILS_ADD_ACTIVITY = createRequestTypes("activities/details/ADD_ACTIVITY_TO_MANAGER");
export const ACTIVITIES_DETAILS_DELETE_ACTIVITY = createRequestTypes("activities/details/DELETE_ACTIVITY_FROM_MANAGER");

export const fetchManagers = ({ activityId }) => ({
  type: ACTIVITIES_DETAILS_FETCH_MANAGERS.REQUEST,
  payload: {
    activityId,
  },
});

export const addActivityToManager = ({ managerId, activityId }) => ({
  type: ACTIVITIES_DETAILS_ADD_ACTIVITY.REQUEST,
  payload: {
    activityId,
    managerId,
  },
});

export const deleteActivityFromManager = ({ managerId, activityId }) => ({
  type: ACTIVITIES_DETAILS_DELETE_ACTIVITY.REQUEST,
  payload: {
    activityId,
    managerId,
  },
});

const initState = {
  managers: {
    states: loadingStates,
    data: [],
  },
  loadingId: null,
  addActivity: {
    states: loadingStates,
  },
  deleteActivity: {
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [ACTIVITIES_DETAILS_DELETE_ACTIVITY.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: action.payload.managerId,
        deleteActivity: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_DELETE_ACTIVITY.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: null,
        deleteActivity: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_DELETE_ACTIVITY.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: null,
        deleteActivity: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_ADD_ACTIVITY.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: action.payload.managerId,
        addActivity: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_ADD_ACTIVITY.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: null,
        addActivity: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_ADD_ACTIVITY.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        loadingId: null,
        addActivity: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_FETCH_MANAGERS.REQUEST]: (state) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_FETCH_MANAGERS.SUCCESS]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [ACTIVITIES_DETAILS_FETCH_MANAGERS.FAILURE]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
});
