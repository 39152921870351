import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Route, Redirect, withRouter } from "react-router";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import { routes } from "shared/constants/routes";

export const ProtectedRoute = ({ component: Component, redirectTo, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return includes(rest.roles, role) && redirectTo === undefined ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo || routes.app.default.path,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  role: PropTypes.string,
};

const mapStateToProps = ({ auth }) => ({
  role: auth.data.role,
});
export default compose(withRouter, connect(mapStateToProps))(ProtectedRoute);
