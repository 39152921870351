import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import map from "lodash/map";
import ceil from "lodash/ceil";
import { intl } from "shared/hoc/Intl";
import { Table, Thead, Tbody, Tr, Td, Th } from "shared/components/Table";
import Pagination from "shared/components/Pagination";
import Translated, { langLabel } from "shared/components/Translated";
import Modal from "shared/components/Modal";
import Button from "shared/components/Button";
import { Trash } from "shared/components/Icons";
import CreateCountyModal from "modules/Counties/containers/CreateCountyModal";
import DeleteCountyModal from "modules/Counties/containers/DeleteCountyModal";
import { fetchCountiesList } from "modules/Counties/reducers/list";
import { fetchDefaultCounty } from "modules/Counties/reducers/defaultCounty";
import { VOID_COUNTY } from "shared/constants/county.js";

import "./styles.scss";

class List extends PureComponent {
  state = {
    createModal: false,
    createDefaultModal: false,
    deleteModal: false,
    modalData: {},
  };

  componentDidMount() {
    this.props.fetchDefaultCounty();
    this.props.fetchCountiesList({});
  }

  toggleModal = (key, modalData = {}) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  onSort = (key) => (value) => {
    const {
      filters: { sort },
    } = this.props;
    const newSort = {
      field: key,
      direction: "DESC",
    };
    if (sort.direction === "DESC" && key === sort.field) {
      newSort.direction = "ASC";
    }
    if (sort.direction === "ASC" && key === sort.field) {
      newSort.direction = "DESC";
    }
    this.props.fetchCountiesList({ sort: newSort });
  };

  onPageChange = (page) => {
    this.props.fetchCountiesList({ pager: { page, limit: this.props.filters.pager.limit } });
  };

  render() {
    const { isLoading, filters, pages, lang, listCountiesData, listCountiesTotal, defaultCounty } = this.props;
    return (
      <div className="CountyList">
        <Modal title={lang.createCounty} isOpen={this.state.createModal} onClose={this.toggleModal("createModal")}>
          <CreateCountyModal onCancel={this.toggleModal("createModal")} />
        </Modal>

        <Modal
          title={lang.createCounty}
          isOpen={this.state.createDefaultModal}
          onClose={this.toggleModal("createDefaultModal")}
        >
          <CreateCountyModal voidCounty={VOID_COUNTY} onCancel={this.toggleModal("createDefaultModal")} />
        </Modal>

        <Modal title={lang.deleteCounty} isOpen={this.state.deleteModal} onClose={this.toggleModal("deleteModal", {})}>
          <DeleteCountyModal onCancel={this.toggleModal("deleteModal")} data={this.state.modalData} />
        </Modal>
        <div className="container">
          <div className="CountyList__header d-flex align-items-center">
            <div className="CountyList__header__row">
              <h1>
                <Translated path="counties" shared /> ({listCountiesTotal})<br />
              </h1>
              <Button icon="plus" type="primary" noMargin onClick={this.toggleModal("createModal")}>
                <Translated path="createCounty" shared />
              </Button>
            </div>
            <div className="CountyList__header__row">
              <span className={"defaultCounty"}>
                <span>
                  <Translated path="defaultCounty" shared />
                </span>{" "}
                :
                {defaultCounty && (
                  <Fragment>
                    <span className="has-value">{defaultCounty.managerCounty}</span>
                    <Trash
                      className="UsersList__icon"
                      onClick={this.toggleModal("deleteModal", {
                        id: defaultCounty.id,
                      })}
                      data-rh={langLabel("tooltips.deleteRule", this.props)}
                    />
                  </Fragment>
                )}
                {!defaultCounty && (
                  <Button
                    icon="plus_black"
                    type="white"
                    noMargin
                    onClick={this.toggleModal("createDefaultModal")}
                    className="btn-create-default-county"
                  >
                    {lang.missingDefaultCounty}
                  </Button>
                )}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={isLoading}>
                <Thead>
                  <Tr>
                    <Th sortable onSort={this.onSort("requestCounty")}>
                      <Translated path="county.labels.requestCounty" shared />
                    </Th>
                    <Th sortable onSort={this.onSort("managerCounty")}>
                      <Translated path="county.labels.managerCounty" shared />
                    </Th>
                    <Th specialClass="col-center">{lang.action}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(listCountiesData, (county) => (
                    <Tr key={`county-${county.id}`}>
                      <Td>{county.requestCounty}</Td>
                      <Td>{county.managerCounty}</Td>

                      <Td specialClass="col-center-row">
                        <Trash
                          className="UsersList__icon"
                          onClick={this.toggleModal("deleteModal", {
                            id: county.id,
                          })}
                          data-rh={langLabel("tooltips.deleteRule", this.props)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination onPageChange={this.onPageChange} pages={pages} currentPage={filters.pager.page} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ counties }) => ({
  defaultCounty: counties.defaultCounty.value.managerCounty !== VOID_COUNTY ? counties.defaultCounty.value : undefined,
  filters: counties.list.filters,
  listCountiesData: counties.list.data.rows,
  listCountiesTotal: counties.list.data.total,
  pages: ceil(counties.list.data.total / counties.list.filters.pager.limit),
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchCountiesList, fetchDefaultCounty }, dispatch);

export default compose(intl("modules.Counties.List"), connect(mapStateToProps, mapDispatchToProps))(List);
