import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { ACTIVITIES_CREATE_NEW } from "modules/Activities/reducers/create/";
import { fetchActivitiesList } from "shared/reducers/activities";

function* createActivity(action) {
  try {
    const { data } = yield apiService({}).post("/activity", action.payload);
    yield put({
      type: ACTIVITIES_CREATE_NEW.SUCCESS,
      payload: { ...data, response: "responses.success" },
    });
    yield put(fetchActivitiesList());
  } catch (err) {
    yield put({ type: ACTIVITIES_CREATE_NEW.FAILURE, payload: getError(err) });
  }
}

function* createActivitySaga() {
  yield takeEvery(ACTIVITIES_CREATE_NEW.REQUEST, createActivity);
}

export default [createActivitySaga];
