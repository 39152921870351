import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { SHARED_ACTIVITIES_FETCH_LIST } from "shared/reducers/activities/";

function* fetchActivities(action) {
  try {
    var httpHeader = {};
    if (
      action !== undefined &&
      action.payload !== undefined &&
      action.payload.token !== undefined &&
      action.payload.token != null
    ) {
      httpHeader = { headers: { "registration-token": action.payload.token } };
    }
    const { data } = yield apiService(httpHeader).get("/activity");
    yield put({
      type: SHARED_ACTIVITIES_FETCH_LIST.SUCCESS,
      payload: {
        rows: data,
        total: data.length,
      },
    });
  } catch (err) {
    yield put({
      type: SHARED_ACTIVITIES_FETCH_LIST.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchActivitiesSaga() {
  yield takeEvery(SHARED_ACTIVITIES_FETCH_LIST.REQUEST, fetchActivities);
}

export default [fetchActivitiesSaga];
