import { validatePhoneNumber } from "shared/utils/validation.js";

export default ({ firstName, lastName, email, phoneNumber }) => {
  const errors = {};
  if (!email) {
    errors["email"] = "NO_EMAIL";
  }
  if (!firstName) {
    errors["firstName"] = "FILL_IN";
  }
  if (!lastName) {
    errors["lastName"] = "FILL_IN";
  }
  if (phoneNumber) {
    if (!validatePhoneNumber(phoneNumber)) {
      errors["phoneNumber"] = "WRONG_PHONE_FORMAT";
    }
  }
  return errors;
};
