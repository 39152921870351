import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import map from "lodash/map";
import { routes } from "shared/constants/routes";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import { Filter } from "shared/components/Icons";
import Request from "modules/Planning/components/Request";
import RequestListFilters from "modules/Planning/containers/RequestListFilters";
import { retrieveActivityName } from "shared/utils/request.js";
import { fetchActivitiesList } from "shared/reducers/activities";
import { orangeCol, greyFilterCol } from "shared/constants/colors.js";

import "./styles.scss";

class RequestList extends PureComponent {
  state = {
    filterTool: false,
    filterActive: false,
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
  }

  onClick = (id) => (e) => {
    this.props.history.push(routes.planning.details({ id }).path);
  };

  filter = (values) => {
    if (values !== undefined) this.setState({ filterActive: true });
    else this.setState({ filterActive: false });
    this.setState({ filterTool: false });
    this.props.delegatePerformPendingRequestFetch(values);
  };

  toggleFilters = () => this.setState({ filterTool: !this.state.filterTool });

  render() {
    const { dataRequestsPending, sharedLang, activities } = this.props;
    // intermediate var, otherwise dataRequestsPending is undefined the first render
    let data = dataRequestsPending ? dataRequestsPending : [];
    let colorFilter;
    if (this.state.filterActive) colorFilter = { fill: orangeCol };
    else colorFilter = { fill: greyFilterCol };
    return (
      <div id="external-waiting-events" className="PlanningRequestsList">
        <span
          className="ExpandButton"
          style={{
            backgroundColor: this.props.expand ? "transparent" : "#626262",
            border: this.props.expand ? "" : "solid 2px white",
          }}
        >
          <img
            className="ExpandImage"
            onClick={this.props.toggleExpand}
            src={require(`assets/icons/arrow_right_green.svg`)}
            alt="arrow"
            style={{
              transform: this.props.expand ? "rotate(180deg)" : "rotate(0)",
              filter: this.props.expand ? "" : " brightness(2)",
            }}
          ></img>
        </span>
        <h2 className="PlanningRequestsList__title">
          <span
            className={
              data.length > 9 ? "PlanningRequestsList__title__count__big" : "PlanningRequestsList__title__count"
            }
          >
            {data.length < 100 ? data.length : "99+"}
          </span>
          <Translated path="pendingRequests" />
          <button onClick={this.toggleFilters} className="PlanningRequestsList__filter">
            <Filter style={colorFilter} />
          </button>
          <RequestListFilters
            sharedLang={sharedLang}
            isOpen={this.state.filterTool}
            onFilter={this.filter}
            onClickOutside={this.toggleFilters}
          />
        </h2>
        <div
          className="row"
          style={{
            opacity: this.props.expand ? "1" : "0",
            transition: ".4s ease-out",
          }}
        >
          <div className="col-md-12" id="external-cos-fc-events">
            {map(data, (request, index) => {
              return (
                <div className="dragSelection" title={request.clientReference} data={request.id} key={request.id}>
                  <Request
                    key={`request-${index}-${request.projectName}`}
                    request={request}
                    activityName={retrieveActivityName(activities, request)}
                    onClick={this.onClick(request.id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ shared }) => ({
  activities: shared.activities.data.rows,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchActivitiesList }, dispatch);

export default compose(intl("modules.Planning.RequestList"), connect(mapStateToProps, mapDispatchToProps))(RequestList);
