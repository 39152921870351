import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { CREATE_ICS_URL, GET_AN_ICS_URL, REMOVE_ICS_URL } from "modules/Account/reducers/icsUrl";

function* removeIcsUrl() {
  try {
    yield apiService({}).delete("/icalendar");
    yield put({
      type: REMOVE_ICS_URL.SUCCESS,
    });
  } catch (err) {
    yield put({ type: REMOVE_ICS_URL.FAILURE, payload: getError(err) });
  }
}

function* getIcsUrl() {
  try {
    const { data } = yield apiService({}).get("/icalendar");
    yield put({
      type: GET_AN_ICS_URL.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: GET_AN_ICS_URL.FAILURE, payload: getError(err) });
  }
}

function* createIcsUrl() {
  try {
    const { data } = yield apiService({}).post("/icalendar");
    yield put({
      type: CREATE_ICS_URL.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: CREATE_ICS_URL.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(GET_AN_ICS_URL.REQUEST, getIcsUrl);
  },
  function* () {
    yield takeEvery(REMOVE_ICS_URL.REQUEST, removeIcsUrl);
  },
  function* () {
    yield takeEvery(CREATE_ICS_URL.REQUEST, createIcsUrl);
  },
];
