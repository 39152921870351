import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";

export const IntlContext = React.createContext();
export const intl = (path) => (WrappedComponent) => {
  class Intl extends React.Component {
    render() {
      const lang = get(this.props.lang, path) || {};
      const shared = get(this.props.lang, "shared") || {};
      return (
        <IntlContext.Provider value={{ lang, shared }}>
          <WrappedComponent {...this.props} lang={lang} sharedLang={shared} />
        </IntlContext.Provider>
      );
    }
  }

  return connect(({ lang }) => ({ lang: lang[lang.currentLang] }))(Intl);
};
