import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { isRouteActive } from "shared/services/routes";
import { NavContext } from "shared/context/nav";
import "./styles.scss";

const NavigationItem = ({ children, to, exactActive, className }) => {
  return (
    <NavContext.Consumer>
      {({ activeRoute }) => {
        const classes = classnames(
          "NavigationItem",
          {
            "NavigationItem--active": isRouteActive(activeRoute, to, exactActive),
          },
          className
        );
        return (
          <Link className={classes} to={to}>
            {children}
          </Link>
        );
      }}
    </NavContext.Consumer>
  );
};

export default NavigationItem;
