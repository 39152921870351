import React from "react";
import ReactExport from "react-data-export";

import Button from "shared/components/Button";
import { intl } from "shared/hoc/Intl";
import Translated, { langLabel } from "shared/components/Translated";

import "./styles.scss";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ButtonExcelExport extends React.Component {
  render() {
    const { combinedDataSet, tabName } = this.props;
    let filename = "export_claimover_" + new Date().toLocaleString().slice(0, 10).replace(/-|\//g, "");
    if (combinedDataSet.columns === undefined) combinedDataSet.columns = [];
    if (combinedDataSet.data === undefined) combinedDataSet.data = [];
    return (
      <ExcelFile
        filename={filename}
        element={
          <Button
            noMargin
            icon="xls"
            className="PlanningCalendarHeadingButtons__button btn-export-excel"
            data-rh={langLabel("tooltips.ExcelExport", this.props)}
          >
            <Translated path="exportXls" />
          </Button>
        }
      >
        <ExcelSheet data={combinedDataSet.data} name={tabName}>
          {combinedDataSet.columns.map((columnDef) => (
            <ExcelColumn label={columnDef.label} value={columnDef.value} key={columnDef.value} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default intl("shared.ExcelExport")(ButtonExcelExport);
