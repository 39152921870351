import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import FieldWrapper from "shared/components/FieldWrapper";
import "./styles.scss";

class TextField extends PureComponent {
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      input,
      label,
      meta,
      noMarginTop,
      noMargin,
      className,
      disabled,
      labelLeft,
      iconLeft,
      icon,
      tooltip,
      setRef,
      isRequired,
      hiddenDisabled,
      isLoading,
      ...props
    } = this.props;

    const inputClasses = classnames("TextField__input", {
      "TextField__input--disabled": disabled && !hiddenDisabled,
      "TextField__input--icon": icon,
      "TextField__input--required": isRequired,
    });

    return (
      <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading}>
        <input
          {...input}
          value={input.value}
          id={input.name}
          {...props}
          className={inputClasses}
          disabled={disabled}
          ref={setRef}
          onKeyPress={
            (e) => {
              e.key === "Enter" && e.preventDefault();
            } /* to avoid an auto-submit when the enter key is pressed */
          }
        />
      </FieldWrapper>
    );
  }
}

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOf(["scope", "arrow_down"]),
};

export default TextField;
