import find from "lodash/find";
import forEach from "lodash/forEach";
import { moment, DATE_FORMAT } from "shared/services/date";

export const fillUnusedSpace = (labels, data) => {
  let result = [];
  forEach(labels, (label, key) => {
    result[key] = find(data, {
      x: moment(label).format(DATE_FORMAT),
    }) || { y: 0, x: label };
  });
  return result;
};
