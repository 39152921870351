import { MIN_REQUEST_DELAY } from "shared/constants/request.js";
import { ROLE_MANAGER } from "shared/constants/auth";
import { getGlobalState } from "store/index.js";
import { moment } from "shared/services/date";

const requiredFields = [
  "projectName",
  "clientReference",
  "date",
  "time",
  "witness",
  "equipment",
  "equipmentParts",
  "equipmentPartsNumber",
  "safety",
  "activityId",
];

const contractorRequiredFields = ["contractorCompany", "createdAt"];

const contractorRequiredSubFields = ["name", "surname", "email"];

//
const whichRole = () => {
  let role = "UNDEFINED"; // "dummy" value if the GlobalState.auth.data.role is not defined yet
  let ggs = getGlobalState();
  if (ggs && ggs.auth && ggs.auth.data && ggs.auth.data.role) role = ggs.auth.data.role;
  return role;
};
export const errorChecks = (fields) => {
  const errors = {};
  const { equipmentPartsNumber, suggestedDateBegin, suggestedDateEnd, location = {} } = fields;
  if (!equipmentPartsNumber) {
    errors.equipmentPartsNumber = "FILL_IN";
  }
  if (!location.lat || !location.lng) {
    errors.location = "FILL_IN";
  }

  requiredFields.forEach((field) => {
    if (!fields[field] && fields[field] !== false) {
      errors[field] = "FILL_IN";
    }
  });

  if (fields.thirdParties) {
    const tpArrayErrors = [];
    fields.thirdParties.forEach((tp, tpIndex) => {
      const tpErrors = {};
      if (!tp || !tp.name) {
        tpErrors.name = "FILL_IN";
        tpArrayErrors[tpIndex] = tpErrors;
      }
    });
    errors.thirdParties = tpArrayErrors;
  }

  // These checks are only valid fore the ROLE_MANAGER who has more fields in the form
  // redux-form does not handle this case natively
  if (whichRole() === ROLE_MANAGER) {
    contractorRequiredFields.forEach((field) => {
      if (!fields[field] && fields[field] !== false) {
        errors[field] = "FILL_IN";
      }
    });

    let contractorDetails = fields["contractorDetails"];
    if (contractorDetails) {
      let errorsContractor = null;
      contractorRequiredSubFields.forEach((field) => {
        if (!contractorDetails[field] && contractorDetails[field] !== false) {
          if (errorsContractor == null) errorsContractor = {};
          errorsContractor[field] = "FILL_IN";
        }
      });
      if (errorsContractor != null) errors["contractorDetails"] = errorsContractor;
    }
  }

  if (
    suggestedDateBegin &&
    moment.isMoment(suggestedDateBegin) &&
    (suggestedDateEnd === undefined || suggestedDateEnd == null)
  ) {
    errors["suggestedDateEnd"] = "END_AND_START_DATE_MUST_BE_BOTH_FILLED_OR_BOTH_EMPTY";
  }
  if (
    suggestedDateEnd &&
    moment.isMoment(suggestedDateEnd) &&
    (suggestedDateBegin === undefined || suggestedDateBegin == null)
  ) {
    errors["suggestedDateBegin"] = "END_AND_START_DATE_MUST_BE_BOTH_FILLED_OR_BOTH_EMPTY";
  }
  if (
    suggestedDateBegin &&
    moment.isMoment(suggestedDateBegin) &&
    suggestedDateEnd &&
    moment.isMoment(suggestedDateEnd) &&
    typeof suggestedDateEnd.isBefore == "function" &&
    suggestedDateEnd.isBefore(suggestedDateBegin)
  ) {
    errors["suggestedDateEnd"] = "END_SUGGESTED_DATE_BEFORE_START_SUGGESTED_DATE";
  }
  if (suggestedDateBegin && !moment.isMoment(suggestedDateBegin) && !(suggestedDateBegin instanceof Date))
    errors["suggestedDateBegin"] = "WRONG_DATE_FORMAT";
  if (suggestedDateEnd && !moment.isMoment(suggestedDateEnd) && !(suggestedDateEnd instanceof Date))
    errors["suggestedDateEnd"] = "WRONG_DATE_FORMAT";

  return errors;
};

export const warnChecks = (fields) => {
  const warnings = {};
  var { createdAt, suggestedDateBegin, suggestedDateEnd } = fields;

  if (suggestedDateBegin && moment.isMoment(suggestedDateBegin) && tenDaysDelay(suggestedDateBegin)) {
    warnings.suggestedDateBegin = "WRONG_MIN_REQUEST_DELAY";
  }
  // This warning is not raised if the suggestedDateEnd < suggestedDateBegin wich raise an error
  // otherwise, the two message are printed overwritten
  if (
    suggestedDateEnd &&
    moment.isMoment(suggestedDateEnd) &&
    tenDaysDelay(suggestedDateEnd) &&
    suggestedDateBegin &&
    moment.isMoment(suggestedDateBegin) &&
    typeof suggestedDateEnd.isBefore == "function" &&
    suggestedDateBegin.isBefore(suggestedDateEnd)
  ) {
    warnings.suggestedDateEnd = "WRONG_MIN_REQUEST_DELAY";
  }
  // These checks are only valid fore the ROLE_MANAGER who has more fields in the form
  // redux-form does not handle this case natively
  if (whichRole() === ROLE_MANAGER) {
    if (createdAt !== undefined && createdAt != null && isAfterToday(createdAt)) {
      warnings.createdAt = "POST_DATE";
    }
  }
  return warnings;
};

// Basic function which does not take in account the public holydays
const tenDaysDelay = (date) => {
  // Beware that it's ten times 24h, if the actual time is the 1st at 14h, the warning will raise until the 11th 14h of the month
  let tenOpeningDaysAfterToday = new Date(Date.now() + 86400 * 1000 * MIN_REQUEST_DELAY);
  // workaround to be able to compare on the year
  let cmp = new Date(date);
  if (!isNaN(cmp) && cmp < tenOpeningDaysAfterToday) return true;
  else return false;
};

const isAfterToday = (date) => {
  let cmpDate = new Date(date);
  let today = new Date();
  if (cmpDate.getFullYear() <= today.getFullYear()) {
    if (cmpDate.getMonth() < today.getMonth()) return false;
    else if (cmpDate.getMonth() === today.getMonth() && cmpDate.getDay() <= today.getDay()) return false;
  }
  return true;
};
