import map from "lodash/map";
import split from "lodash/split";
import filter from "lodash/filter";
import { routes } from "shared/constants/routes";
import { utcToLocal } from "shared/services/date";
import { ROLE_INSPECTOR, ROLE_MANAGER, ROLE_CONTRACTOR } from "shared/constants/auth";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

/**
 * @param {String} base
 * @returns {{REQUEST,SUCCESS,FAILURE}}
 */
export const createRequestTypes = (base, additional = []) => {
  return [REQUEST, SUCCESS, FAILURE, ...additional].reduce(
    (acc, type) => Object.assign(acc, { [type]: `${base}_${type}` }),
    {}
  );
};

export const locationToString = (location) => (location ? `${location.lat}, ${location.lng}` : null);

export const arrayToIds = (data) => map(data, (id) => ({ id }));

/* Request Entity */
export const mapRequestResponseToFormWithThirdPartie = (data) => {
  if (data.thirdParties) {
    data.thirdParties = JSON.parse(data.thirdParties, (k, v) => (v === "true" ? true : v === "false" ? false : v));
  }
  return mapRequestResponseToForm(data);
};
export const mapRequestResponseToForm = (data) => ({
  ...data,
  location: {
    lat: data.locationLat,
    lng: data.locationLong,
    name: data.locationText,
  },
  mailingList: filter(split(data.mailingList, ";"), (email) => email),
  suggestedDateBegin: utcToLocal(data.suggestedDateBegin).toDate(),
  suggestedDateEnd: utcToLocal(data.suggestedDateEnd).toDate(),
});

export const redirections = ({ requestId }) => ({
  [ROLE_INSPECTOR]: routes.calendar.details({ id: requestId }).path,
  [ROLE_MANAGER]: routes.planning.details({ id: requestId }).path,
  [ROLE_CONTRACTOR]: routes.requests.details(requestId).path,
});

// associate an activity name to th request.activity.id
export const retrieveActivityName = (activities, request) => {
  if (!(activities === undefined) && !(request.activityId === undefined)) {
    const rows = filter(activities, (activity) => {
      return activity.id === request.activityId;
    });
    if (!(rows === undefined) && rows.length > 0 && !(rows[0] === undefined)) {
      return rows[0].activityType;
    } else return "?";
  } else return "?";
};
