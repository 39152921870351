import get from "lodash/get";

export const STORAGE_AUTH_KEY = "auth";
export const ROLE_MANAGER = "MANAGER";
export const ROLE_SUPERADMIN = "SUPERADMIN";
export const ROLE_INSPECTOR = "INSPECTOR";
export const ROLE_CONTRACTOR = "CONTRACTOR";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_TOTO = "ROLE_TOTO";

export const USER_ROLES = (sharedLang) => {
  return [
    { value: ROLE_ADMIN, label: get(sharedLang, "user.role.ADMIN") },
    { value: ROLE_MANAGER, label: get(sharedLang, "user.role.MANAGER") },
    { value: ROLE_INSPECTOR, label: get(sharedLang, "user.role.INSPECTOR") },
    { value: ROLE_CONTRACTOR, label: get(sharedLang, "user.role.CONTRACTOR") },
  ];
};

export const USER_STATUS_EXTERNAL = "EXTERNAL";
export const USER_STATUS_REGISTERED = "REGISTERED";
export const USER_STATUS_DISABLED = "DISABLED";
export const USER_STATUS_PENDING = "PENDING";
export const USER_STATUS_EXPIRED_TOKEN = "EXPIRED_TOKEN";
