import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";
import FieldError from "shared/components/FieldError";

class CheckboxField extends PureComponent {
  render() {
    const { input, meta, noMarginTop, className, disabled, children, isLight, id, ...props } = this.props;

    const inputClasses = classnames("CheckboxField__input", {});
    const classes = classnames("CheckboxField", className, {
      "CheckboxField--light": isLight,
    });
    const boxClasses = classnames("CheckboxField__box", {
      "CheckboxField__box--active": input.value,
    });

    return (
      <label className={classes} htmlFor={id}>
        <input type="checkbox" id={id} {...input} {...props} className={inputClasses} disabled={disabled} />
        <div className={boxClasses}>&#10003;</div>
        <span className="CheckboxField__label">{children}</span>
        <FieldError meta={meta} />
      </label>
    );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  isLight: PropTypes.bool,
};

export default CheckboxField;
