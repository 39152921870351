import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import includes from "lodash/includes";
import { intl } from "shared/hoc/Intl";
import RotateLoader from "shared/components/RotateLoader";
import Translated from "shared/components/Translated";
import Modal from "shared/components/Modal";
import Button from "shared/components/Button";
import { Calendar } from "shared/components/Icons";
import Tabs from "shared/components/Tabs";
import {
  REQUEST_STATUS_ASSIGNED,
  REQUEST_STATUS_PENDING_MANAGER,
  REQUEST_STATUS_ASSIGNED_AS_DRAFT,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_STATUS_DRAFT,
  REQUEST_COLORS,
} from "shared/constants/request";
import { ROLE_CONTRACTOR } from "shared/constants/auth";
import { readableDate } from "shared/services/date";
import RequestDetailsEquipment from "shared/components/RequestDetailsEquipment";
import RequestDetailsContact from "shared/components/RequestDetailsContact";
import {
  performFetchRequestWithHistory,
  cancelRequest,
  deleteRequest,
  declineRequest,
} from "shared/reducers/requests/requestDetails";
import { unAssignInspector } from "modules/Planning/reducers/inspectors";
import { approveRequests } from "modules/Planning/reducers/requests";
import RequestDetailsLocation from "shared/components/RequestDetailsLocation";
import RequestDetailsOverall from "shared/components/RequestDetailsOverall";
import RequestDetailsHistory from "shared/components/RequestDetailsHistory";
import ConfirmModal from "shared/components/ConfirmModal";
import RequestDetailsFeedback from "shared/components/RequestDetailsFeedback";

import { ROLE_MANAGER } from "shared/constants/auth";
import { routes } from "shared/constants/routes";
import "./styles.scss";

import { fetchActivitiesList } from "shared/reducers/activities";
import filter from "lodash/filter";

import printPDF from "shared/utils/pdf.js";

class Details extends PureComponent {
  state = {
    deleteModal: false,
    cancelModal: false,
    declineModal: false,
    unAssignValidate: false,
  };

  myState = {
    alreadyValidatedOrUnassigned: false,
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
    if (this.props.isOpen) {
      this.props.performFetchRequestWithHistory({ id: this.props.id });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id && prevProps.id !== this.props.id) {
      this.props.performFetchRequestWithHistory({ id: this.props.id });
    }
    if (this.props.isDeleted && prevProps.isDeleting) {
      this.props.onClose();
      this.setState({ deleteModal: false });
    }
    if (this.props.isCanceled && prevProps.isCanceling) {
      this.props.onClose();
      this.setState({ cancelModal: false });
    }
    if (this.props.isDeclined && prevProps.isDeclining) {
      this.props.onClose();
      this.setState({ declineModal: false });
    }
    if (
      this.props.userRole === ROLE_MANAGER &&
      this.props.data.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT &&
      false === this.myState.alreadyValidatedOrUnassigned
    ) {
      this.setState({ unAssignValidate: true });
    } else {
      this.setState({ unAssignValidate: false });
    }
  }

  toggleModal = (key, modalData) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key], modalData });
  };

  cancel = () => {
    this.props.cancelRequest({ id: this.props.id });
  };

  decline = () => {
    this.props.declineRequest({ id: this.props.id });
    let idToFind = "waitingListRequestId" + this.props.id;
    let requestDiv = document.getElementById(idToFind);
    if (requestDiv) {
      requestDiv = requestDiv.parentNode.removeChild(requestDiv);
    }
  };

  delete = () => {
    this.props.deleteRequest({ id: this.props.id });
  };

  goToEdit = (id) => () => {
    this.props.history.push(routes.requests.draft(id).path);
  };

  goToReassign = (id) => () => {
    this.props.history.push(routes.requests.reassign(id).path);
  };

  goToDuplicate = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(routes.requests.duplicate(id).path);
  };

  getModalTitle = () => {
    const { isLoaded, hasError, data } = this.props;
    if (isLoaded) {
      var requestNumberClass = "RequestNumber " + (data.witness ? "witness" : "hold");
      return (
        <span className="RequestDetails__title">
          <span className={requestNumberClass}>#{data.id}</span> - {data.projectName}
        </span>
      );
    }
    if (hasError) {
      return (
        <span className="RequestDetails__title">
          <Translated path="request.noAccess" />
        </span>
      );
    }
    return "";
  };

  retrieveActivityName = (activities, data) => {
    if (!(activities === undefined) && !(data.activityId === undefined)) {
      const rows = filter(activities, (activity) => {
        return activity.id === data.activityId;
      });
      if (!(rows === undefined) && rows.length > 0 && !(rows[0] === undefined)) {
        return rows[0].activityType;
      } else return "?";
    } else return "?";
  };

  includesActiveStatuses = (status) => {
    return includes(
      [
        REQUEST_STATUS_ASSIGNED,
        REQUEST_STATUS_PENDING_MANAGER,
        REQUEST_STATUS_ASSIGNED_AS_DRAFT,
        REQUEST_STATUS_PENDING_SUBCONTRACTOR,
      ],
      status
    );
  };

  validateRequest = (requestId) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ unAssignValidate: false });
    this.myState.alreadyValidatedOrUnassigned = true;
    this.props.approveRequests({ ids: [requestId] });
    this.forceUpdate();
  };

  unAssignInspector = (data) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ unAssignValidate: false });
    this.myState.alreadyValidatedOrUnassigned = true;
    this.props.unAssignInspector({ requestId: data.id, inspectorId: data.inspectorDetails.userId });
    this.forceUpdate();
  };

  localOnClose = (e) => {
    this.setState({ unAssignValidate: false });
    this.myState.alreadyValidatedOrUnassigned = false;
    this.props.onClose(e);
  };

  render() {
    const {
      isLoading,
      isLoaded,
      data,
      isOpen,
      userRole,
      isDeleting,
      isCanceling,
      isDeclining,
      activities,
      isUnAssigning,
      isValidating,
    } = this.props;

    const dataActivity = Object.assign(data, { activityName: this.retrieveActivityName(activities, data) });

    const dateBegin = data.plannedDateBegin
      ? readableDate(data.plannedDateBegin)
      : data.suggestedDateBegin
      ? readableDate(data.suggestedDateBegin)
      : "-";
    const dateEnd = data.plannedDateEnd
      ? readableDate(data.plannedDateEnd)
      : data.suggestedDateEnd
      ? readableDate(data.suggestedDateEnd)
      : "-";

    return (
      <Modal
        specialClass="ModalRequestDetails"
        title={this.getModalTitle()}
        isOpen={isOpen}
        onClose={this.localOnClose}
        subTitle={
          isLoaded ? (
            <div className="RequestDetails__heading">
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <span
                  className="RequestDetails__heading__circle"
                  style={{ backgroundColor: REQUEST_COLORS[data.status] }}
                />
                {data.status && <Translated path={`request.status.${data.status}`} />}
                <span className="RequestDetails__heading__date">
                  <div>
                    <Calendar />
                    &nbsp;
                  </div>
                  <div>
                    <Translated path="request.labels.fromDate" /> {dateBegin}
                    <br />
                    <Translated path="request.labels.toDate" /> {dateEnd}
                  </div>
                </span>
              </div>
              {this.state.unAssignValidate && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Button onClick={this.validateRequest(data.id)} isLoading={isValidating} type="primary" heading>
                    <Translated path="buttons.validate" shared />
                  </Button>
                  <Button onClick={this.unAssignInspector(data)} isLoading={isUnAssigning} type="yellow" heading>
                    <Translated path="buttons.unassign" shared />
                  </Button>
                </div>
              )}
            </div>
          ) : (
            ""
          )
        }
      >
        <ConfirmModal
          title={<Translated path="modal.areYouSure" shared />}
          isLoading={isCanceling}
          isOpen={this.state.cancelModal}
          paragraph={<Translated path="request.modal.cancelParagraph" shared />}
          onClose={this.toggleModal("cancelModal")}
          onConfirm={this.cancel}
          confirmLabel={<Translated path="buttons.yes" shared />}
          cancelLabel={<Translated path="buttons.no" shared />}
        />
        <ConfirmModal
          title={<Translated path="modal.areYouSure" shared />}
          isLoading={isDeleting}
          isOpen={this.state.deleteModal}
          paragraph={<Translated path="request.modal.deleteParagraph" shared />}
          onClose={this.toggleModal("deleteModal")}
          onConfirm={this.delete}
          confirmLabel={<Translated path="buttons.delete" shared />}
          cancelLabel={<Translated path="buttons.cancel" shared />}
        />
        <ConfirmModal
          title={<Translated path="modal.areYouSure" shared />}
          isLoading={isDeclining}
          isOpen={this.state.declineModal}
          paragraph={<Translated path="request.modal.declineParagraph" shared />}
          onClose={this.toggleModal("declineModal")}
          onConfirm={this.decline}
          confirmLabel={<Translated path="buttons.decline" shared />}
          cancelLabel={<Translated path="buttons.cancel" shared />}
        />
        <div className="RequestDetails">
          {isLoading && (
            <div className="RequestDetails__loader">
              <RotateLoader relative />
            </div>
          )}
          {isLoaded && (
            <Tabs overflow>
              <div id="tab-request-details" label={<Translated path="request.modal.details" shared />}>
                <RequestDetailsOverall data={dataActivity} />
              </div>
              <div id="tab-request-equipment" label={<Translated path="request.modal.equipment" shared />}>
                <RequestDetailsEquipment data={data} />
              </div>
              <div id="tab-request-location" label={<Translated path="request.modal.location" shared />}>
                <RequestDetailsLocation lat={data.locationLat} lng={data.locationLong} text={data.locationText} />
              </div>
              <div id="tab-request-history" label={<Translated path="request.modal.history" shared />}>
                <RequestDetailsHistory data={data} />
              </div>
              <div id="tab-request-contact" label={<Translated path="request.modal.contact" shared />}>
                <RequestDetailsContact data={data} />
              </div>
              <div id="tab-request-feedback" label={<Translated path="request.modal.feedback" shared />}>
                <RequestDetailsFeedback requestId={data.id} userRole={userRole} />
              </div>
            </Tabs>
          )}

          {isLoaded && (
            <div className="RequestDetails__buttons">
              {
                // Decline
                userRole === ROLE_MANAGER &&
                  includes(
                    [REQUEST_STATUS_PENDING_MANAGER, REQUEST_STATUS_ASSIGNED_AS_DRAFT, REQUEST_STATUS_ASSIGNED],
                    data.status
                  ) && (
                    <Button onClick={this.toggleModal("declineModal")} noMargin isLoading={isDeclining}>
                      <Translated path="buttons.decline" shared />
                    </Button>
                  )
              }
              {
                // Delete (only for draft request)
                userRole === ROLE_CONTRACTOR && includes([REQUEST_STATUS_DRAFT], data.status) && (
                  <Button onClick={this.toggleModal("deleteModal")} noMargin isLoading={isDeleting}>
                    <Translated path="buttons.delete" shared />
                  </Button>
                )
              }
              {
                // Cancel
                userRole === ROLE_CONTRACTOR && this.includesActiveStatuses(data.status) && (
                  <Button onClick={this.toggleModal("cancelModal")} noMargin isLoading={isCanceling}>
                    <Translated path="buttons.cancel" shared />
                  </Button>
                )
              }
              {
                // Reassign
                userRole === ROLE_MANAGER && includes([REQUEST_STATUS_PENDING_MANAGER], data.status) && (
                  <Button onClick={this.goToReassign(data.id)} noMargin>
                    <Translated path="buttons.reassign" shared />
                  </Button>
                )
              }
              {
                // Edit
                ((userRole === ROLE_MANAGER &&
                  includes(
                    [REQUEST_STATUS_PENDING_MANAGER, REQUEST_STATUS_ASSIGNED_AS_DRAFT, REQUEST_STATUS_ASSIGNED],
                    data.status
                  )) ||
                  (userRole === ROLE_CONTRACTOR &&
                    includes(
                      [
                        REQUEST_STATUS_DRAFT,
                        REQUEST_STATUS_ASSIGNED,
                        REQUEST_STATUS_PENDING_MANAGER,
                        REQUEST_STATUS_ASSIGNED_AS_DRAFT,
                        REQUEST_STATUS_PENDING_SUBCONTRACTOR,
                      ],
                      data.status
                    ))) && (
                  <Button onClick={this.goToEdit(data.id)} noMargin>
                    <Translated path="buttons.edit" shared />
                  </Button>
                )
              }
              {
                // Duplicate
                (userRole === ROLE_CONTRACTOR || userRole === ROLE_MANAGER) && (
                  <Button onClick={this.goToDuplicate(data.id)} noMargin>
                    <Translated path="buttons.duplicate" shared />
                  </Button>
                )
              }
              {<Button onClick={printPDF(data)}>PDF</Button>}
              <div />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  shared,
  auth: {
    data: { role },
  },
  planning,
}) => ({
  isLoading: shared.requestDetails.states.isLoading,
  hasError: shared.requestDetails.states.hasError,
  isDeleting: shared.requestDetails.delete.states.isLoading,
  isDeleted: shared.requestDetails.delete.states.isLoaded,
  isDeclining: shared.requestDetails.decline.states.isLoading,
  isDeclined: shared.requestDetails.decline.states.isLoaded,
  isCanceling: shared.requestDetails.cancel.states.isLoading,
  isCanceled: shared.requestDetails.cancel.states.isLoaded,
  isLoaded: shared.requestDetails.states.isLoaded,
  activities: shared.activities.data.rows,
  data: shared.requestDetails.data,
  isUnAssigning: planning.inspectors.unassign.isLoading,
  isValidating: planning.requests.approve.isLoading,
  userRole: role,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      performFetchRequestWithHistory,
      cancelRequest,
      deleteRequest,
      declineRequest,
      fetchActivitiesList,
      unAssignInspector,
      approveRequests,
    },
    dispatch
  );

export default compose(intl("modules.Requests.Details"), connect(mapStateToProps, mapDispatchToProps))(Details);
