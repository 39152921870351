import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import "./styles.scss";
import FieldError from "shared/components/FieldError";

class RadioField extends PureComponent {
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const { label, meta, noMargin, className, labelLeft, options } = this.props;

    var input = this.props.input;
    // #5whkby Fill automaticaly the third parties value, but not the witness and safety
    if (
      input.name &&
      input.name !== "witness" &&
      input.name !== "safety" &&
      (input.value === undefined || input.value === null || input.value === "")
    )
      input.value = true;

    const classes = classnames("RadioField", className, {
      "RadioField--label-left": labelLeft,
      "RadioField--no-margin": noMargin,
    });
    const labelClasses = classnames("RadioField__label", className, {
      "RadioField__label--left": labelLeft,
    });
    const wrapperClasses = classnames("RadioField__input-wrapper", className, {
      "RadioField__input-wrapper--error": meta.touched && meta.error,
    });

    return (
      <div className={classes} onClick={this.onClick}>
        {label && (
          <p className={labelClasses}>
            {label} {this.props.isRequired && "*"}
          </p>
        )}
        <div className={wrapperClasses}>
          {map(options, (option) => (
            <div key={`${input.name}-${option.value}`} className="RadioField__input">
              <input
                className="RadioField__input__field"
                type="radio"
                name={input.name}
                value={option.value}
                checked={String(option.value) === String(input.value)}
                onChange={input.onChange}
                id={`${input.name}-${option.value}`}
              />
              <label htmlFor={`${input.name}-${option.value}`} className="RadioField__input__label">
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <FieldError meta={meta} />
      </div>
    );
  }
}

RadioField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOf(["scope", "arrow_down"]),
};

export default RadioField;
