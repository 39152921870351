import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { ACTIVITIES_EDIT } from "modules/Activities/reducers/edit/";
import { fetchActivitiesList } from "shared/reducers/activities";

function* editActivity(action) {
  try {
    const { data } = yield apiService({}).put(`/activity/${action.payload.id}`, {
      activityType: action.payload.activityType,
    });
    yield put({ type: ACTIVITIES_EDIT.SUCCESS, payload: { ...data, response: "responses.success" } });
    yield put(fetchActivitiesList());
  } catch (err) {
    yield put({ type: ACTIVITIES_EDIT.FAILURE, payload: getError(err) });
  }
}

function* editActivitySaga() {
  yield takeEvery(ACTIVITIES_EDIT.REQUEST, editActivity);
}

export default [editActivitySaga];
