import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const FETCH_KPI = createRequestTypes("dashboard/FETCH_KPI");

export const fetchKpi = ({ startDate, endDate }) => ({
  type: FETCH_KPI.REQUEST,
  payload: { startDate, endDate },
});

const initState = {
  states: loadingStates,
  data: {},
  response: null,
};

export default createReducer(initState, {
  [FETCH_KPI.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [FETCH_KPI.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [FETCH_KPI.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
