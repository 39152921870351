import update from "immutability-helper";
import filter from "lodash/filter";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";
import { ASSIGN_INSPECTOR } from "modules/Planning/reducers/inspectors";
import { APPROVE_REQUESTS } from "modules/Planning/reducers/types";

export const PENDING_REQUESTS_FETCH = createRequestTypes("planning/FETCH_PENDING_REQUESTS");

export const approveRequests = ({ ids }) => ({
  type: APPROVE_REQUESTS.REQUEST,
  payload: { ids },
});

export const performPendingRequestFetch = (filters = {}) => ({
  type: PENDING_REQUESTS_FETCH.REQUEST,
  payload: {
    filter: {
      status: "PENDING_MANAGER",
      ...filters,
    },
    pager: {
      page: 1,
      limit: 100,
    },
    sort: {
      field: "suggestedDateBegin",
      direction: "ASC",
    },
  },
});

const initState = {
  pending: {
    states: loadingStates,
    data: {
      rows: [],
      total: 0,
    },
  },
  approve: {
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [ASSIGN_INSPECTOR.SUCCESS]: (state, action) => {
    return update(state, {
      pending: {
        $merge: {
          data: {
            rows: filter(state.pending.data.rows, (row) => row.id !== action.payload.requestId),
          },
        },
      },
    });
  },
  [PENDING_REQUESTS_FETCH.REQUEST]: (state, action) => {
    return update(state, {
      pending: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [PENDING_REQUESTS_FETCH.SUCCESS]: (state, action) => {
    return update(state, {
      pending: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [PENDING_REQUESTS_FETCH.FAILURE]: (state, action) => {
    return update(state, {
      pending: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [APPROVE_REQUESTS.REQUEST]: (state, action) => {
    return update(state, {
      approve: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [APPROVE_REQUESTS.SUCCESS]: (state, action) => {
    return update(state, {
      approve: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [APPROVE_REQUESTS.FAILURE]: (state, action) => {
    return update(state, {
      approve: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
});
