import { langLabel } from "shared/components/Translated";
import map from "lodash/map";
import filter from "lodash/filter";
import { xlsxDate } from "shared/services/date";

// Excel RequestsList, field description
// Translation                                       Content
// request.labels.projectTitle	                 :   "request.projectName"
// request.labels.subReference    	             :   "request.contractorReference"
// request.labels.clientReference	             :   "request.clientReference"
// request.labels.operationDesignation	         :   "request.operation_designation"
// request.labels.subSubContractorName	         :   "request.subSubcontractorName"
// request.labels.equipment	                     :   "request.equipment"
// request.labels.equipmentParts	             :   "request.equipmentParts"
// request.labels.equipmentPartsNumber           :   "request.equipmentPartsNumber"
// request.labels.followupDocumentRevisionNumber :   "request.followupDocumentRevisionNumber"
// request.labels.phaseNumber	                 :   "request.phaseNumber"
// request.labels.safety.label	                 :   "request.safety"
// request.labels.witness.label	                 :   "request.witness"
// request.labels.otherContactInformation	     :   "request.otherContactInformation"
// request.labels.mailingList	                 :   "request.mailingList"
// request.labels.suggestedDateBegin	         :   "request.suggestedDateBegin"
// request.labels.suggestedDateEnd	             :   "request.suggestedDateEnd"
// request.labels.status	                     :   "request.status"
// request.labels.created_at	                 :   "request.createdAt"
// request.labels.inspectionDateBegin            :   "request.plannedDateBegin"
// request.labels.inspectionDateEnd              :   "request.plannedDateEnd"
// request.labels.location	                     :   "request.locationText"
// request.labels.manager_first_assigned_date    :   "request.managerFirstAssignedDate"
// request.modal.contactLabels.manager.name  	 :   "manager surname + name"
// request.modal.contactLabels.contractor.name   :   "contractor surname + name"
// request.labels.contractorCompany	             :   "contractor company"
// request.modal.contactLabels.inspector.name	 :   "inspector surname + name"
// request.labels.activity	                     :   "activities.name" (request.activityId)

// label="Name" value="name"
const headLine = (props) => {
  return [
    { label: "N°", value: "request_labels_id" },
    { label: langLabel("request.labels.projectTitle", props), value: "request_labels_projectTitle" },
    { label: langLabel("request.labels.subReference", props), value: "request_labels_subReference" },
    { label: langLabel("request.labels.clientReference", props), value: "request_labels_clientReference" },
    { label: langLabel("request.labels.operationDesignation", props), value: "request_labels_operationDesignation" },
    { label: langLabel("request.labels.subSubContractorName", props), value: "request_labels_subSubContractorName" },
    { label: langLabel("request.labels.equipment", props), value: "request_labels_equipment" },
    { label: langLabel("request.labels.equipmentParts", props), value: "request_labels_equipmentParts" },
    { label: langLabel("request.labels.equipmentPartsNumber", props), value: "request_labels_equipmentPartsNumber" },
    {
      label: langLabel("request.labels.followupDocumentRevisionNumber", props),
      value: "request_labels_followupDocumentRevisionNumber",
    },
    { label: langLabel("request.labels.phaseNumber", props), value: "request_labels_phaseNumber" },
    { label: langLabel("request.labels.safety.label", props), value: "request_labels_safety_label" },
    { label: langLabel("request.labels.witness.label", props), value: "request_labels_witness_label" },
    {
      label: langLabel("request.labels.otherContactInformation", props),
      value: "request_labels_otherContactInformation",
    },
    { label: langLabel("request.labels.mailingList", props), value: "request_labels_mailingList" },
    { label: langLabel("request.labels.suggestedDateBegin", props), value: "request_labels_suggestedDateBegin" },
    { label: langLabel("request.labels.suggestedDateEnd", props), value: "request_labels_suggestedDateEnd" },
    { label: langLabel("request.labels.status", props), value: "request_labels_status" },
    { label: langLabel("request.labels.created_at", props), value: "request_labels_created_at" },
    { label: langLabel("request.labels.inspectionDateBegin", props), value: "request_labels_inspectionDateBegin" },
    { label: langLabel("request.labels.inspectionDateEnd", props), value: "request_labels_inspectionDateEnd" },
    { label: langLabel("request.labels.location", props), value: "request_labels_location" },
    {
      label: langLabel("request.labels.manager_first_assigned_date", props),
      value: "request_labels_manager_first_assigned_date",
    },
    {
      label: langLabel("request.modal.contactLabels.manager.name", props),
      value: "request_modal_contactLabels_manager_name",
    },
    {
      label: langLabel("request.modal.contactLabels.contractor.name", props),
      value: "request_modal_contactLabels_contractor_name",
    },
    { label: langLabel("request.labels.contractorCompany", props), value: "request_labels_contractorCompany" },
    {
      label: langLabel("request.modal.contactLabels.inspector.name", props),
      value: "request_modal_contactLabels_inspector_name",
    },
    { label: langLabel("request.labels.activity", props), value: "request_labels_activity" },
  ];
};

const managerName = (request, props) => {
  if (request.managerDetails !== undefined)
    return (
      (request.managerDetails.surname === undefined
        ? langLabel("shared.error", props)
        : request.managerDetails.surname) +
      " " +
      (request.managerDetails.name === undefined ? langLabel("shared.error", props) : request.managerDetails.name)
    );
  else return langLabel("shared.error", props);
};

const activityName = (request, props) => {
  if (props.activitiesList !== undefined && props.activitiesList.length !== 0) {
    return filter(props.activitiesList, (activity) => request.activityId === activity.id)[0].activityType;
  } else return langLabel("shared.error", props);
};

const contractorName = (request, props) => {
  if (request.contractorDetails !== undefined)
    return (
      (request.contractorDetails.surname === undefined
        ? langLabel("shared.error", props)
        : request.contractorDetails.surname) +
      " " +
      (request.contractorDetails.name === undefined ? langLabel("shared.error", props) : request.contractorDetails.name)
    );
  else return langLabel("shared.error", props);
};

const inspectorName = (request, props) => {
  if (request.inspectorDetails !== undefined)
    return (
      (request.inspectorDetails.surname === undefined
        ? langLabel("shared.error", props)
        : request.inspectorDetails.surname) +
      " " +
      (request.inspectorDetails.name === undefined ? langLabel("shared.error", props) : request.inspectorDetails.name)
    );
  // When no inspector is affected
  else return "";
};

const mergeRequestOther = (props) => (request) => {
  if (request === undefined) return {};
  else {
    return {
      request_labels_id: request.id,
      request_labels_projectTitle: request.projectName,
      request_labels_subReference: request.contractorReference,
      request_labels_clientReference: request.clientReference,
      request_labels_operationDesignation: request.operationDesignation,
      request_labels_subSubContractorName: request.subSubcontractorName,
      request_labels_equipment: request.equipment,
      request_labels_equipmentParts: request.equipmentParts,
      request_labels_equipmentPartsNumber: request.equipmentPartsNumber,
      request_labels_followupDocumentRevisionNumber: request.followupDocumentRevisionNumber,
      request_labels_phaseNumber: request.phaseNumber,
      request_labels_safety_label: request.safety
        ? langLabel("request.relevant", props)
        : langLabel("request.notrelevant", props),
      request_labels_witness_label: request.witness ? "WITNESS" : "HOLD",
      request_labels_otherContactInformation: request.otherContactInformation,
      request_labels_mailingList: request.mailingList,
      request_labels_suggestedDateBegin: xlsxDate(request.suggestedDateBegin),
      request_labels_suggestedDateEnd: xlsxDate(request.suggestedDateEnd),
      request_labels_status: request.status,
      request_labels_created_at: xlsxDate(request.createdAt),
      request_labels_inspectionDateBegin: xlsxDate(request.plannedDateBegin),
      request_labels_inspectionDateEnd: xlsxDate(request.plannedDateEnd),
      request_labels_location: request.locationText,
      request_labels_manager_first_assigned_date: xlsxDate(request.managerFirstAssignedDate),
      request_modal_contactLabels_manager_name: managerName(request, props),
      request_modal_contactLabels_contractor_name: contractorName(request, props),
      request_labels_contractorCompany: request.contractorCompany,
      request_modal_contactLabels_inspector_name: inspectorName(request, props),
      request_labels_activity: activityName(request, props),
    };
  }
};

export const doRequestsLists = (props) => {
  if (props.requestsListWithDetails !== undefined)
    return {
      columns: headLine(props),
      data: map(props.requestsListWithDetails, mergeRequestOther(props)),
    };
  else return {};
};
