import { combineReducers } from "redux";

import create from "./create";
import edit from "./edit";
import remove from "./remove";
import details from "./details";

export default combineReducers({
  create,
  edit,
  remove,
  details,
});
