import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { CloseSmall } from "shared/components/Icons";
import FieldOptions from "shared/components/FieldOptions";
import FieldWrapper from "shared/components/FieldWrapper";
import Translated from "shared/components/Translated";
import { darkGreyCol } from "shared/constants/colors.js";
import "./styles.scss";
import { extractAllEmail } from "shared/utils/validation.js";

class TagsField extends PureComponent {
  state = {
    value: [],
    isOpen: false,
    notValid: false,
    notActivity: false,
  };

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onChange = (e) => {
    this.setState({ value: e.target.value, isOpen: true });
  };

  onKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "," || e.key === ";" || e.key === " ") {
      e.preventDefault();
      e.stopPropagation();
      this.addTag();
    } else this.setState({ notValid: false, notActivity: false });
  };

  addTag = () => {
    const { validateTag, dataSource, input } = this.props;

    if (this.state.value.length === 0) {
      this.setState({ notValid: false, notActivity: false });
      return false;
    }

    if (validateTag && !validateTag(this.state.value)) {
      const emailArray = extractAllEmail(this.state.value);
      if (!emailArray) {
        this.setState({ notValid: true });
        return false;
      } else {
        this.setState({ value: emailArray }, this.submitTag);
      }
    } else {
      if (input && input.name === "activities") {
        let exist = false;
        dataSource.forEach((element) => {
          if (element.label === this.state.value) exist = true;
        });
        if (exist === true) {
          this.submitTag();
        } else {
          this.setState({ notActivity: true });
          return false;
        }
      } else {
        this.submitTag();
      }
    }
  };

  submitTag = () => {
    const { input, dataSource } = this.props;
    if (input.value instanceof Array) {
      if (input.name && input.name === "activities") {
        let addElement = dataSource.filter((element) => element.label === this.state.value);
        if (addElement.length > 0) {
          this.props.input.onChange(input.value.concat(addElement[0].value));
        }
      } else {
        this.props.input.onChange(input.value.concat(this.state.value));
      }
    } else {
      this.props.input.onChange(Array.isArray(this.state.value) ? this.state.value : [this.state.value]);
    }
    this.setState({ value: "" });
  };

  onFocus = () => {
    this.setState({ isOpen: true });
  };

  onBlur = () => {
    this.setState({ isOpen: false });
    if (this.props.dataSource) {
      return;
    }
    this.addTag();
  };

  deleteTag = (value) => () => {
    const values = filter(this.props.input.value, (tag) => tag !== value);
    this.props.input.onChange(values);
  };

  onSelect = (newValue) => () => {
    const { value } = this.props.input;
    const { validateTag } = this.props;
    if (validateTag && !validateTag(newValue)) {
      return false;
    }
    if (value instanceof Array) {
      let notIn = true;
      if (value.length > 0) {
        value.forEach((element) => {
          if (element === newValue) notIn = false;
        });
      }
      if (notIn === true) {
        this.props.input.onChange([...value, newValue]);
      }
    } else {
      this.props.input.onChange([newValue]);
    }
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  filterDataSource = () => {
    const {
      input: { value },
      dataSource,
    } = this.props;
    const notUsedValues = filter(dataSource, (val) => !includes(value, val.value));
    const searchedValues = filter(notUsedValues, (val) => includes(val.label, this.state.value));

    return this.state.value ? searchedValues : notUsedValues;
  };

  getLabel = (value) => get(find(this.props.dataSource, { value }), "label") || value;

  render() {
    const { disabled, icon, TagIcon, dataSource } = this.props;

    const inputClasses = classnames("TagsField__input", {
      "TagsField__input--disabled": disabled,
      "TagsField__input--icon": icon,
    });

    return (
      <div>
        <FieldWrapper {...this.props} onClick={this.onClick} onKeyDown={this.onKeyDown}>
          <div className="TagsField">
            {map(this.props.input.value, (value, index) => (
              <span className="TagsField__tag" key={`${this.props.input.name}-${index}-${value}`}>
                {TagIcon && <TagIcon className="TagsField__tag__icon" fill={darkGreyCol} />}
                {this.getLabel(value)}
                {!disabled && (
                  <CloseSmall fill="#667481" className="TagsField__tag__delete" onClick={this.deleteTag(value)} />
                )}
              </span>
            ))}
            <input
              value={this.state.value}
              onChange={this.onChange}
              className={inputClasses}
              disabled={disabled}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </div>

          {dataSource && (
            <FieldOptions
              dataSource={this.filterDataSource()}
              isOpen={this.state.isOpen}
              onSelect={this.onSelect}
              onClose={this.onClose}
              currentValue={this.props.input.value}
              name={this.props.input.name}
            />
          )}
        </FieldWrapper>
        {this.state.notValid && (
          <div className="wrongEmailFormat">
            <Translated path="shared.formErrors.WRONG_EMAIL_FORMAT" shared />
          </div>
        )}
        {this.state.notActivity && (
          <div className="wrongEmailFormat">
            <Translated path="shared.formErrors.INEXISTANT_ACTIVITIES" shared />
          </div>
        )}
      </div>
    );
  }
}

TagsField.propTypes = {
  label: PropTypes.node,
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOf(["scope", "arrow_down"]),
  validateTag: PropTypes.func,
  TagIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // instead of " PropTypes.func " only to avoid :: Warning: Failed prop type: Invalid prop `TagIcon` of type `object` supplied to `TagsField`, expected `function`.
};

export default TagsField;
