import { validatePhoneNumber } from "shared/utils/validation.js";

import forEach from "lodash/forEach";
import { passwordValidation } from "shared/utils/validation";
import { ROLE_MANAGER } from "shared/constants/auth";
const requiredFields = ["name", "surname", "password", "confirmPassword", "phoneNumber", "terms"];

export default (fields, { userRole }) => {
  const { password, confirmPassword, phoneNumber } = fields;
  const errors = {};
  if (userRole === ROLE_MANAGER) {
    requiredFields.push("location", "activities");
  }
  if (!passwordValidation(password)) {
    errors.password = "WRONG_PASSWORD_FORMAT";
  }
  if (confirmPassword !== password) {
    errors.confirmPassword = "PASSWORDS_MUST_MATCH";
  }
  if (phoneNumber) {
    if (!validatePhoneNumber(phoneNumber)) {
      errors["phoneNumber"] = "WRONG_PHONE_FORMAT";
    }
  }
  forEach(requiredFields, (field) => {
    if (!fields[field]) {
      errors[field] = "FILL_IN";
    }
  });

  return errors;
};
