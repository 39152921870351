import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { USERS_FETCH_DETAILS } from "modules/Users/reducers/details/";

function* fetchDetailsUser(action) {
  try {
    const { data } = yield apiService({}).get(`/user/${action.payload.id}`);
    yield put({ type: USERS_FETCH_DETAILS.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: USERS_FETCH_DETAILS.FAILURE, payload: getError(err) });
  }
}

function* fetchDetailsUserSaga() {
  yield takeEvery(USERS_FETCH_DETAILS.REQUEST, fetchDetailsUser);
}

export default [fetchDetailsUserSaga];
