import React from "react";
import { Field, reduxForm } from "redux-form";
import map from "lodash/map";
import Button from "shared/components/Button";
import TextField from "shared/components/TextField";
import Translated from "shared/components/Translated";
import GoogleField from "shared/components/GoogleField";
import TagsField from "shared/components/TagsField";
// #12thze import RadioField from 'shared/components/RadioField';
import formValidation from "./formValidation";
import CheckboxField from "shared/components/CheckboxField";
import { ROLE_MANAGER } from "shared/constants/auth";
import "./styles.scss";

export default reduxForm({
  form: "user/register",
  validate: formValidation,
})(({ activities, userRole, isLoading }) => (
  <div className="RegisterForm">
    <div className="row">
      <div className="col-md-6">
        <Field component={TextField} name="name" label={<Translated path="user.labels.name" shared />} />
      </div>
      <div className="col-md-6">
        <Field component={TextField} name="surname" label={<Translated path="user.labels.surname" shared />} />
      </div>
      {/* #12thze gender question desactivated
      <div className="col-md-12">
        <Field
          component={RadioField}
          options={[
            {
              label: <Translated path="user.labels.male" shared />,
              value: 'male'
            },
            {
              label: <Translated path="user.labels.female" shared />,
              value: 'female'
            }
          ]}
          name="gender"
          label={<Translated path="user.labels.gender" shared />}
        />
      </div>
      */}
      <div className="col-md-12">
        <Field
          component={TextField}
          autoComplete="tel-local"
          name="phoneNumber"
          label={<Translated path="user.labels.phone" shared />}
          type="tel"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <Field
          component={TextField}
          name="password"
          label={<Translated path="user.labels.password" shared />}
          autoComplete="new-password"
          type="password"
          tooltip={
            <div className="RegisterForm__password-tooltip">
              <h4>
                <Translated path="passwordTooltip.title" />
              </h4>
              <ul>
                <li>
                  <Translated path="passwordTooltip.subTitle1" />
                </li>
                <li>
                  <Translated path="passwordTooltip.subTitle2" />
                </li>
                <li>
                  <Translated path="passwordTooltip.subTitle3" />
                </li>
                <li>
                  <Translated path="passwordTooltip.subTitle4" />
                </li>
              </ul>
            </div>
          }
        />
      </div>
      <div className="col-md-6">
        <Field
          component={TextField}
          autoComplete="new-password"
          name="confirmPassword"
          type="password"
          label={<Translated path="user.labels.confirmPassword" shared />}
        />
      </div>
      {userRole === ROLE_MANAGER && (
        <div className="col-md-12">
          <Field component={GoogleField} name="location" label={<Translated path="user.labels.location" shared />} />
        </div>
      )}
      {userRole === ROLE_MANAGER && (
        <div className="col-md-12">
          <Field
            component={TagsField}
            name="activities"
            label={<Translated path="user.labels.activityType" shared />}
            dataSource={map(activities, ({ activityType, id }) => ({
              label: activityType,
              value: id,
            }))}
          />
        </div>
      )}
      <div className="col-md-6 d-flex justify-content-start">
        <Field component={CheckboxField} name="terms" isLight id="terms">
          <Translated path="labels.acceptTerms" />
        </Field>
      </div>
      <div className="col-md-6 d-flex justify-content-end">
        <Button type="primary" isLoading={isLoading}>
          <Translated path="labels.register" />
        </Button>
      </div>
    </div>
  </div>
));
