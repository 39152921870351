import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { FETCH_CURRENT_USER } from "modules/App/reducers/user/";
import { ROLE_SUPERADMIN } from "shared/constants/auth";

function* fetchCurrent(action) {
  try {
    const {
      auth: {
        data: { role },
      },
    } = yield select();
    let data = {
      name: "A",
      surname: "A",
    };
    if (role !== ROLE_SUPERADMIN) {
      const { data: currentData } = yield apiService({}).get("/user/current");
      data = currentData;
    }
    yield put({
      type: FETCH_CURRENT_USER.SUCCESS,
      payload: { data, response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: FETCH_CURRENT_USER.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(FETCH_CURRENT_USER.REQUEST, fetchCurrent);
  },
];
