import { put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import apiService, { getError } from "shared/services/api";
import { routes } from "shared/constants/routes";
import { ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";
import { USERS_EDIT, USERS_EDIT_FETCH_USER, USERS_EDIT_FETCH_MANAGERS } from "modules/Users/reducers/edit/";

function* editUser(action) {
  try {
    if (action.assignedManagerId && action.payload.role === ROLE_INSPECTOR) {
      yield apiService({}).put(`/inspector/${action.id}/manager/${action.assignedManagerId}`);
    }
    const { data } = yield apiService({}).put(`/user/${action.id}`, action.payload);
    if (action.payload.disabled) {
      yield apiService({}).put(`/user/${action.id}/disable`);
      yield put(push(routes.users.list.path));
    }
    yield put({
      type: USERS_EDIT.SUCCESS,
      payload: { data, response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: USERS_EDIT.FAILURE, payload: getError(err) });
  }
}

function* fetchUser(action) {
  try {
    let { data } = yield apiService({}).get(`/user/${action.payload.id}`);
    if (data.skillsCertifications) {
      data.skillsCertifications = JSON.parse(data.skillsCertifications);
    }
    yield put({
      type: USERS_EDIT_FETCH_USER.SUCCESS,
      payload: {
        ...data,
        location: {
          lat: data.locationLat,
          lng: data.locationLong,
        },
      },
    });
  } catch (err) {
    yield put({ type: USERS_EDIT_FETCH_USER.FAILURE, payload: getError(err) });
  }
}

function* fetchManagers(action) {
  try {
    const uri = encodeURIComponent(`filter[role]`);
    const { data } = yield apiService({}).get(`/user?${uri}=${ROLE_MANAGER}`);
    yield put({ type: USERS_EDIT_FETCH_MANAGERS.SUCCESS, payload: data.data });
  } catch (err) {
    yield put({
      type: USERS_EDIT_FETCH_MANAGERS.FAILURE,
      payload: getError(err),
    });
  }
}

function* editUserSaga() {
  yield takeEvery(USERS_EDIT.REQUEST, editUser);
}

function* fetchUserSaga() {
  yield takeEvery(USERS_EDIT_FETCH_USER.REQUEST, fetchUser);
}

function* fetchManagersSaga() {
  yield takeEvery(USERS_EDIT_FETCH_MANAGERS.REQUEST, fetchManagers);
}

export default [editUserSaga, fetchUserSaga, fetchManagersSaga];
