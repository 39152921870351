import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { ACTIVITIES_DELETE } from "modules/Activities/reducers/remove/";
import { fetchActivitiesList } from "shared/reducers/activities";

function* deleteActivity(action) {
  try {
    const { data } = yield apiService({}).delete(`/activity/${action.payload.id}`);
    yield put({ type: ACTIVITIES_DELETE.SUCCESS, payload: data });
    yield put(fetchActivitiesList());
  } catch (err) {
    yield put({ type: ACTIVITIES_DELETE.FAILURE, payload: getError(err) });
  }
}

function* deleteActivitySaga() {
  yield takeEvery(ACTIVITIES_DELETE.REQUEST, deleteActivity);
}

export default [deleteActivitySaga];
