import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { intl } from "shared/hoc/Intl";
import Navigation from "modules/App/containers/Navigation";
import HeaderProfile from "modules/App/containers/HeaderProfile";
import HeaderHelper from "modules/App/containers/HeaderHelper";
import HeaderNotifications from "modules/App/containers/HeaderNotifications";

import "./styles.scss";

const Header = () => {
  return (
    <div className="Header">
      <img src={require("assets/logo menu bymr.png")} alt="" className="Header__logo" />
      <Navigation />
      <div className="Header__right">
        <HeaderHelper />
        <HeaderNotifications />
        <HeaderProfile />
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(intl("modules.App.Header"), connect(mapStateToProps, mapDispatchToProps))(Header);
