import React, { PureComponent } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { Link } from "react-router-dom";
import get from "lodash/get";

import Button from "shared/components/Button";
import { routes } from "shared/constants/routes";
import Notification from "shared/components/Notification";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import AuthTitle from "modules/Auth/components/AuthTitle";
import AuthTextField from "modules/Auth/components/AuthTextField";
import { performResetPassword, performTokenValidation } from "modules/Auth/reducers/resetPassword";
import formValidation from "./formValidation";
import "./styles.scss";
import RotateLoader from "shared/components/RotateLoader";

const FORM_NAME = "auth/RESET_PASSWORD";

class ResetPassword extends PureComponent {
  componentDidMount() {
    const token = get(this.props, "match.params.token");
    this.props.performTokenValidation({ token });
  }

  performForgot = () => {
    const token = get(this.props, "match.params.token");
    this.props.performResetPassword({
      password: this.props.formValues.password,
      token,
      email: this.props.email,
    });
  };

  render() {
    const {
      handleSubmit,
      isLoading,
      submitSucceeded,
      submitFailed,
      isLoaded,
      hasError,
      response,
      isTokenWrong,
      isTokenValid,
      isVerifying,
      isLoginIn,
    } = this.props;
    return (
      <div className="ResetPassword">
        <AuthTitle>
          <Translated path={"title"} />
        </AuthTitle>
        {isVerifying && <RotateLoader relative />}
        {isTokenWrong && (
          <div>
            <Translated path={"expired"} />
          </div>
        )}
        {isTokenValid && (
          <form onSubmit={handleSubmit(this.performForgot)}>
            <Field
              component={AuthTextField}
              name="password"
              type="password"
              placeholder={this.props.lang.password}
              tooltip={
                <div className="RegisterForm__password-tooltip">
                  <h4>
                    <Translated path="passwordTooltip.title" />
                  </h4>
                  <ul>
                    <li>
                      <Translated path="passwordTooltip.subTitle1" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle2" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle3" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle4" />
                    </li>
                  </ul>
                </div>
              }
            />
            <Field
              component={AuthTextField}
              type="password"
              name="confirmPassword"
              placeholder={this.props.lang.confirmPassword}
              tooltip={
                <div className="RegisterForm__password-tooltip">
                  <h4>
                    <Translated path="passwordTooltip.title" />
                  </h4>
                  <ul>
                    <li>
                      <Translated path="passwordTooltip.subTitle1" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle2" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle3" />
                    </li>
                    <li>
                      <Translated path="passwordTooltip.subTitle4" />
                    </li>
                  </ul>
                </div>
              }
            />
            <Button type="primary" fullWidth isLoading={isLoading || isLoginIn}>
              <Translated path={"submit"} />
            </Button>
            <Link className="ResetPassword__login" to={routes.auth.login.path}>
              <Translated path={"login"} />
            </Link>
            {(submitSucceeded || submitFailed) && (
              <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
                {<Translated path={response} />}
              </Notification>
            )}
          </form>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ auth, ...state }) => ({
  isLoading: auth.resetPassword.states.isLoading,
  isLoginIn: auth.login.states.isLoading,
  isLoaded: auth.resetPassword.states.isLoaded,
  hasError: auth.resetPassword.states.hasError,
  response: auth.resetPassword.response,
  formValues: getFormValues(FORM_NAME)(state),
  isVerifying: auth.resetPassword.verify.states.isLoading,
  isTokenValid: auth.resetPassword.verify.states.isLoaded,
  email: auth.resetPassword.verify.data.email,
  isTokenWrong: auth.resetPassword.verify.states.hasError,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ performResetPassword, performTokenValidation }, dispatch);
export default compose(
  intl("modules.Auth.ResetPassword"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(ResetPassword);
