import get from "lodash/get";
import storeJs from "store";
import { initialize } from "shared/reducers/lang";
import { DEFAULT_LANG, LANG_STORE_KEY } from "shared/constants/lang";
import { getBrowserLang, isLangPossible } from "shared/services/lang";

const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export default (store) => (next) => (action) => {
  if (action.type !== LOCATION_CHANGE) {
    return next(action);
  }
  if (!store.getState().lang.initialized) {
    const savedLang = get(storeJs.get(LANG_STORE_KEY), "lang");
    const browserLang = getBrowserLang();
    const lang = savedLang || browserLang;
    store.dispatch(
      initialize({
        lang: isLangPossible(lang) ? lang : DEFAULT_LANG,
      })
    );
  }

  return next(action);
};
