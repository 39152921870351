import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import Translated from "shared/components/Translated";
import FormButtons from "shared/components/FormButtons";
import { performUserRemove } from "modules/Users/reducers/remove";
import "./styles.scss";

class DeleteUserModal extends PureComponent {
  performRemove = () => {
    this.props.performUserRemove({ id: this.props.data.id });
  };

  render() {
    const { onCancel, isLoading, data } = this.props;
    return (
      <div className="DeleteUserModal">
        <div className="DeleteUserModal__paragraph">
          <Translated path="areYouSure" />
          {data.name} {data.surname}?
          <Translated path="canNotUndo" />
        </div>

        <FormButtons>
          <Button type="red" isLoading={isLoading} onClick={this.performRemove}>
            <Translated path="shared.buttons.yesRemove" />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="shared.buttons.cancel" />
          </Button>
        </FormButtons>
      </div>
    );
  }
}
const mapStateToProps = ({ users, ...state }) => ({
  isLoading: users.remove.states.isLoading,
  hasError: users.remove.states.hasError,
  isLoaded: users.remove.states.isLoaded,
  response: users.remove.response,
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performUserRemove }, dispatch);

export default compose(
  intl("modules.Users.DeleteUserModal"),
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteUserModal);
