import update from "immutability-helper";
import store from "store";
import get from "lodash/get";
import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";
import { STORAGE_AUTH_KEY } from "shared/constants/auth";
import { AUTH_PERFORM_LOGIN } from "modules/Auth/reducers/login";
export const FETCH_AUTH_USER = createRequestTypes("auth/FETCH_AUTH_USER");

export const performLogin = () => ({
  type: FETCH_AUTH_USER.REQUEST,
});
const auth = store.get(STORAGE_AUTH_KEY);
const initState = {
  token: get(auth, "access_token") || "",
  refresh_token: get(auth, "refresh_token") || "",
  role: get(auth, "role") || "",
  user: {
    fetch: loadingStates,
    data: {},
    response: "",
  },
};

export default createReducer(initState, {
  [AUTH_PERFORM_LOGIN.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        token: action.payload.access_token,
        role: action.payload.role,
        refresh_token: action.payload.refresh_token,
      },
    });
  },
  [FETCH_AUTH_USER.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        user: { fetch: setLoadingStates({ isLoading: true }) },
      },
    });
  },
  [FETCH_AUTH_USER.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        user: {
          fetch: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [FETCH_AUTH_USER.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        user: {
          fetch: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
