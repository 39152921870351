import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { FETCH_NOTIFICATIONS, MARK_ALL_NOTIFICATIONS_AS_READ } from "modules/App/reducers/notifications/";

function* fetchNotifications(action) {
  try {
    const { data } = yield apiService({}).get("/notification?sort.field=createdAt&sort.direction=ASC", action.payload);
    yield put({
      type: FETCH_NOTIFICATIONS.SUCCESS,
      payload: { data, response: "responses.success" },
    });
  } catch (err) {
    yield put({ type: FETCH_NOTIFICATIONS.FAILURE, payload: getError(err) });
  }
}

function* fetchNotificationsSaga() {
  yield takeEvery(FETCH_NOTIFICATIONS.REQUEST, fetchNotifications);
}

function* markAllAsRead(action) {
  try {
    const { data } = yield apiService({}).put("/notification/read", action.payload);
    yield put({
      type: MARK_ALL_NOTIFICATIONS_AS_READ.SUCCESS,
      payload: { data, response: "responses.success" },
    });
  } catch (err) {
    yield put({
      type: MARK_ALL_NOTIFICATIONS_AS_READ.FAILURE,
      payload: getError(err),
    });
  }
}

function* markAllAsReadSaga() {
  yield takeEvery(MARK_ALL_NOTIFICATIONS_AS_READ.REQUEST, markAllAsRead);
}

export default [fetchNotificationsSaga, markAllAsReadSaga];
