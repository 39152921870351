import { combineReducers } from "redux";

import absence from "./absence";
import activities from "./activities";
import requestDetails from "./requests/requestDetails";
import requestsListWithDetails from "./requests/requestsListWithDetails";

export default combineReducers({
  absence,
  activities,
  requestDetails,
  requestsListWithDetails,
});
