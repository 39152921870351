import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const ACTIVITIES_CREATE_NEW = createRequestTypes("activities/CREATE_NEW");

export const performActivityCreation = ({ activityType }) => ({
  type: ACTIVITIES_CREATE_NEW.REQUEST,
  payload: {
    activityType,
  },
});

const initState = {
  states: loadingStates,
  response: null,
};

export default createReducer(initState, {
  [ACTIVITIES_CREATE_NEW.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [ACTIVITIES_CREATE_NEW.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
      },
    });
  },
  [ACTIVITIES_CREATE_NEW.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
