import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import map from "lodash/map";
import get from "lodash/get";
import TextField from "shared/components/TextField";
import FormButtons from "shared/components/FormButtons";
import ConfirmModal from "shared/components/ConfirmModal";
import Notification from "shared/components/Notification";
import Translated, { langLabel } from "shared/components/Translated";
import TagsField from "shared/components/TagsField";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import { routes } from "shared/constants/routes";
import SingleFormPage from "shared/components/SingleFormPage";
import { Phone, Duplicate, Trash } from "shared/components/Icons";
import { fetchActivitiesList } from "shared/reducers/activities";
import { fetchCurrentProfile, performUserEdit } from "modules/Account/reducers/profile";
import formValidation from "./formValidation";
import { ROLE_MANAGER, ROLE_INSPECTOR, ROLE_CONTRACTOR, ROLE_ADMIN } from "shared/constants/auth";
import ProfileNavigationPopUp from "modules/Account/components/ProfileNavigationPopUp";
import { copyToClipboard } from "shared/utils/api.js";
import { removeIcsUrl, getIcsUrl, createIcsUrl } from "modules/Account/reducers/icsUrl";
import Modal from "shared/components/Modal";
import DeleteIcsUrlModal from "modules/Account/components/DeleteIcsUrlModal";
import "./styles.scss";

export const FORM_NAME = "account/INVITE_USER";

class ProfileForm extends PureComponent {
  state = {
    confirmModal: false,
    deleteModal: false,
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
    this.props.fetchCurrentProfile();
    if (this.props.role === ROLE_MANAGER || this.props.role === ROLE_INSPECTOR || this.props.role === ROLE_CONTRACTOR)
      this.props.getIcsUrl();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  toggleModal = (key) => (e) => {
    this.setState({ [key]: !this.state[key] });
  };

  performEdit = () => {
    const { id } = this.props.match.params;
    this.setState({ confirmModal: false });
    this.props.performUserEdit({ ...this.props.formValues, id });
  };

  onCancel = () => this.props.history.push(routes.app.default.path);

  onCloseIcsDelete = () => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ deleteModal: false });
  };

  onOkIcsDelete = () => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.removeIcsUrl();
    this.setState({ deleteModal: false });
    // document.location.reload();
  };

  createIcs = () => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.createIcsUrl();
    // document.location.reload();
  };

  render() {
    const {
      isLoading,
      handleSubmit,
      submitSucceeded,
      submitFailed,
      isLoaded,
      hasError,
      response,
      activities,
      isSaving,
      formValues,
      role,
      icsUrl,
    } = this.props;

    return (
      <SingleFormPage title={<Translated path="title" />} backTo={routes.app.default.path} isLoading={isLoading}>
        <ConfirmModal
          title={<Translated path="confirmChanges" />}
          isOpen={this.state.confirmModal}
          paragraph={<Translated path="areYouSure" />}
          onClose={this.toggleModal("confirmModal")}
          onConfirm={this.performEdit}
          confirmLabel={<Translated path="buttons.save" />}
          cancelLabel={<Translated path="buttons.cancel" />}
        />
        <Modal
          title={<Translated path="deleteIcsLink" />}
          isOpen={this.state.deleteModal}
          onClose={this.onCloseIcsDelete()}
        >
          <DeleteIcsUrlModal onOk={this.onOkIcsDelete()} onCancel={this.onCloseIcsDelete()} />
        </Modal>
        <div align="center">
          {(role === ROLE_MANAGER || role === ROLE_INSPECTOR || role === ROLE_CONTRACTOR) &&
            (!icsUrl || 0 === icsUrl.length) && (
              <Button
                icon="plus"
                type="default"
                noMargin
                onClick={this.createIcs()}
                className="ProfilIcsLinkButtons"
                data-rh={langLabel("createIcsLink", this.props)}
              >
                <strong>
                  <Translated path="icsLink" />
                </strong>
              </Button>
            )}
          {(role === ROLE_MANAGER || role === ROLE_INSPECTOR || role === ROLE_CONTRACTOR) &&
            icsUrl &&
            0 < icsUrl.length && (
              <div className="BoxICalendar">
                <Duplicate
                  style={{ cursor: "pointer" }}
                  className="UsersList__icon"
                  onClick={copyToClipboard(icsUrl)}
                  data-rh={langLabel("copyToClipboard", this.props) + " - " + icsUrl}
                />
                <strong>
                  <Translated path="icsLink" />
                </strong>
                <Trash
                  style={{ cursor: "pointer" }}
                  className="UsersList__icon"
                  onClick={this.toggleModal("deleteModal")}
                  data-rh={langLabel("deleteIcsLink", this.props)}
                />
              </div>
            )}
        </div>
        <form onSubmit={handleSubmit(this.toggleModal("confirmModal"))}>
          <div className="row">
            <div className="col-md-12">
              <Field component={TextField} label={<Translated path="user.labels.name" />} placeholder="" name="name" />
            </div>
            <div className="col-md-12">
              <Field
                component={TextField}
                label={<Translated path="user.labels.surname" />}
                placeholder=""
                name="surname"
              />
            </div>
            <div className="col-md-12">
              <Field
                component={TextField}
                label={<Translated path="user.labels.email" />}
                placeholder=""
                disabled
                name="email"
              />
            </div>
          </div>
          <Field
            component={TextField}
            label={<Translated path="user.labels.phone" />}
            placeholder=""
            name="phoneNumber"
            iconLeft={<Phone />}
          />
          {get(formValues, "role") === ROLE_MANAGER && (
            <Field
              component={TagsField}
              name="activities"
              label={<Translated path="labels.activityType" />}
              dataSource={map(activities, ({ activityType, id }) => ({
                label: activityType,
                value: id,
              }))}
              disabled
              placeholder={"Search for"}
            />
          )}
          <FormButtons>
            <Button type="primary" isLoading={isSaving}>
              <Translated path="buttons.save" />
            </Button>
            <Button type="white" buttonType="button" onClick={this.onCancel}>
              <Translated path="buttons.cancel" />
            </Button>
          </FormButtons>
          {(submitSucceeded || submitFailed) && (
            <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
              {<Translated path={response} />}
            </Notification>
          )}
        </form>
        <div className="popUpWrapper">
          <ProfileNavigationPopUp icon="paper" to="https://www.mobenrooster.com/mentions-legales">
            <Translated path="legalMentions" />
          </ProfileNavigationPopUp>
          {/* #58n8jh remove this link, code commented for a future reversal
          <ProfileNavigationPopUp
            icon="paper"
            to="https://www.mobenrooster.com/donnees-personnelles"
          >
            <Translated path="privacyPolicy" />
          </ProfileNavigationPopUp>
           */}
          {role === ROLE_ADMIN && (
            <ProfileNavigationPopUp icon="paper" to="https://www.mobenrooster.com/conditions-utilisation">
              <Translated path="qualityCharter" />
            </ProfileNavigationPopUp>
          )}
        </div>
      </SingleFormPage>
    );
  }
}

const mapStateToProps = ({ auth, account: { profile, icsUrl }, shared, ...state }) => ({
  role: auth.data.role,
  isSaving: profile.edit.states.isLoading,
  isLoading:
    profile.edit.states.isLoading ||
    profile.user.states.isLoading ||
    icsUrl.getIcsUrl.states.isLoading ||
    icsUrl.createIcsUrl.states.isLoading ||
    icsUrl.removeIcsUrl.states.isLoading,
  icsUrl: icsUrl.data, // icsUrl.getIcsUrl.data,
  hasError: profile.edit.states.hasError,
  isLoaded: profile.edit.states.isLoaded,
  response: profile.edit.response,
  formValues: getFormValues(FORM_NAME)(state),
  activities: shared.activities.data.rows,
  data: profile.user.data,
  initialValues: {
    ...profile.user.data,
    activities: map(profile.user.data.activities, (activity) => activity.id),
  },
});
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      performUserEdit,
      fetchActivitiesList,
      fetchCurrentProfile,
      removeIcsUrl,
      getIcsUrl,
      createIcsUrl,
    },
    dispatch
  );

export default compose(
  intl("modules.Account.Profile"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(ProfileForm);
