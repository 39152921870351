import React from "react";
import { ArrowRight } from "shared/components/Icons";
import classnames from "classnames";
import map from "lodash/map";
import "./styles.scss";

const Pagination = ({ pages, currentPage, offset = 3, onPageChange, props }) => {
  const pageArray = [];
  for (let i = 1; i <= pages; i++) {
    const isLastPage = pages === i;
    const isFirstPage = i === 0;
    if (i < currentPage - offset && !isFirstPage && !isLastPage) {
      continue;
    }
    if (i > currentPage + offset && !isFirstPage && !isLastPage) {
      continue;
    }
    pageArray.push(i);
  }
  const pageChange = (page) => () => onPageChange(page);
  return (
    <div className="Pagination">
      {currentPage > 1 && (
        <button className="Pagination__previous" onClick={pageChange(currentPage - 1)}>
          <ArrowRight />
        </button>
      )}
      {map(pageArray, (page) => (
        <button
          className={classnames("Pagination__page", {
            "Pagination__page--active": currentPage === page,
          })}
          onClick={pageChange(page)}
          key={`pagination-${page}`}
        >
          {page}
        </button>
      ))}
      {currentPage < pages && (
        <button className="Pagination__continue" onClick={pageChange(currentPage + 1)}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
};
export default Pagination;
