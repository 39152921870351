import React, { PureComponent } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import get from "lodash/get";
import forEach from "lodash/forEach";
import queryString from "qs";
import TextField from "shared/components/TextField";
import SelectField from "shared/components/SelectField";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_PENDING_MANAGER,
  REQUEST_STATUS_PENDING_SUBCONTRACTOR,
  REQUEST_STATUS_CANCELLED,
  REQUEST_STATUS_DECLINED,
  REQUEST_STATUS_DONE,
  REQUEST_STATUS_ASSIGNED,
  REQUEST_STATUS_ASSIGNED_AS_DRAFT,
} from "shared/constants/request";
import { intl } from "shared/hoc/Intl";
import { performRequestFetch } from "modules/Requests/reducers/list";
import "./styles.scss";

const FORM_NAME = "users/LIST_SEARCH";

class ListSearch extends PureComponent {
  constructor() {
    super();
    this.performChange = debounce(this.performSearch, 400);
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    forEach(parsed, (value, key) => {
      this.props.change(key, value);
    });
  }

  performSearch = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(this.props.formValues),
    });
    this.props.performRequestFetch({
      ...this.props.formValues,
      pager: { page: 1, limit: this.props.filters.pager.limit },
    });
  };

  render() {
    const { lang, sharedLang } = this.props;

    return (
      <div className="RequestsListSearch">
        <div className="row d-flex justify-content-end">
          <div className="col-md-6">
            <Field
              component={SelectField}
              label={lang.status}
              labelLeft
              noMargin
              onChange={this.performChange}
              name="filter[status]"
              dataSource={[
                {
                  value: "",
                  label: get(sharedLang, "common.all"),
                },
                {
                  value: REQUEST_STATUS_DRAFT,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_DRAFT}`),
                },
                {
                  value: REQUEST_STATUS_PENDING_MANAGER,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_PENDING_MANAGER}`),
                },
                {
                  value: REQUEST_STATUS_PENDING_SUBCONTRACTOR,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_PENDING_SUBCONTRACTOR}`),
                },
                {
                  value: REQUEST_STATUS_ASSIGNED,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_ASSIGNED}`),
                },
                {
                  value: REQUEST_STATUS_ASSIGNED_AS_DRAFT,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_ASSIGNED_AS_DRAFT}`),
                },
                {
                  value: REQUEST_STATUS_CANCELLED,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_CANCELLED}`),
                },
                {
                  value: REQUEST_STATUS_DECLINED,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_DECLINED}`),
                },
                {
                  value: REQUEST_STATUS_DONE,
                  label: get(sharedLang, `request.status.${REQUEST_STATUS_DONE}`),
                },
              ]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={TextField}
              placeholder={lang.searchPlaceholder}
              name="query"
              noMargin
              icon="scope"
              onChange={this.performChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = ({ users, ...state }) => ({
  filters: users.list.filters,
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: {
    query: "",
    filter: {
      status: "",
    },
  },
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performRequestFetch }, dispatch);
export default compose(
  intl("modules.Requests.ListSearch"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME })
)(ListSearch);
