import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./styles.scss";

const Button = ({
  children,
  onClick,
  isLoading,
  uppercase,
  type,
  fullWidth,
  icon,
  noMargin,
  buttonType,
  className,
  small,
  heading,
  ...props
}) => {
  const buttonClasses = classnames("Button", {
    [`Button--${type}`]: type,
    "Button--uppercase": uppercase,
    "Button--full-width": fullWidth,
    "Button--no-margin": noMargin,
    "Button--heading": heading,
    "Button--small": small,
    [className]: className,
  });
  const loaderClasses = classnames({
    Button__loader: true,
    "Button__loader--active": isLoading,
    "Button__loader--primary": type === "default",
  });

  return (
    <button className={buttonClasses} onClick={onClick} {...props} type={buttonType}>
      {icon && <img src={require(`assets/icons/${icon}.svg`)} alt="icon" className="Button__icon" />}
      {children}
      <span className={loaderClasses} />
    </button>
  );
};

Button.defaultProps = {
  type: "default",
  buttonType: "submit",
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  icon: PropTypes.oneOf(["plus", "paper", "csv", "xls", "plus_black", "save"]),
  uppercase: PropTypes.bool,
  type: PropTypes.oneOf(["red", "blue", "yellow", "white", "default", "primary"]),
  fullWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  buttonType: PropTypes.oneOf(["submit", "button"]),
};

export default Button;
