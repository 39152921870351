import { put, takeEvery, select } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { FETCH_KPI } from "modules/Dashboard/reducers/kpi/";
import { ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";

function* fetchKpi(action) {
  const { startDate, endDate } = action.payload;
  const {
    auth: {
      data: { role },
    },
  } = yield select();
  const urlMapping = {
    [ROLE_CONTRACTOR]: "contractor",
    [ROLE_MANAGER]: "manager",
    [ROLE_INSPECTOR]: "inspector",
  };
  try {
    const { data } = yield apiService({}).get(`/kpi/${urlMapping[role]}?startDate=${startDate}&endDate=${endDate}`);
    yield put({
      type: FETCH_KPI.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: FETCH_KPI.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(FETCH_KPI.REQUEST, fetchKpi);
  },
];
