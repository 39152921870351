import update from "immutability-helper";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const REQUESTS_FETCH = createRequestTypes("request/FETCH_REQUESTS");
export const REQUESTS_FILTER_CLEAR = "REQUESTS_FILTER_CLEAR";

export const performRequestFetch = ({ pager, query, sort, filter }) => ({
  type: REQUESTS_FETCH.REQUEST,
  payload: {
    filter,
    pager,
    query,
    sort,
  },
});

export const requestClearFilter = () => ({
  type: REQUESTS_FILTER_CLEAR,
});

const initState = {
  states: loadingStates,
  data: {
    rows: [],
    total: 0,
  },
  filters: {
    filter: {
      role: "",
      status: "",
    },
    pager: {
      page: 1,
      limit: 10,
    },
    query: "",
    sort: {
      field: "suggestedDateBegin",
      direction: "DESC",
    },
  },
  response: null,
};
const checkIfExists = (val) => !!(identity(val) || val === "");

export default createReducer(initState, {
  [REQUESTS_FILTER_CLEAR]: (state, action) => {
    return initState;
  },
  [REQUESTS_FETCH.REQUEST]: (state, action) => {
    const filters = update(state.filters, {
      $merge: {
        ...pickBy(action.payload, checkIfExists),
      },
    });
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
        filters,
      },
    });
  },
  [REQUESTS_FETCH.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [REQUESTS_FETCH.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
