import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
// import { DropTarget } from 'react-dnd';
import classnames from "classnames";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import { intl } from "shared/hoc/Intl";
import { moment } from "shared/services/date";
import CalendarHeading from "shared/components/CalendarHeading";
import Translated from "shared/components/Translated";
import { REQUEST_STATUS_ASSIGNED_AS_DRAFT } from "shared/constants/request";
import { fetchInspectors, assignInspector, unAssignInspector } from "modules/Planning/reducers/inspectors";
import { approveRequests } from "modules/Planning/reducers/requests";
import CalendarHeadingButtons from "modules/Planning/components/CalendarHeadingButtons";
import CalendarLoader from "modules/Planning/components/CalendarLoader";
import ConfirmModal from "shared/components/ConfirmModal";
import { routes } from "shared/constants/routes";
import RequestDetails from "shared/containers/RequestDetails";
import ExtFullCalendar from "shared/containers/ExtFullCalendar";

import "./styles.scss";

class Calendar extends PureComponent {
  constructor(props) {
    super(props);
    const currentDate = moment();
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;
    // y = w.innerHeight || e.clientHeight || g.clientHeight;
    this.windowWidth = x;
    const { id } = props.match.params;
    this.state = {
      detailsModal: !!id,
      onDropModal: false,
      onDropModalData: {},
      currentDate,
      startWeek: moment(currentDate).utc().startOf("isoWeek"),
      endWeek: moment(currentDate).utc().endOf("isoWeek"),
      inspectorsFiltered: [],
      searchValue: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { startWeek, endWeek } = this.state;
    this.props.fetchInspectors({
      startDate: startWeek.utc().format(),
      endDate: endWeek.utc().format(),
    });
    this.setState({
      inspectorsFiltered: this.props.inspectors,
      searchValue: "",
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.searchValue === "") {
      this.setState({
        inspectorsFiltered: nextProps.inspectors,
      });
    } else {
      this.setState({
        inspectorsFiltered: nextProps.inspectors.filter((i) =>
          (i.name + " " + i.surname).toLowerCase().includes(this.state.searchValue.toLowerCase())
        ),
      });
    }
  }

  onClose = () => {
    this.props.history.push(routes.planning.default.path);
    this.setState({ detailsModal: false });
  };

  onEventDelete = ({ requestId, inspectorId }) => {
    this.props.unAssignInspector({ requestId, inspectorId });
  };

  onEventValidate = ({ requestId }) => {
    this.props.approveRequests({ ids: [requestId] });
  };

  removeRequestInRequestsList = (draggedEl) => {
    draggedEl.parentNode.removeChild(draggedEl);
  };

  onDrop = (draggedEl, request, dateStr, isDrop, viewType) => {
    let plannedDateBegin, plannedDateEnd;
    let suggestedDateBegin = moment.parseZone(request.suggestedDateBegin, "YYYY-MM-DD HH:mm:ssZZ");
    let suggestedDateEnd = moment.parseZone(request.suggestedDateEnd, "YYYY-MM-DD HH:mm:ssZZ");
    let inspectorId =
      request.inspectorUserId === undefined || request.inspectorUserId === null
        ? request.inspectorId
        : request.inspectorUserId;
    if (isDrop || request.status === "PENDING_MANAGER") {
      // Compute the duration of the planned event
      let duration = moment.duration(suggestedDateEnd.diff(suggestedDateBegin));
      // Compute the new range according to the drop date
      plannedDateBegin = moment.parseZone(dateStr, "YYYY-MM-DD HH:mm:ssZZ");
      plannedDateEnd = moment(moment(plannedDateBegin).add(duration));
    } else {
      // When a request is dropped on the month view, assign the suggested date
      if (viewType === "resourceTimelineMonth") {
        let duration = moment.duration(suggestedDateEnd.diff(suggestedDateBegin));

        // Compute the new range according to the drop date
        plannedDateBegin = moment.parseZone(dateStr, "YYYY-MM-DD HH:mm:ssZZ");
        plannedDateBegin = plannedDateBegin.set({
          hour: moment.parseZone(request.suggestedDateBegin).hour(),
          minute: moment.parseZone(request.suggestedDateBegin).minute(),
        });
        plannedDateEnd = moment(moment(plannedDateBegin).add(duration));
      } else {
        plannedDateBegin = request.plannedDateBegin;
        plannedDateEnd = request.plannedDateEnd;
      }
    }
    const data = {
      requestId: request.id,
      inspectorId: inspectorId,
      plannedDateBegin: plannedDateBegin,
      plannedDateEnd: plannedDateEnd,
      draggedEl: draggedEl,
      isDrop: isDrop,
    };
    if (suggestedDateBegin.diff(plannedDateBegin) === 0 && suggestedDateEnd.diff(plannedDateEnd) === 0) {
      this.props.assignInspector(data);
      if (isDrop) {
        this.removeRequestInRequestsList(draggedEl);
      }
    } else {
      this.setState({ onDropModal: true, onDropModalData: data });
    }
  };

  assignInspectorFromModal = () => {
    this.props.assignInspector(this.state.onDropModalData);
    if (this.state.onDropModalData.isDrop) this.removeRequestInRequestsList(this.state.onDropModalData.draggedEl);
    this.setState({ onDropModal: false, onDropModalData: {} });
  };

  toggleModal = () => {
    this.setState({
      onDropModal: !this.state.onDropModal,
      onDropModalData: {},
    });
    const { startWeek, endWeek } = this.state;
    this.props.fetchInspectors({
      startDate: startWeek.utc().format(),
      endDate: endWeek.utc().format(),
    });
    this.props.refresh();
    return false;
  };

  setDateRange = (dateRange) => {
    this.setState({
      startWeek: moment(dateRange.start),
      endWeek: moment(dateRange.end),
    });
    this.props.fetchInspectors({
      startDate: this.state.startWeek.utc().format(),
      endDate: this.state.endWeek.utc().format(),
    });
  };

  handleSearchChange(e) {
    let newInspectorsList = [];
    this.setState({
      searchValue: e.target.value,
    });
    newInspectorsList = this.props.inspectors.filter((i) => this.isInspectorInSearchFilter(i, e.target.value));
    this.setState({
      inspectorsFiltered: newInspectorsList,
    });
  }

  isInspectorInSearchFilter(inspector, searchFilter) {
    let res = false;
    if (searchFilter.length > 0) {
      let splitted = searchFilter.split(/;|,/g);
      splitted.forEach((item) => {
        if ((inspector.name + " " + inspector.surname).toLowerCase().includes(item.toLowerCase())) {
          res = true;
        }
      });
    } else {
      res = true;
    }
    return res;
  }

  prepareInspectorRequest = (inspectorsFiltered) => {
    var requestList = [];
    inspectorsFiltered.forEach((inspector) => {
      inspector.requests.forEach((request) => {
        request["inspectorId"] = inspector.userId;
        requestList.push(request);
      });
    });
    return requestList;
  };

  render() {
    // inspectors is replaced by inspectorsFiltered (for the search input)
    const { isOver, isLoading, dataRequestsPending } = this.props;
    const { id } = this.props.match.params;
    const { inspectorsFiltered } = this.state;
    const classes = classnames("PlanningCalendar", {
      "PlanningCalendar--dragging": isOver,
    });

    //const requestList = inspectorsFiltered.map(inspector => inspector.requests ).flat();
    const requestList = this.prepareInspectorRequest(inspectorsFiltered);
    const requestsLength = filter(
      flatMap(inspectorsFiltered, (inspector) => inspector.requests),
      (request) => (request ? request.status === REQUEST_STATUS_ASSIGNED_AS_DRAFT : false)
    ).length;

    return (
      <div className={classes}>
        <RequestDetails isOpen={this.state.detailsModal} onClose={this.onClose} id={id} history={this.props.history} />
        <ConfirmModal
          title={<Translated path="dropConfirm.title" />}
          isLoading={false}
          isOpen={this.state.onDropModal}
          paragraph={<Translated path="dropConfirm.paragraph" />}
          onClose={this.toggleModal}
          onConfirm={this.assignInspectorFromModal}
          confirmLabel={<Translated path="buttons.confirm" />}
          cancelLabel={<Translated path="buttons.cancel" />}
        />
        <CalendarLoader isLoading={isLoading} />
        <CalendarHeading lang={this.props.lang} handleSearchChange={this.handleSearchChange}>
          <CalendarHeadingButtons requestsLength={requestsLength} />
        </CalendarHeading>
        <ExtFullCalendar
          requestsList={requestList}
          onDrop={this.onDrop}
          inspectorList={inspectorsFiltered}
          history={this.props.history}
          setDateRangeFunction={this.setDateRange}
          dataRequestsPending={dataRequestsPending}
        />
      </div>
    );
  }
}

const parseSkillsCertifications = (inspectorsList) => {
  inspectorsList.forEach((inspector) => {
    if (inspector.skillsCertifications && typeof inspector.skillsCertifications === "string") {
      inspector.skillsCertifications = JSON.parse(inspector.skillsCertifications);
    }
  });
  return inspectorsList;
};

const mapStateToProps = ({ planning: { inspectors, requests } }) => ({
  inspectors: parseSkillsCertifications(inspectors.data.rows),
  isLoading:
    inspectors.states.isLoading ||
    requests.pending.states.isLoading ||
    inspectors.assign.states.isLoading ||
    inspectors.unassign.states.isLoading ||
    requests.approve.states.isLoading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ assignInspector, fetchInspectors, unAssignInspector, approveRequests }, dispatch);

export default compose(intl("modules.Planning.Calendar"), connect(mapStateToProps, mapDispatchToProps))(Calendar);
