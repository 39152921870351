import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { PENDING_REQUESTS_FETCH } from "modules/Planning/reducers/requests/";
import { APPROVE_REQUESTS } from "modules/Planning/reducers/types";
import { objToParams } from "shared/utils/api";
import forEach from "lodash/forEach";
import join from "lodash/join";
import flatMap from "lodash/flatMap";
import { callFetchRequest } from "shared/services/request";

function* approveRequests(action) {
  try {
    const { data } = yield apiService({}).put(`/request/${join(action.payload.ids, ",")}/approve`);

    yield put({
      type: APPROVE_REQUESTS.SUCCESS,
      payload: flatMap(data, (row) => row.id),
    });
  } catch (err) {
    yield put({ type: APPROVE_REQUESTS.FAILURE, payload: getError(err) });
  }
}
function* fetchList(action) {
  try {
    const { filter, sort, pager } = action.payload;
    const params = {};
    forEach(sort, (value, key) => (params[`sort.${key}`] = value));
    forEach(pager, (value, key) => (params[`pager.${key}`] = value));
    forEach(filter, (value, key) => (value ? (params[encodeURIComponent(`filter[${key}]`)] = value) : null));

    const queryParams = objToParams(params);
    const data = yield callFetchRequest(queryParams);
    yield put({
      type: PENDING_REQUESTS_FETCH.SUCCESS,
      payload: {
        rows: data.data,
        total: data.total,
      },
    });
  } catch (err) {
    yield put({ type: PENDING_REQUESTS_FETCH.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(PENDING_REQUESTS_FETCH.REQUEST, fetchList);
  },
  function* () {
    yield takeEvery(APPROVE_REQUESTS.REQUEST, approveRequests);
  },
];
