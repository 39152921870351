import React from "react";
import includes from "lodash/includes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { intl } from "shared/hoc/Intl";
import { routes } from "shared/constants/routes";
import { NavContext } from "shared/context/nav";
import Translated from "shared/components/Translated";
import NavigationItem from "modules/App/components/NavigationItem";
import { ROLE_SUPERADMIN, ROLE_ADMIN, ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR } from "shared/constants/auth";
import "./styles.scss";

const Navigation = ({ activeRoute, role }) => {
  return (
    <div className="Navigation">
      <NavContext.Provider value={{ activeRoute }}>
        {includes([ROLE_CONTRACTOR, ROLE_MANAGER], role) && (
          <NavigationItem to={routes.requests.create.path} className="newRequest">
            <img src={require(`assets/icons/plus.svg`)} alt="icon" className="Button__icon" />
            <span className="create-new-text">
              <Translated path="createNew" />
            </span>
          </NavigationItem>
        )}
        {includes([ROLE_ADMIN], role) && [
          <NavigationItem to={routes.users.default.path} key="nav-users">
            <Translated path="users" />
          </NavigationItem>,
          <NavigationItem to={routes.activities.default.path} key="nav-activities">
            <Translated path="activities" />
          </NavigationItem>,
          <NavigationItem to={routes.counties.default.path} key="admin-counties">
            <Translated path="counties" />
          </NavigationItem>,
        ]}
        {includes([ROLE_MANAGER], role) && (
          <NavigationItem to={routes.planning.default.path}>
            <Translated path="planning" />
          </NavigationItem>
        )}
        {includes([ROLE_INSPECTOR], role) && (
          <NavigationItem to={routes.calendar.default.path}>
            <Translated path="calendar" />
          </NavigationItem>
        )}
        {includes([ROLE_CONTRACTOR, ROLE_MANAGER, ROLE_INSPECTOR], role) && (
          <NavigationItem to={routes.requests.default.path} className="step-request-history-link">
            <Translated path="requests" />
          </NavigationItem>
        )}
        {includes([ROLE_MANAGER, ROLE_CONTRACTOR, ROLE_INSPECTOR], role) && (
          <NavigationItem to={routes.dashboard.default.path} exactActive className="step-dashboard-link">
            <Translated path="dashboard" />
          </NavigationItem>
        )}
        {role === ROLE_SUPERADMIN && [
          <NavigationItem to={routes.companies.default.path} key="admin-companies">
            <Translated path="companies" />
          </NavigationItem>,
        ]}
      </NavContext.Provider>
    </div>
  );
};
const mapStateToProps = ({ router, auth }) => ({
  role: auth.data.role,
  activeRoute: router.location.pathname,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(intl("modules.App.Navigation"), connect(mapStateToProps, mapDispatchToProps))(Navigation);
