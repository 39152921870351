import React from "react";
import "./styles.scss";

export default ({ title, subTitle, children, right }) => (
  <div className="DashboardBox">
    <div className="DashboardBox__title">
      {title}
      {subTitle && <h4 className="DashboardBox__subtitle">{subTitle}</h4>}
    </div>
    {right && <div className="DashboardBox__right">{right}</div>}
    <div className="DashboardBox__content">{children}</div>
  </div>
);
