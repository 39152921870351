import React from "react";
import "./styles.scss";

const ProfileNavigationPopUp = ({ children, to, icon }) => {
  return (
    <a rel="noopener noreferrer" className="HeaderProfileNavigationPopUp" href={to} target="_blank">
      <img src={require(`assets/icons/${icon}.svg`)} alt="Profile" className="HeaderProfileNavigationItem__icon" />
      {children}
    </a>
  );
};

export default ProfileNavigationPopUp;
