import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

const HeaderProfileNavigationItem = ({ children, icon, localStorageRoute }) => {
  return (
    <Link
      to={localStorageRoute[0]}
      className="HeaderProfileNavigationItem"
      onClick={() => {
        const TourManager = JSON.parse(localStorage.getItem("TourManager"));
        let tourManagerOnClose = TourManager;
        tourManagerOnClose[localStorageRoute[0]][localStorageRoute[1]][localStorageRoute[2]] = false;
        localStorage.setItem("TourManager", JSON.stringify(tourManagerOnClose));
      }}
    >
      {/* <img
                src={require(`assets/icons/${icon}.svg`)}
                alt="Profile"
                className="HeaderProfileNavigationItem__icon"
            /> */}

      {children}
    </Link>
  );
};

export default HeaderProfileNavigationItem;
