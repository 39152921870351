import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";
import { REQUEST_STATUS_CANCELLED } from "shared/constants/request";

export const REQUESTS_FETCH_FEEDBACK = createRequestTypes("shared/request/REQUESTS_FETCH_FEEDBACK");

export const REQUESTS_MODIFY_FEEDBACK = createRequestTypes("shared/request/REQUESTS_MODIFY_FEEDBACK");

export const REQUESTS_FETCH_DETAILS = createRequestTypes("shared/request/REQUESTS_FETCH_DETAILS");

export const REQUESTS_FETCH_ONE = createRequestTypes("shared/request/REQUESTS_FETCH_ONE");

export const REQUESTS_CANCEL_REQUEST = createRequestTypes("shared/request/REQUESTS_CANCEL_REQUEST");
export const REQUESTS_DELETE_REQUEST = createRequestTypes("shared/request/REQUESTS_DELETE_REQUEST");
export const REQUESTS_DECLINE_REQUEST = createRequestTypes("shared/request/REQUESTS_DECLINE_REQUEST");

export const performFetchRequestFeedback = ({ id }) => ({
  type: REQUESTS_FETCH_FEEDBACK.REQUEST,
  payload: {
    id,
  },
});

export const modifyRequestFeedback = (feedbackFormValues) => ({
  type: REQUESTS_MODIFY_FEEDBACK.REQUEST,
  payload: {
    feedbackFormValues,
  },
});

export const performFetchOneRequest = ({ id }) => ({
  type: REQUESTS_FETCH_ONE.REQUEST,
  payload: {
    id,
  },
});

export const performFetchRequestWithHistory = ({ id }) => ({
  type: REQUESTS_FETCH_DETAILS.REQUEST,
  payload: {
    id,
  },
});

export const deleteRequest = ({ id }) => ({
  type: REQUESTS_DELETE_REQUEST.REQUEST,
  payload: {
    id,
  },
});
export const declineRequest = ({ id }) => ({
  type: REQUESTS_DECLINE_REQUEST.REQUEST,
  payload: {
    id,
  },
});

export const cancelRequest = ({ id }) => ({
  type: REQUESTS_CANCEL_REQUEST.REQUEST,
  payload: {
    id,
  },
});

const initState = {
  states: loadingStates,
  response: null,
  data: {},
  delete: {
    states: loadingStates,
    response: null,
  },
  decline: {
    states: loadingStates,
    response: null,
  },
  cancel: {
    states: loadingStates,
    response: null,
  },
  feedback: {
    feedbackData: {},
    states: loadingStates,
    response: null,
  },
};

export default createReducer(initState, {
  [REQUESTS_FETCH_FEEDBACK.REQUEST]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_FETCH_FEEDBACK.SUCCESS]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          feedbackData: action.payload,
        },
      },
    });
  },
  [REQUESTS_FETCH_FEEDBACK.FAILURE]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          feedbackData: {},
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_MODIFY_FEEDBACK.REQUEST]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_MODIFY_FEEDBACK.SUCCESS]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          feedbackData: action.payload,
          response: "request.modal.feedbackSuccess",
        },
      },
    });
  },
  [REQUESTS_MODIFY_FEEDBACK.FAILURE]: (state, action) => {
    return update(state, {
      feedback: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          feedbackData: {},
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_FETCH_ONE.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [REQUESTS_FETCH_ONE.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [REQUESTS_FETCH_ONE.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
  [REQUESTS_FETCH_DETAILS.REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [REQUESTS_FETCH_DETAILS.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [REQUESTS_FETCH_DETAILS.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
  [REQUESTS_CANCEL_REQUEST.REQUEST]: (state, action) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_CANCEL_REQUEST.SUCCESS]: (state, action) => {
    return update(state, {
      data: {
        $set: {
          ...state.data,
          status: REQUEST_STATUS_CANCELLED,
        },
      },
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REQUESTS_CANCEL_REQUEST.FAILURE]: (state, action) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_DELETE_REQUEST.REQUEST]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_DELETE_REQUEST.SUCCESS]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REQUESTS_DELETE_REQUEST.FAILURE]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REQUESTS_DECLINE_REQUEST.REQUEST]: (state, action) => {
    return update(state, {
      decline: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REQUESTS_DECLINE_REQUEST.SUCCESS]: (state, action) => {
    return update(state, {
      decline: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REQUESTS_DECLINE_REQUEST.FAILURE]: (state, action) => {
    return update(state, {
      decline: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
