import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm, getFormValues } from "redux-form";
import map from "lodash/map";
import get from "lodash/get";
import includes from "lodash/includes";
import TextField from "shared/components/TextField";
import GoogleField from "shared/components/GoogleField";
import ConfirmModal from "shared/components/ConfirmModal";
import IosCheckboxField from "shared/components/IosCheckboxField";
import SelectField from "shared/components/SelectField";
import FormButtons from "shared/components/FormButtons";
import Notification from "shared/components/Notification";
import Translated from "shared/components/Translated";
import TagsField from "shared/components/TagsField";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import { routes } from "shared/constants/routes";
import SingleFormPage from "shared/components/SingleFormPage";
import { USER_ROLES } from "shared/constants/auth";
import { Phone, User } from "shared/components/Icons";
import { fetchActivitiesList } from "shared/reducers/activities";
import { performUserEdit, fetchUser, fetchManagers } from "modules/Users/reducers/edit";
import formValidation from "./formValidation";
import { SingleFormSectionTitle } from "shared/components/SingleFormPage";
import {
  ROLE_CONTRACTOR,
  ROLE_MANAGER,
  ROLE_INSPECTOR,
  USER_STATUS_PENDING,
  USER_STATUS_REGISTERED,
  USER_STATUS_DISABLED,
} from "shared/constants/auth";
import { VOID_COUNTY } from "shared/constants/county";

export const FORM_NAME = "users/INVITE_USER";

const skillsCertificationsForm = ({ fields }) => {
  return (
    <div className="orgsWrapper">
      {fields.map((skillsCertifications, index) => (
        <div className="formOrgWrapper" key={index}>
          <div className="col-md-12 col-sm-12">
            <Field
              component={TextField}
              name={`${skillsCertifications}`}
              label={<Translated path="user.labels.skillCertificationName" shared />}
              placeholder=""
            />
          </div>
          <span className="TrashWrapper" onClick={() => fields.remove(index)}>
            <img src={require(`assets/icons/trash.svg`)} alt="Profile" className="TrashButton"></img>
          </span>
        </div>
      ))}
      <div className="AddWrapper">
        <span className="ButtonWrapper" onClick={() => fields.push()}>
          <img src={require(`assets/icons/plus.svg`)} alt="Profile" className="PlusButton"></img>
        </span>
        <div style={{ transform: "scale(.9)" }}>{<Translated path="user.labels.addSkillCertification" shared />}</div>
      </div>
    </div>
  );
};

class EditUserForm extends PureComponent {
  state = {
    blocked: false,
    confirmModal: false,
  };

  componentDidMount() {
    this.props.fetchActivitiesList();
    this.props.fetchUser({ id: this.props.match.params.id });
    this.props.fetchManagers();
  }

  componentDidUpdate(prevProps) {
    if (includes([USER_STATUS_PENDING], this.props.data.status) && !this.state.blocked) {
      this.setState({ blocked: true });
    }
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  performEdit = () => {
    if (this.props.formValues.disabled) {
      this.toggleModal();
      return;
    }
    this.edit();
  };

  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
    return false;
  };

  edit = () => {
    const { id } = this.props.match.params;
    this.props.performUserEdit({ ...this.props.formValues, id });
    if (this.props.formValues.disabled) {
      this.toggleModal();
    }
  };

  onCancel = (role) => {
    if (role === ROLE_MANAGER) {
      this.props.history.push(routes.planning.default.path);
    } else {
      this.props.history.push(routes.users.list.path);
    }
  };

  render() {
    const {
      isLoading,
      handleSubmit,
      submitSucceeded,
      submitFailed,
      isLoaded,
      hasError,
      response,
      activities,
      isSaving,
      formValues,
      managers,
      sharedLang,
      data,
      role,
    } = this.props;

    if (this.state.blocked) {
      return "Not possible to perform action";
    }

    // let assignManagerList = managers;
    let assignManagerList = managers.filter((manager) => manager.status === USER_STATUS_REGISTERED);

    // WorkAround, the county is calculated by Google, but when you edit an user, the async calculation is longer than the refresh of the component
    // if the manager user exist, take the county value from the column county in the table manager from DB
    // if the user is new or the GoogleField is empty, the user is forced to validate the input address and this workaround is without effect
    let initCounty;
    if (this.props.initialValues && this.props.initialValues.county) {
      initCounty = this.props.initialValues.county;
    } else {
      initCounty = VOID_COUNTY;
    }
    return (
      <SingleFormPage
        title={<Translated path="user.modal.title" />}
        backTo={role === ROLE_MANAGER ? routes.planning.default.path : routes.users.default.path}
        isLoading={isLoading}
      >
        <ConfirmModal
          title={
            data.status === USER_STATUS_DISABLED ? (
              <Translated path="confirmChangesEnable" shared />
            ) : (
              <Translated path="confirmChangesDisable" />
            )
          }
          paragraph={
            data.status === USER_STATUS_DISABLED ? (
              <Translated path="areYouSureEnable" shared />
            ) : (
              <Translated path="areYouSureDisable" />
            )
          }
          isOpen={this.state.confirmModal}
          onClose={this.toggleModal}
          onConfirm={this.edit}
          confirmLabel={<Translated path="buttons.save" />}
          cancelLabel={<Translated path="buttons.cancel" />}
        />
        <form onSubmit={handleSubmit(this.performEdit)}>
          <div className="row">
            <div className="col-md-12">
              <Field
                component={TextField}
                label={<Translated path="user.labels.name" shared />}
                placeholder=""
                name="name"
              />
            </div>
            <div className="col-md-12">
              <Field
                component={TextField}
                label={<Translated path="user.labels.surname" shared />}
                placeholder=""
                name="surname"
              />
            </div>
            <div className="col-md-12">
              <Field
                component={TextField}
                label={<Translated path="user.labels.email" shared />}
                placeholder=""
                name="email"
              />
            </div>
          </div>
          <Field
            component={TextField}
            label={<Translated path="user.labels.phone" shared />}
            placeholder=""
            name="phoneNumber"
            iconLeft={<Phone />}
          />
          <Field
            component={SelectField}
            label="Role"
            iconLeft={<User />}
            placeholder={get(sharedLang, "user.placeholders.selectRole")}
            name="role"
            disabled
            dataSource={USER_ROLES(sharedLang)}
          />
          {get(formValues, "role") === ROLE_MANAGER && (
            <Field
              component={GoogleField}
              name="location"
              label={<Translated path="user.labels.location" shared />}
              initCounty={initCounty}
            />
          )}
          {get(formValues, "role") === ROLE_MANAGER && (
            <Field
              component={TagsField}
              name="activities"
              label={<Translated path="user.labels.activityType" shared />}
              dataSource={map(activities, ({ activityType, id }) => ({
                label: activityType,
                value: id,
              }))}
              placeholder={<Translated path="user.labels.searchActivity" shared />}
            />
          )}

          {get(formValues, "role") === ROLE_INSPECTOR && (
            <div>
              <Field
                component={SelectField}
                label={<Translated path="user.labels.manager" shared />}
                iconLeft={<User />}
                placeholder={get(sharedLang, "user.labels.selectManager")}
                name="assignedManagerId"
                dataSource={assignManagerList}
              />
              <SingleFormSectionTitle>
                <Translated path="user.labels.skillsCertifications" shared />
              </SingleFormSectionTitle>
              <FieldArray name="skillsCertifications" component={skillsCertificationsForm} />
              <div style={{ height: "40px" }} />
            </div>
          )}

          {get(formValues, "role") === ROLE_CONTRACTOR && (
            <Field
              component={TextField}
              label={<Translated path="user.labels.contractorCompanyName" shared />}
              name="contractorCompany"
            />
          )}

          <Field
            component={IosCheckboxField}
            label={
              data.status === USER_STATUS_DISABLED ? (
                <Translated path="user.labels.enabled" shared />
              ) : (
                <Translated path="user.labels.disabled" shared />
              )
            }
            name="disabled"
          />
          <FormButtons>
            <Button type="primary" isLoading={isSaving}>
              <Translated path="buttons.save" shared />
            </Button>
            <Button type="white" buttonType="button" onClick={() => this.onCancel(role)}>
              <Translated path="buttons.cancel" shared />
            </Button>
          </FormButtons>
          {(submitSucceeded || submitFailed) && (
            <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
              {<Translated path={response} />}
            </Notification>
          )}
        </form>
      </SingleFormPage>
    );
  }
}
const mapStateToProps = ({ auth, users, shared, ...state }) => ({
  managers: map(users.edit.managers.data, (manager) => ({
    value: manager.id,
    label: `${manager.name} ${manager.surname}`,
    status: manager.status,
  })),
  role: auth.data.role,
  isSaving: users.edit.states.isLoading,
  isLoading: users.edit.states.isLoading || users.edit.user.states.isLoading,
  hasError: users.edit.states.hasError,
  isLoaded: users.edit.states.isLoaded,
  response: users.edit.response,
  formValues: getFormValues(FORM_NAME)(state),
  activities: shared.activities.data.rows,
  data: users.edit.user.data,
  initialValues: {
    ...users.edit.user.data,
    activities: map(users.edit.user.data.activities, (activity) => activity.id),
  },
});
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ performUserEdit, fetchActivitiesList, fetchUser, fetchManagers }, dispatch);

export default compose(
  intl("modules.Users.Edit"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate: formValidation,
    enableReinitialize: true,
  })
)(EditUserForm);
