import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes } from "shared/utils/request";

export const ACTIVITIES_DELETE = createRequestTypes("activities/DELETE");

export const performActivityRemoval = ({ id }) => ({
  type: ACTIVITIES_DELETE.REQUEST,
  payload: {
    id,
  },
});

const initState = {
  states: loadingStates,
  response: null,
};

export default createReducer(initState, {
  [ACTIVITIES_DELETE.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [ACTIVITIES_DELETE.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
      },
    });
  },
  [ACTIVITIES_DELETE.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
