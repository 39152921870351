import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { REGISTER_CREATE_ACCOUNT } from "modules/Register/reducers/create/";
import { performLogin } from "modules/Auth/reducers/login";

function* createAccount(action) {
  try {
    const { data } = yield apiService({}).put("/user/registration", action.payload);

    yield put(
      performLogin({
        username: action.additional.email,
        password: action.payload.password,
        remember: true,
      })
    );
    yield put({
      type: REGISTER_CREATE_ACCOUNT.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: REGISTER_CREATE_ACCOUNT.FAILURE,
      payload: getError(err),
    });
  }
}

function* createAccountSaga() {
  yield takeEvery(REGISTER_CREATE_ACCOUNT.REQUEST, createAccount);
}

export default [createAccountSaga];
