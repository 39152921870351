import React, { PureComponent } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import TextField from "shared/components/TextField";
import SelectField from "shared/components/SelectField";
import FormButtons from "shared/components/FormButtons";
import Notification from "shared/components/Notification";
import Translated from "shared/components/Translated";
import { Mail, User } from "shared/components/Icons";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import { USER_ROLES } from "shared/constants/auth";
import { performUserInvitation } from "modules/Users/reducers/invite";
import formValidation from "./formValidation";
export const FORM_NAME = "users/INVITE_USER";

class InviteUserForm extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && this.props.isLoaded) {
      this.props.onCancel();
    }
  }

  performInvite = () => {
    this.props.performUserInvitation(this.props.formValues);
  };

  render() {
    const {
      onCancel,
      isLoading,
      handleSubmit,
      submitSucceeded,
      submitFailed,
      isLoaded,
      hasError,
      response,
      formValues,
      sharedLang,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.performInvite)}>
        <Field
          component={TextField}
          label={<Translated path="user.labels.email" shared />}
          placeholder={get(sharedLang, "user.placeholders.email")}
          name="email"
          iconLeft={<Mail />}
        />

        <Field
          component={SelectField}
          label={<Translated path="user.labels.role" shared />}
          iconLeft={<User />}
          placeholder={get(sharedLang, "user.placeholders.selectRole")}
          name="role"
          dataSource={USER_ROLES(sharedLang)}
        />
        {get(formValues, "role") === "CONTRACTOR" && (
          <Field
            component={TextField}
            label={<Translated path="user.labels.contractorCompanyName" shared />}
            name="contractorCompany"
          />
        )}
        <FormButtons>
          <Button type="primary" isLoading={isLoading}>
            <Translated path="buttons.create" shared />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </FormButtons>
        {(submitSucceeded || submitFailed) && (
          <Notification isLoaded={isLoaded} isLoading={isLoading} hasError={hasError}>
            {<Translated path={"responses." + response} />}
          </Notification>
        )}
      </form>
    );
  }
}
const mapStateToProps = ({ users, ...state }) => ({
  isLoading: users.invite.states.isLoading,
  hasError: users.invite.states.hasError,
  isLoaded: users.invite.states.isLoaded,
  response: users.invite.response,
  initialValues: {},
  formValues: getFormValues(FORM_NAME)(state),
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performUserInvitation }, dispatch);

export default compose(
  intl("modules.Users.InviteUserForm"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME, validate: formValidation })
)(InviteUserForm);
