import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import {
  ACTIVITIES_DETAILS_FETCH_MANAGERS,
  ACTIVITIES_DETAILS_ADD_ACTIVITY,
  ACTIVITIES_DETAILS_DELETE_ACTIVITY,
  fetchManagers,
} from "modules/Activities/reducers/details/";

function* addActivityToManager(action) {
  try {
    const { data } = yield apiService({}).put(
      `/manager/${action.payload.managerId}/add-activity/${action.payload.activityId}`
    );
    yield put({
      type: ACTIVITIES_DETAILS_ADD_ACTIVITY.SUCCESS,
      payload: data,
    });
    yield put(fetchManagers({ activityId: action.payload.activityId }));
  } catch (err) {
    yield put({
      type: ACTIVITIES_DETAILS_ADD_ACTIVITY.FAILURE,
      payload: getError(err),
    });
  }
}
function* deleteActivityFromManager(action) {
  try {
    const { data } = yield apiService({}).put(
      `/manager/${action.payload.managerId}/remove-activity/${action.payload.activityId}`
    );
    yield put(fetchManagers({ activityId: action.payload.activityId }));
    yield put({
      type: ACTIVITIES_DETAILS_DELETE_ACTIVITY.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ACTIVITIES_DETAILS_DELETE_ACTIVITY.FAILURE,
      payload: getError(err),
    });
  }
}
function* fetchManagersSaga(action) {
  try {
    const { data } = yield apiService({}).get(`/manager/with-activity-check/${action.payload.activityId}`);
    yield put({
      type: ACTIVITIES_DETAILS_FETCH_MANAGERS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ACTIVITIES_DETAILS_FETCH_MANAGERS.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(ACTIVITIES_DETAILS_FETCH_MANAGERS.REQUEST, fetchManagersSaga);
  },
  function* () {
    yield takeEvery(ACTIVITIES_DETAILS_ADD_ACTIVITY.REQUEST, addActivityToManager);
  },
  function* () {
    yield takeEvery(ACTIVITIES_DETAILS_DELETE_ACTIVITY.REQUEST, deleteActivityFromManager);
  },
];
