import includes from "lodash/includes";
import get from "lodash/get";
import { getInitialsFromName, stringToHslColor } from "./avatar";
import { ROLE_INSPECTOR, ROLE_MANAGER, ROLE_CONTRACTOR } from "shared/constants/auth";
import {
  NOTIFICATION_REQUEST_APPROVED,
  NOTIFICATION_REQUEST_CANCELED,
  NOTIFICATION_REQUEST_EDITED,
} from "shared/constants/notification";
import { redirections } from "./request";

const shouldRedirect = (type, role) => {
  if (!includes([NOTIFICATION_REQUEST_CANCELED, NOTIFICATION_REQUEST_APPROVED], type) && role === ROLE_MANAGER) {
    return true;
  }
  if (!includes([], type) && role === ROLE_CONTRACTOR) {
    return true;
  }
  if (includes([NOTIFICATION_REQUEST_APPROVED, NOTIFICATION_REQUEST_EDITED], type) && role === ROLE_INSPECTOR) {
    return true;
  }
  return false;
};

export const getNotificationBodyByType = (type, payload, role) => {
  var name = get(payload, "createdByName");
  var surname = get(payload, "createdBySurname");
  return {
    initials: getInitialsFromName(name, surname),
    avatar: { color: stringToHslColor(name, surname) },
    text: `${type}.${role}`,
    to: shouldRedirect(type, role) ? redirections({ requestId: get(payload, "requestId") })[role] : null,
    nameInitial: get(name, "[0]") || "",
    surname: surname,
    role: role,
    requestId: get(payload, "requestId"),
    contractorCompany: get(payload, "contractorCompany"),
    suggestedDateBegin: get(payload, "suggestedDateBegin"),
    suggestedDateEnd: get(payload, "suggestedDateEnd"),
  };
};
