import { put, takeEvery } from "redux-saga/effects";
import apiService, { getError } from "shared/services/api";
import { DEFAULT_COUNTY_FETCH_LIST } from "modules/Counties/reducers/defaultCounty/";

function* fetchDefaultCounty() {
  try {
    const { data } = yield apiService({}).get("/county-rule/default");
    yield put({
      type: DEFAULT_COUNTY_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: DEFAULT_COUNTY_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

function* fetchDefaultCountySaga() {
  yield takeEvery(DEFAULT_COUNTY_FETCH_LIST.REQUEST, fetchDefaultCounty);
}

export default [fetchDefaultCountySaga];
