import React, { PureComponent } from "react";
import { intl } from "shared/hoc/Intl";
import Translated from "shared/components/Translated";
import Button from "shared/components/Button";
import ValidateRequests from "modules/Planning/containers/ValidateRequests";
import { replaceValues } from "shared/services/lang";
import { compose } from "redux";

import "./styles.scss";

class PlanningCalendarHeadingButtons extends PureComponent {
  state = {
    validateModal: false,
  };

  componentDidMount() {}

  toggleModal = (key) => (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ [key]: !this.state[key] });
  };

  onCloseValidate = () => {
    this.setState({ validateModal: false });
  };

  render() {
    const { requestsLength, lang } = this.props;
    return (
      <div className="PlanningCalendarHeadingButtons">
        <ValidateRequests isOpen={this.state.validateModal} onClose={this.onCloseValidate} />
        <span className="PlanningCalendarHeadingButtons__count">
          <strong>{replaceValues(lang.requests, { requests: requestsLength })}</strong> <Translated path="toValidate" />
        </span>
        <Button
          type="primary"
          noMargin
          onClick={this.toggleModal("validateModal")}
          className="PlanningCalendarHeadingButtons__button"
        >
          <Translated path="validateAll" />
        </Button>
      </div>
    );
  }
}

export default compose(intl("modules.Planning.CalendarHeadingButtons"))(PlanningCalendarHeadingButtons);
