// !!!!! Warning !!!!!
// Some of these colors are duplicated in the js file : src/assets/styles/_colors.css
// If you modify a value here, do not forget to report the modification in the other file

// $color-primary-light: #cbf4e8;
// $color-primary: #29fabd;
// $color-primary2: #00ab91;
// $color-primary-medium: #51dbb3;
// $color-primary-dark: #42c19a;

// $color-orange: #f99e44;
// $color-yellow: #f9c665;
// $color-light-red: #f13a55;
// $color-red: #cb354b;

// $color-light-blue: #5acff9;
// $color-blue: #69baed;
// $color-dark-blue: #78a4e2;
// $color-light-violet: #8790d5;
// $color-violet: #a465be;
// $color-dark-violet: #b67fa5;

// $color-white: white;
// $color-lighter-grey: #f2f2f2;
// $color-light-grey: #dddddd;
// $color-medium-grey: #9c9c9c;
// $color-dark-grey: #7783a1;
// $color-darker-grey: #626262;
// $color-black: black;

export const orangeCol = "#f99e44"; // $color-orange
export const darkGreyCol = "#7783a1"; // $color-dark-grey
export const darkerGreyCol = "#626262"; // $color-darker-grey
export const greyFilterCol = "#a9b5c7"; // $color-grey-filter
export const blueCol = "#69baed"; // $color-blue: #69baed;
export const lightRedCol = "#f13a55"; // $color-light-red
export const whiteCol = "#ffffff"; // $color-white: white;
