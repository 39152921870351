import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextField from "shared/components/TextField";
import DayPicker from "react-day-picker";
//import Datetime from "react-datetime";
import Moment from "moment";
//import { moment } from "shared/services/date";
import { DATE_FORMAT } from "shared/services/date";
import "./styles.scss";

class DateField extends PureComponent {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {}

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  handleDayClick = (day) => {
    this.setState({ isOpen: false });
    this.props.input.onChange(day);
  };

  // The ISO 8601 syntax (YYYY-MM-DD) is also the preferred JavaScript date format
  reverseDateISO8601 = (dateStr) => {
    // for the new convocation, the date format is already ISO8601
    let regexp = /[0-9]{4}-/;
    let index = dateStr.search(regexp);
    if (index === 0) {
      return dateStr;
    }
    // Otherwise application use DD/MM/YYYY format and new Date() use the "YYYY-MM-DD"
    else {
      return dateStr.substring(6) + "-" + dateStr.substring(3, 5) + "-" + dateStr.substring(0, 2);
    }
  };

  render() {
    const { originalDate, dataSource, ...props } = this.props;

    const classes = classnames("DateField", "", {});
    let value = this.props.input.value;
    if (typeof value === "string" || value instanceof String) {
      if (value === "Invalid Date" || value.length === 0) {
        value = originalDate;
      } else {
        value = this.reverseDateISO8601(value);
      }
    }
    value = new Date(value);
    // const value = this.props.input.value ? new Date(this.props.input.value) : "";
    const visibleValue = value instanceof Date ? Moment(value).format(DATE_FORMAT) : value;
    const input = { ...props.input, value: visibleValue };
    const selectedDay = value instanceof Date ? value : new Date();

    return (
      <div className={classes}>
        <TextField {...props} readOnly input={input} onClick={this.toggleOpen} ref="test" />
        {this.state.isOpen && (
          <div className="DateField__calendar">
            <DayPicker firstDayOfWeek={1} onDayClick={this.handleDayClick} selectedDays={selectedDay} />
          </div>
        )}
      </div>
    );
  }
}

DateField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
};

export default DateField;
