import update from "immutability-helper";

import { createReducer } from "shared/utils/redux";
import { loadingStates, setLoadingStates } from "shared/constants/redux";
import { createRequestTypes, arrayToIds } from "shared/utils/request";
import { cleanPhoneNumber } from "shared/utils/validation.js";

export const USERS_EDIT = createRequestTypes("users/EDIT");
export const USERS_EDIT_FETCH_USER = createRequestTypes("users/FETCH_USER");
export const USERS_EDIT_FETCH_MANAGERS = createRequestTypes("users/EDIT_FETCH_MANAGERS");

export const fetchManagers = () => ({
  type: USERS_EDIT_FETCH_MANAGERS.REQUEST,
});

export const fetchUser = ({ id }) => ({
  type: USERS_EDIT_FETCH_USER.REQUEST,
  payload: {
    id,
  },
});

export const performUserEdit = ({
  id,
  name,
  surname,
  contractorCompany,
  disabled,
  activities,
  role,
  phoneNumber,
  assignedManagerId,
  location,
  email,
  skillsCertifications,
}) => ({
  type: USERS_EDIT.REQUEST,
  payload: {
    name,
    surname,
    contractorCompany,
    disabled,
    role,
    activities: arrayToIds(activities),
    phoneNumber: cleanPhoneNumber(phoneNumber),
    userId: id,
    locationLat: location.lat,
    locationLong: location.lng,
    locationText: location.name,
    county: location.county,
    email,
    skillsCertifications: skillsCertifications ? JSON.stringify(skillsCertifications) : JSON.stringify([]),
  },
  assignedManagerId,
  id,
});

const initState = {
  response: null,
  states: loadingStates,
  user: {
    data: {},
    states: loadingStates,
  },
  managers: {
    data: [],
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [USERS_EDIT_FETCH_MANAGERS.REQUEST]: (state) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [USERS_EDIT_FETCH_MANAGERS.SUCCESS]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [USERS_EDIT_FETCH_MANAGERS.FAILURE]: (state, action) => {
    return update(state, {
      managers: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [USERS_EDIT_FETCH_USER.REQUEST]: (state) => {
    return update(state, {
      user: {
        $merge: {
          data: {},
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [USERS_EDIT_FETCH_USER.SUCCESS]: (state, action) => {
    return update(state, {
      user: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [USERS_EDIT_FETCH_USER.FAILURE]: (state, action) => {
    return update(state, {
      user: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [USERS_EDIT.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [USERS_EDIT.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response,
      },
    });
  },
  [USERS_EDIT.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
