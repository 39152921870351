import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { intl } from "shared/hoc/Intl";
import filter from "lodash/filter";
import map from "lodash/map";
import flatMap from "lodash/flatMap";
import { moment } from "shared/services/date";
import { getNotificationBodyByType } from "shared/utils/notifications";
import { markAllAsRead } from "modules/App/reducers/notifications";
import HeaderNotification from "modules/App/components/HeaderNotification";
import "./styles.scss";
import Translated from "shared/components/Translated";

const HeaderNotifications = ({ notifications, markAllAsRead, role }) => {
  const unReadNotifications = filter(notifications, (notification) => !notification.displayed);

  const markAllNotificationsAsRead = () => {
    markAllAsRead({ ids: flatMap(unReadNotifications, ({ id }) => id) });
  };

  const markNotificationAsRead = (id) => () => {
    markAllAsRead({ ids: [id] });
  };
  // unReadNotifications.length = 150;
  return (
    <div className="HeaderNotifications">
      <div className="HeaderNotifications__icon">
        <img src={require("assets/icons/notification.svg")} className="" alt="Notifications" />
        {unReadNotifications.length > 0 && (
          <div
            className={
              unReadNotifications.length < 10
                ? "HeaderNotifications__icon__badge"
                : "HeaderNotifications__icon__badge__big"
            }
          >
            {unReadNotifications.length > 99 ? "99+" : unReadNotifications.length}
          </div>
        )}
      </div>

      <div className="HeaderNotifications__content">
        <div className="HeaderNotifications__content__header">
          <div className="HeaderNotifications__content__title">
            <Translated path="title" />
          </div>
          <button className="HeaderNotifications__content__read" onClick={markAllNotificationsAsRead}>
            <Translated path="markAllAsRead" />
          </button>
        </div>
        {map(notifications, (notification) => (
          <HeaderNotification
            body={getNotificationBodyByType(notification.type, notification.payload, role)}
            onClick={markNotificationAsRead(notification.id)}
            time={moment(notification.createdAt).fromNow()}
            isRead={notification.displayed}
            key={`app-notification-${notification.id}`}
          />
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = ({ app, auth }) => ({
  notifications: app.notifications.data,
  role: auth.data.role,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ markAllAsRead }, dispatch);

export default compose(
  intl("modules.App.HeaderNotifications"),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderNotifications);
