import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import TextField from "shared/components/TextField";
import FormButtons from "shared/components/FormButtons";
import Notification from "shared/components/Notification";
import Translated from "shared/components/Translated";
import { intl } from "shared/hoc/Intl";
import Button from "shared/components/Button";
import formValidation from "./formValidation";
import { performCountyCreation } from "modules/Counties/reducers/create";
import { VOID_COUNTY } from "shared/constants/county.js";

export const FORM_NAME = "counties/CREATE";

class CreateCountyModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.states.isLoading && this.props.states.isLoaded) {
      this.props.onCancel();
    }
    if (this.props.voidCounty === VOID_COUNTY) this.props.change("requestCounty", VOID_COUNTY);
  }

  performInvite = () => {
    this.props.performCountyCreation(this.props.formValues);
  };

  render() {
    const { onCancel, handleSubmit, submitSucceeded, submitFailed, states, response, voidCounty } = this.props;
    return (
      <form onSubmit={handleSubmit(this.performInvite)}>
        {voidCounty === undefined && (
          <Field
            component={TextField}
            label={<Translated path="county.labels.requestCounty" shared />}
            name="requestCounty"
          />
        )}
        <Field
          component={TextField}
          label={<Translated path="county.labels.managerCounty" shared />}
          name="managerCounty"
        />
        <FormButtons>
          <Button type="primary" isLoading={states.isLoading}>
            <Translated path="buttons.create" shared />
          </Button>
          <Button type="white" buttonType="button" onClick={onCancel}>
            <Translated path="buttons.cancel" shared />
          </Button>
        </FormButtons>
        {(submitSucceeded || submitFailed) && <Notification {...states}>{<Translated path={response} />}</Notification>}
      </form>
    );
  }
}

const mapStateToProps = ({ counties, ...state }) => ({
  states: counties.create.states,
  response: counties.create.response,
  formValues: getFormValues(FORM_NAME)(state),
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ performCountyCreation }, dispatch);

export default compose(
  intl("modules.Counties.CreateCountyModal"),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME, validate: formValidation })
)(CreateCountyModal);
