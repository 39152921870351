import React from "react";
import get from "lodash/get";
import { IntlContext } from "shared/hoc/Intl";
import ReactHtmlParser from "react-html-parser";

export const langLabel = (path, props) => {
  const { lang, sharedLang } = props;
  return get(lang, path) || get({ sharedLang, ...sharedLang }, path) || get(sharedLang, "error");
};

const Translated = ({ path }) => (
  <IntlContext.Consumer>
    {({ lang, shared }) => ReactHtmlParser(get(lang, path) || get({ shared, ...shared }, path) || get(shared, "error"))}
  </IntlContext.Consumer>
);

export default Translated;
