import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import lang from "shared/reducers/lang";
import auth from "modules/Auth/reducers";
import users from "modules/Users/reducers";
import requests from "modules/Requests/reducers";
import activities from "modules/Activities/reducers";
import companies from "modules/Companies/reducers";
import register from "modules/Register/reducers";
import app from "modules/App/reducers";
import planning from "modules/Planning/reducers";
import calendar from "modules/Calendar/reducers";
import dashboard from "modules/Dashboard/reducers";
import account from "modules/Account/reducers";
import counties from "modules/Counties/reducers";
import shared from "shared/reducers";

export default (history) =>
  combineReducers({
    app,
    auth,
    account,
    activities,
    dashboard,
    calendar,
    companies,
    counties,
    form: formReducer,
    lang,
    router: connectRouter(history),
    users,
    shared,
    planning,
    register,
    requests,
  });
